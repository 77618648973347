import { call, select, put, Effect } from 'redux-saga/effects'
import _ from 'lodash'

// Types
import { MainConstants } from 'store/main/types'

// Methods
import { workerCheckAuthentication, workerSignOut } from 'store/auth/sagas'
import { getLots } from 'api/lot'
import Exception from 'helpers/Exception'
import { LotConstants, Lot, LotAcessIndex } from './types'
import { LotQueryFields, LotQueryArgs } from 'api/lot/types'
import { AnimalConstants } from 'store/animal/types'

function* putLotData(payload: any) {
  yield put({
    type: LotConstants.REDUCER_SET_LOT_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetSelectedLots(action: Effect): any {
  try {
    const queryFields: LotQueryFields = {
      id: true,
      description: true,
      animalsCount: true,
      initDate: true,
      pen: {
        id: true,
        code: true
      },
      profit: {
        current: {
          predictedTime: true,
          animalsAmount: true,
          costByAnimal: true,
          profitByAnimal: true,
          revenueByAnimal: true,
          costByWeightUnity: true,
          profitByWeightUnity: true,
          revenueByWeightUnity: true,
          totalProfit: true,
          totalCost: true,
          totalRevenue: true
        },
        traditionalMethod: {
          predictedTime: true,
          animalsAmount: true,
          costByAnimal: true,
          profitByAnimal: true,
          revenueByAnimal: true,
          costByWeightUnity: true,
          profitByWeightUnity: true,
          revenueByWeightUnity: true,
          totalProfit: true,
          totalCost: true,
          totalRevenue: true
        },
        beeftraderMethod: {
          saleType: true,
          totalProfit: true,
          sale: {
            predictedTime: true,
            animalsAmount: true,
            costByAnimal: true,
            profitByAnimal: true,
            revenueByAnimal: true,
            costByWeightUnity: true,
            profitByWeightUnity: true,
            revenueByWeightUnity: true,
            totalProfit: true,
            totalCost: true,
            totalRevenue: true
          }
        }
      },
      zootechnicalData: {
        current: {
          predictedTime: true,
          animalsAmount: true,
          dwg: true,
          arrobaStock: true,
          totalFastingKg: true,
          totalFastingArroba: true,
          energeticEfficiency: true,
          feedConversion: true,
          ingestion: true,
          weight: true
        },
        traditionalMethod: {
          predictedTime: true,
          animalsAmount: true,
          dwg: true,
          arrobaStock: true,
          totalFastingKg: true,
          totalFastingArroba: true,
          energeticEfficiency: true,
          feedConversion: true,
          ingestion: true,
          weight: true
        },
        beeftraderMethod: {
          saleType: true,
          sale: {
            predictedTime: true,
            animalsAmount: true,
            dwg: true,
            arrobaStock: true,
            totalFastingKg: true,
            totalFastingArroba: true,
            energeticEfficiency: true,
            feedConversion: true,
            ingestion: true,
            weight: true
          }
        }
      },
      environmentalData: {
        current: {
          predictedTime: true,
          animalsAmount: true,
          environmentEfficiency: true,
          waterIntake: true,
          totalWaterIntake: true,
          totalmanure: true,
          totalCo2Emission: true
        },
        traditionalMethod: {
          predictedTime: true,
          animalsAmount: true,
          environmentEfficiency: true,
          waterIntake: true,
          totalWaterIntake: true,
          totalmanure: true,
          totalCo2Emission: true
        },
        beeftraderMethod: {
          saleType: true,
          sale: {
            predictedTime: true,
            animalsAmount: true,
            environmentEfficiency: true,
            waterIntake: true,
            totalWaterIntake: true,
            totalmanure: true,
            totalCo2Emission: true
          }
        }
      },
      animalsSlaughterhouse: {
        beeftraderMethod: {
          saleType: true,
          sales: {
            sale: true,
            animalsSlaughterhouse: {
              animals: true,
              slaughterhouseId: true
            }
          }
        }
      },
      averageWeight: {
        weight: true,
        day: true
      }
    }
    yield call(putLotData, { lotLoading: true })
    yield call(workerCheckAuthentication)

    const lotStore = yield select((s) => s.lot)
    let { lots, selectedLot } = lotStore
    const { feedlotId, lotId } = action.payload

    if (selectedLot && selectedLot.id == lotId) {
      yield call(putLotData, { lotLoading: false })
      return
    }

    yield put({
      type: AnimalConstants.REDUCER_CLEAR_ANIMAL_DATA
    })
    if (!lots.length) {
      const queryArgs: LotQueryArgs = {
        parameters: {
          feedlotId: feedlotId
        }
      }
      lots = []
      lots = yield call(getLots, queryFields, queryArgs)
    }
    selectedLot = undefined
    if (lots.length) selectedLot = _.find(lots, (k: Lot) => k.id === Number(lotId)) || lots[0]

    yield call(putLotData, {
      lots,
      selectedLot,
      lotLoading: false,
      lotAccessIndex: undefined
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetLotAccessIndex(): any {
  try {
    const queryFields: LotQueryFields = {
      id: true,
      animalsCount: true,
      animals: {
        id: true,
        weighing: {
          day: true,
          type: true,
          weight: true
        }
      }
    }
    yield call(putLotData, { lotAccessIndexLoading: true })
    yield call(workerCheckAuthentication)

    const feedlotStore = yield select((s) => s.feedlot)
    const { selectedFeedlot } = feedlotStore

    const lotStore = yield select((s) => s.lot)
    let { lotAccessIndex } = lotStore
    const { selectedLot, feedlotWeeksNumber } = lotStore
    let lot: Lot[]
    if (lotAccessIndex) {
      yield call(putLotData, { lotAccessIndexLoading: false })
      return
    } else {
      lotAccessIndex = []
      const queryArgs: LotQueryArgs = {
        parameters: {
          feedlotId: selectedFeedlot?.id,
          lotId: selectedLot?.id
        }
      }
      lot = yield call(getLots, queryFields, queryArgs)

      if (lot[0]?.animals?.length) {
        const lotWeeks = feedlotWeeksNumber

        for (let i = 1; i <= lotWeeks; i++) {
          lotAccessIndex[i] = { week: i, frequency: 0 }
          for (let animal = 0; animal < lot[0].animals.length; animal++) {
            const weekWeighings = lot[0].animals[animal].weighing.find(
              (w: any) => w.day >= i * 7 - 7 && w.day <= i * 7 && w.type === 'r'
            )
            if (weekWeighings) lotAccessIndex[i].frequency = lotAccessIndex[i].frequency + 1
          }
        }
      }
      lotAccessIndex = lotAccessIndex.map((l: LotAcessIndex) => ({
        ...l,
        frequency: Number((l.frequency ? (l.frequency / lot[0].animalsCount) * 100 : 0).toFixed())
      }))
    }

    lotAccessIndex.shift()
    lotAccessIndex = lotAccessIndex.filter((l: LotAcessIndex) => l.frequency !== 0)
    yield call(putLotData, {
      lotAccessIndex,
      lotAccessIndexLoading: false
    })
  } catch (error: any) {
    console.log(error)
    yield call(putLotData, {
      lotAccessIndexLoading: false
    })
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}
