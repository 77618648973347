import { put, call, select, Effect } from 'redux-saga/effects'
import _ from 'lodash'
import Exception from 'helpers/Exception'

// Types
import { MainConstants } from 'store/main/types'
import { FeedlotSlaughterhouseConstants, FeedlotSlaughterhouse } from './types'

// Methods
import { workerCheckAuthentication, workerSignOut } from 'store/auth/sagas'
import {
  createFeedlotSlaughterhouses,
  deleteFeedlotSlaughterhouses,
  getFeedlotConfiguration,
  getFeedlotSlaughterhouses,
  updateFeedlotConfiguration,
  updateFeedlotSlaughterhouses
} from 'api/feedlot-slaughterhouse'
import { EconomicConstants } from 'store/economic/types'
import {
  createFeedlotSlaughterhousePrice,
  getFeedlotSlaughterhousePrice,
  updateFeedlotSlaughterhousePrice
} from 'api/economic'
import {
  FeedlotSlaughterhousePriceCreate,
  FeedlotSlaughterhousePriceUpdate
} from 'api/economic/types'

function* putFeedlotSlaughterhouseData(payload: any) {
  yield put({
    type: FeedlotSlaughterhouseConstants.REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetFeedlotSlaughterhouses(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putFeedlotSlaughterhouseData, { feedlotSlaughterhouseLoading: true })
    yield call(workerCheckAuthentication)

    const feedlotSlaughterhouses = yield call(getFeedlotSlaughterhouses, params)
    yield call(putFeedlotSlaughterhouseData, {
      feedlotSlaughterhouses,
      feedlotSlaughterhouseLoading: false
    })
  } catch (error: any) {
    console.log(error)
    yield call(putFeedlotSlaughterhouseData, { feedlotSlaughterhouseLoading: false })
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerSaveFeedlotSlaughterhouse(action: Effect): any {
  const { argsSlaughterhouse, argsPrices, registerPrices } = action.payload
  yield call(putFeedlotSlaughterhouseData, { saving: true })
  try {
    yield call(workerCheckAuthentication)
    let newFeedlotSlaughterhouseId: number[] = []
    if (argsSlaughterhouse) {
      const { feedlotSlaughterhouses } = argsSlaughterhouse
      argsSlaughterhouse.feedlotSlaughterhouses = [argsSlaughterhouse.feedlotSlaughterhouses]

      if (feedlotSlaughterhouses.id == -1) {
        delete argsSlaughterhouse.feedlotSlaughterhouses[0].id
        newFeedlotSlaughterhouseId = yield call(createFeedlotSlaughterhouses, argsSlaughterhouse)
      } else {
        yield call(updateFeedlotSlaughterhouses, argsSlaughterhouse)
      }
    }

    if (registerPrices) {
      const feedlotSlaughterhousePriceStore = yield select((s) => s.economic)

      const { feedlotSlaughterhousePrice } = argsPrices

      if (newFeedlotSlaughterhouseId.length > 0)
        feedlotSlaughterhousePrice.forEach((item: any) => {
          item.feedlotSlaughterhouseId = newFeedlotSlaughterhouseId[0]
        })
      yield call(workerCheckAuthentication)

      const listToInsert: FeedlotSlaughterhousePriceCreate[] = []
      const listToUpdate: FeedlotSlaughterhousePriceUpdate[] = []

      feedlotSlaughterhousePrice.forEach((item: any) => {
        if (item.id == -1) {
          delete item.id
          listToInsert.push(item)
        } else {
          listToUpdate.push(item)
        }
      })

      if (listToInsert.length > 0) {
        yield call(createFeedlotSlaughterhousePrice, { feedlotSlaughterhousePrice: listToInsert })
      }
      if (listToUpdate.length > 0) {
        yield call(updateFeedlotSlaughterhousePrice, { feedlotSlaughterhousePrice: listToUpdate })
      }
    }

    yield call(putFeedlotSlaughterhouseData, { saving: false })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield call(putFeedlotSlaughterhouseData, { saving: false })
  }
}

export function* workerGetFeedlotConfiguration(action: Effect): any {
  const { args } = action.payload
  try {
    yield call(putFeedlotSlaughterhouseData, { feedlotConfigurationLoading: true })
    yield call(workerCheckAuthentication)

    const feedlotConfiguration = yield call(getFeedlotConfiguration, args)
    yield call(putFeedlotSlaughterhouseData, {
      feedlotConfiguration: feedlotConfiguration[0],
      feedlotSlaughterhouseLoading: false
    })
  } catch (error: any) {
    console.log(error)
    yield call(putFeedlotSlaughterhouseData, { feedlotConfigurationLoading: false })
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerSaveFeedlotConfiguration(action: Effect): any {
  const { args } = action.payload
  try {
    yield call(putFeedlotSlaughterhouseData, { feedlotConfigurationLoading: true })
    yield call(workerCheckAuthentication)

    yield call(updateFeedlotConfiguration, args)
    const feedlotConfiguration = yield call(getFeedlotConfiguration)

    yield call(putFeedlotSlaughterhouseData, {
      feedlotConfiguration: feedlotConfiguration[0],
      feedlotConfigurationLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield call(putFeedlotSlaughterhouseData, {
      feedlotConfigurationLoading: false
    })
  }
}

export function* workerDeleteFeedlotSlaughterhouse(action: Effect): any {
  const { ids } = action.payload
  try {
    yield call(putFeedlotSlaughterhouseData, { saving: true })
    yield call(workerCheckAuthentication)

    yield call(deleteFeedlotSlaughterhouses, { ids })

    yield call(putFeedlotSlaughterhouseData, {
      saving: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield call(putFeedlotSlaughterhouseData, {
      saving: false
    })
  }
}
