import { getAnimals, getGendersQuery, getWeighingsQuery } from 'api/animal'
import {
  AnimalEconomicDailyDataFields,
  AnimalEnvironmentalDailyDataFields,
  AnimalQueryArgs,
  AnimalQueryFields,
  AnimalZootechnicalDailyDataFields,
  WeighingQueryFields
} from 'api/animal/types'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import _ from 'lodash'
import { all, call, Effect, put, select } from 'redux-saga/effects'
// Methods
import { workerCheckAuthentication, workerSignOut } from 'store/auth/sagas'
// Types
import { Lot } from 'store/lot/types'
import { MainConstants } from 'store/main/types'
import { AnimalsEconomicDaily, LotAnimalsSlaughterhouse } from 'store/profit/types'
import { Animal, AnimalConstants } from './types'

function* putAnimalData(payload: any) {
  yield put({
    type: AnimalConstants.REDUCER_SET_ANIMAL_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetAnimalGeneralFunction(action: Effect): any {
  const { args, fields } = action.payload
  try {
    yield call(putAnimalData, { animalLoading: true })
    yield call(workerCheckAuthentication)

    const zootechnicalDailyData = fields.zootechnicalDailyData
    delete fields.zootechnicalDailyData

    const zootechnicalDailyDataArgs = args.parameters.zootechnicalDailyDataArgs
    delete args.parameters.zootechnicalDailyDataArgs

    const animals = yield call(
      getAnimals,
      fields,
      args,
      undefined,
      undefined,
      zootechnicalDailyDataArgs,
      zootechnicalDailyData
    )

    yield call(putAnimalData, {
      animals,
      animalLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetSelectedAnimals(action: Effect): any {
  try {
    const queryFields: AnimalQueryFields = {
      id: true,
      earring: true,
      tag: true,
      sisbov: true,
      active: true,
      feedlotId: true,
      lotId: true,
      lastWeighingDate: true,
      initialWeight: true,
      endDate: true,
      additionalField: true,
      ultrasoundAssessment: {
        collectDate: true,
        egs: true
      },
      breed: {
        id: true,
        code: true,
        description: true
      },
      gender: {
        id: true,
        code: true,
        description: true
      },
      profit: {
        current: {
          predictedTime: true,
          dailyProfit: true,
          totalProfit: true,
          totalCost: true,
          totalRevenue: true,
          costByWeightUnity: true,
          profitByWeightUnity: true,
          revenueByWeightUnity: true,
          arrobaStock: true
        },
        beeftraderMethod: {
          saleType: true,
          sale: true,
          result: {
            predictedTime: true,
            dailyProfit: true,
            totalProfit: true,
            totalCost: true,
            totalRevenue: true,
            costByWeightUnity: true,
            profitByWeightUnity: true,
            revenueByWeightUnity: true,
            arrobaStock: true
          }
        }
      },
      zootechnicalData: {
        current: {
          predictedTime: true,
          dwg: true,
          realDwg: true,
          feedConversion: true,
          feedConversionInTime: true,
          ingestion: true,
          ingestionInTime: true,
          dailyIngestion: true,
          weight: true,
          egs: true,
          egsCategory: true
        },
        beeftraderMethod: {
          saleType: true,
          sale: true,
          result: {
            predictedTime: true,
            dwg: true,
            realDwg: true,
            feedConversion: true,
            feedConversionInTime: true,
            ingestion: true,
            ingestionInTime: true,
            dailyIngestion: true,
            weight: true,
            egs: true,
            egsCategory: true
          }
        }
      },
      environmentalData: {
        current: {
          waterIntake: true,
          co2Emission: true,
          manure: true,
          environmentEfficiency: true,
          predictedTime: true
        },
        beeftraderMethod: {
          saleType: true,
          sale: true,
          result: {
            waterIntake: true,
            co2Emission: true,
            manure: true,
            environmentEfficiency: true,
            predictedTime: true
          }
        }
      }
    }
    yield call(putAnimalData, { animalLoading: true })
    yield call(workerCheckAuthentication)

    const animalStore = yield select((s) => s.animal)
    let { animals, selectedAnimal } = animalStore
    const { lotId, feedlotId, animalId } = action.payload

    if (selectedAnimal && selectedAnimal.id == animalId) {
      yield call(putAnimalData, { animalLoading: false })
      return
    }

    if (!animals.length) {
      const queryArgs: AnimalQueryArgs = {
        parameters: {
          feedlotId: feedlotId,
          lotId: lotId
        }
      }
      animals = []
      animals = yield call(
        getAnimals,
        queryFields,
        queryArgs,
        undefined,
        undefined,
        undefined,
        undefined
      )
    }

    selectedAnimal = _.find(animals, (k: Animal) => k.id === animalId) || animals[0]
    if (!animals.length || !selectedAnimal) {
      throw new Exception(Codes.UserInteraction.FEEDLOT_NOT_FOUND, 'workerGetSelectedAnimals')
    }
    yield call(putAnimalData, {
      animals,
      selectedAnimal,
      animalLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetZootechnicalDataDailyAnimals(action: Effect): any {
  try {
    const queryFields: AnimalQueryFields = {
      id: true,
      earring: false,
      tag: false,
      sisbov: false,
      active: false,
      feedlotId: false,
      lotId: false,
      lastWeighingDate: false,
      initialWeight: false,
      endDate: false
    }

    const queryZootechnicalDailyDataFields: AnimalZootechnicalDailyDataFields = {
      dwg: true,
      weight: false,
      ingestion: true,
      feedConversion: true,
      time: true,
      date: true
    }

    yield call(putAnimalData, { animalZootechnicalDailyDataLoading: true })
    yield call(workerCheckAuthentication)
    const { lotId, feedlotId } = action.payload
    const animalStore = yield select((s) => s.animal)
    const { animalsZootechnicalDailyData } = animalStore
    if (animalsZootechnicalDailyData.length > 0) {
      yield call(putAnimalData, { animalZootechnicalDailyDataLoading: false })
      return
    }

    const resultDaily = yield call(
      getAnimals,
      queryFields,
      {
        parameters: {
          feedlotId: feedlotId,
          lotId: lotId
        }
      },
      undefined,
      undefined,
      {
        startTime: 1,
        endTime: 200,
        interval: 10
      },
      queryZootechnicalDailyDataFields
    )

    yield call(putAnimalData, {
      animalsZootechnicalDailyData: resultDaily,
      animalZootechnicalDailyDataLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetEnvironmentalDataDailyAnimals(action: Effect): any {
  try {
    const queryFields: AnimalQueryFields = {
      id: true,
      earring: false,
      tag: false,
      sisbov: false,
      active: false,
      feedlotId: false,
      lotId: false,
      lastWeighingDate: false,
      initialWeight: false,
      endDate: false
    }

    const queryEnvironmentalDailyDataFields: AnimalEnvironmentalDailyDataFields = {
      waterIntake: true,
      co2Emission: true,
      manure: true,
      predictedTime: true
    }

    yield call(putAnimalData, { animalEnvironmentalDailyDataLoading: true })
    yield call(workerCheckAuthentication)
    const { lotId, feedlotId } = action.payload

    const animalStore = yield select((s) => s.animal)
    const { animalsZootechnicalDailyData } = animalStore
    if (animalsZootechnicalDailyData.length > 0) {
      yield call(putAnimalData, { animalZootechnicalDailyDataLoading: false })
      return
    }

    const resultDaily = yield call(
      getAnimals,
      queryFields,
      {
        parameters: {
          feedlotId: feedlotId,
          lotId: lotId
        }
      },
      undefined,
      undefined,
      undefined,
      undefined,
      {
        startTime: 1,
        endTime: 200,
        interval: 10
      },
      queryEnvironmentalDailyDataFields
    )
    yield call(putAnimalData, {
      animalsEnvironmentalDailyData: resultDaily,
      animalEnvironmentalDailyDataLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetEconomicDailyAnimals(action: Effect): any {
  try {
    const queryFields: AnimalQueryFields = {
      id: true,
      earring: false,
      tag: false,
      sisbov: false,
      active: false,
      feedlotId: false,
      lotId: false,
      lastWeighingDate: false,
      initialWeight: false,
      endDate: false
    }

    const queryEconomicDailyDataFields: AnimalEconomicDailyDataFields = {
      accumulatedProfit: true,
      accumulatedCost: true,
      accumulatedRevenue: true,
      time: true
    }

    yield call(putAnimalData, { animalEconomicDailyLoading: true })
    yield call(workerCheckAuthentication)
    const { lotId, feedlotId } = action.payload
    const lotStore = yield select((s) => s.lot)
    const animalStore = yield select((s) => s.animal)
    const { animalsEconomicDaily } = animalStore
    if (animalsEconomicDaily.length > 0) {
      yield call(putAnimalData, { animalEconomicDailyLoading: false })
      return
    }

    const selectedLot: Lot = lotStore.selectedLot
    let lotAnimalsSlaughterhouse: LotAnimalsSlaughterhouse[] = []

    selectedLot.animalsSlaughterhouse?.beeftraderMethod.map((a) => {
      a.sales.map((s) => {
        s.animalsSlaughterhouse.map((animalsSlaughterhouse: LotAnimalsSlaughterhouse) => {
          const aux = lotAnimalsSlaughterhouse.find(
            (l) => l.slaughterhouseId === animalsSlaughterhouse.slaughterhouseId
          )
          if (!aux) {
            lotAnimalsSlaughterhouse.push(animalsSlaughterhouse)
          } else {
            let auxAnimals = animalsSlaughterhouse.animals
            auxAnimals = [...auxAnimals, ...aux.animals]
            auxAnimals = auxAnimals.filter((v, i, a) => a.indexOf(v) === i)

            lotAnimalsSlaughterhouse = [
              ...lotAnimalsSlaughterhouse.filter(
                (l) => l.slaughterhouseId !== animalsSlaughterhouse.slaughterhouseId
              ),
              { slaughterhouseId: aux.slaughterhouseId, animals: auxAnimals }
            ]
          }
        })
      })
    })

    const resultDaily = yield all(
      lotAnimalsSlaughterhouse.map((e) => {
        return call(
          getAnimals,
          queryFields,
          {
            parameters: {
              feedlotId: feedlotId,
              lotId: lotId,
              animalId: e.animals
            }
          },
          {
            slaughterhouseId: e.slaughterhouseId,
            startTime: 1,
            endTime: 200,
            interval: 10
          },
          queryEconomicDailyDataFields,
          undefined,
          undefined
        )
      })
    )
    const data: any[] = []
    resultDaily.map((a: AnimalsEconomicDaily[], index: number) => {
      data.push({ slaughterhouseId: lotAnimalsSlaughterhouse[index].slaughterhouseId, animals: a })
    })
    yield call(putAnimalData, {
      animalsEconomicDaily: data,
      animalEconomicDailyLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetWeighingAnimals(action: Effect): any {
  try {
    const queryFields: AnimalQueryFields = {
      id: true,
      earring: true,
      tag: true,
      active: true,
      weighing: {
        day: true,
        weight: true,
        type: true
      }
    }
    yield call(putAnimalData, { animalWeighingLoading: true })
    yield call(workerCheckAuthentication)

    const { lotId, feedlotId, pagination } = action.payload
    const TAKE = pagination ? 10 : 10000000 //Nº de animais por vez que serão buscados
    const animalStore = yield select((s) => s.animal)
    const { animalsWeighingData, animalWeighingComplete, animalsWeighingPage } = animalStore
    if (animalWeighingComplete) {
      yield call(putAnimalData, {
        animalWeighingLoading: false
      })
      return
    }
    const resultDaily = yield call(
      getAnimals,
      queryFields,
      {
        parameters: {
          feedlotId: feedlotId,
          lotId: lotId
        },
        pagination: {
          page: pagination ? animalsWeighingPage : 1,
          take: TAKE
        }
      },
      undefined,
      undefined,
      undefined,
      undefined
    )

    if (resultDaily.length < TAKE) {
      yield call(putAnimalData, {
        animalWeighingComplete: true
      })
    }
    yield call(putAnimalData, {
      animalsWeighingData: pagination ? [...animalsWeighingData, ...resultDaily] : [...resultDaily],
      animalWeighingLoading: false,
      animalsWeighingPage: animalsWeighingPage + 1,
      allWeighinsLoaded: pagination ? false : true
    })
    return
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetWeighing(action: Effect): any {
  try {
    const queryFields: WeighingQueryFields = {
      id: true,
      animalId: true,
      earring: true,
      day: true,
      weight: true,
      type: true
    }
    yield call(putAnimalData, { weighingLoading: true })
    yield call(workerCheckAuthentication)

    const { lotId, feedlotId } = action.payload
    const weighing = yield call(getWeighingsQuery, queryFields, {
      parameters: {
        feedlotId: feedlotId,
        lotId: lotId
      }
    })
    yield call(putAnimalData, {
      weighing: [...weighing],
      weighingLoading: false
    })
    return
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetGenders(action: Effect): any {
  const { fields } = action.payload
  try {
    yield call(putAnimalData, { animalGendersLoading: true })
    yield call(workerCheckAuthentication)

    const genders = yield call(getGendersQuery, fields)

    yield call(putAnimalData, {
      genders,
      animalLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    const payload = {
      message: error.getMessage()
    }
    yield call(putMainData, payload)
  }
}
