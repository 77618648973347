import Message from '../../helpers/Message'

export type MainStore = {
  isCheckingSettings: boolean
  message?: Message
  infoModal: {
    type: AlertModalTypes.info | AlertModalTypes.warning | AlertModalTypes.error
    open: boolean
    message?: string
    btnTxt?: string
    btnLink?: string
  }
  theme: Themes
  locale: Locales
  timeZone: string
  isDrawerOpen: boolean
  dataType: DataType
  columnsAnimalBasic: ColumnsCollapse
  columnsAnimalEconomic: ColumnsCollapse
  columnsAnimalCurrentEconomic: ColumnsCollapse
  columnsAnimalCurrentZootechnical: ColumnsCollapse
  columnsAnimalZootechnical: ColumnsCollapse
  columnsAnimalCurrentEnvironmental: ColumnsCollapse
  columnsAnimalEnvironmental: ColumnsCollapse
  columnsAnimalBasicOriginal: ColumnsCollapse
  columnsAnimalEconomicOriginal: ColumnsCollapse
  columnsAnimalCurrentEconomicOriginal: ColumnsCollapse
  columnsAnimalCurrentZootechnicalOriginal: ColumnsCollapse
  columnsAnimalZootechnicalOriginal: ColumnsCollapse
  columnsAnimalCurrentEnvironmentalOriginal: ColumnsCollapse
  columnsAnimalEnvironmentalOriginal: ColumnsCollapse
  columnsLot: ColumnsLot[]
}

export type ColumnsLot = {
  id: string
  category: string
  label: string
  active: boolean
}
export type InfoModal = {
  type:
    | AlertModalTypes.info
    | AlertModalTypes.warning
    | AlertModalTypes.error
    | AlertModalTypes.success
  open: boolean
  message?: string
  btnTxt?: string
  btnLink?: string
  title?: string
}

export type ColumnsCollapse = {
  order: number
  id: string
  label: any
  collapse: boolean
  columns: Columns[]
}
export type Columns = {
  order: number
  id: string
  active: boolean
  disablePadding: boolean
  type: string
  label: any
}

export enum AlertModalTypes {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success'
}

export enum Locales {
  en = 'en',
  pt = 'pt'
}

export enum UnitySystem {
  imperial = 'imperial',
  metric = 'metric'
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum DataType {
  ZOOTECHNICAL = 'zootechnical',
  ECONOMIC = 'economic',
  ENVIRONMENTAL = 'environmental',
  CUSTOM = 'custom'
}

export enum LotWeighingDataType {
  TABLE = 'table',
  CHARTS = 'charts',
  FREQUENCY = 'frequency'
}

export enum MainConstants {
  ACTION_CHECK_SETTINGS = 'ACTION_CHECK_SETTINGS',
  ACTION_SET_DATA_TYPE = 'ACTION_SET_DATA_TYPE',
  ACTION_SET_COLUMNS_ANIMAL_BASIC = 'ACTION_SET_COLUMNS_ANIMAL_BASIC',
  ACTION_SET_COLUMNS_ANIMAL_ADDITIONAL_INFO = 'ACTION_SET_COLUMNS_ANIMAL_ADDITIONAL_INFO',
  ACTION_SET_COLUMNS_ANIMAL_ECONOMIC = 'ACTION_SET_COLUMNS_ANIMAL_ECONOMIC',
  ACTION_SET_COLUMNS_ANIMAL_CURRENT_ECONOMIC = 'ACTION_SET_COLUMNS_ANIMAL_CURRENT_ECONOMIC',
  ACTION_SET_COLUMNS_ANIMAL_ZOOTECHNICAL = 'ACTION_SET_COLUMNS_ANIMAL_ZOOTECHNICAL',
  ACTION_SET_COLUMNS_ANIMAL_CURRENT_ZOOTECHNICAL = 'ACTION_SET_COLUMNS_ANIMAL_CURRENT_ZOOTECHNICAL',
  ACTION_SET_COLUMNS_ANIMAL_ENVIRONMENTAL = 'ACTION_SET_COLUMNS_ANIMAL_ENVIRONMENTAL',
  ACTION_SET_COLUMNS_ANIMAL_CURRENT_ENVIRONMENTAL = 'ACTION_SET_COLUMNS_ANIMAL_CURRENT_ENVIRONMENTAL',
  ACTION_SET_COLUMNS_LOT = 'ACTION_SET_COLUMNS_LOT',
  ACTION_SET_THEME = 'ACTION_SET_THEME',
  ACTION_SET_LOCALE = 'ACTION_SET_LOCALE',
  REDUCER_CLEAR_MAIN_DATA = 'REDUCER_CLEAR_MAIN_DATA',
  REDUCER_SET_MAIN_DATA = 'REDUCER_SET_MAIN_DATA'
}
