import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

// Types
import {
  CarcassYieldDataRef,
  CarcassYieldDataProps,
  LotCarcassYieldQuery,
  LotCarcassYieldGridData
} from './types'
import { CarcassYield, LotCarcassYield } from 'api/params/types'
import { ApplicationStore } from 'store/types'

// Methods
import {
  getLotCarcassYield,
  createLotCarcassYield,
  updateLotCarcassYield,
  deleteLotCarcassYield
} from 'api/params'

const CarcassYieldData: React.RefForwardingComponent<CarcassYieldDataRef, CarcassYieldDataProps> = (
  {
    carcassYields,
    setCarcassYields,
    carcassYieldGridActions,
    setCarcassYieldGridActions
  }: CarcassYieldDataProps,
  ref
) => {
  const selectedLot = useSelector((state: ApplicationStore) => state.lot.selectedLot)
  // function setCarcassYieldGridDataNewIds(newCarcassYieldIds: number[]) {
  //   const currentCarcassYieldsGridData = [...carcassYields.filter((n) => n.id !== 0)]
  //   const newCarcassYieldsGridData = [...carcassYields.filter((n) => n.id === 0)]
  //   const newCarcassYieldsWithIds = newCarcassYieldsGridData.map((n, index) => {
  //     return { ...n, id: newCarcassYieldIds[index] }
  //   })

  //   setCarcassYields([...currentCarcassYieldsGridData, ...newCarcassYieldsWithIds])
  // }

  async function submit() {
    function convertGridDataToLotCarcassYields(
      carcassYieldsGridData: LotCarcassYieldGridData[] | undefined
    ): LotCarcassYield | undefined {
      if (carcassYieldsGridData && carcassYieldsGridData.length > 0) {
        const aux: CarcassYield[] = []
        carcassYieldsGridData?.map((n) => {
          aux.push({
            carcassYield: n.carcassYield,
            day: n.day
          })
        })
        return {
          id: carcassYieldsGridData[0].id ? carcassYieldsGridData[0].id : 0,
          lotId: carcassYieldsGridData[0].lotId,
          values: aux
        }
      } else return undefined
    }

    try {
      const newCarcassYields = convertGridDataToLotCarcassYields(carcassYields)
      const deleteNutritions = convertGridDataToLotCarcassYields(carcassYieldGridActions.delete)
      // const updateCarcassYields = convertGridDataToLotCarcassYields(carcassYields)
      if (newCarcassYields !== undefined && newCarcassYields.id == 0) {
        const newCarcassYieldIds = await createLotCarcassYield(newCarcassYields)
        const aux: LotCarcassYieldGridData[] = []
        newCarcassYields.values.map((r, index) => {
          aux.push({
            id: newCarcassYieldIds,
            lotId: newCarcassYields.lotId,
            day: r.day,
            carcassYield: r.carcassYield,
            _addId: index
          })
        })
        setCarcassYields(aux as LotCarcassYieldGridData[])
        // setCarcassYieldGridDataNewIds(newCarcassYieldIds)
      } else if (newCarcassYields == undefined && deleteNutritions) {
        await deleteLotCarcassYield(deleteNutritions)
      } else if (newCarcassYields !== undefined) {
        await updateLotCarcassYield(newCarcassYields)
      }

      setCarcassYieldGridActions({})
    } catch (error: any) {
      throw error
    }
  }

  useImperativeHandle(ref, () => ({
    submit
  }))

  useEffect(() => {
    async function getCarcassYields() {
      const response: LotCarcassYield = await getLotCarcassYield(
        LotCarcassYieldQuery,
        selectedLot?.id || 0
      )
      const aux: LotCarcassYieldGridData[] = []
      if (response == null || response == undefined) {
        setCarcassYields([])
      } else {
        response.values.map((r, index) => {
          aux.push({
            id: response.id,
            lotId: response.lotId,
            day: r.day,
            carcassYield: r.carcassYield,
            _addId: index
          })
        })
        setCarcassYields(aux as LotCarcassYieldGridData[])
      }
    }

    selectedLot ? getCarcassYields() : setCarcassYields([])
    setCarcassYieldGridActions({})
  }, [selectedLot])

  return <></>
}

export default forwardRef(CarcassYieldData)
