import { getCities, getStates } from 'api/address'
import { put, call, Effect } from 'redux-saga/effects'

// Types
import { MainConstants } from 'store/main/types'
import { AddressConstants, State } from './types'

// Methods
import { workerCheckAuthentication, workerSignOut } from '../auth/sagas'
import Exception from 'helpers/Exception'

function* putAddressData(payload: any) {
  yield put({
    type: AddressConstants.REDUCER_SET_ADDRESS_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetCities(action: Effect): any {
  const { args, fields } = action.payload
  try {
    yield call(putAddressData, { cityLoading: true })
    yield call(workerCheckAuthentication)

    const cities = yield call(getCities, { fields, args })
    yield call(putAddressData, {
      cities,
      cityLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield call(putAddressData, {
      cityLoading: false
    })
  }
}

export function* workerGetStates(action: Effect): any {
  const { args, fields } = action.payload
  try {
    yield call(putAddressData, { stateLoading: true })
    yield call(workerCheckAuthentication)

    let states = yield call(getStates, { fields, args })
    states = states.filter((s: State) => s.cities?.length !== 0)
    yield call(putAddressData, {
      states,
      stateLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield call(putAddressData, {
      stateLoading: false
    })
  }
}
