import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ListItem, Popover, List, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

// Types
import { Props } from './types'
import { Feedlot } from 'store/feedlot/types'
import { ApplicationStore } from 'store/types'

// Methods
import Icon from '@mdi/react'
import { getSelectedFeedlot } from 'store/feedlot/actions'

// Resources
import { mdiChevronDown } from '@mdi/js'
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'

const FeedlotSwitcher = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  useEffect(() => {
    props.getSelectedFeedlot(0)
  }, [])

  const navigate = (id: number) => {
    props.getSelectedFeedlot(id)
  }

  return (
    <div className={'feedlot-switcher'}>
      <Button onClick={handleMenu} className={'feedlot-switcher-button'}>
        <span className={'feedlot-switcher-button-text'}>{props.selectedFeedlot?.description}</span>
        <Icon path={mdiChevronDown} size={1} color={colors(props.theme, Colors.white)} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List component='nav' aria-labelledby='nested-list-subheader'>
          {props.feedlots.map((item: Feedlot) => (
            <ListItem
              key={item.id}
              button
              onClick={() => {
                handleClose()
                navigate(item.id)
              }}
            >
              <text className={`feedlot-list-item-${props.theme}`}>{item.description}</text>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  feedlots: store.feedlot.feedlots,
  selectedFeedlot: store.feedlot.selectedFeedlot
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSelectedFeedlot
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(FeedlotSwitcher))
