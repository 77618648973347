import { all, takeLatest } from 'redux-saga/effects'
import { workerGetCities, workerGetStates } from './sagas'
import { AddressConstants } from './types'

export function* watcherGetCity() {
  yield takeLatest(AddressConstants.ACTION_GET_CITY, workerGetCities)
}
export function* watcherGetState() {
  yield takeLatest(AddressConstants.ACTION_GET_STATE, workerGetStates)
}

export default function* authRootSaga() {
  yield all([watcherGetCity(), watcherGetState()])
}
