import { Address } from 'store/address/types'

export type FeedlotSlaughterhouse = {
  id: number
  feedlotId: number
  description: string
  status?: boolean
  address?: Address
  updateDate?: Date
}

export type FeedlotConfiguration = {
  id: number
  feedlotId: number
  regionIdPreference?: number
  customPrice: boolean
  updateDate: Date
}

export type FeedlotSlaughterhouseStore = {
  readonly saving: boolean
  readonly feedlotSlaughterhouses?: FeedlotSlaughterhouse[]
  readonly feedlotSlaughterhouseLoading: boolean
  readonly feedlotConfiguration?: FeedlotConfiguration
  readonly feedlotConfigurationLoading: boolean
}

export enum FeedlotSlaughterhouseConstants {
  REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA = 'REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA',
  REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA = 'REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA',
  ACTION_GET_FEEDLOT_SLAUGHTERHOUSES = 'ACTION_GET_FEEDLOT_SLAUGHTERHOUSES',
  ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE = 'ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE',
  ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE = 'ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE',
  ACTION_GET_FEEDLOT_CONFIGURATION = 'ACTION_GET_FEEDLOT_CONFIGURATION',
  ACTION_SAVE_FEEDLOT_CONFIGURATION = 'ACTION_SET_FEEDLOT_CONFIGURATION'
}
