import produce from 'immer'
import { AnyAction } from 'redux'

import { PenStore, PenConstants } from './types'

const INITIAL_STORE: PenStore = {
  pens: [],
  selectedPen: undefined,
  penLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case PenConstants.REDUCER_SET_PEN_DATA:
        Object.assign(draft, payload)
        break
      case PenConstants.REDUCER_CLEAR_PEN_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
