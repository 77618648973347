import React from 'react'
import { Button } from '@material-ui/core'
import './styles.scss'
import { LotWeighingDataType } from 'store/main/types'

interface Props {
  t: any
  selectedType: LotWeighingDataType
  handleTypeChange: (type: LotWeighingDataType) => void
}

const CardTypesButtons: React.FC<Props> = ({ t, selectedType, handleTypeChange }: Props) => {
  function handleClick(event: React.MouseEvent<HTMLElement>, type: LotWeighingDataType) {
    event.stopPropagation()
    handleTypeChange(type)
  }
  return (
    <div className={'type-button'}>
      <Button
        className={
          selectedType === LotWeighingDataType.CHARTS
            ? 'selected-card-type-button'
            : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          handleClick(event, LotWeighingDataType.CHARTS)
        }}
      >
        <span
          className={
            selectedType === LotWeighingDataType.CHARTS ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('charts')}
        </span>
      </Button>
      <Button
        className={
          selectedType === LotWeighingDataType.TABLE
            ? 'selected-card-type-button'
            : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, LotWeighingDataType.TABLE)
        }}
      >
        <span
          className={
            selectedType === LotWeighingDataType.TABLE ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('table')}
        </span>
      </Button>
      <Button
        className={
          selectedType === LotWeighingDataType.FREQUENCY
            ? 'selected-card-type-button'
            : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, LotWeighingDataType.FREQUENCY)
        }}
      >
        <span
          className={
            selectedType === LotWeighingDataType.FREQUENCY
              ? 'selected-type-text'
              : 'type-button-text'
          }
        >
          {t('frequency')}
        </span>
      </Button>
    </div>
  )
}

export default CardTypesButtons
