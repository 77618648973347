import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ListItem, Popover, List, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'
import { Locales } from 'store/main/types'

// Methods
import { setLocale } from 'store/main/actions'

// Resources
import './styles.scss'
import brazil from 'resources/img/flags/brazil.png'
import uk from 'resources/img/flags/uk.png'

const LocaleSelector = (props: Props) => {
  const { i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <div>
      <Button onClick={handleMenu}>
        <img src={i18n.language === Locales.pt ? brazil : uk} className={'flag-img'} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List component='nav' aria-labelledby='nested-list-subheader' className='setting-menu-root'>
          <ListItem
            button
            onClick={() => {
              handleClose()
              props.setLocale(Locales.pt)
            }}
          >
            <img src={brazil} className={'flag-img'} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleClose()
              props.setLocale(Locales.en)
            }}
          >
            <img src={uk} className={'flag-img'} />
          </ListItem>
        </List>
      </Popover>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  locale: store.main.locale
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLocale
    },
    dispatch
  )

export default connect(mapStoreToProps, mapDispatchToProps)(LocaleSelector)
