import React from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory, withRouter } from 'react-router-dom'

// Components
import { Dialog, Button, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

// Resources
import './styles.scss'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'
import { useTranslation } from 'react-i18next'

// Methods
import { closeInfoModal, setInfoModalMessage } from 'store/main/actions'
import { getColor, getIcon } from './helpers'

const MenuModal = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { theme } = useSelector((store: ApplicationStore) => store.main)

  const navigate = () => {
    props.closeInfoModal()
    if (props.infoModal?.btnLink) history.push(props.infoModal?.btnLink)
  }

  return (
    <Dialog
      open={props.infoModal.open}
      onClose={() => props.closeInfoModal()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={'info-modal'}
    >
      <DialogContent
        dividers
        style={{
          backgroundColor: getColor(props.infoModal.type, true, theme)
        }}
      >
        <DialogContentText className={'content'}>
          <span>{getIcon(props.infoModal.type, theme)}</span>
          <span>{props.infoModal.message ?? ''}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: getColor(props.infoModal.type, true, theme)
        }}
      >
        {props.infoModal.btnTxt ? (
          <>
            <Button autoFocus onClick={navigate}>
              {props.infoModal.btnTxt}
            </Button>
            <Button autoFocus onClick={props.closeInfoModal}>
              {t('ok')}
            </Button>
          </>
        ) : (
          <Button autoFocus onClick={props.closeInfoModal}>
            {t('ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  infoModal: store.main.infoModal
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeInfoModal,
      setInfoModalMessage
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(MenuModal))
