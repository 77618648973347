import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'
import { Lot } from 'store/lot/types'

//Resources
import './styles.scss'

//Methods

//Components
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer
} from '@material-ui/core'
import Format from 'helpers/Format'
import FormatDate from 'helpers/FormatDate'
import { setSaleDate } from '../helpers'
import { useHistory } from 'react-router-dom'
import { getSelectedLot } from 'store/lot/actions'

const LotsSummaryTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const [dataTable, setDataTable] = useState<any[]>([])
  const [numberLines, setNumberLines] = useState<number>(0)

  useEffect(() => {
    if (props.dataLot) createDataTable(props.dataLot, props.saleSelected)
  }, [
    props.dataLot,
    props.saleSelected,
    props.columnsZootechnical,
    props.columnsEconomic,
    props.columnsEnvironment
  ])

  useEffect(() => {
    setNumberLines(props.numberOfColumns)
  }, [props.numberOfColumns])

  const createDataTable = (lotData: Lot, value: number) => {
    const aux = []
    const columns = props.columnsZootechnical
    // [
    //   'totalFastingArroba',
    //   'dwg',
    //   'weight',
    //   'ingestion',
    //   'feedConversion',
    //   'energeticEfficiency'
    // ]
    const columnsProfit = props.columnsEconomic
    // [
    //   'profitByAnimal',
    // 'costByAnimal',
    // 'revenueByAnimal',
    //'profitByWeightUnity',
    //'costByWeightUnity',
    //'revenueByWeightUnity',
    // 'totalProfit',
    // 'totalCost',
    //'totalRevenue'
    // ]
    const columnsEnvironment: string[] = props.columnsEnvironment

    // [
    //'environmentEfficiency',
    //'waterIntake'
    // ]

    const methodEnvironment: any[] | undefined = lotData?.environmentalData?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditionalEnvironment: any = lotData?.environmentalData?.traditionalMethod
    const currentEnvironment: any = lotData?.environmentalData?.current

    const methodProfit: any[] | undefined = lotData?.profit?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditionalProfit: any = lotData?.profit?.traditionalMethod
    const currentProfit: any = lotData?.profit?.current

    const method: any[] | undefined = lotData?.zootechnicalData?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditional: any = lotData?.zootechnicalData?.traditionalMethod
    const current: any = lotData?.zootechnicalData?.current
    if (
      method &&
      traditional &&
      current &&
      methodProfit &&
      traditionalProfit &&
      currentProfit &&
      methodEnvironment &&
      traditionalEnvironment &&
      currentEnvironment
    ) {
      if (value === 1) {
        aux.push(
          ...[
            // {
            //   typeData: 'sale',
            //   traditional: 1,
            //   current: 1,
            //   sale1: 1
            // },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime))
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount
            }
          ]
        )
      } else if (value === 2) {
        aux.push(
          ...[
            // {
            //   typeData: 'sale',
            //   traditional: 1,
            //   current: 1,
            //   sale1: 1,
            //   sale2: 2
            // },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime,
              sale2: method.length >= 2 ? method[1].predictedTime : '-',
              total: Format(
                Number(
                  (
                    method.reduce((sum, current) => {
                      return (Number(sum) + Number(current.predictedTime)).toFixed(2)
                    }, 0) / 2
                  ).toFixed()
                )
              )
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime)),
              sale2:
                method.length >= 2
                  ? FormatDate(setSaleDate(lotData?.initDate, method[1].predictedTime))
                  : '-',
              total: '-'
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount,
              sale2: method.length >= 2 ? method[1].animalsAmount : '-',
              total: Format(
                method.reduce((sum, current) => {
                  return Number(sum) + Number(current.animalsAmount)
                }, 0)
              )
            }
          ]
        )
      } else if (value === 3) {
        aux.push(
          ...[
            // {
            //   typeData: 'sale',
            //   traditional: 1,
            //   current: 1,
            //   sale1: 1,
            //   sale2: 2,
            //   sale3: 3
            // },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime,
              sale2: method.length >= 2 ? method[1].predictedTime : '-',
              sale3: method.length >= 3 ? method[2].predictedTime : '-',
              total: Format(
                Number(
                  (
                    method.reduce((sum, current) => {
                      return (Number(sum) + Number(current.predictedTime)).toFixed(2)
                    }, 0) / 3
                  ).toFixed()
                )
              )
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime)),
              sale2:
                method.length >= 2
                  ? FormatDate(setSaleDate(lotData?.initDate, method[1].predictedTime))
                  : '-',
              sale3:
                method.length >= 3
                  ? FormatDate(setSaleDate(lotData?.initDate, method[2].predictedTime))
                  : '-',
              total: '-'
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount,
              sale2: method.length >= 2 ? method[1].animalsAmount : '-',
              sale3: method.length >= 3 ? method[2].animalsAmount : '-',
              total: Format(
                method.reduce((sum, current) => {
                  return Number(sum) + Number(current.animalsAmount)
                }, 0)
              )
            }
          ]
        )
      }
      for (const column of columns) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2)),
            sale2: method.length >= 2 ? Format(Number(method[1][column]).toFixed(2)) : '-',
            total:
              column === 'dwg' || column === 'weight' || column === 'arrobaStock'
                ? Format(
                    Number(
                      method.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0) / 2
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      method.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    ).toFixed(2)
                  )
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2)),
            sale2: method.length >= 2 ? Format(Number(method[1][column]).toFixed(2)) : '-',
            sale3: method.length >= 3 ? Format(Number(method[2][column]).toFixed(2)) : '-',
            total:
              column === 'dwg' || column === 'weight' || column === 'arrobaStock'
                ? Format(
                    Number(
                      method.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0) / 3
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      method.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    ).toFixed(2)
                  )
          })
        }
      }
      for (const column of columnsProfit) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2)),
            sale2:
              methodProfit.length >= 2 ? Format(Number(methodProfit[1][column]).toFixed(2)) : '-',
            total:
              column === 'profitByWeightUnity' ||
              column === 'costByWeightUnity' ||
              column === 'revenueByWeightUnity' ||
              column === 'revenueByAnimal' ||
              column === 'costByAnimal' ||
              column === 'profitByAnimal'
                ? Format(
                    Number(
                      methodProfit.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0) / 2
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      methodProfit.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    ).toFixed(2)
                  )
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2)),
            sale2:
              methodProfit.length >= 2 ? Format(Number(methodProfit[1][column]).toFixed(2)) : '-',
            sale3:
              methodProfit.length >= 3 ? Format(Number(methodProfit[2][column]).toFixed(2)) : '-',
            total:
              column === 'profitByWeightUnity' ||
              column === 'costByWeightUnity' ||
              column === 'revenueByWeightUnity' ||
              column === 'revenueByAnimal' ||
              column === 'costByAnimal' ||
              column === 'profitByAnimal'
                ? Format(
                    Number(
                      methodProfit.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0) / 3
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      methodProfit.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    ).toFixed(2)
                  )
          })
        }
      }

      // environmentEfficiency

      for (const column of columnsEnvironment) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2)),
            sale2:
              methodEnvironment.length >= 2
                ? Format(Number(methodEnvironment[1][column]).toFixed(2))
                : '-',
            total:
              column === 'environmentEfficiency' || column === 'waterIntake'
                ? Format(
                    Number(
                      methodEnvironment.reduce((sum, current) => {
                        return Number(sum) + Number(current[column])
                      }, 0) / 2
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      methodEnvironment.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    )
                  )
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2)),
            sale2:
              methodEnvironment.length >= 2
                ? Format(Number(methodEnvironment[1][column]).toFixed(2))
                : '-',
            sale3:
              methodEnvironment.length >= 3
                ? Format(Number(methodEnvironment[2][column]).toFixed(2))
                : '-',
            total:
              column === 'environmentEfficiency' || column === 'waterIntake'
                ? Format(
                    Number(
                      methodEnvironment.reduce((sum, current) => {
                        return Number(sum) + Number(current[column])
                      }, 0) / 3
                    ).toFixed(2)
                  )
                : Format(
                    Number(
                      methodEnvironment.reduce((sum, current) => {
                        return (Number(sum) + Number(current[column])).toFixed(2)
                      }, 0)
                    )
                  )
          })
        }
      }
    }

    setDataTable(aux)
  }

  const navigate = (lotId: number) => {
    if (selectedFeedlot) {
      dispatch(getSelectedLot(selectedFeedlot.id, lotId))
      history.push(`/lots`)
    }
  }
  const getTranslateDataTable = (name: string) => {
    if (name == 'sale') return <text>{t('sale')}</text>
    else if (name == 'date') return <text>{t('date')}</text>
    else if (name == 'animalsAmount') return <text>{t('lot:n_animals')}</text>
    else if (name == 'predictedTime') return <text>{t('lot:confinement_days')}</text>
    else if (name == 'totalFastingArroba')
      return (
        <text>
          {t('weight_unity_arroba')} {t('lot:produced')}
        </text>
      )
    else if (name == 'dwg')
      return (
        <text>
          {t('zootechnical:dwg')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'weight')
      return (
        <text>
          {t('zootechnical:live_weight')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'arrobaStock') return <text>{t('animal:arroba_stock')}</text>
    else if (name == 'ingestion')
      return (
        <text>
          {t('zootechnical:ingestion')}, % {t('lot:lw')}
        </text>
      )
    else if (name == 'feedConversion')
      return (
        <text>
          {t('zootechnical:feed_conversion')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'energeticEfficiency')
      return (
        <text>
          {t('lot:energy_efficiency')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'profitByAnimal')
      return (
        <text>
          {t('animal:animal_profit')}, {t('currency')}
        </text>
      )
    else if (name == 'costByAnimal')
      return (
        <text>
          {t('animal:animal_cost')}, {t('currency')}
        </text>
      )
    else if (name == 'revenueByAnimal')
      return (
        <text>
          {t('animal:animal_revenue')}, {t('currency')}
        </text>
      )
    else if (name == 'profitByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_profit')}, {t('currency')}
        </text>
      )
    else if (name == 'costByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_cost')}, {t('currency')}
        </text>
      )
    else if (name == 'revenueByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_revenue')}, {t('currency')}
        </text>
      )
    else if (name == 'totalProfit') return <text>{t('animal:total_profit')}</text>
    else if (name == 'totalCost') return <text>{t('animal:total_cost')}</text>
    else if (name == 'totalRevenue') return <text>{t('animal:total_revenue')}</text>
    else if (name == 'total') return <text>{t('total')}</text>
    else if (name == 'environmentEfficiency')
      return <text>{t('environmental:environment_efficiency')}</text>
    else if (name == 'waterIntake') return <text>{t('environmental:water_intake')}</text>
    else return ''
  }

  return (
    <div className={'table-summary'}>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table' padding={'none'} className={'table'}>
            <TableHead>
              <TableRow>
                {props.firstLot ? (
                  <Fragment>
                    <TableCell className={'text-current'}></TableCell>
                    <TableCell className={'text-current'}></TableCell>
                    <TableCell align='center' className={'text-current background-current'}>
                      {t('current')}
                    </TableCell>
                    <TableCell align='center' className={'text-traditional background-traditional'}>
                      {t('traditional')}
                    </TableCell>
                    {[...Array(props.saleSelected)].map((x, i) => (
                      <TableCell align='center' key={i} className={'header-button-selected'}>
                        {i + 1}º {t('sale')}
                      </TableCell>
                    ))}
                    {props.saleSelected >= 2 ? (
                      <TableCell align='center' className={'header-button-selected'}>
                        {t('total')}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                ) : (
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={'text-traditional table-description-value'}
                  width='10%'
                  align='center'
                  padding={'none'}
                  rowSpan={numberLines + 3}
                >
                  <p className='text-title'>
                    {t('feedlot:pen')} {props.dataLot?.pen.code}
                  </p>
                  <p className='text-title'>
                    {t('lot:lot')} {props.dataLot?.description}
                  </p>
                </TableCell>
              </TableRow>
              {dataTable.map((row) => (
                <TableRow key={row.typeData}>
                  <TableCell width='20%' align='left' className={'table-description-value'}>
                    {getTranslateDataTable(row.typeData)}
                  </TableCell>
                  <TableCell
                    width='10%'
                    align='center'
                    className={'table-values background-current'}
                  >
                    {row.current}
                  </TableCell>
                  <TableCell
                    width='10%'
                    align='center'
                    className={'table-values background-traditional'}
                  >
                    {row.traditional}
                  </TableCell>
                  <TableCell
                    width='10%'
                    align='center'
                    className={'table-values background-beeftrader'}
                  >
                    {row.sale1}
                  </TableCell>
                  {props.saleSelected >= 2 ? (
                    <TableCell
                      width='10%'
                      align='center'
                      className={
                        props.saleSelected >= 2
                          ? 'table-values background-beeftrader'
                          : 'table-values'
                      }
                    >
                      {row.sale2}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {props.saleSelected >= 3 ? (
                    <TableCell
                      width='10%'
                      align='center'
                      className={
                        props.saleSelected == 3
                          ? 'table-values background-beeftrader'
                          : 'table-values'
                      }
                    >
                      {row.sale3}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {props.saleSelected >= 2 ? (
                    <TableCell
                      width='10%'
                      align='center'
                      className={'table-values background-beeftrader'}
                    >
                      {row.total}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default LotsSummaryTable
