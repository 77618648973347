import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { IconButton } from '@material-ui/core'
import { mdiMenu } from '@mdi/js'
import Icon from '@mdi/react'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'
import { Themes } from 'store/main/types'

// Methods
import { toggleDrawer } from 'store/main/actions'
import colors, { Colors } from 'styles/variables/colors'

const Hamburger = (props: Props) => (
  <IconButton
    aria-label='delete'
    onClick={() => {
      props.toggleDrawer(!props.isDrawerOpen)
    }}
  >
    <Icon size={1.5} color={colors(Themes.LIGHT, Colors.white)} path={mdiMenu} />
  </IconButton>
)

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  isDrawerOpen: store.main.isDrawerOpen
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleDrawer
    },
    dispatch
  )

export default connect(mapStoreToProps, mapDispatchToProps)(Hamburger)
