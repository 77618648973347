import gql from 'graphql-tag'
import API from 'api'

export async function forgotPassword(email: string): Promise<any> {
  const MUTATION = gql`
    mutation MUTATION($email: String!) {
      forgotPassword(email: $email)
    }
  `
  const { errors, data } = await API.mutate(MUTATION, { email: email })

  console.log('')
  if (errors) {
    return errors[0]
  }

  return data
}

export async function recoverPassword(
  email: string,
  token: string,
  newPassword: string
): Promise<any> {
  const MUTATION = gql`
    mutation MUTATION($email: String!, $token: String!, $newPassword: String!) {
      resetPassword(data: { email: $email, token: $token, newPassword: $newPassword })
    }
  `
  const { errors, data } = await API.mutate(MUTATION, {
    email,
    token,
    newPassword
  })

  if (errors) {
    return errors[0]
  }

  return data
}
