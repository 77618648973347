import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

//Methods
import { CustomTooltip, getGradientFill, renderLegend } from './helpers'

// Components
import {
  CartesianGrid,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
  Legend
} from 'recharts'

// Types
import { Props } from './types'
import { AnimalZootechnicalDataBySale } from 'store/animal/types'
import { AnimalProfitBySale } from 'store/profit/types'
import { ApplicationStore } from 'store/types'

// Resources
import './styles.scss'

const ScatterCharts: React.FC<Props> = ({ animals, yFieldKey, xFieldKey, chartName }: Props) => {
  const { t } = useTranslation()
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const [firstSaleData, setFirstSaleData] = useState<
    AnimalZootechnicalDataBySale[] | AnimalProfitBySale[] | []
  >([])
  const [secondSaleData, setSecondSaleData] = useState<
    AnimalZootechnicalDataBySale[] | AnimalProfitBySale[] | []
  >([])
  const [thirdSaleData, setThirdSaleData] = useState<
    AnimalZootechnicalDataBySale[] | AnimalProfitBySale[] | []
  >([])
  // const [allData, setAllData] = useState<AnimalZootechnicalDataBySale[] | AnimalProfitBySale[] | []>([])
  const [maxYAxisValue, setMaxYAxisValue] = useState<number>(3000)
  const [minYAxisValue, setMinYAxisValue] = useState<number>(0)
  const [maxXAxisValue, setMaxXAxisValue] = useState<number>(3000)
  const [minXAxisValue, setMinXAxisValue] = useState<number>(0)
  const zaxisName = t('animal:earring')
  // const [minChartTime, setMinChartTime] = useState<number>(1)
  // const [maxChartTime, setMaxChartTime] = useState<number>(200)

  useEffect(() => {
    if (animals?.length) {
      let firstSaleAnimals: any = []
      let secondSaleAnimals: any = []
      let thirdSaleAnimals: any = []
      const all: any = []
      let count = 0
      animals?.map((a: AnimalZootechnicalDataBySale | AnimalProfitBySale) => {
        if (a !== undefined) {
          if (a.sale == 1) firstSaleAnimals.push(a)
          if (a.sale == 2) secondSaleAnimals.push(a)
          if (a.sale == 3) thirdSaleAnimals.push(a)
        }
      })

      firstSaleAnimals = firstSaleAnimals.map(
        (fa: AnimalZootechnicalDataBySale | AnimalProfitBySale) => {
          return { ...fa, order: count++ }
        }
      )
      secondSaleAnimals = secondSaleAnimals.map(
        (sa: AnimalZootechnicalDataBySale | AnimalProfitBySale) => {
          return { ...sa, order: count++ }
        }
      )
      thirdSaleAnimals = thirdSaleAnimals.map(
        (ta: AnimalZootechnicalDataBySale | AnimalProfitBySale) => {
          return { ...ta, order: count++ }
        }
      )

      setFirstSaleData(firstSaleAnimals)
      setSecondSaleData(secondSaleAnimals)
      setThirdSaleData(thirdSaleAnimals)

      all.push(...firstSaleAnimals, ...secondSaleAnimals, ...thirdSaleAnimals)

      // If needs all data active this code line
      // setAllData(all)

      if (all.length) {
        const maxY = all?.reduce(
          (
            prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
            current: AnimalZootechnicalDataBySale | AnimalProfitBySale
          ) => (prev.result[yFieldKey] > current.result[yFieldKey] ? prev : current)
        )
        setMaxYAxisValue(Number(maxY.result[yFieldKey].toFixed()))
        const minY = all?.reduce(
          (
            prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
            current: AnimalZootechnicalDataBySale | AnimalProfitBySale
          ) => (prev.result[yFieldKey] < current.result[yFieldKey] ? prev : current)
        )
        setMinYAxisValue(Number(minY.result[yFieldKey].toFixed()))

        const maxX = all?.reduce(
          (
            prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
            current: AnimalZootechnicalDataBySale | AnimalProfitBySale
          ) => (prev.result[xFieldKey] > current.result[xFieldKey] ? prev : current)
        )
        setMaxXAxisValue(
          xFieldKey === 'dwg'
            ? Number(maxX.result[xFieldKey])
            : Number(maxX.result[xFieldKey].toFixed())
        )
        const minX = all?.reduce(
          (
            prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
            current: AnimalZootechnicalDataBySale | AnimalProfitBySale
          ) => (prev.result[xFieldKey] < current.result[xFieldKey] ? prev : current)
        )
        setMinXAxisValue(
          xFieldKey === 'dwg'
            ? Number(minX.result[xFieldKey])
            : Number(minX.result[xFieldKey].toFixed())
        )
      }

      // If x axis key is predicted time

      // const minTime = all.reduce(
      //   (
      //     prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
      //     current: AnimalZootechnicalDataBySale | AnimalProfitBySale
      //   ) => (prev.result.predictedTime < current.result.predictedTime ? prev : current)
      // )
      // setMinChartTime(minTime.result.predictedTime)
      // const maxTime = all.reduce(
      //   (
      //     prev: AnimalZootechnicalDataBySale | AnimalProfitBySale,
      //     current: AnimalZootechnicalDataBySale | AnimalProfitBySale
      //   ) => (prev.result.predictedTime > current.result.predictedTime ? prev : current)
      // )
      // setMaxChartTime(maxTime.result.predictedTime)
    }
  }, [animals])
  return (
    <div className={'scatter-chart'}>
      <ResponsiveContainer className={'chart-responsive-container'}>
        <ScatterChart
          // width={530}
          // height={250}
          margin={{ top: 5, right: 20, bottom: 35, left: 10 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={'result.' + xFieldKey}
            name={t(xFieldKey)}
            type='number'
            allowDecimals={xFieldKey === 'dwg' ? true : false}
            domain={[minXAxisValue, maxXAxisValue]}
            // tick={false}
          ></XAxis>
          <YAxis
            dataKey={'result.' + yFieldKey}
            name={t('chart:' + chartName)}
            type='number'
            allowDecimals={false}
            domain={[minYAxisValue - 3, maxYAxisValue + 3]}
          />
          <ZAxis dataKey='earring' name={zaxisName} />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={({ active, payload }) =>
              CustomTooltip({ active, payload, xFieldKey, yFieldKey })
            }
          />
          {/* <Legend
            margin={{ bottom: 0 }}
            content={(props: any) =>
              renderLegend(props, firstSaleData, secondSaleData, thirdSaleData)
            }
          /> */}
          <Scatter
            name={'1º ' + t('sale')}
            data={firstSaleData}
            fill={getGradientFill(theme, 1, chartName)}
          />
          <Scatter
            name={'2º ' + t('sale')}
            data={secondSaleData}
            fill={getGradientFill(theme, 2, chartName)}
          />
          <Scatter
            name={'3º ' + t('sale')}
            data={thirdSaleData}
            fill={getGradientFill(theme, 3, chartName)}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ScatterCharts
