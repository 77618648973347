import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dispatch, bindActionCreators } from 'redux'
// Components
import {
  CartesianGrid,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
// Types
import { Props } from './types'
// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { ApplicationStore } from 'store/types'
import { connect } from 'react-redux'

const WeighingScatterCharts = (props: Props) => {
  const { t } = useTranslation()
  // Texts
  const realWeighingsScatterName: string = t('animal:current_weighings')
  const predictedWeighingsScatterName: string = t('animal:predicted_weighings')
  const interpolationWeighingsScatterName: string = t('animal:interpolation_weighings')
  const lotWeighingsAverageScatterName: string = t('animal:lot_weighings_average')
  const day: string = t('day')
  const weight: string = t('zootechnical:weight')
  const weighingsAverage = props.lotWeighingsAverage?.map((lw) => ({ ...lw }))
  const realData = props.realWeighings?.filter((r) => r.type == 'r')
  const interpolacaoData = props.realWeighings?.filter((r) => r.type == 'i')
  const predictedData = props.realWeighings?.filter((r) => r.type == 'p')

  if (props.lotWeighingsAverage?.length && weighingsAverage?.length) {
    weighingsAverage[0].weight = parseInt(props?.lotWeighingsAverage[0]?.weight?.toFixed() || '0')
    weighingsAverage[weighingsAverage?.length - 1 || 0].weight = parseInt(
      props.lotWeighingsAverage[props.lotWeighingsAverage?.length - 1].weight?.toFixed() || '0'
    )
  }

  const maxChartDay = predictedData ? predictedData[predictedData?.length - 1]?.day : 200
  let minChartWeight = props.realWeighings ? props.realWeighings[0]?.weight : 200
  const averageMinWeightValue = weighingsAverage ? weighingsAverage[0]?.weight : 200
  if (averageMinWeightValue && minChartWeight)
    minChartWeight = minChartWeight < averageMinWeightValue ? minChartWeight : averageMinWeightValue
  const maxChartWeight = predictedData
    ? predictedData[predictedData?.length - 1]?.weight || '700'
    : 700
  return (
    <div className={'scatter-chart'}>
      <ResponsiveContainer className={'chart-responsive-container'}>
        <ScatterChart
          // width={530}
          // height={250}
          margin={{ top: 5, right: 20, bottom: 35, left: 10 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={'day'}
            name={day}
            type='number'
            allowDecimals={false}
            domain={[1, maxChartDay || 200]}
          ></XAxis>
          <YAxis
            dataKey='weight'
            name={weight}
            unit='kg'
            allowDecimals={false}
            domain={[minChartWeight || 150, maxChartWeight || 700]}
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter
            name={realWeighingsScatterName}
            data={realData}
            fill={colors(props.theme, Colors.redDark)}
          />
          <Scatter
            name={interpolationWeighingsScatterName}
            data={interpolacaoData}
            fill={colors(props.theme, Colors.gray)}
          />
          <Scatter
            name={predictedWeighingsScatterName}
            data={predictedData}
            fill={colors(props.theme, Colors.accent)}
          />
          <Scatter
            name={lotWeighingsAverageScatterName}
            data={weighingsAverage}
            fill={colors(props.theme, Colors.yellow)}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStoreToProps, mapDispatchToProps)(WeighingScatterCharts)
