import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import QueryBuilder from 'helpers/QueryBuilder'
import {
  FeedlotConfiguration,
  FeedlotConfigurationQueryArgs,
  FeedlotConfigurationUpdateArgs,
  FeedlotSlaughterhouse,
  FeedlotSlaughterhouseCreateArgs,
  FeedlotSlaughterhouseDelete,
  FeedlotSlaughterhouseQueryParams,
  FeedlotSlaughterhouseUpdateArgs
} from './types'
import { workerSignOut } from 'store/auth/sagas'
import { getItemFromStorage } from 'storage'
import { StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'

export async function getFeedlotSlaughterhouses(
  params: FeedlotSlaughterhouseQueryParams
): Promise<FeedlotSlaughterhouse[]> {
  if (!params.fields) {
    params.fields = {
      id: true,
      feedlotId: true,
      description: true,
      status: true,
      cityId: true,
      updateDate: true,
      address: {
        countryId: true,
        countryDescription: true,
        stateId: true,
        stateDescription: true,
        cityId: true,
        cityDescription: true,
        cityStateDescription: true
      }
    }
  }
  const QUERY = gql`
      query QUERY($id: Int, $feedlotId:[Int!]) {
        feedlotSlaughterhouse(id: $id, feedlotId: $feedlotId) ${QueryBuilder(params.fields)}
      }
    `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, params.args, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/getFeedlotSlaughterhouse',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getFeedlotSlaughterhouse', errors)
    }
    return data.feedlotSlaughterhouse as FeedlotSlaughterhouse[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getFeedlotSlaughterhouse', error)
  }
}

export async function getFeedlotConfiguration(
  args?: FeedlotConfigurationQueryArgs
): Promise<FeedlotConfiguration[]> {
  const fields = {
    id: true,
    feedlotId: true,
    regionIdPreference: true,
    customPrice: true,
    updateDate: true
  }
  const QUERY = gql`
      query QUERY($feedlotId:[Int!]) {
        feedlotConfiguration(feedlotId: $feedlotId) ${QueryBuilder(fields)}
      }
    `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, args, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        console.log(errors)
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/getFeedlotConfiguration',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getFeedlotConfiguration', errors)
    }
    return data.feedlotConfiguration as FeedlotConfiguration[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getFeedlotConfiguration', error)
  }
}

export const createFeedlotSlaughterhouses = async (
  params: FeedlotSlaughterhouseCreateArgs
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotSlaughterhouses: [CreateFeedlotSlaughterhouse!]!) {
        createFeedlotSlaughterhouses(feedlotSlaughterhouses: $feedlotSlaughterhouses)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { ...params }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/createFeedlotSlaughterhouses',
        errors
      )
    }
    return data.createFeedlotSlaughterhouses as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/createFeedlotSlaughterhouses', error)
  }
}

export const updateFeedlotSlaughterhouses = async (
  params: FeedlotSlaughterhouseUpdateArgs
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotSlaughterhouses: [UpdateFeedlotSlaughterhouse!]!) {
        updateFeedlotSlaughterhouses(feedlotSlaughterhouses: $feedlotSlaughterhouses)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { ...params }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/updateFeedlotSlaughterhouses',
        errors
      )
    }
    return data.updateFeedlotSlaughterhouses as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/updateFeedlotSlaughterhouses', error)
  }
}

export const deleteFeedlotSlaughterhouses = async (
  params: FeedlotSlaughterhouseDelete
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($ids: [Float!]!) {
        deleteFeedlotSlaughterhouses(ids: $ids)
      }
    `
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { ...params }, credentials.token)
    if (errors) {
      const { code } = errors
      throw new Exception(
        code || Codes.Internals.UNEXPECTED_ERROR,
        'api/deleteFeedlotSlaughterhouses',
        errors
      )
    }
    return data.deleteFeedlotSlaughterhouses as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/deleteFeedlotSlaughterhouses', error)
  }
}

export const updateFeedlotConfiguration = async (
  params: FeedlotConfigurationUpdateArgs
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotConfiguration: UpdateFeedlotConfiguration!) {
        updateFeedlotConfiguration(feedlotConfiguration: $feedlotConfiguration)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { ...params }, credentials.token)
    if (errors) {
      console.log(errors)
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/updateFeedlotConfiguration',
        errors
      )
    }
    return data.updateFeedlotConfiguration as number[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/updateFeedlotConfiguration', error)
  }
}
