import { all, takeLatest } from 'redux-saga/effects'

// Types
import { PenConstants } from './types'

// Methods
import { workerGetSelectedPens } from './sagas'

export function* watcherGetSelectedPens() {
  yield takeLatest(PenConstants.ACTION_GET_SELECTED_PEN, workerGetSelectedPens)
}

export default function* penWatchers() {
  yield all([watcherGetSelectedPens()])
}
