import { FeedlotSlaughterhouse } from 'store/feedlot-slaughterhouse/types'

export type FeedlotSlaughterhousePrice = {
  [key: string]: any
  id?: number
  genderId?: number
  feedlotSlaughterhouse?: FeedlotSlaughterhouse
  currency?: Currency
  weightUnity?: WeightUnity
  price?: number
  gender?: Gender
}

export type QuoteSlaughterhousePrice = {
  [key: string]: any
  id?: number
  price: number
  price30Days: number
  grossPrice: number
  grossPrice30Days: number
  updateDate: boolean
  region: RegionPrice
  currency?: Currency
  weightUnity?: WeightUnity
  gender?: Gender
}
export type RegionPrice = {
  id: number
  description: string
  address: string
  latitude: number
  longitude: number
}
export type Gender = {
  id: number
  code: string
  description: string
}

export type FeedlotSlaughterhousePriceCategory = {
  id: number
  description: string
  code: string
}

export type Currency = {
  id: number
  code?: string
  symbol?: string
  description?: string
}

export type WeightUnity = {
  id: number
  code?: string
  symbol?: string
}

export type EconomicStore = {
  readonly feedlotSlaughterhousePrice: FeedlotSlaughterhousePrice[]
  readonly feedlotSlaughterhousePriceLoading: boolean
  readonly quoteSlaughterhousePrice: QuoteSlaughterhousePrice[]
  readonly quoteSlaughterhousePriceLoading: boolean
  readonly currencies: Currency[]
  readonly currencyLoading: boolean
  readonly weightUnits: WeightUnity[]
  readonly weightUnityLoading: boolean
  readonly slaughterhousePriceGenders: Array<FeedlotSlaughterhousePriceCategory>
}

export enum EconomicConstants {
  REDUCER_SET_ECONOMIC_DATA = 'REDUCER_SET_ECONOMIC_DATA',
  REDUCER_CLEAR_ECONOMIC_DATA = 'REDUCER_CLEAR_ECONOMIC_DATA',
  ACTION_GET_FEEDLOT_SLAUGHTERHOUSE_PRICE = 'ACTION_GET_FEEDLOT_SLAUGHTERHOUSE_PRICE',
  ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE_PRICE = 'ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE_PRICE',
  ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE_PRICE = 'ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE_PRICE',
  ACTION_GET_CURRENCY = 'ACTION_GET_CURRENCY',
  ACTION_GET_WEIGHTUNITY = 'ACTION_GET_WEIGHTUNITY',
  ACTION_GET_QUOTE_SLAUGHTERHOUSE_PRICE = 'ACTION_GET_QUOTE_SLAUGHTERHOUSE_PRICE'
}
