import gql from 'graphql-tag'

// Classes
import API from '../'
import Exception from 'helpers/Exception'

// Types
import { Credentials, ReplacePasswordParams } from './types'
import Codes from 'helpers/Codes'
import { AuthData } from 'storage/types'
import { workerSignOut } from 'store/auth/sagas'

export const authenticate = async (email: string, password: string): Promise<string> => {
  const MUTATION = gql`
    mutation MUTATION($authUser: AuthCredentialsDto!) {
      refreshToken(authUser: $authUser)
    }
  `

  try {
    const authUser = {
      email,
      password,
      app: 'beeftrader',
      env: 'web'
    }
    const response = await API.mutate(MUTATION, { authUser })
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        if (statusCode === 401) workerSignOut
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/auth/authenticate',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/authenticate', errors)
    }

    return data.refreshToken
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/authenticate', error)
  }
}

const requestCredentials = async (refreshToken: string): Promise<Credentials> => {
  const MUTATION = gql`
    mutation MUTATION {
      getToken {
        token
      }
    }
  `

  try {
    const response = await API.mutate(MUTATION, {}, refreshToken)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        console.log('errors', errors)
        const { statusCode } = errors[0]
        if (statusCode === 401) workerSignOut
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/auth/requestCredentials',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/requestCredentials', errors)
    }

    const credentials: Credentials = {
      token: data.getToken.token,
      customerId: data.getToken.customerId
    }
    return credentials
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/getCredentials', error)
  }
}

export const validateAuthentication = async (
  authData: AuthData
): Promise<Credentials | undefined> => {
  const { refreshToken, credentials } = authData
  try {
    const MUTATION = gql`
      mutation MUTATION {
        validate
      }
    `

    const token = credentials?.token

    const { data, errors } = await API.mutate(MUTATION, {}, token)

    if (errors) {
      const { statusCode } = errors[0]
      if (statusCode === 401) workerSignOut
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/auth/validateAuthentication(1)',
        errors
      )
    }
  } catch (error: any) {
    if (error instanceof Exception) {
      if (error.code === Codes.HTTP.UNAUTHORIZED_401) {
        return await requestCredentials(refreshToken)
      }
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/auth/validateAuthentication(2)',
      error
    )
  }
}

export const sendRecoverPasswordRequest = async (email: string): Promise<void> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($email: String!) {
        forgotPassword(email: $email)
      }
    `

    const { errors } = await API.mutate(MUTATION, { email })
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/auth/sendRecoverPasswordRequest(1)',
        errors
      )
    }
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/auth/sendRecoverPasswordRequest(2)',
      error
    )
  }
}

export const checkResetPasswordToken = async (token: string): Promise<void> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($token: String!) {
        checkResetPassword(token: $token)
      }
    `

    const { errors } = await API.mutate(MUTATION, { token })
    if (errors) {
      const { statusCode } = errors[0]
      if (statusCode === 401) workerSignOut
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/auth/checkResetPasswordToken(2)',
        errors
      )
    }
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/checkResetPasswordToken', error)
  }
}

export const replacePasswordRequest = async (params: ReplacePasswordParams): Promise<void> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($token: String, $password: String, $repeatPassword: String) {
        resetPassword(
          managementPassword: {
            token: $token
            password: $password
            repeatPassword: $repeatPassword
          }
        )
      }
    `

    const { errors } = await API.mutate(MUTATION, { ...params })
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/auth/replacePasswordRequest(2)',
        errors
      )
    }
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/replacePasswordRequest', error)
  }
}
