import React from 'react'
import { Button } from '@material-ui/core'
import { setSelectedSaleType } from 'store/feedlot/actions'
import './styles.scss'
import { useDispatch } from 'react-redux'

interface Props {
  t: any
  selectedSales: number
  handleSalesChange: (sales: number) => void
}

const CardSalesButtons: React.FC<Props> = ({ t, selectedSales, handleSalesChange }: Props) => {
  const dispatch = useDispatch()
  function handleClick(event: React.MouseEvent<HTMLElement>, sales: number) {
    event.stopPropagation()
    // Save selected sale type on store to be used by another pages
    dispatch(setSelectedSaleType(sales ?? 1))
    handleSalesChange(sales)
  }
  return (
    <div className={'sales-button'}>
      <Button
        className={selectedSales === 1 ? 'selected-card-sales-button' : 'card-sales-button'}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          handleClick(event, 1)
        }}
      >
        <span className={selectedSales === 1 ? 'selected-sales-text' : 'sales-button-text'}>
          1 {t('sale')}
        </span>
      </Button>
      <Button
        className={selectedSales === 2 ? 'selected-card-sales-button' : 'card-sales-button'}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, 2)
        }}
      >
        <span className={selectedSales === 2 ? 'selected-sales-text' : 'sales-button-text'}>
          2 {t('sales')}
        </span>
      </Button>
      <Button
        className={selectedSales === 3 ? 'selected-card-sales-button' : 'card-sales-button'}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, 3)
        }}
      >
        <span className={selectedSales === 3 ? 'selected-sales-text' : 'sales-button-text'}>
          3 {t('sales')}
        </span>
      </Button>
    </div>
  )
}

export default CardSalesButtons
