import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Resources
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'
import { DataType } from 'store/main/types'

// Components

// Methods
import { getSelectedLot } from 'store/lot/actions'
import { MenuItem, Select } from '@material-ui/core'
import { getSelectedPen } from 'store/pen/actions'
import { useTranslation } from 'react-i18next'
import LotDetailsTable from 'components/tables/lot/LotDetailsTable'

const Lots: React.FC<Props> = () => {
  const { t } = useTranslation()

  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const { dataType } = useSelector((state: ApplicationStore) => state.main)
  const { lots, selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const { pens, selectedPen } = useSelector((state: ApplicationStore) => state.pen)
  const dispatch = useDispatch()

  const [lotSelected, setLotSelected] = useState(0)
  const [penSelected, setPenSelected] = useState(0)
  const [saleSelected, setSaleSelected] = useState<number>(1)

  const handleChange = (event: any) => {
    if (selectedFeedlot) {
      dispatch(getSelectedLot(selectedFeedlot.id, event.target.value as number))
      setLotSelected(event.target.value as number)
    }
  }
  const handleChangePen = (event: any) => {
    if (selectedFeedlot && selectedLot) {
      dispatch(getSelectedPen(selectedFeedlot.id, event.target.value as number))
      setPenSelected(event.target.value as number)
    }
  }

  useEffect(() => {
    if (selectedFeedlot && selectedLot && selectedPen) {
      dispatch(getSelectedPen(selectedFeedlot.id, selectedPen.id))
      setPenSelected(selectedPen.id)
      setLotSelected(selectedLot.id)
    }
  }, [selectedFeedlot, selectedLot, selectedPen])

  // useEffect(() => {
  //   if (selectedFeedlot) {
  //     dispatch(getSelectedLot(selectedFeedlot.id))
  //   }
  // }, [selectedFeedlot])

  return (
    <div className={'lot-body'}>
      <div className='lot-row'>
        <div className='group'>
          <div className='group-select'>
            <text className={'text-select'}>{t('feedlot:pen')}</text>
            <Select
              className={'text-select-item'}
              value={penSelected}
              label={selectedPen?.code}
              onChange={handleChangePen}
            >
              {pens?.map((item) => (
                <MenuItem className={'text-select-item'} key={item.id} value={item.id}>
                  {item.code}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className='group-select'>
          <text className={'text-select'}>{t('lot:lot')}</text>
          <Select
            className={'text-select-item'}
            value={lotSelected}
            label={selectedLot?.description}
            onChange={handleChange}
          >
            {selectedPen?.lot.map((item) => (
              <MenuItem className={'text-select-item'} key={item.id} value={item.id}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className='group-select'>
          <text className={'text-select'}>{t('sale')}</text>
          <Select
            className={'text-select-item'}
            value={saleSelected}
            label={saleSelected}
            onChange={(e) => {
              setSaleSelected(e.target.value as number)
            }}
          >
            <MenuItem className={'text-select-item'} value={1}>
              1 {t('sale')}
            </MenuItem>
            <MenuItem className={'text-select-item'} value={2}>
              2 {t('sales')}
            </MenuItem>
            <MenuItem className={'text-select-item'} value={3}>
              3 {t('sales')}
            </MenuItem>
          </Select>
        </div>
      </div>
      <div className='lot-container'>
        {selectedLot ? (
          <LotDetailsTable dataLot={selectedLot} saleSelected={saleSelected} />
        ) : (
          <></>
        )}
        {/* {dataType === DataType.ECONOMIC
          ? lots.map((item) => <LotProfitCard lotData={item} key={item.id} />)
          : lots.map((item) => <LotZootechnicalCard lotData={item} key={item.id} />)} */}
      </div>
    </div>
  )
}

export default Lots
