export type AuthStore = {
  isAuthenticating: boolean
  isCheckingAuthentication: boolean
  isAuthenticated: boolean
  credentials: any
  isCheckingRecoveryToken: boolean
  requestingRecoveryEmail: boolean
  requestEmailStatus?: boolean
}

export enum AuthConstants {
  ACTION_SIGN_IN = 'ACTION_SIGN_IN',
  ACTION_SIGN_OUT = 'ACTION_SIGN_OUT',
  ACTION_REQUEST_CHANGE_PWD_EMAIL = 'ACTION_REQUEST_CHANGE_PWD_EMAIL',
  ACTION_CHANGE_PWD = 'ACTION_CHANGE_PWD',
  ACTION_CHECK_CHANGE_PWD_TOKEN = 'ACTION_CHECK_CHANGE_PWD_TOKEN',
  ACTION_CHECK_AUTHENTICATION = 'ACTION_CHECK_AUTHENTICATION',
  REDUCER_CLEAR_AUTH_DATA = 'REDUCER_CLEAR_AUTH_DATA',
  REDUCER_SET_AUTH_DATA = 'REDUCER_SET_AUTH_DATA'
}
