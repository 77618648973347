import { Order, Filter } from './types'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function filterData(data: any, filters: Filter[]) {
  return data.filter((x: any) => {
    let check = true
    filters.map((fil) => {
      if (fil.condition === '=') {
        if (fil.type === 'string') {
          if (!String(x[fil.id]).toLowerCase().includes(fil.value.toLowerCase())) {
            check = false
            return
          }
        } else {
          if (x[fil.id] !== Number(fil.value) && fil.value !== '') {
            check = false
            return
          }
        }
      } else if (fil.condition === '>' && fil.value !== '') {
        if (x[fil.id] < Number(fil.value)) {
          check = false
          return
        }
      } else if (fil.condition === '<' && fil.value !== '') {
        if (x[fil.id] > Number(fil.value)) {
          check = false
          return
        }
      }
    })
    return check
  })
}
