import Loading from 'components/shared/Loading'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Components
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { CustomTooltip } from './helpers'

// Types
import { ApplicationStore } from 'store/types'

// Methods
import { getLotAccessIndex } from 'store/lot/actions'

// Resources
import './styles.scss'

const AccessFrequencyPairChart: React.FC = () => {
  const { lotAccessIndexLoading, lotAccessIndex, selectedLot } = useSelector(
    (state: ApplicationStore) => state.lot
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dayTxt = t('day')

  useEffect(() => {
    if (!lotAccessIndex && selectedLot) dispatch(getLotAccessIndex())
  })

  return (
    <div className={'access-frequency-body'}>
      <p className={'chart-title-text'}>{t('lot_access_frequency')}</p>
      {lotAccessIndexLoading ? (
        <Loading />
      ) : (
        <ResponsiveContainer
          className={'chart-responsive-container'}
          width={lotAccessIndex?.length ? lotAccessIndex?.length * 90 : 400}
          height={400}
        >
          <BarChart data={lotAccessIndex}>
            <XAxis
              dataKey='week'
              domain={[1, lotAccessIndex?.length ? lotAccessIndex?.length : 30]}
            />
            <YAxis domain={[1, 100]} unit={'%'} />
            <Tooltip content={({ active, payload }) => CustomTooltip({ active, payload })} />
            <Tooltip />
            <Brush
              dataKey='week'
              name={t('week')}
              height={lotAccessIndex?.length ? lotAccessIndex?.length : 30}
              stroke='#939597'
            />
            <Bar
              dataKey='frequency'
              name={t('frequency')}
              unit='%'
              fill={'#922700'}
              // stroke='#000'
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default AccessFrequencyPairChart
