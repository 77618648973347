import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Types
import { ApplicationStore } from 'store/types'
import { Animal, AnimalEnvironmentalDataBySale } from 'store/animal/types'
import { Columns, ColumnsCollapse } from 'store/main/types'

//Resources
import '../styles.scss'

//Methods
import { getAnimals } from 'store/animal/actions'
import {
  setColumnsAnimalBasic,
  setColumnsAnimalEnvironmental,
  setColumnsAnimalCurrentEnvironmental
} from 'store/main/actions'
import { handleColumnsSelected } from '../helpers'

//Components
import CustomTable from 'components/shared/CustomTable'
import CustomSelectMultiple from 'components/shared/CustomSelectMultiple'
import Loading from 'components/shared/Loading'
import _ from 'lodash'
import FormatDate from 'helpers/FormatDate'
import { setSaleDate } from 'pages/helpers'

type Props = {
  sale: number
}
const AnimalEnvironmentalTable = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    columnsAnimalEnvironmental,
    columnsAnimalCurrentEnvironmental,
    columnsAnimalBasic,
    columnsAnimalEnvironmentalOriginal,
    columnsAnimalCurrentEnvironmentalOriginal,
    columnsAnimalBasicOriginal
  } = useSelector((state: ApplicationStore) => state.main)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const { animals, animalLoading } = useSelector((state: ApplicationStore) => state.animal)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const [additionalInfoKeys, setAdditionalInfoKeys] = useState<Array<string>>([])

  const [rows, setRows] = useState<any[]>([])
  const [columnsSelectedAnimalsEnvironmental, setColumnsSelectedAnimalsEnvironmental] =
    React.useState<ColumnsCollapse>(columnsAnimalEnvironmental)
  const [
    columnsSelectedAnimalsCurrentEnvironmental,
    setColumnsSelectedAnimalsCurrentEnvironmental
  ] = React.useState<ColumnsCollapse>(columnsAnimalCurrentEnvironmental)
  const [columnsSelectedAnimalsBasic, setColumnsSelectedAnimalsBasic] =
    React.useState<ColumnsCollapse>(columnsAnimalBasic)

  const handleChangeFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    const dataColumns: string[] = event.target.value as string[]

    let columnsUpdate: ColumnsCollapse
    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsBasic, dataColumns)
    dispatch(setColumnsAnimalBasic(columnsUpdate))
    setColumnsSelectedAnimalsBasic(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsCurrentEnvironmental, dataColumns)
    dispatch(setColumnsAnimalCurrentEnvironmental(columnsUpdate))
    setColumnsSelectedAnimalsCurrentEnvironmental(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsEnvironmental, dataColumns)
    dispatch(setColumnsAnimalEnvironmental(columnsUpdate))
    setColumnsSelectedAnimalsEnvironmental(columnsUpdate)
  }

  useEffect(() => {
    if (selectedFeedlot?.id && selectedLot?.id) {
      setAdditionalInfoKeys([])
      dispatch(getAnimals(selectedFeedlot?.id, selectedLot.id))
    }
  }, [selectedLot])

  useEffect(() => {
    if (animals && !animalLoading) {
      getEnvironmentalData(props.sale)
    }
  }, [animals, animalLoading, props.sale])

  const getEnvironmentalData = (sale: number) => {
    let keys: Array<string> = []
    const dataTable: any[] = []

    if (animals.length) {
      if (!additionalInfoKeys.length) {
        _.map(animals, (a) => {
          if (a.additionalField && a.additionalField !== {})
            for (const key in a.additionalField) keys.push(key)
        })
        keys = _.uniq(keys)
        setAdditionalInfoKeys(keys)

        if (keys.length) {
          let order = columnsAnimalBasic.columns[columnsAnimalBasic.columns.length - 1].order + 1
          const columnsAnimalBasicCP = JSON.parse(JSON.stringify(columnsAnimalBasic))
          const columnsAnimalCurrentEnvironmentalCP = JSON.parse(
            JSON.stringify(columnsAnimalCurrentEnvironmental)
          )
          const columnsAnimalEnvironmentalCP = JSON.parse(
            JSON.stringify(columnsAnimalEnvironmental)
          )

          keys.map((key) => {
            const findedKey = columnsAnimalBasicCP.columns.find((c: Columns) => c.id === key)
            if (!findedKey) {
              const column: Columns = {
                order: order++,
                id: key,
                active: true,
                disablePadding: false,
                type: 'any',
                label: key
              }
              columnsAnimalBasicCP.columns.push(column)
            }
          })

          columnsAnimalCurrentEnvironmentalCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          columnsAnimalEnvironmentalCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.environmentalData.beeftraderMethod.find((p) => p.saleType === sale),
                  keys
                )
              )
            }
          })
          if (columnsAnimalBasic.columns.length !== columnsAnimalBasicCP.columns.length) {
            setColumnsAnimalBasic(columnsAnimalBasicCP)
            setColumnsSelectedAnimalsBasic(columnsAnimalBasicCP)
            dispatch(setColumnsAnimalBasic(columnsAnimalBasicCP))

            setColumnsSelectedAnimalsCurrentEnvironmental(columnsAnimalCurrentEnvironmentalCP)
            setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalCP)
            dispatch(setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalCP))

            setColumnsSelectedAnimalsEnvironmental(columnsAnimalEnvironmentalCP)
            setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalCP)
            dispatch(setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalCP))
          }
        } else {
          setColumnsAnimalBasic(columnsAnimalBasicOriginal)
          setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
          dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

          setColumnsSelectedAnimalsCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal)
          setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal)
          dispatch(setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal))

          setColumnsSelectedAnimalsEnvironmental(columnsAnimalEnvironmentalOriginal)
          setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalOriginal)
          dispatch(setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalOriginal))

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.environmentalData.beeftraderMethod.find((p) => p.saleType === sale)
                )
              )
            }
          })
        }
      } else {
        setColumnsAnimalBasic(columnsAnimalBasic)
        setColumnsSelectedAnimalsBasic(columnsAnimalBasic)
        dispatch(setColumnsAnimalBasic(columnsAnimalBasic))

        setColumnsSelectedAnimalsCurrentEnvironmental(columnsAnimalCurrentEnvironmental)
        setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmental)
        dispatch(setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmental))

        setColumnsSelectedAnimalsEnvironmental(columnsAnimalEnvironmental)
        setColumnsAnimalEnvironmental(columnsAnimalEnvironmental)
        dispatch(setColumnsAnimalEnvironmental(columnsAnimalEnvironmental))

        animals.map((a) => {
          if (a.profit) {
            dataTable.push(
              createData(
                a,
                a.environmentalData.beeftraderMethod.find((p) => p.saleType === sale)
              )
            )
          }
        })
      }
    } else {
      setAdditionalInfoKeys([])
      setColumnsAnimalBasic(columnsAnimalBasicOriginal)
      setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
      dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

      setColumnsSelectedAnimalsCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal)
      setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal)
      dispatch(setColumnsAnimalCurrentEnvironmental(columnsAnimalCurrentEnvironmentalOriginal))

      setColumnsSelectedAnimalsEnvironmental(columnsAnimalEnvironmentalOriginal)
      setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalOriginal)
      dispatch(setColumnsAnimalEnvironmental(columnsAnimalEnvironmentalOriginal))
    }

    setRows(dataTable)
  }

  function createData(
    animal: Animal,
    environmental: AnimalEnvironmentalDataBySale | undefined,
    keys?: string[]
  ) {
    if (environmental) {
      const animalData: any = {
        earring: animal.earring,
        tag: animal.tag,
        sisbov: animal.sisbov,
        sale: Number(environmental.sale),
        saleDate: FormatDate(
          setSaleDate(selectedLot?.initDate, environmental.result.predictedTime)
        ),
        breed: animal.breed.description,
        gender: animal.gender.code,
        environmentEfficiency: Number(
          Number(animal.environmentalData.current.environmentEfficiency).toFixed(2)
        ),
        waterIntake: Number(Number(environmental.result.waterIntake).toFixed(2)),
        manure: Number(Number(environmental.result.manure).toFixed(2)),
        co2Emission: Number(Number(environmental.result.co2Emission).toFixed(2)),
        currentEnvironmentEfficiency: Number(
          Number(environmental.result.environmentEfficiency).toFixed(2)
        ),
        currentWaterIntake: Number(Number(animal.environmentalData.current.waterIntake).toFixed(2)),
        currentManure: Number(Number(animal.environmentalData.current.manure).toFixed(2)),
        currentCo2Emission: Number(Number(animal.environmentalData.current.co2Emission).toFixed(2))
      }
      const aiKeys = keys?.length ? keys : additionalInfoKeys
      if (aiKeys?.length) {
        aiKeys.map((key: any) => {
          if (animal.additionalField) animalData[key] = animal.additionalField[key] ?? ''
        })
      }
      return animalData
    }
  }

  const handleCollapseColumns = (info: any) => {
    if (columnsSelectedAnimalsBasic.id === info.id) {
      setColumnsSelectedAnimalsBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
      setColumnsAnimalBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsCurrentEnvironmental.id === info.id) {
      setColumnsSelectedAnimalsCurrentEnvironmental({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalCurrentEnvironmental({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsEnvironmental.id === info.id) {
      setColumnsSelectedAnimalsEnvironmental({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalEnvironmental({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    }
  }

  return animalLoading ? (
    <Loading />
  ) : (
    <div className='table-animal'>
      <div className={'header-table'}>
        <CustomSelectMultiple
          value={[
            ...columnsSelectedAnimalsBasic.columns,
            ...columnsSelectedAnimalsCurrentEnvironmental.columns,
            ...columnsSelectedAnimalsEnvironmental.columns
          ]}
          textFixed={t('fields_selected')}
          onChangeValue={handleChangeFields}
        />
      </div>
      <div className={'border-group'}>
        <CustomTable
          columns={[
            columnsSelectedAnimalsBasic,
            columnsSelectedAnimalsCurrentEnvironmental,
            columnsSelectedAnimalsEnvironmental
          ]}
          onCollapseColumns={handleCollapseColumns}
          data={rows}
        />
      </div>
    </div>
  )
}

export default AnimalEnvironmentalTable
