import React, { Fragment } from 'react'

import colors, { Colors } from 'styles/variables/colors'
import { Themes } from 'store/main/types'
import Format from 'helpers/Format'

export const getMethodColor = (theme: Themes, index: string) => {
  if (index === 'traditional') {
    return colors(theme, Colors.gray)
  }
  if (index === 'current') {
    return colors(theme, Colors.redDark)
  }
  return colors(theme, Colors.accent)
}

export const renderCustomBarLabel = (
  t: any,
  theme: Themes,
  teste: any,
  { x, y, width, value }: any,
  measurementUnitChart: string,
  smallBar: number,
  minDwg: number
) => {
  let xpos = 0
  if (value >= 10000 || value <= -1000) xpos = width / 2 + x - 47
  else if (value >= 1000 || value <= -100) xpos = width / 2 + x - 42
  else if (value >= 100 || value <= -10) xpos = width / 2 + x - 37
  else if (value >= 10 || value < 0) xpos = width / 2 + x - 32
  else xpos = width / 2 + x - 35
  if (Number(value) !== 0) {
    if (measurementUnitChart === 'weight') {
      value = Number(value).toFixed(2)
      // setSizeBar([{pos:index, height:height}])
      return (
        <text
          className={'chart-value-text'}
          y={value > 0 ? y - 12 : y + 12}
          x={x + 7}
          dy={6}
          fill={colors(theme, Colors.redDark)}
        >
          {Format(value)} {t('weight_unity_kg').toLowerCase()}
        </text>
      )
    } else if (measurementUnitChart === 'dwg') {
      value = Number(value).toFixed(3)
      return (
        <Fragment>
          <g stroke='none' fill={colors(theme, Colors.accentLight)}>
            <rect
              id='box'
              y={155 - smallBar + smallBar / (Number(value) + minDwg + 1.5)}
              x={x - 5}
              width={width + 10}
              height='30'
            />
          </g>
          <text
            className={'chart-value-text'}
            y={155 - smallBar + smallBar / (Number(value) + minDwg + 1.5) + 15}
            x={x + 15}
            textAnchor='center'
            dy={6}
            fill={colors(theme, Colors.white)}
          >
            {value}
          </text>
        </Fragment>
      )
    }
  }
  return
}

export const gradients = (theme: Themes) => (
  <defs>
    <linearGradient id='profitCurrent' x1='0' y1='0' x2='0' y2='1'>
      <stop offset='0%' stopColor={colors(theme, Colors.gray)} stopOpacity={1} />
      <stop offset='100%' stopColor={colors(theme, Colors.gray)} stopOpacity={0.5} />
    </linearGradient>
    <linearGradient id='profitTraditional' x1='0' y1='0' x2='0' y2='1'>
      <stop offset='0%' stopColor={colors(theme, Colors.foreground)} stopOpacity={1} />
      <stop offset='100%' stopColor={colors(theme, Colors.foreground)} stopOpacity={0.8} />
    </linearGradient>
    <linearGradient id='profitSale1' x1='0' y1='0' x2='0' y2='1'>
      <stop offset='0%' stopColor={colors(theme, Colors.accentPlusLight)} stopOpacity={1} />
      <stop offset='100%' stopColor={colors(theme, Colors.accentPlusLight)} stopOpacity={0.9} />
    </linearGradient>
    <linearGradient id='profitSale2' x1='0' y1='0' x2='0' y2='1'>
      <stop offset='0%' stopColor={colors(theme, Colors.accent)} stopOpacity={1} />
      <stop offset='100%' stopColor={colors(theme, Colors.accent)} stopOpacity={0.9} />
    </linearGradient>
    <linearGradient id='profitSale3' x1='0' y1='0' x2='0' y2='1'>
      <stop offset='0%' stopColor={colors(theme, Colors.accentDark)} stopOpacity={1} />
      <stop offset='100%' stopColor={colors(theme, Colors.accentDark)} stopOpacity={0.9} />
    </linearGradient>
  </defs>
)

export const getGradientFill = (theme: Themes, index: number) => {
  if (index === -1) return colors(theme, Colors.redDark)
  if (index === 0) return colors(theme, Colors.gray)
  if (index === 1) return colors(theme, Colors.accent)
  if (index === 2) return colors(theme, Colors.accent70)
  if (index === 3) return colors(theme, Colors.accent35)
}

export const textTooltip = (t: any, value: any, measurementUnitChart: string) => {
  if (measurementUnitChart === 'currency') {
    if (value.type === 'traditional')
      return (
        <p className={'text-description'}>
          {t('traditional')}: {t('currency')} {Format(Number(value.traditional).toFixed(2))}
        </p>
      )
    if (value.type === 'current')
      return (
        <p className={'text-description'}>
          {t('current')}: {t('currency')} {Format(Number(value.current).toFixed(2))}
        </p>
      )
    if (value.type === 'sale1')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {t('currency')} {Format(Number(value.sale1).toFixed(2))}
        </p>
      )
    if (value.type === 'sale2')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {t('currency')} {Format(Number(value.sale1).toFixed(2))}
          <br />
          2ª {t('sale')}: {t('currency')} {Format(Number(value.sale2).toFixed(2))}
        </p>
      )
    if (value.type === 'sale3')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {t('currency')} {Format(Number(value.sale1).toFixed(2))}
          <br />
          2ª {t('sale')}: {t('currency')} {Format(Number(value.sale2).toFixed(2))}
          <br />
          3ª {t('sale')}: {t('currency')} {Format(Number(value.sale3).toFixed(2))}
        </p>
      )
  } else if (measurementUnitChart === 'weight') {
    if (value.type === 'traditional')
      return (
        <p className={'text-description'}>
          {t('traditional')}: {Format(Number(value.traditional).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
        </p>
      )
    if (value.type === 'current')
      return (
        <p className={'text-description'}>
          {t('current')}: {Format(Number(value.current).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
        </p>
      )
    if (value.type === 'sale1')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {Format(Number(value.sale1).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
        </p>
      )
    if (value.type === 'sale2')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {Format(Number(value.sale1).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
          <br />
          2ª {t('sale')}: {Format(Number(value.sale2).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
        </p>
      )
    if (value.type === 'sale3')
      return (
        <p className={'text-description'}>
          1ª {t('sale')}: {Format(Number(value.sale1).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
          <br />
          2ª {t('sale')}: {Format(Number(value.sale2).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
          <br />
          3ª {t('sale')}: {Format(Number(value.sale3).toFixed(2))}{' '}
          {t('weight_unity_kg').toLowerCase()}
        </p>
      )
  }
}
