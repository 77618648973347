import { AuthConstants } from './types'

export const signIn = (email: string, password: string) => ({
  type: AuthConstants.ACTION_SIGN_IN,
  payload: {
    email,
    password
  }
})

export const signOut = () => ({
  type: AuthConstants.ACTION_SIGN_OUT,
  payload: { logout: true }
})

export const requestRecoveryEmail = (email: string) => ({
  type: AuthConstants.ACTION_REQUEST_CHANGE_PWD_EMAIL,
  payload: {
    email
  }
})

export const changePwd = (token: string, password: string) => ({
  type: AuthConstants.ACTION_CHANGE_PWD,
  payload: {
    token,
    password
  }
})

export const checkChangePwdToken = (token: string) => ({
  type: AuthConstants.ACTION_CHECK_CHANGE_PWD_TOKEN,
  payload: {
    token
  }
})

export const checkAuthentication = () => ({
  type: AuthConstants.ACTION_CHECK_AUTHENTICATION,
  payload: {
    checkingFromRouter: true
  }
})
