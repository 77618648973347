import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

// Types
import {
  DailyFeedlotDataRef,
  DailyFeedlotDataProps,
  dailyFeedlotQuery,
  DailyFeedlotGridData
} from './types'
import { DailyFeedlot } from 'api/params/types'
import { ApplicationStore } from 'store/types'

// Methods
import {
  getDailyFeedlot as getDailyFeedlotService,
  createDailyFeedlot,
  updateDailyFeedlot,
  deleteDailyFeedlot
} from 'api/params'

const DailyFeedlotData: React.RefForwardingComponent<DailyFeedlotDataRef, DailyFeedlotDataProps> = (
  {
    dailyFeedlot,
    setDailyFeedlot,
    dailyFeedlotGridActions,
    setDailyFeedlotGridActions
  }: DailyFeedlotDataProps,
  ref
) => {
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)

  function setDailyFeedlotGridDataNewIds(newDailyFeedlotIds: number[]) {
    const currentDailyFeedlotGridData = [...dailyFeedlot.filter((d) => d.id !== 0)]
    const newDailyFeedlotGridData = [...dailyFeedlot.filter((d) => d.id === 0)]
    const newDailyFeedlotWithIds = newDailyFeedlotGridData.map((d, index) => {
      return { ...d, id: newDailyFeedlotIds[index] }
    })

    setDailyFeedlot([...currentDailyFeedlotGridData, ...newDailyFeedlotWithIds])
  }

  async function submit() {
    function convertGridDataToDailyFeedlot(
      dailyFeedlotGridData: DailyFeedlotGridData[] | undefined
    ): DailyFeedlot[] | undefined {
      return (
        dailyFeedlotGridData?.map((d) => {
          return {
            id: d.id,
            feedlotId: d.feedlotId,
            startingWeight: d.startingWeight,
            finalWeight: d.finalWeight,
            cost: d.cost
          }
        }) ?? []
      )
    }

    try {
      const newDailyFeedlotGridData = [...dailyFeedlot.filter((d) => d.id === 0)]
      const updateDailyFeedlotGridData = [
        ...dailyFeedlot.filter((d) => dailyFeedlotGridActions.update?.some((dga) => d.id === dga))
      ]

      const newDailyFeedlot = convertGridDataToDailyFeedlot(newDailyFeedlotGridData)
      const updatedDailyFeedlot = convertGridDataToDailyFeedlot(updateDailyFeedlotGridData)
      const deletedDailyFeedlot = convertGridDataToDailyFeedlot(dailyFeedlotGridActions.delete)

      const newDailyFeedlotIds = await createDailyFeedlot(newDailyFeedlot)
      setDailyFeedlotGridDataNewIds(newDailyFeedlotIds)

      await updateDailyFeedlot(updatedDailyFeedlot)
      await deleteDailyFeedlot(deletedDailyFeedlot)
      setDailyFeedlotGridActions({})
    } catch (error: any) {
      throw error
    }
  }

  useImperativeHandle(ref, () => ({
    submit
  }))

  useEffect(() => {
    async function getDailyFeedlot() {
      const response: DailyFeedlot[] = await getDailyFeedlotService(
        dailyFeedlotQuery,
        selectedFeedlot?.id || 0
      )
      setDailyFeedlot(response as DailyFeedlotGridData[])
    }

    selectedFeedlot ? getDailyFeedlot() : setDailyFeedlot([])
    setDailyFeedlotGridActions({})
  }, [selectedFeedlot])

  return <></>
}

export default forwardRef(DailyFeedlotData)
