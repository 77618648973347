/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellClassParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community'

import * as Yup from 'yup'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'

import Modal from '../../../components/ExcelModal'
// import {Animal} from '../../store/animal/types'
import Erros from '../ErrorsData/index'

//register history
import History from '../RegisterHistory/index'
import { Props as RegisterHistory } from '../RegisterHistory/types'

//parametros
import { TabPanel } from '../helpers'

// Resources
import './styles.scss'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import * as XLSX from 'xlsx'

import { Form } from '@unform/web'

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { AiFillYoutube } from 'react-icons/ai'
import { HiUpload } from 'react-icons/hi'

import validator from 'validator'
import { useDispatch, useSelector } from 'react-redux'
import { setRegisterAnimals } from 'store/configuration/animal-management/actions'
import { ApplicationStore } from 'store/types'
import { Feedlot } from 'store/feedlot/types'
import Loading from 'components/shared/Loading'
import Alert, { AlertHandles } from 'components/shared/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'

const RegisterAnimals: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const [isSaving, setIsSaving] = React.useState(false)

  //modal excel visualization
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  //tabs
  const [toggleState, setToggleState] = useState<number>(1)

  // tabs animal register
  const [expanded, setExpanded] = useState<string | false>(false)
  const [expandedAll, setExpandedAll] = useState<boolean>(false)

  //fill the grid modal
  const [rowDataAnimalVerify, setRowDataAnimalVerify] = useState<any>([])

  //Register History
  const [registerHistory, setRegisterHistory] = useState<RegisterHistory[]>([])

  // ag-grid ref
  const gridRef = useRef<AgGridReact>(null)

  //controller error type, not working
  const [required, setRequired] = useState<boolean>(false)
  const [numeric, setNumeric] = useState<boolean>(false)
  const [date, setDate] = useState<boolean>(false)
  const [breed, setBreed] = useState<number>(0)
  const [gender, setGender] = useState<boolean>(false)
  const [ecc, setEcc] = useState<boolean>(false)
  const [headers, setHeaders] = useState<boolean>(false)
  const { animalManagementLoading, messageRegister } = useSelector(
    (state: ApplicationStore) => state.animalManagement
  )

  const [open, setOpen] = React.useState(false)

  const successAlertRef = useRef<AlertHandles>(null)
  const errorAlertRef = useRef<AlertHandles>(null)
  const warningAlertRef = useRef<AlertHandles>(null)
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!animalManagementLoading && isModalOpen) {
      toogleModal()
      if (messageRegister === 'Error') {
        setAlertMessage('error_register')
        errorAlertRef.current?.openAlert()
      } else {
        successAlertRef.current?.openAlert()
      }
    }
  }, [animalManagementLoading])

  //verify information
  const getData = (params: ValueGetterParams) => {
    if (params.colDef.field) return params.data[params.colDef.field]
  }

  const setData = (params: ValueSetterParams) => {
    if (params.newValue !== undefined) {
      if (params.colDef.field) params.data[params.colDef.field] = params.newValue
      return true
    } else {
      return false
    }
  }

  const cellStyleDataNotNull = (params: CellClassParams) => {
    if (params.value !== undefined) {
      if (params.value !== '') {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#ff000070'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }
  const cellStyleData = (params: CellClassParams) => {
    return {
      backgroundColor: '#FFFFFF'
    }
  }

  const cellStylePesoEntrada = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (validator.isDecimal(params.value)) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#008000'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const cellStyleECC = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (validator.isInt(params.value, { min: 1, max: 9 })) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#deb887'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const cellStyleAge = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (validator.isInt(params.value, { min: 1 })) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#deb887'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const cellStyleRaca = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (
        validator.isAlpha(params.value) &&
        (params.value == 'NEL' ||
          params.value == 'CB' ||
          params.value == 'CRC' ||
          params.value == 'CH' ||
          params.value == 'CLG' ||
          params.value == 'CC' ||
          params.value == 'CL' ||
          params.value == 'CA' ||
          params.value == 'BR' ||
          params.value == 'GZ' ||
          params.value == 'CLJ' ||
          params.value == 'H' ||
          params.value == 'HI' ||
          params.value == 'BT' ||
          params.value == 'SE' ||
          params.value == 'BL')
      ) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#8a2be270'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const cellStyleGeneroSexo = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (
        validator.isAlpha(params.value) &&
        (params.value == 'MC' ||
          params.value == 'MI' ||
          params.value == 'VC' ||
          params.value == 'NV')
      ) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#5f9ea0'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const cellStyleDataEntradaBaia = (params: CellClassParams) => {
    if (params.value !== undefined && params.value !== '') {
      if (validator.isDate(params.value, { format: 'DD/MM/YYYY', delimiters: ['/'] })) {
        return {
          backgroundColor: '#FFFFFF'
        }
      } else {
        return {
          backgroundColor: '#00ffff'
        }
      }
    } else {
      return {
        backgroundColor: '#ff000070'
      }
    }
  }

  const [columnDefsAnimalsVerify, setColumnDefsAnimalsVerify] = useState([
    {
      field: 'Brinco',
      filter: true,
      minWidth: 100,
      width: 150,
      valueGetter: getData,
      cellStyle: cellStyleDataNotNull,
      valueSetter: setData
    },
    {
      field: 'Tag',
      filter: true,
      minWidth: 100,
      width: 150,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'Sisbov',
      filter: true,
      minWidth: 100,
      width: 150,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'PesoEntrada',
      headerName: 'Peso de Entrada',
      filter: true,
      minWidth: 130,
      width: 140,
      valueGetter: getData,
      cellStyle: cellStylePesoEntrada,
      valueSetter: setData
    },
    {
      field: 'ECC',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleECC,
      valueSetter: setData
    },
    {
      field: 'Idade',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleAge,
      valueSetter: setData
    },
    {
      field: 'Raca',
      headerName: 'Raça',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleRaca,
      valueSetter: setData
    },

    {
      field: 'GeneroSexo',
      headerName: 'Gênero/Sexo',
      filter: true,
      minWidth: 100,
      width: 120,
      valueGetter: getData,
      cellStyle: cellStyleGeneroSexo,
      valueSetter: setData
    },
    {
      field: 'dataEntradaBaia',
      headerName: 'Data de entrada na Baia',
      filter: true,
      minWidth: 170,
      width: 170,
      valueGetter: getData,
      cellStyle: cellStyleDataEntradaBaia,
      valueSetter: setData
    },
    {
      field: 'Lote',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleDataNotNull,
      valueSetter: setData
    },
    {
      field: 'Baia',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleDataNotNull,
      valueSetter: setData
    },
    {
      field: 'ADC1',
      headerName: 'ADC1',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'ADC2',
      headerName: 'ADC2',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'ADC3',
      headerName: 'ADC3',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'ADC3',
      headerName: 'ADC3',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'ADC3',
      headerName: 'ADC3',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    },
    {
      field: 'ADC3',
      headerName: 'ADC3',
      filter: true,
      minWidth: 60,
      width: 70,
      valueGetter: getData,
      cellStyle: cellStyleData,
      valueSetter: setData
    }
  ])

  //custom the grid
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      editable: true
    }
  }, [])

  //modal toogle
  const toogleModal = () => setIsModalOpen(!isModalOpen)

  //import excel
  const fileChange = (e: any) => {
    const file = e.target.files[0]
    if (file !== '') {
      const convert = convertDataToWorkbook(file)
      e.value = ''
    }
  }

  const convertDataToWorkbook = (file: any) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = (e: any) => {
      const bufferArray = new Uint8Array(e.target.result)
      const arr = []
      for (let i = 0; i !== bufferArray.length; i++) {
        arr[i] = String.fromCharCode(bufferArray[i])
      }

      const bstr = arr.join('')

      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' })

      populateGrid(wb)
    }
  }

  const populateGrid = (workbook: any) => {
    const firstSheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[firstSheetName]
    // set the headers
    const headers = []
    const columnCount = XLSX.utils.decode_range(worksheet['!ref'])
    let columns: Record<string, string> = {}
    // get the headers of the excel file
    const range = XLSX.utils.decode_range(worksheet['!ref'])
    const R = range.s.r + 5
    let C = range.s.r

    for (C = range.s.c; C <= range.e.c; ++C) {
      const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })]

      let hdr = 'UNKNOWN ' + C
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)

      headers.push(hdr)
    }
    columns = {
      A: 'Número',
      B: 'Identificação da baia',
      C: 'Identificação do lote',
      D: 'Brinco da fazenda (alta frequência)',
      E: 'SISBOV',
      F: 'Sexo',
      G: 'Raça',
      H: 'Idade',
      I: 'ECC',
      J: 'Peso de entrada na fazenda (kg)',
      K: 'Data de entrada na fazenda',
      L: 'Peso Inicial na entrada da baia (kg)',
      M: 'Data de pesagem na entrada da baia',
      N: 'ADC1',
      O: 'ADC2',
      P: 'ADC3',
      Q: 'ADC4',
      R: 'ADC5',
      S: 'ADC6'
    }
    const columnsDef = [
      {
        field: 'Número',
        filter: true,
        minWidth: 100,
        width: 40,
        valueGetter: getData,
        cellStyle: cellStyleDataNotNull,
        valueSetter: setData
      },
      {
        field: 'Identificação da baia',
        filter: true,
        minWidth: 100,
        width: 150,
        valueGetter: getData,
        cellStyle: cellStyleDataNotNull,
        valueSetter: setData
      },
      {
        field: 'Identificação do lote',
        filter: true,
        minWidth: 100,
        width: 150,
        valueGetter: getData,
        cellStyle: cellStyleDataNotNull,
        valueSetter: setData
      },
      {
        field: 'Brinco da fazenda (alta frequência)',
        filter: true,
        minWidth: 130,
        width: 140,
        valueGetter: getData,
        cellStyle: cellStyleDataNotNull,
        valueSetter: setData
      },
      {
        field: 'SISBOV',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'Sexo',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleGeneroSexo,
        valueSetter: setData
      },
      {
        field: 'Raça',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleRaca,
        valueSetter: setData
      },
      {
        field: 'Idade',
        filter: true,
        minWidth: 100,
        width: 120,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ECC',
        filter: true,
        minWidth: 100,
        width: 120,
        valueGetter: getData,
        cellStyle: cellStyleECC,
        valueSetter: setData
      },
      {
        field: 'Peso de entrada na fazenda (kg)',
        filter: true,
        minWidth: 170,
        width: 170,
        valueGetter: getData,
        cellStyle: cellStylePesoEntrada,
        valueSetter: setData
      },
      {
        field: 'Data de entrada na fazenda',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleDataEntradaBaia,
        valueSetter: setData
      },
      {
        field: 'Peso Inicial na entrada da baia (kg)',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStylePesoEntrada,
        valueSetter: setData
      },
      {
        field: 'Data de pesagem na entrada da baia',
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleDataEntradaBaia,
        valueSetter: setData
      },
      {
        field: 'ADC1',
        headerName: headers[13],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ADC2',
        headerName: headers[14],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ADC3',
        headerName: headers[15],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ADC4',
        headerName: headers[16],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ADC5',
        headerName: headers[17],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      },
      {
        field: 'ADC6',
        headerName: headers[18],
        filter: true,
        minWidth: 60,
        width: 70,
        valueGetter: getData,
        cellStyle: cellStyleData,
        valueSetter: setData
      }
    ]

    setColumnDefsAnimalsVerify(columnsDef)
    setHeaders(true)

    const rowData: any = []

    let rowIndex = 7

    while (worksheet['A' + rowIndex]) {
      const row: any = {}
      Object.keys(columns).forEach((column) => {
        if (!worksheet[column + 8]?.w && ['N', 'O', 'P', 'Q', 'R', 'S'].includes(column)) return

        row[columns[column]] = worksheet[column + rowIndex]?.w
      })
      rowData.push(row)
      rowIndex++
    }
    setRowDataAnimalVerify(rowData)
    toogleModal()
  }

  //save the data from the verify page to the main grid on the animal registration
  const onBtExport = async (feedlot: number) => {
    setIsSaving(true)
    const csvFile = gridRef.current?.api.getDataAsCsv()
    await csvToJson(csvFile, feedlot)
  }
  function checkData(animals: any[]) {
    const breed = [
      'NEL',
      'CB',
      'CRC',
      'CH',
      'CLG',
      'CC',
      'CL',
      'CA',
      'BR',
      'GZ',
      'CLJ',
      'H',
      'HI',
      'BT',
      'SE',
      'BL',
      'NI'
    ]
    const gender = ['MI', 'MC', 'M', 'VC', 'NV']
    for (const a of animals) {
      if (
        a.penDescription == '' ||
        a.lotDescription == '' ||
        a.earring == '' ||
        a.tag == '' ||
        a.genderId == '' ||
        a.breedId == '' ||
        a.ecc == '' ||
        a.initialWeightPen == '' ||
        a.initialWeightFarm == '' ||
        a.entryDateFarm == '' ||
        a.entryDatePen == ''
      ) {
        setAlertMessage('required_fields')
        return 1
      } else {
        if (
          !validator.isInt(a.ecc, { min: 1, max: 9 }) ||
          !validator.isNumeric(a.initialWeightPen) ||
          !validator.isNumeric(a.initialWeightFarm) ||
          !validator.isDate(a.entryDateFarm, { format: 'DD/MM/YYYY', delimiters: ['/'] }) ||
          !validator.isDate(a.entryDatePen, { format: 'DD/MM/YYYY', delimiters: ['/'] }) ||
          !gender.includes(a.genderId) ||
          !breed.includes(a.breedId)
        ) {
          setAlertMessage('incorrect_data')
          return 1
        }
      }
    }
    return 0
  }

  // convert csv to json for the backend
  const csvToJson = (csv: any, feedlotId?: number) => {
    const lines: any = csv?.split('"\r\n"')
    let header: string[] = []
    const result = []

    const firstLine = lines[0].split('","')
    header = [
      'earring',
      'penDescription',
      'lotDescription',
      'tag',
      'sisbov',
      'genderId',
      'breedId',
      'age',
      'ecc',
      'initialWeightFarm',
      'entryDateFarm',
      'initialWeightPen',
      'entryDatePen'
    ]
    if (firstLine[13]) header.push('ADC.' + firstLine[13])
    if (firstLine[14]) header.push('ADC.' + firstLine[14])
    if (firstLine[15]) header.push('ADC.' + firstLine[15])
    if (firstLine[16]) header.push('ADC.' + firstLine[16])
    if (firstLine[17]) header.push('ADC.' + firstLine[17])
    if (firstLine[18]) header.push('ADC.' + firstLine[18])

    let lastColumn = 0
    for (let i = 1; i < lines.length; i++) {
      const obj: any = {}
      const currentline = lines[i].split('","')

      for (let j = 0; j < header.length; j++) {
        obj[header[j]] = currentline[j]
        lastColumn = j
      }

      result.push(obj)
      result[result.length - 1][header[lastColumn]] =
        result[result.length - 1][header[lastColumn]].split('"')[0]
    }
    if (feedlotId && checkData(result) == 0) dispatch(setRegisterAnimals(result, feedlotId))
    else {
      errorAlertRef.current?.openAlert()
    }
  }

  //control the tab selection
  const toggleTab = (index: number) => {
    setToggleState(index)
  }

  //  control the accordion Menu on Animal register
  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  //Register History
  useEffect(() => {
    setRegisterHistory([
      {
        name: 'Marcus Iguma',
        time: '10:40',
        filename: 'Animals.xlsx',
        date: '2022-08-03'
      },
      {
        name: 'Guilherme Rissato',
        time: '10:28',
        filename: 'Animals2.xlsx',
        date: '2022-08-04'
      }
    ])
  }, [])

  //handle the information on history component
  const handleHistory = () => {
    return registerHistory.map((element, key) => {
      return (
        <>
          <History
            key={key}
            name={element.name}
            time={element.time}
            filename={element.filename}
            date={element.date}
          />
        </>
      )
    })
  }
  //handle visibility of the error types
  const handleErrorChange = () => {
    return (
      <>
        <Erros required={true} numeric={true} date={true} breed={true} gender={true} ECC={true} />
      </>
    )
  }

  return (
    <div className={'register-animals'}>
      <Alert ref={successAlertRef} type='success' />
      <Alert ref={errorAlertRef} type='error' customMessage={alertMessage} />
      <Alert ref={warningAlertRef} type='warning' />
      <div className={'register-animals-container-contents-tabs-panel-config'}>
        <Form
          // style={{ width: '98%' }}
          onSubmit={() => {
            null
          }}
        >
          <ExpansionPanel
            expanded={expanded === 'excel-instructions' || expandedAll}
            onChange={handlePanelChange('excel-instructions')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='excel-instructions-content'
              id='excel-instructions-header'
            >
              <Typography>{t('model_sheet_instruction')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div
                className={'register-animals-container-contents-tabs-panel-content-excel-download'}
              >
                <a
                  className={
                    'register-animals-container-contents-tabs-panel-content-excel-download-link'
                  }
                  href='https://s3.us-west-2.amazonaws.com/dev2.appbeeftrader-br.com/static/media/AnimalRegister.xlsx'
                  target='_blank'
                  // onClick={handleDownloadFile}
                  rel='noreferrer'
                >
                  <FontAwesomeIcon
                    className={
                      'register-animals-container-contents-tabs-panel-content-excel-download-link-icon'
                    }
                    icon={faFileExcel}
                    size='5x'
                  />
                  <p
                    className={
                      'register-animals-container-contents-tabs-panel-content-excel-download-link-label'
                    }
                  >
                    Baixe aqui
                    {/* {t('download_sheet_register_animals')} */}
                  </p>
                </a>
              </div>
              <div className={'register-animals-container-contents-tabs-panel-content-youtube'}>
                <div
                  className={'register-animals-container-contents-tabs-panel-content-youtube-link'}
                >
                  <a
                    href='#'
                    target='_blank'
                    rel='noreferrer'
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-icon'
                    }
                  >
                    {' '}
                    <AiFillYoutube size='30px' />
                  </a>
                  <div
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-text'
                    }
                  >
                    {t('whats_register_animals')}
                  </div>
                </div>
                <div
                  className={'register-animals-container-contents-tabs-panel-content-youtube-link'}
                >
                  <a
                    href='#'
                    target='_blank'
                    rel='noreferrer'
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-icon'
                    }
                  >
                    <AiFillYoutube size='30px' />
                  </a>
                  <div
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-text'
                    }
                  >
                    {t('how_fill_sheet')}
                  </div>
                </div>
                <div
                  className={'register-animals-container-contents-tabs-panel-content-youtube-link'}
                >
                  <a
                    href='#'
                    target='_blank'
                    rel='noreferrer'
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-icon'
                    }
                  >
                    {' '}
                    <AiFillYoutube size='30px' />
                  </a>
                  <div
                    className={
                      'register-animals-container-contents-tabs-panel-content-youtube-link-text'
                    }
                  >
                    {t('how_upload_sheet')}
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            expanded={expanded === 'animal-register'}
            onChange={handlePanelChange('animal-register')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='animal-register-content'
              id='animal-register-header'
            >
              <Typography>{t('new_upload_animals')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={'register-animals-container-contents-tabs-panel-content'}>
                <div className={'register-animals-container-contents-tabs-panel-content-import'}>
                  <div
                    className={
                      'register-animals-container-contents-tabs-panel-content-import-input'
                    }
                  >
                    <input
                      type={'File'}
                      className={
                        'register-animals-container-contents-tabs-panel-content-import-input-file'
                      }
                      id={'ImportExcel'}
                      accept={'.xlsx,.xls'}
                      onChange={(e) => {
                        fileChange(e)
                      }}
                    />
                    <label
                      htmlFor='ImportExcel'
                      className={
                        'register-animals-container-contents-tabs-panel-content-import-input-label'
                      }
                    >
                      {t('select_file')}
                      <br /> {t('to_upload')}
                      <div
                        className={
                          'register-animals-container-contents-tabs-panel-content-import-input-label-icon'
                        }
                      >
                        <HiUpload size='20px' />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* <ExpansionPanel
            expanded={expanded === 'register-history'}
            onChange={handlePanelChange('register-history')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='register-history-content'
              id='register-history-header'
            >
              <Typography>Histórico de Cadastro</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={'register-animals-container-contents-tabs-panel-content-history'}>
                {handleHistory()}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel> */}
        </Form>
      </div>

      <Modal title={'Entrada de dados'} isOpen={isModalOpen} onClose={toogleModal}>
        <div className={'register-animals-modal'}>
          <div className={'register-animals-modal-title'}>
            <div className={'register-animals-modal-title-errors'}>{handleErrorChange()}</div>
          </div>
          <div className={'register-animals-modal-table'}>
            <div
              className={'ag-theme-balham'}
              style={{
                height: '100%',
                width: '100%',
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0
              }}
            >
              <AgGridReact
                ref={gridRef}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowData={rowDataAnimalVerify}
                columnDefs={columnDefsAnimalsVerify}
              />
            </div>
          </div>
          <div className='register-animals-button'>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                onBtExport(Number(selectedFeedlot?.id))
              }}
              disabled={animalManagementLoading}
              startIcon={
                animalManagementLoading ? (
                  <CircularProgress style={{ maxWidth: '1.5rem', maxHeight: '1.5rem' }} />
                ) : (
                  <div />
                )
              }
            >
              <text className='text-button'>{t('save')}</text>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RegisterAnimals
