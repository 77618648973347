import {
  FeedlotSlaughterhouseQueryParams,
  FeedlotSlaughterhouseMutationArgs,
  FeedlotConfigurationUpdateArgs,
  FeedlotConfigurationQueryArgs
} from 'api/feedlot-slaughterhouse/types'
import { FeedlotSlaughterhouseConstants } from './types'
import { FeedlotSlaughterhousePriceMutationArgs } from 'api/economic/types'

export const getFeedlotSlaughterhouses = (params?: FeedlotSlaughterhouseQueryParams) => ({
  type: FeedlotSlaughterhouseConstants.ACTION_GET_FEEDLOT_SLAUGHTERHOUSES,
  payload: { params }
})
export const saveFeedlotSlaughterhouse = (
  registerPrices: boolean,
  argsPrices: FeedlotSlaughterhousePriceMutationArgs,
  argsSlaughterhouse?: FeedlotSlaughterhouseMutationArgs
) => ({
  type: FeedlotSlaughterhouseConstants.ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE,
  payload: { argsSlaughterhouse, registerPrices, argsPrices }
})

export const getFeedlotConfiguration = (args: FeedlotConfigurationQueryArgs) => ({
  type: FeedlotSlaughterhouseConstants.ACTION_GET_FEEDLOT_CONFIGURATION,
  payload: { args }
})

export const saveFeedlotConfiguration = (args: FeedlotConfigurationUpdateArgs) => ({
  type: FeedlotSlaughterhouseConstants.ACTION_SAVE_FEEDLOT_CONFIGURATION,
  payload: { args }
})

export const deleteFeedlotSlaughterhouse = (ids: number[]) => ({
  type: FeedlotSlaughterhouseConstants.ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE,
  payload: { ids }
})
