import { all, takeLatest } from 'redux-saga/effects'
import {
  workerGetSelectedAnimals,
  workerGetEconomicDailyAnimals,
  workerGetZootechnicalDataDailyAnimals,
  workerGetAnimalGeneralFunction,
  workerGetWeighingAnimals,
  workerGetEnvironmentalDataDailyAnimals,
  workerGetGenders,
  workerGetWeighing
} from './sagas'
import { AnimalConstants } from './types'

export function* watcherGetSelectedAnimals() {
  yield takeLatest(AnimalConstants.ACTION_GET_ANIMALS, workerGetSelectedAnimals)
}

export function* watcherGetAnimalGeneralFunction() {
  yield takeLatest(
    AnimalConstants.ACTION_GET_ANIMAL_GENERAL_FUNCTION,
    workerGetAnimalGeneralFunction
  )
}

export function* watcherGetEconomicDailyAnimals() {
  yield takeLatest(AnimalConstants.ACTION_GET_ECONOMIC_DAILY_ANIMALS, workerGetEconomicDailyAnimals)
}

export function* watcherGetZootechnicalDataDailyAnimals() {
  yield takeLatest(
    AnimalConstants.ACTION_GET_ZOOTECHNICAL_DAILY_DATA_ANIMALS,
    workerGetZootechnicalDataDailyAnimals
  )
}

export function* watcherGetEnvironmentalDataDailyAnimals() {
  yield takeLatest(
    AnimalConstants.ACTION_GET_ENVIRONMENTAL_DAILY_DATA_ANIMALS,
    workerGetEnvironmentalDataDailyAnimals
  )
}

export function* watcherGetWeighingAnimals() {
  yield takeLatest(AnimalConstants.ACTION_GET_WEIGHING_ANIMALS, workerGetWeighingAnimals)
}

export function* watcherGetWeighing() {
  yield takeLatest(AnimalConstants.ACTION_GET_WEIGHING, workerGetWeighing)
}

export function* watcherGetGenders() {
  yield takeLatest(AnimalConstants.ACTION_GET_GENDERS, workerGetGenders)
}

export default function* animalWatchers() {
  yield all([
    watcherGetSelectedAnimals(),
    watcherGetAnimalGeneralFunction(),
    watcherGetEconomicDailyAnimals(),
    watcherGetZootechnicalDataDailyAnimals(),
    watcherGetEnvironmentalDataDailyAnimals(),
    watcherGetWeighingAnimals(),
    watcherGetGenders(),
    watcherGetWeighing()
  ])
}
