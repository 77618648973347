import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { withRouter } from 'react-router-dom'
import { Dialog, IconButton, Tooltip, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { mdiClose } from '@mdi/js'

// Resources
import './styles.scss'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'

// Methods
import { toggleDrawer } from 'store/main/actions'
import Icon from '@mdi/react'
import { DrawerItem } from 'components/Drawer/types'
import { drawerItens, IconItem } from 'components/Drawer'

const MenuModal = (props: Props) => {
  const { pathname } = props.location
  const { t } = useTranslation()

  const handleClick = (path: string) => {
    props.history.push(path)
    props.toggleDrawer(false)
  }

  const renderMenuItems = () =>
    drawerItens.map((item: DrawerItem, index: number) => {
      if (index % 2 !== 0) {
        const previousItem = drawerItens[index - 1]
        return (
          <div key={item.key} className='menu-modal-row'>
            <Tooltip key={previousItem.key} title={t(previousItem.label) as string}>
              <Button
                className={'menu-modal-button'}
                aria-label={t(previousItem.label)}
                onClick={() => handleClick(previousItem.path)}
              >
                <div className='menu-modal-button-content'>
                  <IconItem item={previousItem} active={previousItem.checkActivePath(pathname)}>
                    {' '}
                  </IconItem>
                  {/* <Icon
                    path={previousItem.icon}
                    size={2}
                    className={`drawer-icons-${
                      previousItem.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  /> */}
                  <p
                    className={`menu-button-text-${
                      previousItem.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  >
                    {t(previousItem.label)}
                  </p>
                </div>
              </Button>
            </Tooltip>
            <Tooltip key={item.key} title={t(item.label) as string}>
              <Button
                className={'menu-modal-button'}
                aria-label={t(item.label)}
                onClick={() => handleClick(item.path)}
              >
                <div className='menu-modal-button-content'>
                  <IconItem item={item} active={item.checkActivePath(pathname)}>
                    {' '}
                  </IconItem>
                  {/* <Icon
                    path={item.icon}
                    size={2}
                    className={`drawer-icons-${
                      item.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  /> */}
                  <p
                    className={`menu-button-text-${
                      item.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  >
                    {t(item.label)}
                  </p>
                </div>
              </Button>
            </Tooltip>
          </div>
        )
      } else if (!drawerItens[index + 1]) {
        return (
          <div className='menu-modal-row' key={item.key}>
            <Tooltip key={item.key} title={t(item.label) as string}>
              <Button
                className={'menu-modal-button'}
                aria-label={t(item.label)}
                onClick={() => handleClick(item.path)}
              >
                <div className='menu-modal-button-content'>
                  <IconItem item={item} active={item.checkActivePath(pathname)}>
                    {' '}
                  </IconItem>
                  {/* <Icon
                    path={item.icon}
                    size={2}
                    className={`drawer-icons-${
                      item.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  /> */}
                  <p
                    className={`menu-button-text-${
                      item.checkActivePath(pathname) ? 'active' : 'inactive'
                    }`}
                  >
                    {t(item.label)}
                  </p>
                </div>
              </Button>
            </Tooltip>
          </div>
        )
      }
    })

  return (
    <Dialog
      className={`modal-menu-${props.theme} modal-menu-${props.isDrawerOpen ? 'open' : 'closed'}`}
      fullScreen
      open={props.isDrawerOpen}
      onClose={() => props.toggleDrawer(false)}
    >
      <div className={'menu-modal-content'}>
        <IconButton
          className={'modal-menu-close-button'}
          aria-label={t('close')}
          onClick={() => {
            props.toggleDrawer(false)
          }}
        >
          <Icon path={mdiClose} size={1.5} className={'modal-menu-close-icon'} />
        </IconButton>
        {renderMenuItems()}
      </div>
    </Dialog>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  isDrawerOpen: store.main.isDrawerOpen
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleDrawer
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(MenuModal))
