import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// Components
import { Button, TextField, Tooltip } from '@material-ui/core'
import CustomMaterialTable from 'components/tables/CustomMaterialTable'
import { MTableToolbar } from 'material-table'
import Loading from 'components/shared/Loading'
import Legend from 'components/shared/Legend'
import ReactExport from 'react-data-export'
import Icon from '@mdi/react'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { mdiFileDownload } from '@mdi/js'

// Types
import { ApplicationStore } from 'store/types'
import { AnimalWeighing, DateType } from './types'

// Methods
import './helpers'
import {
  cellStyle,
  getAnimalsWeighings,
  getCellColor,
  getLotDaysRange,
  getTableColumnName,
  getWeighingsPeriod,
  handleDateChange
} from './helpers'
import { getWeighing } from 'store/animal/actions'
import _ from 'lodash'

const LotWeighingTable = () => {
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)
  const { selectedLot, lotLoading } = useSelector((state: ApplicationStore) => state.lot)
  const { weighing, weighingLoading } = useSelector((state: ApplicationStore) => state.animal)
  const [weighingsPeriod, setWeighingsPeriod] = React.useState(
    getWeighingsPeriod(new Date(new Date().setDate(new Date().getDate() - 6)), new Date())
  )
  const [lotInitDate, setLotInitDate] = React.useState('')
  const [lotDaysRange, setLotDaysRange] = React.useState<[number, number] | []>([])
  const [animalsWeighings, setAnimalsWeighings] = React.useState<AnimalWeighing[]>([])
  const [animalsWeighingsFiltered, setAnimalsWeighingsFiltered] = React.useState<AnimalWeighing[]>(
    []
  )
  const [columns, setColumns] = React.useState<Record<string, any>[]>([])
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [weighingsFile, setWeighingsFile] = React.useState<any>([
    {
      columns: [],
      data: []
    }
  ])
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelSheet
  const exportTxt = t('export_to_xlsx')

  useEffect(() => {
    if (selectedFeedlot?.id && selectedLot && !weighing.length) {
      dispatch(getWeighing(selectedFeedlot?.id, selectedLot.id))
    }
  }, [selectedFeedlot, selectedLot])

  useEffect(() => {
    if (!weighingLoading && weighing?.length) loadingWeighing()
  }, [weighing])

  const loadingWeighing = () => {
    const _lotInitDate = selectedLot?.initDate
    let animals = weighing.map((w) => w.earring)
    animals = _.uniq(animals)
    const aux: AnimalWeighing[] = []
    animals.map((a) => {
      const weighings = weighing.filter((w) => w.earring === a)
      aux.push({
        earring: a?.toString() ?? '',
        weighings: weighings.map((w) => {
          return {
            color: getCellColor(w?.type, theme),
            date: moment(_lotInitDate)
              .add(w.day - 1, 'days')
              .toDate(),
            time: w.day,
            type: w.type,
            weight: w.weight
          }
        })
      })
    })
    if (_lotInitDate) setLotInitDate(_lotInitDate)
    getDaysRange(_lotInitDate)
    setAnimalsWeighings(aux)
  }

  const getDaysRange = (initDate?: string) => {
    const [startDay, endDay] = getLotDaysRange(
      initDate ?? lotInitDate ?? weighingsPeriod.startDate,
      weighingsPeriod
    )

    if (startDay && endDay) {
      setLotDaysRange([startDay, endDay])
    } else {
      setLotDaysRange([])
    }
  }

  useEffect(() => {
    getDaysRange()
  }, [weighingsPeriod])

  useEffect(() => {
    if (animalsWeighings.length && lotDaysRange.length) {
      getAnimalsWeighings(
        setAnimalsWeighings,
        setAnimalsWeighingsFiltered,
        animalsWeighings,
        lotDaysRange,
        theme,
        selectedFeedlot?.id,
        selectedLot
      )
    }
  }, [lotDaysRange, animalsWeighings])

  useEffect(() => {
    if (animalsWeighingsFiltered.length && lotDaysRange.length && lotInitDate !== '') {
      const cols: any = [
        {
          title: t('animal:earring'),
          field: 'earring'
        }
      ]
      for (let i = 0; i <= lotDaysRange[1] - lotDaysRange[0]; i++) {
        cols.push({
          title: getTableColumnName(i, lotDaysRange, lotInitDate),
          field: 'weighings[' + i + '].weight',
          type: 'numeric',
          cellStyle: (value: any, rowData: any) => cellStyle(value, rowData, i)
        })
      }
      setColumns(cols)
    }
  }, [animalsWeighingsFiltered, lotDaysRange])

  useEffect(() => {
    const file: any = [
      {
        columns: [],
        data: []
      }
    ]

    columns.map((c: any) => {
      file[0].columns.push({ title: c.title, width: { wpx: 100 } })
    })

    animalsWeighingsFiltered.map((a: any) => {
      const animalWeights: any = []
      animalWeights.push({
        value: a.earring,
        style: { font: { color: { rgb: 'FF000000' } } }
      })
      a.weighings.map((w: any) => {
        animalWeights.push({
          value: w.weight,
          style: { font: { color: { rgb: 'FF' + w.color } } }
        })
      })
      file[0].data.push(animalWeights)
    })
    setWeighingsFile(file)
  }, [animalsWeighingsFiltered, columns])

  return (
    <div className='lot-weighings-body'>
      {weighingLoading || lotLoading ? (
        <Loading />
      ) : (
        <>
          <Legend average={false} />
          <CustomMaterialTable
            style={{ width: '100%' }}
            columns={columns}
            data={columns && animalsWeighings.length ? animalsWeighingsFiltered : []}
            options={{
              showTitle: false,
              pageSize: 20,
              searchFieldAlignment: 'right'
              // exportButton: { csv: true },
              // exportAllData: false,
              // exportCsv
            }}
            title='Pesagens'
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <div className={'toolbar'}>
                    <TextField
                      id='initDate'
                      label={t('from')}
                      helperText={
                        lotInitDate
                          ? `${t('lot:lot_start')}: ${moment(lotInitDate).format('MM/DD/YYYY')}`
                          : ''
                      }
                      type='date'
                      defaultValue={weighingsPeriod.start}
                      onMouseLeave={(event: any) =>
                        handleDateChange(DateType.start, event, weighingsPeriod, setWeighingsPeriod)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      className='input-margin'
                    />
                    <TextField
                      id='endDate'
                      label={t('to')}
                      type='date'
                      defaultValue={weighingsPeriod.end}
                      onMouseLeave={(event: any) =>
                        handleDateChange(DateType.end, event, weighingsPeriod, setWeighingsPeriod)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      className='input-margin'
                    />
                    <ExcelFile
                      filename={t('animal:weighings') + ' ' + selectedFeedlot?.description}
                      element={
                        <Tooltip title={exportTxt}>
                          <Button>
                            <Icon
                              path={mdiFileDownload}
                              size={1}
                              color={colors(theme, Colors.grayDark)}
                            />
                          </Button>
                        </Tooltip>
                      }
                    >
                      <ExcelSheet
                        dataSet={weighingsFile}
                        name={
                          t('feedlot:pen') +
                          ' ' +
                          selectedLot?.pen?.code +
                          ' - ' +
                          t('lot:lot') +
                          ' ' +
                          selectedLot?.description
                        }
                      />
                    </ExcelFile>
                  </div>
                </div>
              )
            }}
          />
        </>
      )}
    </div>
  )
}

export default LotWeighingTable
