import { call, select, put, Effect } from 'redux-saga/effects'
import _ from 'lodash'

// Types
import { MainConstants } from 'store/main/types'

// Methods
import { workerCheckAuthentication, workerSignOut } from 'store/auth/sagas'
import { getFeedlotsRequest } from 'api/feedlot'
import Exception from 'helpers/Exception'
import { FeedlotConstants, Feedlot, NextSale, Lot } from './types'
import Codes from 'helpers/Codes'
import { LotConstants } from 'store/lot/types'
import { AnimalConstants } from 'store/animal/types'
import { PenConstants } from 'store/pen/types'
import moment from 'moment'
import { NextSaleArgs } from 'api/feedlot/types'
import { FeedlotSlaughterhouseConstants } from 'store/feedlot-slaughterhouse/types'

function* putFeedlotData(payload: any) {
  yield put({
    type: FeedlotConstants.REDUCER_SET_FEEDLOT_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetSelectedFeedlots(action: Effect): any {
  try {
    yield call(putFeedlotData, { feedlotLoading: true })
    yield call(workerCheckAuthentication)

    const feedlotStore = yield select((s) => s.feedlot)
    let {
      feedlots,
      selectedFeedlot,
      scaledLots,
      nextSaleLots,
      initialLots,
      scaledLotsAnimalsNumber,
      nextSaleLotsAnimalsNumber,
      initialLotsAnimalsNumber
    } = feedlotStore

    const { nextSalesArgs } = feedlotStore
    const { feedlotId } = action.payload
    if (selectedFeedlot && selectedFeedlot.id == feedlotId) {
      yield call(putFeedlotData, { feedlotLoading: false })
      return
    }
    if (!feedlots.length) {
      const queryArgs: NextSaleArgs = {
        minTime: 1,
        maxTime: nextSalesArgs.nextLotsMaxSaleTime,
        saleType: 1
      }
      feedlots = yield call(getFeedlotsRequest, queryArgs)
    }
    selectedFeedlot = _.find(feedlots, (k: Feedlot) => k.id === feedlotId) || feedlots[0]
    if (!feedlots.length || !selectedFeedlot) {
      throw new Exception(Codes.UserInteraction.FEEDLOT_NOT_FOUND, 'workerGetSelectedFeedlots')
    }
    scaledLots = []
    nextSaleLots = []
    initialLots = []
    scaledLotsAnimalsNumber = 0
    nextSaleLotsAnimalsNumber = 0
    initialLotsAnimalsNumber = 0

    if (selectedFeedlot.nextSales.length) {
      const now = moment(new Date())

      selectedFeedlot.nextSales.map((l: NextSale) => {
        const saleDate = moment(l.date)
        const duration = moment.duration(saleDate.diff(now))
        const daysToSale = duration.asDays()

        if (daysToSale <= nextSalesArgs.scaledLotsMaxSaleTime) {
          scaledLots.push(l)
          scaledLotsAnimalsNumber += l.animalsNumber
        } else if (
          daysToSale > nextSalesArgs.scaledLotsMaxSaleTime &&
          daysToSale <= nextSalesArgs.nextLotsMaxSaleTime
        ) {
          nextSaleLots.push(l)
          nextSaleLotsAnimalsNumber += l.animalsNumber
        }
      })
    }

    if (selectedFeedlot.lots) {
      initialLots = []
      const now = moment(new Date())
      selectedFeedlot.lots.map((l: Lot) => {
        const initDate = moment(l.initDate)
        const lotDuration = moment.duration(now.diff(initDate))
        if (lotDuration.asDays() < nextSalesArgs.maxFeedlotTime) {
          const lot: NextSale = {
            lotId: l.id,
            description: l.description,
            date: new Date(l.initDate),
            animalsNumber: l.animalsCount
          }
          initialLotsAnimalsNumber += l.animalsCount

          initialLots.push(lot)
        }
      })
    }
    yield call(putFeedlotData, {
      feedlots,
      selectedFeedlot,
      scaledLots,
      nextSaleLots,
      initialLots,
      initialLotsAnimalsNumber,
      scaledLotsAnimalsNumber,
      nextSaleLotsAnimalsNumber,
      feedlotLoading: false,
      selectedSaleType: 1
    })
    yield put({
      type: LotConstants.REDUCER_CLEAR_LOT_DATA
    })
    yield put({
      type: PenConstants.REDUCER_CLEAR_PEN_DATA
    })

    yield put({
      type: AnimalConstants.REDUCER_CLEAR_ANIMAL_DATA
    })

    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA
    })
    yield put({
      type: PenConstants.ACTION_GET_SELECTED_PEN,
      payload: {
        feedlotId: selectedFeedlot.id
      }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}

export function* workerGetFeedlotNextSales(action: Effect): any {
  try {
    yield call(putFeedlotData, { feedlotNextSalesLoading: true })
    yield call(workerCheckAuthentication)

    const feedlotStore = yield select((s) => s.feedlot)
    let {
      feedlots,
      selectedFeedlot,
      scaledLots,
      nextSaleLots,
      initialLots,
      scaledLotsAnimalsNumber,
      nextSaleLotsAnimalsNumber,
      initialLotsAnimalsNumber
    } = feedlotStore

    const { nextSalesArgs } = feedlotStore
    const { feedlotId, saleType } = action.payload

    const queryArgs: NextSaleArgs = {
      minTime: 1,
      maxTime: nextSalesArgs.nextLotsMaxSaleTime,
      saleType: saleType
    }
    feedlots = yield call(getFeedlotsRequest, queryArgs)

    selectedFeedlot = _.find(feedlots, (k: Feedlot) => k.id === feedlotId) || feedlots[0]
    if (!feedlots.length || !selectedFeedlot) {
      throw new Exception(Codes.UserInteraction.FEEDLOT_NOT_FOUND, 'workerGetSelectedFeedlots')
    }
    scaledLots = []
    nextSaleLots = []
    initialLots = []
    scaledLotsAnimalsNumber = 0
    nextSaleLotsAnimalsNumber = 0
    initialLotsAnimalsNumber = 0

    if (selectedFeedlot.nextSales.length) {
      const now = moment(new Date())

      selectedFeedlot.nextSales.map((l: NextSale) => {
        const saleDate = moment(l.date)
        const duration = moment.duration(saleDate.diff(now))
        const daysToSale = duration.asDays()

        if (daysToSale <= nextSalesArgs.scaledLotsMaxSaleTime) {
          scaledLots.push(l)
          scaledLotsAnimalsNumber += l.animalsNumber
        } else if (
          daysToSale > nextSalesArgs.scaledLotsMaxSaleTime &&
          daysToSale <= nextSalesArgs.nextLotsMaxSaleTime
        ) {
          nextSaleLots.push(l)
          nextSaleLotsAnimalsNumber += l.animalsNumber
        }
      })
    }

    if (selectedFeedlot.lots) {
      initialLots = []
      const now = moment(new Date())
      selectedFeedlot.lots.map((l: Lot) => {
        const initDate = moment(l.initDate)
        const lotDuration = moment.duration(now.diff(initDate))
        if (lotDuration.asDays() < nextSalesArgs.maxFeedlotTime) {
          const lot: NextSale = {
            lotId: l.id,
            description: l.description,
            date: new Date(l.initDate),
            animalsNumber: l.animalsCount
          }
          initialLotsAnimalsNumber += l.animalsCount

          initialLots.push(lot)
        }
      })
    }
    yield call(putFeedlotData, {
      scaledLots,
      nextSaleLots,
      initialLots,
      initialLotsAnimalsNumber,
      scaledLotsAnimalsNumber,
      nextSaleLotsAnimalsNumber,
      feedlotNextSalesLoading: false,
      selectedSaleType: saleType ?? 1
    })
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}
