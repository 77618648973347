import React from 'react'
import { Props } from './types'

const ChartIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 110 78.74'
      className={'card-icon'}
    >
      <g id='chartIcon' transform='translate(-20 -35.63)'>
        <path
          id='Caminho_18'
          data-name='Caminho 18'
          d='M54.87,114.07H34.77V67.82h20.1Zm-16.1-4h12.1V71.82H38.77Z'
          fill={fill}
        />
        <path
          id='Caminho_19'
          data-name='Caminho 19'
          d='M85.05,114.07H65V51.72H85.1Zm-16.1-4h12.1V55.72H69Z'
          fill={fill}
        />
        <path
          id='Caminho_20'
          data-name='Caminho 20'
          d='M115.23,114.07H95.13V35.63h20.1Zm-16.1-4h12.1V39.63H99.13Z'
          fill={fill}
        />
        <rect
          id='Retângulo_68'
          data-name='Retângulo 68'
          width='110'
          height='4'
          transform='translate(20 110.37)'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default ChartIcon
