import produce from 'immer'
import { AnyAction } from 'redux'
import { number } from 'yup'

import { AnimalStore, AnimalConstants } from './types'

const INITIAL_STORE: AnimalStore = {
  animals: [],
  allWeighinsLoaded: false,
  selectedAnimal: undefined,
  animalsEconomicDaily: [],
  animalsZootechnicalDailyData: [],
  animalsWeighingData: [],
  animalLoading: false,
  animalEconomicDailyLoading: false,
  animalZootechnicalDailyDataLoading: false,
  animalWeighingLoading: false,
  animalWeighingComplete: false,
  animalsWeighingPage: 1,
  weighing: [],
  weighingLoading: false,
  animalsEnvironmentalDailyData: [],
  animalEnvironmentalDailyDataLoading: false,
  animalGendersLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case AnimalConstants.REDUCER_SET_ANIMAL_DATA:
        Object.assign(draft, payload)
        break
      case AnimalConstants.REDUCER_CLEAR_ANIMAL_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
