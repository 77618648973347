import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Resources
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'

// Components

// Methods
import { getSelectedLot } from 'store/lot/actions'
import { useTranslation } from 'react-i18next'
import { DataType, MainConstants } from 'store/main/types'
import AnimalProfitTable from 'components/tables/animal/AnimalProfitTable'
import AnimalZootechnicalTable from 'components/tables/animal/AnimalZootechnicalTable'
import AnimalEnvironmentalTable from 'components/tables/animal/AnimalEnvironmentalTable'
import CardDataTypeButtons from 'components/shared/CardDataTypeButtons'
import { Box, MenuItem, Select } from '@material-ui/core'
import { getSelectedPen } from 'store/pen/actions'
import FormatDate from 'helpers/FormatDate'
import CardSalesButtons from 'components/shared/CardSalesButtons'
import { LotProfitBySale } from 'store/profit/types'
import { setSaleDate } from 'pages/helpers'
import CustomSelectCategory from 'components/shared/CustomSelectCategory'
import moment from 'moment'

const Animals: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedFeedlot, selectedSaleType } = useSelector(
    (state: ApplicationStore) => state.feedlot
  )
  const { dataType } = useSelector((state: ApplicationStore) => state.main)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const { pens, selectedPen, penLoading } = useSelector((state: ApplicationStore) => state.pen)
  const [lotSelected, setLotSelected] = useState<number>(selectedLot ? selectedLot.id : 0)
  const [listGroupLots, setListGroupLots] = useState<any[]>([])
  const [sales, setSales] = useState(selectedSaleType)
  const [saleData, setSaleData] = useState<LotProfitBySale>()

  const handleDataType = (dataTypeSelected: any) => {
    dispatch({
      type: MainConstants.ACTION_SET_DATA_TYPE,
      payload: { dataType: dataTypeSelected }
    })
  }

  const getSalesDate = (sale: number) => {
    setSales(sale)
    if (selectedLot) {
      const data = selectedLot.profit?.beeftraderMethod.find((p) => p.saleType === sale)
      if (data) setSaleData(data)
    } else {
      setSaleData(undefined)
    }
  }

  useEffect(() => {
    if (selectedLot?.id) {
      setLotSelected(selectedLot?.id)
    }
  }, [selectedLot])

  useEffect(() => {
    if (selectedPen && !penLoading && pens) {
      setListGroupLots(pens)
    }
  }, [penLoading])

  const handleFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    // const selected: number = event.target.value as number
    if (selectedFeedlot) {
      dispatch(getSelectedLot(selectedFeedlot.id, event.target.value as number))
      setLotSelected(event.target.value as number)
    }
  }
  return (
    <div className={'animals-body'}>
      <div className={'animals-card'}>
        <div className={'animals-report-column'} style={{ width: '75%' }}>
          <div className={'animals-report-row'}>
            <CardDataTypeButtons
              t={t}
              selectedType={dataType}
              handleTypeChange={(x) => handleDataType(x)}
            />
          </div>
          <div className={'animals-report-row'}>
            <div className='group'>
              <div className='group-select'>
                <CustomSelectCategory
                  textFixed={t('feedlot:pen_lot')}
                  onChangeValue={handleFields}
                  value={listGroupLots}
                  lotSelected={lotSelected}
                />
              </div>
            </div>
            <CardSalesButtons
              t={t}
              selectedSales={sales}
              handleSalesChange={(sales) => getSalesDate(sales)}
            />
            <div className={'subheader-table'}>
              {saleData?.sale.map((p, index: number) => (
                <div className={'group-sales'} key={index}>
                  <p className={'sale-text'} key={index}>
                    {index + 1}ª {t('sale')}:{' '}
                  </p>
                  <p className={'description-text'} key={index}>
                    {FormatDate(setSaleDate(selectedLot?.initDate, p.predictedTime))}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={'animals-report-column'} style={{ width: '25%' }}>
          <div className={'animals-report-row'}>
            <Box className='box'>
              <div className={'animals-report-row'}>
                <text className={'text-title'}>{t('lot_summary')}</text>
              </div>
              <div className={'animals-report-row'}>
                <div className='group-value'>
                  <text className={'text-value'}>
                    {FormatDate(selectedLot?.initDate as string)}
                  </text>
                  <text className={'text-item'}>{t('start_date')}</text>
                </div>
                <div className='group-value'>
                  <text className={'text-value'}>{selectedLot?.animalsCount}</text>
                  <text className={'text-item'}>{t('animal:animals')}</text>
                </div>
                <div className='group-value'>
                  <text className={'text-value'}>
                    {Number(moment().diff(moment(selectedLot?.initDate), 'days')) + 1}
                  </text>
                  <text className={'text-item'}>{t('time_feedlot')}</text>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className={'animals-card'}>
        <div className={'animals-report-row'}>
          {dataType === DataType.ECONOMIC ? (
            <AnimalProfitTable sale={sales} />
          ) : dataType === DataType.ZOOTECHNICAL ? (
            <AnimalZootechnicalTable sale={sales} />
          ) : dataType === DataType.ENVIRONMENTAL ? (
            <AnimalEnvironmentalTable sale={sales} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Animals
