import produce from 'immer'
import { AnyAction } from 'redux'

import { AnimalManagementStore, AnimalManagementConstants } from './types'

const INITIAL_STORE: AnimalManagementStore = {
  animalManagementLoading: false,
  messageRegister: ''
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case AnimalManagementConstants.REDUCER_SET_ANIMAL_MANAGEMENT:
        Object.assign(draft, payload)
        break
      case AnimalManagementConstants.REDUCER_SET_ANIMAL_MANAGEMENT:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
