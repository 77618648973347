import { all, takeLatest } from 'redux-saga/effects'

// Types
import { MainConstants } from './types'

// Methods
import {
  workerCheckSettings,
  workerSetTheme,
  workerSetLocale,
  workerSetDataType,
  workerSetColumnsAnimalEconomic,
  workerSetColumnsAnimalBasic,
  workerSetColumnsAnimalCurrentEconomic,
  workerSetColumnsAnimalZootechnical,
  workerSetColumnsAnimalCurrentZootechnical,
  workerSetColumnsAnimalCurrentEnvironmental,
  workerSetColumnsAnimalEnvironmental,
  workerSetColumnsLot
} from './sagas'

export function* watcherCheckSettings() {
  yield takeLatest(MainConstants.ACTION_CHECK_SETTINGS, workerCheckSettings)
}

export function* watcherSetTheme() {
  yield takeLatest(MainConstants.ACTION_SET_THEME, workerSetTheme)
}
export function* watcherSetDataType() {
  yield takeLatest(MainConstants.ACTION_SET_DATA_TYPE, workerSetDataType)
}

export function* watcherSetColumnsAnimalBasic() {
  yield takeLatest(MainConstants.ACTION_SET_COLUMNS_ANIMAL_BASIC, workerSetColumnsAnimalBasic)
}

export function* watcherSetColumnsAnimalEconomic() {
  yield takeLatest(MainConstants.ACTION_SET_COLUMNS_ANIMAL_ECONOMIC, workerSetColumnsAnimalEconomic)
}

export function* watcherSetColumnsAnimalCurrentEconomic() {
  yield takeLatest(
    MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ECONOMIC,
    workerSetColumnsAnimalCurrentEconomic
  )
}

export function* watcherSetColumnsAnimalZootechnical() {
  yield takeLatest(
    MainConstants.ACTION_SET_COLUMNS_ANIMAL_ZOOTECHNICAL,
    workerSetColumnsAnimalZootechnical
  )
}

export function* watcherSetColumnsAnimalCurrentZootechnical() {
  yield takeLatest(
    MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ZOOTECHNICAL,
    workerSetColumnsAnimalCurrentZootechnical
  )
}

export function* watcherSetColumnsAnimalEnvironmental() {
  yield takeLatest(
    MainConstants.ACTION_SET_COLUMNS_ANIMAL_ENVIRONMENTAL,
    workerSetColumnsAnimalEnvironmental
  )
}

export function* watcherSetColumnsAnimalCurrentEnvironmental() {
  yield takeLatest(
    MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ENVIRONMENTAL,
    workerSetColumnsAnimalCurrentEnvironmental
  )
}
export function* watcherSetColumnsLot() {
  yield takeLatest(MainConstants.ACTION_SET_COLUMNS_LOT, workerSetColumnsLot)
}

export function* watcherSetLocale() {
  yield takeLatest(MainConstants.ACTION_SET_LOCALE, workerSetLocale)
}

export default function* mainWatchers() {
  yield all([
    watcherCheckSettings(),
    watcherSetTheme(),
    watcherSetDataType(),
    watcherSetLocale(),
    watcherSetColumnsAnimalBasic(),
    watcherSetColumnsAnimalEconomic(),
    watcherSetColumnsAnimalCurrentEconomic(),
    watcherSetColumnsAnimalCurrentZootechnical(),
    watcherSetColumnsAnimalZootechnical(),
    watcherSetColumnsAnimalEnvironmental(),
    watcherSetColumnsAnimalCurrentEnvironmental(),
    watcherSetColumnsLot()
  ])
}
