import { put, call, Effect } from 'redux-saga/effects'

// Types
import { EconomicConstants } from './types'
import { MainConstants } from 'store/main/types'

// Methods
import Exception from 'helpers/Exception'
import { workerCheckAuthentication, workerSignOut } from '../auth/sagas'
import {
  createFeedlotSlaughterhousePrice,
  deleteFeedlotSlaughterhousePrice,
  getCurrency,
  getFeedlotSlaughterhousePrice,
  getQuotedSlaughterhousePrice,
  getWeightUnity,
  updateFeedlotSlaughterhousePrice
} from 'api/economic'
import { FeedlotSlaughterhouseConstants } from 'store/feedlot-slaughterhouse/types'

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetFeedlotSlaughterhousePrice(action: Effect): any {
  const { params } = action.payload
  try {
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { feedlotSlaughterhousePriceLoading: true }
    })
    yield call(workerCheckAuthentication)
    const feedlotSlaughterhousePrice = yield call(getFeedlotSlaughterhousePrice, params)
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: {
        feedlotSlaughterhousePriceLoading: false,
        feedlotSlaughterhousePrice: [...feedlotSlaughterhousePrice],
        selectedFeedlotSlaughterhousePrice: undefined
      }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: {
        feedlotSlaughterhousePriceLoading: false
      }
    })
  }
}

export function* workerGetQuoteSlaughterhousePrice(action: Effect): any {
  const { params } = action.payload
  try {
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { quoteSlaughterhousePriceLoading: true }
    })
    yield call(workerCheckAuthentication)

    const quoteSlaughterhousePrice = yield call(getQuotedSlaughterhousePrice, params)
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: {
        quoteSlaughterhousePriceLoading: false,
        quoteSlaughterhousePrice: [...quoteSlaughterhousePrice]
      }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: {
        quoteSlaughterhousePriceLoading: false
      }
    })
  }
}

export function* workerSaveFeedlotSlaughterhousePrice(action: Effect): any {
  const { args } = action.payload
  let { feedlotSlaughterhousePrice } = args
  try {
    // yield put({
    //   type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
    //   payload: { feedlotSlaughterhousePriceLoading: true }
    // })
    yield call(workerCheckAuthentication)

    if (!feedlotSlaughterhousePrice[0].id) yield call(createFeedlotSlaughterhousePrice, args)
    else yield call(updateFeedlotSlaughterhousePrice, args)
    feedlotSlaughterhousePrice = yield call(getFeedlotSlaughterhousePrice, {
      args: { feedlotSlaughterhouseId: feedlotSlaughterhousePrice[0].feedlotSlaughterhouseId }
    })
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: {
        feedlotSlaughterhousePrice: [...feedlotSlaughterhousePrice]
        // feedlotSlaughterhousePriceLoading: false
      }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    // yield put({
    //   type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
    //   payload: {
    //     feedlotSlaughterhousePriceLoading: false
    //   }
    // })
  }
}

export function* workerGetCurrency(action: Effect): any {
  const { params } = action.payload
  try {
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { currencyLoading: true }
    })
    yield call(workerCheckAuthentication)

    const currencies = yield call(getCurrency, params)
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { currencyLoading: false, currencies }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { currencyLoading: false }
    })
  }
}

export function* workerGetWeightUnity(action: Effect): any {
  const { params } = action.payload
  try {
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { weightUnityLoading: true }
    })
    yield call(workerCheckAuthentication)

    const weightUnits = yield call(getWeightUnity, params)

    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { weightUnityLoading: false, weightUnits }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
    yield put({
      type: EconomicConstants.REDUCER_SET_ECONOMIC_DATA,
      payload: { weightUnityLoading: false }
    })
  }
}

export function* workerDeleteFeedlotSlaughterhousePrice(action: Effect): any {
  const { ids } = action.payload
  try {
    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA,
      payload: { saving: true }
    })

    yield call(workerCheckAuthentication)
    console.log(ids)
    yield call(deleteFeedlotSlaughterhousePrice, { ids })

    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA,
      payload: { saving: false }
    })
  } catch (error: any) {
    console.log(error)
    if (
      error?.code === 401 ||
      (error?.additionalInfo && error?.additionalInfo[0]?.statusCode === 401)
    )
      yield call(workerSignOut)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }

    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA,
      payload: { saving: false }
    })
  }
}
