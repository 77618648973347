import { Themes, ColumnsCollapse } from 'store/main/types'
import { Theme, makeStyles, createStyles } from '@material-ui/core'

export type Order = 'asc' | 'desc'
export interface Filter {
  id: any
  type: 'string' | 'numeric'
  condition: string
  value: any
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      // backgroundColor: 'blue',
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0.1,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
)
export interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  onFilterData: (
    event: React.ChangeEvent<unknown>,
    property: any,
    condition: string,
    value: any,
    fieldType: 'string' | 'numeric'
  ) => void
  onActiveColumns: (event: React.MouseEvent<unknown>, info: ColumnsCollapse) => void
  onResetFilterData: (property: any) => void
  order: Order
  orderBy: string
  rowCount: number
  columns: any
  columnsCollapse: ColumnsCollapse[]
  columnsAmountNull: number[]
  filters: Filter[]
  theme: Themes
}
type StoreProps = {
  theme: Themes
}
type DispatchProps = {
  columns: ColumnsCollapse[]
  data: any
  onCollapseColumns?: any
}

export type Props = StoreProps & DispatchProps

export type HeaderCSV = {
  label: string
  key: string
}
