import React from 'react'
import { useTranslation } from 'react-i18next'
// Types
import { Props } from './types'

import './styles.scss'
//Components
import {
  Select,
  MenuItem,
  ListItemText,
  Input,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormControl
} from '@material-ui/core'

const CustomSelectMultipleSublevel = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className={'custom-select-multiple'}>
      <FormControl>
        <InputLabel id='demo-select-small'>{props.textFixed ? t(props.textFixed) : ''}</InputLabel>
        <Select
          // classes={{ root: 'select-root', icon: 'select-icon', select: 'select' }}
          multiple
          style={{ width: '160px' }}
          value={props.activeLots}
          // input={<Input disableUnderline={true} />}
          renderValue={(selected) => {
            const values: string[] = selected as string[]
            const dataLabel: string[] = []
            values.map((s) => {
              for (const v of props.value) {
                for (const c of v.children) {
                  if (c.id == s) {
                    dataLabel.push(c.label)
                    break
                  }
                }
              }
            })
            return dataLabel.map((f) => t(f)).join(', ')
          }}
        >
          {props.value.map((name) => (
            <MenuItem style={{ flexDirection: 'column' }} key={name.id} value={name.id}>
              <FormControlLabel
                style={{ fontWeight: 'bold' }}
                label={t('feedlot:pen') + ' ' + name.label}
                control={
                  <Checkbox
                    indeterminate={name.indeterminate}
                    checked={name.active}
                    onChange={() => {
                      props.onChangeValuePen(name.id)
                    }}
                  />
                }
              />
              {name.children.map((n: any) => (
                <MenuItem key={n.id} value={n.id}>
                  <FormControlLabel
                    label={n.label}
                    control={
                      <Checkbox
                        checked={n.active}
                        onChange={() => {
                          props.onChangeValue(name.id, n.id)
                        }}
                      />
                    }
                  />
                </MenuItem>
              ))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelectMultipleSublevel
