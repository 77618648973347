export type FeedlotStore = {
  feedlots: Feedlot[]
  selectedFeedlot?: Feedlot
  feedlotLoading: boolean
  feedlotNextSalesLoading: boolean
  selectedSale?: number
  selectedSaleType: number
  nextSalesArgs: nextSalesArgs
  scaledLots?: NextSale[]
  nextSaleLots?: NextSale[]
  initialLots?: NextSale[]
  scaledLotsAnimalsNumber?: number
  nextSaleLotsAnimalsNumber?: number
  initialLotsAnimalsNumber?: number
}

export type nextSalesArgs = {
  scaledLotsMaxSaleTime: number
  nextLotsMaxSaleTime: number
  maxFeedlotTime: number
}

export type Feedlot = {
  id: number
  description: string
  pensCount?: number
  animalsCount?: number
  animalsDelayedWeighingCount?: number
  lotsCount?: number
  lots?: Lot[]
  pens?: Pen[]
  profit?: FeedlotProfit
  currentZootechnicalData?: ZootechnicalPenData[]
  nextSales?: NextSale[]
}

export type NextSale = {
  lotId: number
  description: string
  date: Date
  sale?: number
  animalsNumber: number
}

export type Pen = {
  id: number
  code: string
}

export type Lot = {
  id: number
  description: string
  initDate: string
  animalsCount: number
}

export type FeedlotProfit = {
  traditional: number
  current: number
  beeftrader: Profit[]
}

export type Profit = {
  sales: number
  profit: number
}

export type ZootechnicalPenData = {
  penId: number
  lotsData: ZootechnicalLotData[]
}

export type ZootechnicalLotData = {
  lotId: number
  weight: number
  dwg: number
}

export enum FeedlotConstants {
  ACTION_GET_SELECTED_FEEDLOT = 'ACTION_GET_SELECTED_FEEDLOT',
  ACTION_GET_FEEDLOT_NEXT_SALES = 'ACTION_GET_FEEDLOT_NEXT_SALES',
  REDUCER_CLEAR_FEEDLOT_DATA = 'REDUCER_CLEAR_FEEDLOT_DATA',
  REDUCER_SET_FEEDLOT_DATA = 'REDUCER_SET_FEEDLOT_DATA'
}
