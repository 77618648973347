import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

// Types
import {
  NutritionDataRef,
  NutritionDataProps,
  lotNutritionQuery,
  LotNutritionGridData
} from './types'
import { LotNutrition } from 'api/params/types'

// Methods
import {
  getLotNutritions,
  createLotNutritions,
  updateLotNutritions,
  deleteLotNutritions
} from 'api/params'

const NutritionData: React.RefForwardingComponent<NutritionDataRef, NutritionDataProps> = (
  {
    selectedLot,
    nutritions,
    setNutritions,
    nutritionGridActions,
    setNutritionGridActions
  }: NutritionDataProps,
  ref
) => {
  function setNutritionGridDataNewIds(newNutritionIds: number[]) {
    const currentNutritionsGridData = [...nutritions.filter((n) => n.id !== 0)]
    const newNutritionsGridData = [...nutritions.filter((n) => n.id === 0)]
    const newNutritionsWithIds = newNutritionsGridData.map((n, index) => {
      return { ...n, id: newNutritionIds[index] }
    })

    setNutritions([...currentNutritionsGridData, ...newNutritionsWithIds])
  }

  async function submit() {
    function convertGridDataToLotNutritions(
      nutritionsGridData: LotNutritionGridData[] | undefined
    ): LotNutrition[] | undefined {
      return (
        nutritionsGridData?.map((n) => {
          return {
            id: n.id,
            lotId: n.lotId,
            category: n.category,
            start: n.start,
            end: n.end,
            tdn: n.tdn,
            dryMatter: n.dryMatter,
            dietCost: n.dietCost
          }
        }) ?? []
      )
    }

    try {
      const newNutritionsGridData = [...nutritions.filter((n) => n.id === 0)]
      const updateNutritionsGridData = [
        ...nutritions.filter((n) => nutritionGridActions.update?.some((nga) => n.id === nga))
      ]

      const newNutritions = convertGridDataToLotNutritions(newNutritionsGridData)
      const updateNutritions = convertGridDataToLotNutritions(updateNutritionsGridData)
      const deleteNutritions = convertGridDataToLotNutritions(nutritionGridActions.delete)

      if (newNutritions !== undefined && newNutritions?.length > 0) {
        const newNutritionIds = await createLotNutritions(newNutritions)
        setNutritionGridDataNewIds(newNutritionIds)
      }
      if (updateNutritions !== undefined && updateNutritions?.length > 0) {
        await updateLotNutritions(updateNutritions)
      }
      if (deleteNutritions !== undefined && deleteNutritions?.length > 0) {
        await deleteLotNutritions(deleteNutritions)
      }
      setNutritionGridActions({})
    } catch (error: any) {
      throw error
    }
  }

  useImperativeHandle(ref, () => ({
    submit
  }))

  useEffect(() => {
    async function getNutritions() {
      const response: LotNutrition[] = await getLotNutritions(
        lotNutritionQuery,
        selectedLot?.id || 0
      )
      setNutritions(response as LotNutritionGridData[])
    }

    selectedLot ? getNutritions() : setNutritions([])
    setNutritionGridActions({})
  }, [selectedLot])

  return <></>
}

export default forwardRef(NutritionData)
