import { Credentials } from 'api/auth/types'
import { AuthData, StorageKeys } from './types'
import {
  Themes,
  Locales,
  UnitySystem,
  DataType,
  ColumnsCollapse,
  ColumnsLot
} from 'store/main/types'
import {
  columnsAnimalBasic,
  columnsAnimalZootechnical,
  columnsAnimalCurrentZootechnical,
  columnsAnimalEconomic,
  columnsAnimalCurrentEconomic,
  columnsAnimalEnvironmental,
  columnsLot
} from 'store/main/reducers'

export const setItemInStorage = (
  key: StorageKeys,
  value?: Credentials | Themes | Locales | DataType | ColumnsCollapse | string | null | ColumnsLot[]
) => {
  if (!value) {
    localStorage.removeItem(key)
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const getItemFromStorage = (
  key: StorageKeys
):
  | AuthData
  | Themes
  | Locales
  | UnitySystem
  | DataType
  | ColumnsCollapse
  | undefined
  | ColumnsLot[] => {
  try {
    const colCollapse = ['order', 'id', 'collapse', 'columns', 'label']
    const col = ['order', 'id', 'active', 'disablePadding', 'type', 'label']
    switch (key) {
      case StorageKeys.AuthData: {
        const credentialsJSONStr = localStorage.getItem(StorageKeys.Credentials)
        const refreshTokenJSONStr = localStorage.getItem(StorageKeys.RefreshToken)
        if (!refreshTokenJSONStr) return
        const refreshToken = JSON.parse(refreshTokenJSONStr)
        const credentials = credentialsJSONStr ? JSON.parse(credentialsJSONStr) : undefined
        return {
          refreshToken,
          credentials
        }
      }
      case StorageKeys.Theme: {
        const themeJSONStr = localStorage.getItem(StorageKeys.Theme)
        if (!themeJSONStr) {
          setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
          return Themes.LIGHT
        }
        const theme: Themes = JSON.parse(themeJSONStr)
        if (theme !== Themes.LIGHT && theme !== Themes.DARK) {
          setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
          return Themes.LIGHT
        }
        return theme
      }
      case StorageKeys.Locale: {
        const localeJSONStr = localStorage.getItem(StorageKeys.Locale)
        if (!localeJSONStr) {
          setItemInStorage(StorageKeys.Locale, Locales.pt)
          return Locales.pt
        }
        const locale: Locales = JSON.parse(localeJSONStr)
        if (locale !== Locales.en && locale !== Locales.pt) {
          setItemInStorage(StorageKeys.Locale, Locales.pt)
          return Locales.pt
        }
        return locale
      }
      case StorageKeys.DataType: {
        const dataTypeJSONStr = localStorage.getItem(StorageKeys.DataType)
        if (!dataTypeJSONStr) {
          setItemInStorage(StorageKeys.DataType, DataType.ZOOTECHNICAL)
          return DataType.ZOOTECHNICAL
        }
        const dataType: DataType = JSON.parse(dataTypeJSONStr)
        if (dataType !== DataType.ECONOMIC && dataType !== DataType.ZOOTECHNICAL) {
          setItemInStorage(StorageKeys.DataType, DataType.ZOOTECHNICAL)
          return DataType.ZOOTECHNICAL
        }
        return dataType
      }
      case StorageKeys.ColumnsAnimalBasic: {
        const columnsAnimalBasicJSONStr = localStorage.getItem(StorageKeys.ColumnsAnimalBasic)
        if (columnsAnimalBasicJSONStr) {
          const columnsAnimalBasicJson: ColumnsCollapse = JSON.parse(columnsAnimalBasicJSONStr)
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsAnimalBasicJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsAnimalBasicJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                columnsAnimalBasicJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return columnsAnimalBasicJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalBasic, columnsAnimalBasic)
        return columnsAnimalBasic
      }
      case StorageKeys.ColumnsAnimalZootechnical: {
        const columnsAnimalZootechnicalJSONStr = localStorage.getItem(
          StorageKeys.ColumnsAnimalZootechnical
        )
        if (columnsAnimalZootechnicalJSONStr) {
          const columnsAnimalZootechnicalJson: ColumnsCollapse = JSON.parse(
            columnsAnimalZootechnicalJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsAnimalZootechnicalJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsAnimalZootechnicalJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                columnsAnimalZootechnicalJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return columnsAnimalZootechnicalJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalZootechnical, columnsAnimalZootechnical)
        return columnsAnimalZootechnical
      }
      case StorageKeys.ColumnsAnimalCurrentZootechnical: {
        const columnsAnimalCurrentZootechnicalJSONStr = localStorage.getItem(
          StorageKeys.ColumnsAnimalCurrentZootechnical
        )
        if (columnsAnimalCurrentZootechnicalJSONStr) {
          const columnsAnimalCurrentZootechnicalJson: ColumnsCollapse = JSON.parse(
            columnsAnimalCurrentZootechnicalJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsAnimalCurrentZootechnicalJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsAnimalCurrentZootechnicalJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                columnsAnimalCurrentZootechnicalJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return columnsAnimalCurrentZootechnicalJson
            }
          }
        }
        setItemInStorage(
          StorageKeys.ColumnsAnimalCurrentZootechnical,
          columnsAnimalCurrentZootechnical
        )
        return columnsAnimalCurrentZootechnical
      }
      case StorageKeys.ColumnsAnimalEnvironmental: {
        const ColumnsAnimalEnvironmentalJSONStr = localStorage.getItem(
          StorageKeys.ColumnsAnimalEnvironmental
        )
        if (ColumnsAnimalEnvironmentalJSONStr) {
          const ColumnsAnimalEnvironmentalJson: ColumnsCollapse = JSON.parse(
            ColumnsAnimalEnvironmentalJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            ColumnsAnimalEnvironmentalJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (ColumnsAnimalEnvironmentalJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                ColumnsAnimalEnvironmentalJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return ColumnsAnimalEnvironmentalJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalEnvironmental, columnsAnimalEnvironmental)
        return columnsAnimalEnvironmental
      }
      case StorageKeys.ColumnsAnimalEnvironmental: {
        const ColumnsAnimalEnvironmentalJSONStr = localStorage.getItem(
          StorageKeys.ColumnsAnimalEnvironmental
        )
        if (ColumnsAnimalEnvironmentalJSONStr) {
          const ColumnsAnimalEnvironmentalJson: ColumnsCollapse = JSON.parse(
            ColumnsAnimalEnvironmentalJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            ColumnsAnimalEnvironmentalJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (ColumnsAnimalEnvironmentalJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                ColumnsAnimalEnvironmentalJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return ColumnsAnimalEnvironmentalJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalEnvironmental, columnsAnimalEnvironmental)
        return columnsAnimalEnvironmental
      }
      case StorageKeys.ColumnsAnimalEconomic: {
        const columnsAnimalEconomicJSONStr = localStorage.getItem(StorageKeys.ColumnsAnimalEconomic)
        if (columnsAnimalEconomicJSONStr) {
          const columnsAnimalEconomicJson: ColumnsCollapse = JSON.parse(
            columnsAnimalEconomicJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsAnimalEconomicJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsAnimalEconomicJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                columnsAnimalEconomicJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return columnsAnimalEconomicJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalEconomic, columnsAnimalEconomic)
        return columnsAnimalEconomic
      }
      case StorageKeys.ColumnsAnimalCurrentEconomic: {
        const columnsAnimalCurrentEconomicJSONStr = localStorage.getItem(
          StorageKeys.ColumnsAnimalCurrentEconomic
        )
        if (columnsAnimalCurrentEconomicJSONStr) {
          const columnsAnimalCurrentEconomicJson: ColumnsCollapse = JSON.parse(
            columnsAnimalCurrentEconomicJSONStr
          )
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsAnimalCurrentEconomicJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsAnimalCurrentEconomicJson.columns.length > 0) {
              const hasAllKeys = col.every((item) =>
                columnsAnimalCurrentEconomicJson.columns[0].hasOwnProperty(item)
              )
              if (hasAllKeys) return columnsAnimalCurrentEconomicJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsAnimalCurrentEconomic, columnsAnimalCurrentEconomic)
        return columnsAnimalCurrentEconomic
      }
      case StorageKeys.ColumnsLot: {
        const columnsLotJSONStr = localStorage.getItem(StorageKeys.ColumnsLot)
        if (columnsLotJSONStr) {
          const columnsLotJson: ColumnsCollapse = JSON.parse(columnsLotJSONStr)
          const hasAllKeysCollapse = colCollapse.every((item) =>
            columnsLotJson.hasOwnProperty(item)
          )
          if (hasAllKeysCollapse) {
            if (columnsLotJson.columns.length > 0) {
              const hasAllKeys = col.every((item) => columnsLotJson.columns[0].hasOwnProperty(item))
              if (hasAllKeys) return columnsLotJson
            }
          }
        }
        setItemInStorage(StorageKeys.ColumnsLot, columnsLot)
        return columnsLot
      }
      case StorageKeys.UnitySystem: {
        const unitySystemJSONStr = localStorage.getItem(StorageKeys.UnitySystem)
        if (!unitySystemJSONStr) {
          setItemInStorage(StorageKeys.UnitySystem, UnitySystem.metric)
          return UnitySystem.metric
        }
        const unitySystem: UnitySystem = JSON.parse(unitySystemJSONStr)
        if (unitySystem !== UnitySystem.imperial && unitySystem !== UnitySystem.metric) {
          setItemInStorage(StorageKeys.UnitySystem, UnitySystem.metric)
          return UnitySystem.metric
        }
        return unitySystem
      }
      default:
        return
    }
  } catch (error: any) {
    throw error
  }
}
