import React from 'react'
import { useTranslation } from 'react-i18next'
// Types
import { Props } from './types'

import './styles.scss'
//Components
import { Select, InputLabel, FormControl } from '@material-ui/core'

const CustomSelectCategory = (props: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'custom-select-multiple'}>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel>{props.textFixed ? t(props.textFixed) : ''}</InputLabel>
        <Select
          native
          defaultValue={props.lotSelected}
          value={props.lotSelected}
          onChange={props.onChangeValue}
        >
          {props.value.map((name) => (
            <optgroup key={name.id} label={t('feedlot:pen') + ' ' + name.code}>
              {name.lot.map((n: any) => (
                <option key={n.id} value={n.id}>
                  {n.description}
                </option>
              ))}
            </optgroup>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelectCategory
