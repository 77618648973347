import React from 'react'
import { Props } from './types'

const LotsIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='user-line'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 40 40'
    >
      <g id='Boundary' fill='none' stroke='#3c641c' strokeWidth='2.5' opacity='0'>
        <rect width='40' height='40' stroke='none' />
        <rect x='1.25' y='1.25' width='37.5' height='37.5' fill='none' />
      </g>
      <path
        id='Path_chart-bubble'
        data-name='Path / chart-bubble'
        d='M10.4,18.4A6.4,6.4,0,1,1,4,24.8a6.393,6.393,0,0,1,6.4-6.4M25.6,28a4,4,0,1,1-4,4,4,4,0,0,1,4-4m.8-24a9.6,9.6,0,1,1-9.6,9.6A9.6,9.6,0,0,1,26.4,4Z'
        fill='none'
        stroke={fill}
        strokeWidth='2.5'
      />
    </svg>
  )
}

export default LotsIcon
