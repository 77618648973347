import React from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'

// Components
import HeaderBar from 'components/HeaderBar'
import Drawer from 'components/Drawer'
import Routes from './Routes'
import MenuModal from 'components/MenuModal'
import InfoModal from 'components/InfoModal'

// import 'styles/shared/app.scss'

const Container = () => (
  <div>
    <MenuModal />
    <InfoModal />
    <HeaderBar />
    <div className='app-drawer-container'>
      <Drawer />
    </div>
    <div className='app-sub-container'>
      <div className='app-content'>
        <Switch>{[...Routes]}</Switch>
      </div>
    </div>
  </div>
)

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

export default connect(mapStoreToProps)(Container)
