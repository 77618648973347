import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

// Types
import {
  CarcassYieldDataRef,
  CarcassYieldDataProps,
  FeedlotCarcassYieldQuery,
  FeedlotCarcassYieldGridData
} from './types'
import { CarcassYield, FeedlotCarcassYield } from 'api/params/types'
import { ApplicationStore } from 'store/types'

// Methods
import {
  getFeedlotCarcassYield,
  createFeedlotCarcassYield,
  updateFeedlotCarcassYield,
  deleteFeedlotCarcassYield
} from 'api/params'

const CarcassYieldData: React.RefForwardingComponent<CarcassYieldDataRef, CarcassYieldDataProps> = (
  {
    carcassYields,
    setCarcassYields,
    carcassYieldGridActions,
    setCarcassYieldGridActions
  }: CarcassYieldDataProps,
  ref
) => {
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)

  async function submit() {
    function convertGridDataToFeedlotCarcassYields(
      carcassYieldsGridData: FeedlotCarcassYieldGridData[] | undefined
    ): FeedlotCarcassYield | undefined {
      if (carcassYieldsGridData && carcassYieldsGridData.length > 0) {
        const aux: CarcassYield[] = []
        carcassYieldsGridData?.map((n) => {
          aux.push({
            carcassYield: n.carcassYield,
            day: n.day
          })
        })
        return {
          id: carcassYieldsGridData[0].id ? carcassYieldsGridData[0].id : 0,
          feedlotId: carcassYieldsGridData[0].feedlotId,
          values: aux
        }
      } else return undefined
    }

    try {
      const newCarcassYields = convertGridDataToFeedlotCarcassYields(carcassYields)
      const deleteNutritions = convertGridDataToFeedlotCarcassYields(carcassYieldGridActions.delete)
      // const updateCarcassYields = convertGridDataToFeedlotCarcassYields(carcassYields)
      if (newCarcassYields !== undefined && newCarcassYields.id == 0) {
        const newCarcassYieldIds = await createFeedlotCarcassYield(newCarcassYields)
        const aux: FeedlotCarcassYieldGridData[] = []
        newCarcassYields.values.map((r, index) => {
          aux.push({
            id: newCarcassYieldIds,
            feedlotId: newCarcassYields.feedlotId,
            day: r.day,
            carcassYield: r.carcassYield,
            _addId: index
          })
        })
        setCarcassYields(aux as FeedlotCarcassYieldGridData[])
        // setCarcassYieldGridDataNewIds(newCarcassYieldIds)
      } else if (newCarcassYields == undefined && deleteNutritions) {
        await deleteFeedlotCarcassYield(deleteNutritions)
      } else if (newCarcassYields !== undefined) {
        await updateFeedlotCarcassYield(newCarcassYields)
      }

      setCarcassYieldGridActions({})
    } catch (error: any) {
      throw error
    }
  }

  useImperativeHandle(ref, () => ({
    submit
  }))

  useEffect(() => {
    async function getCarcassYields() {
      const response: FeedlotCarcassYield = await getFeedlotCarcassYield(
        FeedlotCarcassYieldQuery,
        selectedFeedlot?.id || 0
      )
      const aux: FeedlotCarcassYieldGridData[] = []
      if (response == null || response == undefined) {
        setCarcassYields([])
      } else {
        response.values.map((r, index) => {
          aux.push({
            id: response.id,
            feedlotId: response.feedlotId,
            day: r.day,
            carcassYield: r.carcassYield,
            _addId: index
          })
        })
        setCarcassYields(aux as FeedlotCarcassYieldGridData[])
      }
    }

    selectedFeedlot ? getCarcassYields() : setCarcassYields([])
    setCarcassYieldGridActions({})
  }, [selectedFeedlot])

  return <></>
}

export default forwardRef(CarcassYieldData)
