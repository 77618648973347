import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Types
import { ApplicationStore } from 'store/types'
import { Animal, AnimalZootechnicalDataBySale } from 'store/animal/types'
import { Columns, ColumnsCollapse } from 'store/main/types'

//Resources
import '../styles.scss'

//Methods
import { getAnimals } from 'store/animal/actions'
import {
  setColumnsAnimalBasic,
  setColumnsAnimalZootechnical,
  setColumnsAnimalCurrentZootechnical
} from 'store/main/actions'
import { handleColumnsSelected, setSaleDate } from '../helpers'

//Components
import CustomTable from 'components/shared/CustomTable'
import CustomSelectMultiple from 'components/shared/CustomSelectMultiple'
import Loading from 'components/shared/Loading'
import _ from 'lodash'
import FormatDate from 'helpers/FormatDate'

type Props = {
  sale: number
}

const AnimalZootechnicalTable = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    columnsAnimalZootechnical,
    columnsAnimalCurrentZootechnical,
    columnsAnimalBasic,
    columnsAnimalZootechnicalOriginal,
    columnsAnimalCurrentZootechnicalOriginal,
    columnsAnimalBasicOriginal
  } = useSelector((state: ApplicationStore) => state.main)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const { animals, animalLoading } = useSelector((state: ApplicationStore) => state.animal)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const [additionalInfoKeys, setAdditionalInfoKeys] = useState<Array<string>>([])

  const [rows, setRows] = useState<any[]>([])
  const [columnsSelectedAnimalsZootechnical, setColumnsSelectedAnimalsZootechnical] =
    React.useState<ColumnsCollapse>(columnsAnimalZootechnical)
  const [columnsSelectedAnimalsCurrentZootechnical, setColumnsSelectedAnimalsCurrentZootechnical] =
    React.useState<ColumnsCollapse>(columnsAnimalCurrentZootechnical)
  const [columnsSelectedAnimalsBasic, setColumnsSelectedAnimalsBasic] =
    React.useState<ColumnsCollapse>(columnsAnimalBasic)

  const handleChangeFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    const dataColumns: string[] = event.target.value as string[]

    let columnsUpdate: ColumnsCollapse
    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsBasic, dataColumns)
    dispatch(setColumnsAnimalBasic(columnsUpdate))
    setColumnsSelectedAnimalsBasic(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsCurrentZootechnical, dataColumns)
    dispatch(setColumnsAnimalCurrentZootechnical(columnsUpdate))
    setColumnsSelectedAnimalsCurrentZootechnical(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsZootechnical, dataColumns)
    dispatch(setColumnsAnimalZootechnical(columnsUpdate))
    setColumnsSelectedAnimalsZootechnical(columnsUpdate)
  }

  useEffect(() => {
    if (selectedFeedlot?.id && selectedLot?.id) {
      setAdditionalInfoKeys([])
      dispatch(getAnimals(selectedFeedlot?.id, selectedLot.id))
    }
  }, [selectedLot])

  useEffect(() => {
    if (animals && !animalLoading) {
      getZootechnicalData(props.sale)
      console.log(animals)
    }
  }, [animals, animalLoading, props.sale])

  const getZootechnicalData = (sale: number) => {
    const dataTable: any[] = []
    let keys: Array<string> = []

    if (animals) {
      if (!additionalInfoKeys.length) {
        _.map(animals, (a) => {
          if (a.additionalField && a.additionalField !== {})
            for (const key in a.additionalField) keys.push(key)
        })
        keys = _.uniq(keys)
        setAdditionalInfoKeys(keys)

        if (keys.length) {
          let order = columnsAnimalBasic.columns[columnsAnimalBasic.columns.length - 1].order + 1
          const columnsAnimalBasicCP = JSON.parse(JSON.stringify(columnsAnimalBasic))
          const columnsAnimalCurrentZootechnicalCP = JSON.parse(
            JSON.stringify(columnsAnimalCurrentZootechnical)
          )
          const columnsAnimalZootechnicalCP = JSON.parse(JSON.stringify(columnsAnimalZootechnical))

          keys.map((key) => {
            const findedKey = columnsAnimalBasicCP.columns.find((c: Columns) => c.id === key)
            if (!findedKey) {
              const column: Columns = {
                order: order++,
                id: key,
                active: true,
                disablePadding: false,
                type: 'any',
                label: key
              }
              columnsAnimalBasicCP.columns.push(column)
            }
          })

          columnsAnimalCurrentZootechnicalCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          columnsAnimalZootechnicalCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.zootechnicalData.beeftraderMethod.find((p) => p.saleType === sale),
                  keys
                )
              )
            }
          })

          if (columnsAnimalBasic.columns.length !== columnsAnimalBasicCP.columns.length) {
            setColumnsAnimalBasic(columnsAnimalBasicCP)
            setColumnsSelectedAnimalsBasic(columnsAnimalBasicCP)
            dispatch(setColumnsAnimalBasic(columnsAnimalBasicCP))

            setColumnsSelectedAnimalsCurrentZootechnical(columnsAnimalCurrentZootechnicalCP)
            setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalCP)
            dispatch(setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalCP))

            setColumnsSelectedAnimalsZootechnical(columnsAnimalZootechnicalCP)
            setColumnsAnimalZootechnical(columnsAnimalZootechnicalCP)
            dispatch(setColumnsAnimalZootechnical(columnsAnimalZootechnicalCP))
          }
        } else {
          setColumnsAnimalBasic(columnsAnimalBasicOriginal)
          setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
          dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

          setColumnsSelectedAnimalsCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal)
          setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal)
          dispatch(setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal))

          setColumnsSelectedAnimalsZootechnical(columnsAnimalZootechnicalOriginal)
          setColumnsAnimalZootechnical(columnsAnimalZootechnicalOriginal)
          dispatch(setColumnsAnimalZootechnical(columnsAnimalZootechnicalOriginal))

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.zootechnicalData.beeftraderMethod.find((p) => p.saleType === sale)
                )
              )
            }
          })
        }
      } else {
        setColumnsAnimalBasic(columnsAnimalBasic)
        setColumnsSelectedAnimalsBasic(columnsAnimalBasic)
        dispatch(setColumnsAnimalBasic(columnsAnimalBasic))

        setColumnsSelectedAnimalsCurrentZootechnical(columnsAnimalCurrentZootechnical)
        setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnical)
        dispatch(setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnical))

        setColumnsSelectedAnimalsZootechnical(columnsAnimalZootechnical)
        setColumnsAnimalZootechnical(columnsAnimalZootechnical)
        dispatch(setColumnsAnimalZootechnical(columnsAnimalZootechnical))

        animals.map((a) => {
          if (a.profit) {
            dataTable.push(
              createData(
                a,
                a.zootechnicalData.beeftraderMethod.find((p) => p.saleType === sale)
              )
            )
          }
        })
      }
    } else {
      setColumnsAnimalBasic(columnsAnimalBasicOriginal)
      setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
      dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

      setColumnsSelectedAnimalsCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal)
      setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal)
      dispatch(setColumnsAnimalCurrentZootechnical(columnsAnimalCurrentZootechnicalOriginal))

      setColumnsSelectedAnimalsZootechnical(columnsAnimalZootechnicalOriginal)
      setColumnsAnimalZootechnical(columnsAnimalZootechnicalOriginal)
      dispatch(setColumnsAnimalZootechnical(columnsAnimalZootechnicalOriginal))
    }

    setRows(dataTable)
  }

  function createData(
    animal: Animal,
    zootechnical: AnimalZootechnicalDataBySale | undefined,
    keys?: string[]
  ) {
    if (zootechnical) {
      const animalData: any = {
        earring: animal.earring,
        tag: animal.tag,
        sisbov: animal.sisbov,
        sale: Number(zootechnical.sale),
        saleDate: FormatDate(setSaleDate(selectedLot?.initDate, zootechnical.result.predictedTime)),
        breed: animal.breed.description,
        gender: animal.gender.code,
        initialWeight: Number(Number(animal.initialWeight).toFixed(2)),
        currentWeight: Number(Number(animal.zootechnicalData.current.weight).toFixed(2)),
        currentDwg: Number(Number(animal.zootechnicalData.current.dwg).toFixed(2)),
        currentFeedConversion: Number(
          Number(animal.zootechnicalData.current.feedConversion).toFixed(2)
        ),
        currentIngestion: Number(Number(animal.zootechnicalData.current.dailyIngestion).toFixed(2)),
        currentEgs: Number(Number(animal.zootechnicalData.current.egs).toFixed(2)),
        currentEgsCategory: String(animal.zootechnicalData.current.egsCategory),
        dwg: Number(Number(zootechnical.result.dwg).toFixed(2)),
        feedConversion: Number(Number(zootechnical.result.feedConversion).toFixed(2)),
        ingestion: Number(Number(zootechnical.result.dailyIngestion).toFixed(2)),
        finalWeight: Number(Number(zootechnical.result.weight).toFixed(2)),
        egs: Number(Number(zootechnical.result.egs).toFixed(2)),
        egsCategory: String(zootechnical.result.egsCategory)
      }

      const aiKeys = keys?.length ? keys : additionalInfoKeys
      if (aiKeys?.length) {
        aiKeys.map((key: any) => {
          if (animal.additionalField) animalData[key] = animal.additionalField[key] ?? ''
        })
      }
      return animalData
    }
  }

  const handleCollapseColumns = (info: any) => {
    if (columnsSelectedAnimalsBasic.id === info.id) {
      setColumnsSelectedAnimalsBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
      setColumnsAnimalBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsCurrentZootechnical.id === info.id) {
      setColumnsSelectedAnimalsCurrentZootechnical({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalCurrentZootechnical({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsZootechnical.id === info.id) {
      setColumnsSelectedAnimalsZootechnical({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalZootechnical({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    }
  }

  return animalLoading ? (
    <Loading />
  ) : (
    <div className='table-animal'>
      <div className={'header-table'}>
        <CustomSelectMultiple
          value={[
            ...columnsSelectedAnimalsBasic.columns,
            ...columnsSelectedAnimalsCurrentZootechnical.columns,
            ...columnsSelectedAnimalsZootechnical.columns
          ]}
          textFixed={t('fields_selected')}
          onChangeValue={handleChangeFields}
        />
      </div>
      <div className={'border-group'}>
        <CustomTable
          columns={[
            columnsSelectedAnimalsBasic,
            columnsSelectedAnimalsCurrentZootechnical,
            columnsSelectedAnimalsZootechnical
          ]}
          onCollapseColumns={handleCollapseColumns}
          data={rows}
        />
      </div>
    </div>
  )
}

export default AnimalZootechnicalTable
