import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// Types
import { ApplicationStore } from 'store/types'
import { Animal } from 'store/animal/types'
import { AnimalProfitBySale } from 'store/profit/types'
import { Columns, ColumnsCollapse } from 'store/main/types'

//Methods
import { getAnimals } from 'store/animal/actions'
import {
  setColumnsAnimalBasic,
  setColumnsAnimalEconomic,
  setColumnsAnimalCurrentEconomic
} from 'store/main/actions'

//Resources
import { setSaleDate, handleColumnsSelected } from '../helpers'
import '../styles.scss'

//Components
import CustomTable from 'components/shared/CustomTable'
import CustomSelectMultiple from 'components/shared/CustomSelectMultiple'
import Loading from 'components/shared/Loading'
import _ from 'lodash'
import FormatDate from 'helpers/FormatDate'

type Props = {
  sale: number
}

const AnimalProfitTable = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    columnsAnimalEconomic,
    columnsAnimalCurrentEconomic,
    columnsAnimalBasic,
    columnsAnimalEconomicOriginal,
    columnsAnimalCurrentEconomicOriginal,
    columnsAnimalBasicOriginal
  } = useSelector((state: ApplicationStore) => state.main)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const { animals, animalLoading } = useSelector((state: ApplicationStore) => state.animal)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const [rows, setRows] = useState<any[]>([])
  const [columnsSelectedAnimalsEconomic, setColumnsSelectedAnimalsEconomic] =
    React.useState<ColumnsCollapse>(columnsAnimalEconomic)
  const [columnsSelectedAnimalsCurrentEconomic, setColumnsSelectedAnimalsCurrentEconomic] =
    React.useState<ColumnsCollapse>(columnsAnimalCurrentEconomic)
  const [columnsSelectedAnimalsBasic, setColumnsSelectedAnimalsBasic] =
    React.useState<ColumnsCollapse>(columnsAnimalBasic)
  const [additionalInfoKeys, setAdditionalInfoKeys] = useState<Array<string>>([])

  const handleChangeFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    const dataColumns: string[] = event.target.value as string[]

    let columnsUpdate: ColumnsCollapse
    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsBasic, dataColumns)
    dispatch(setColumnsAnimalBasic(columnsUpdate))
    setColumnsSelectedAnimalsBasic(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsCurrentEconomic, dataColumns)
    dispatch(setColumnsAnimalCurrentEconomic(columnsUpdate))
    setColumnsSelectedAnimalsCurrentEconomic(columnsUpdate)

    columnsUpdate = handleColumnsSelected(columnsSelectedAnimalsEconomic, dataColumns)
    dispatch(setColumnsAnimalEconomic(columnsUpdate))
    setColumnsSelectedAnimalsEconomic(columnsUpdate)
  }

  useEffect(() => {
    // setColumnsSelectedAnimalsBasic(columnsAnimalBasic)
    // setColumnsSelectedAnimalsEconomic(columnsAnimalEconomic)
    if (selectedFeedlot?.id && selectedLot?.id) {
      setAdditionalInfoKeys([])
      dispatch(getAnimals(selectedFeedlot?.id, selectedLot.id))
    }
  }, [selectedLot])

  useEffect(() => {
    // setColumnsSelectedAnimalsCurrentEconomic(columnsAnimalCurrentEconomic)
    // setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomic)

    if (animals && !animalLoading) {
      getEconomic(props.sale)
    }
  }, [animals, animalLoading, props.sale])

  const getEconomic = (sale: number) => {
    const dataTable: any[] = []
    let keys: Array<string> = []

    if (animals) {
      if (!additionalInfoKeys.length) {
        _.map(animals, (a) => {
          if (a.additionalField && a.additionalField !== {})
            for (const key in a.additionalField) keys.push(key)
        })
        keys = _.uniq(keys)
        setAdditionalInfoKeys(keys)

        if (keys.length) {
          let order = columnsAnimalBasic.columns[columnsAnimalBasic.columns.length - 1].order + 1
          const columnsAnimalBasicCP = JSON.parse(JSON.stringify(columnsAnimalBasic))
          const columnsAnimalCurrentEconomicCP = JSON.parse(
            JSON.stringify(columnsAnimalCurrentEconomic)
          )
          const columnsAnimalEconomicCP = JSON.parse(JSON.stringify(columnsAnimalEconomic))

          keys.map((key) => {
            const findedKey = columnsAnimalBasicCP.columns.find((c: Columns) => c.id === key)
            if (!findedKey) {
              const column: Columns = {
                order: order++,
                id: key,
                active: true,
                disablePadding: false,
                type: 'any',
                label: key
              }
              columnsAnimalBasicCP.columns.push(column)
            }
          })

          columnsAnimalCurrentEconomicCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          columnsAnimalEconomicCP.columns.map((c: Columns) => {
            c.order = c.order + keys.length
          })

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.profit.beeftraderMethod.find((p) => p.saleType === sale),
                  keys
                )
              )
            }
          })

          if (columnsAnimalBasic.columns.length !== columnsAnimalBasicCP.columns.length) {
            setColumnsAnimalBasic(columnsAnimalBasicCP)
            setColumnsSelectedAnimalsBasic(columnsAnimalBasicCP)
            dispatch(setColumnsAnimalBasic(columnsAnimalBasicCP))

            setColumnsSelectedAnimalsCurrentEconomic(columnsAnimalCurrentEconomicCP)
            setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicCP)
            dispatch(setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicCP))

            setColumnsSelectedAnimalsEconomic(columnsAnimalEconomicCP)
            setColumnsAnimalEconomic(columnsAnimalEconomicCP)
            dispatch(setColumnsAnimalEconomic(columnsAnimalEconomicCP))
          }
        } else {
          setColumnsAnimalBasic(columnsAnimalBasicOriginal)
          setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
          dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

          setColumnsSelectedAnimalsCurrentEconomic(columnsAnimalCurrentEconomicOriginal)
          setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicOriginal)
          dispatch(setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicOriginal))

          setColumnsSelectedAnimalsEconomic(columnsAnimalEconomicOriginal)
          setColumnsAnimalEconomic(columnsAnimalEconomicOriginal)
          dispatch(setColumnsAnimalEconomic(columnsAnimalEconomicOriginal))

          animals.map((a) => {
            if (a.profit) {
              dataTable.push(
                createData(
                  a,
                  a.profit.beeftraderMethod.find((p) => p.saleType === sale)
                )
              )
            }
          })
        }
      } else {
        setColumnsAnimalBasic(columnsAnimalBasic)
        setColumnsSelectedAnimalsBasic(columnsAnimalBasic)
        dispatch(setColumnsAnimalBasic(columnsAnimalBasic))

        setColumnsSelectedAnimalsCurrentEconomic(columnsAnimalCurrentEconomic)
        setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomic)
        dispatch(setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomic))

        setColumnsSelectedAnimalsEconomic(columnsAnimalEconomic)
        setColumnsAnimalEconomic(columnsAnimalEconomic)
        dispatch(setColumnsAnimalEconomic(columnsAnimalEconomic))

        animals.map((a) => {
          if (a.profit) {
            dataTable.push(
              createData(
                a,
                a.profit.beeftraderMethod.find((p) => p.saleType === sale)
              )
            )
          }
        })
      }
    } else {
      setColumnsAnimalBasic(columnsAnimalBasicOriginal)
      setColumnsSelectedAnimalsBasic(columnsAnimalBasicOriginal)
      dispatch(setColumnsAnimalBasic(columnsAnimalBasicOriginal))

      setColumnsSelectedAnimalsCurrentEconomic(columnsAnimalCurrentEconomicOriginal)
      setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicOriginal)
      dispatch(setColumnsAnimalCurrentEconomic(columnsAnimalCurrentEconomicOriginal))

      setColumnsSelectedAnimalsEconomic(columnsAnimalEconomicOriginal)
      setColumnsAnimalEconomic(columnsAnimalEconomicOriginal)
      dispatch(setColumnsAnimalEconomic(columnsAnimalEconomicOriginal))
    }

    setRows(dataTable)
  }

  function createData(animal: Animal, profit: AnimalProfitBySale | undefined, keys?: string[]) {
    if (profit) {
      const animalData: any = {
        earring: animal.earring,
        tag: animal.tag,
        sisbov: animal.sisbov,
        sale: Number(profit.sale),
        saleDate: FormatDate(setSaleDate(selectedLot?.initDate, profit.result.predictedTime)),
        breed: animal.breed.description,
        gender: animal.gender.code,
        currentDailyProfit: Number(Number(animal.profit.current.dailyProfit).toFixed(2)),
        currentTotalProfit: Number(Number(animal.profit.current.totalProfit).toFixed(2)),
        currentProfitByWeightUnity: Number(
          Number(animal.profit.current.profitByWeightUnity).toFixed(2)
        ),
        currentCostByWeightUnity: Number(
          Number(animal.profit.current.costByWeightUnity).toFixed(2)
        ),
        currentRevenueByWeightUnity: Number(
          Number(animal.profit.current.revenueByWeightUnity).toFixed(2)
        ),
        currentArrobaStock: Number(Number(animal.profit.current.arrobaStock).toFixed(2)),
        dailyProfit: Number(Number(profit.result.dailyProfit).toFixed(2)),
        totalProfit: Number(Number(profit.result.totalProfit).toFixed(2)),
        totalCost: Number(Number(profit.result.totalCost).toFixed(2)),
        totalRevenue: Number(Number(profit.result.totalRevenue).toFixed(2)),
        profitByWeightUnity: Number(Number(profit.result.profitByWeightUnity).toFixed(2)),
        costByWeightUnity: Number(Number(profit.result.costByWeightUnity).toFixed(2)),
        revenueByWeightUnity: Number(Number(profit.result.revenueByWeightUnity).toFixed(2)),
        arrobaStock: Number(Number(profit.result.arrobaStock).toFixed(2))
      }

      const aiKeys = keys?.length ? keys : additionalInfoKeys
      if (aiKeys?.length) {
        aiKeys.map((key: any) => {
          if (animal.additionalField) animalData[key] = animal.additionalField[key] ?? ''
        })
      }

      return animalData
    }
  }

  const handleCollapseColumns = (info: any) => {
    if (columnsSelectedAnimalsBasic.id === info.id) {
      setColumnsSelectedAnimalsBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
      setColumnsAnimalBasic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsCurrentEconomic.id === info.id) {
      setColumnsSelectedAnimalsCurrentEconomic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalCurrentEconomic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    } else if (columnsSelectedAnimalsEconomic.id === info.id) {
      setColumnsSelectedAnimalsEconomic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })

      setColumnsAnimalEconomic({
        id: info.id,
        label: info.label,
        collapse: !info.collapse,
        columns: info.columns,
        order: info.order
      })
    }
  }
  return animalLoading ? (
    <Loading />
  ) : (
    <div className='table-animal'>
      <div className={'header-table'}>
        <CustomSelectMultiple
          value={[
            ...columnsSelectedAnimalsBasic.columns,
            ...columnsSelectedAnimalsCurrentEconomic.columns,
            ...columnsSelectedAnimalsEconomic.columns
          ]}
          textFixed={t('fields_selected')}
          onChangeValue={handleChangeFields}
        />
      </div>
      <div className='border-group'>
        <CustomTable
          columns={[
            columnsSelectedAnimalsBasic,
            columnsSelectedAnimalsCurrentEconomic,
            columnsSelectedAnimalsEconomic
          ]}
          data={rows}
          onCollapseColumns={handleCollapseColumns}
        />
      </div>
    </div>
  )
}

export default AnimalProfitTable
