import { Pen } from '../structure/pen/types'
import {
  ZootechnicalLotDataBySaleType,
  LotProfitBySaleType,
  LotAnimalsSlaughterhouseByMethod,
  EnvironmentalLotDataBySaleType
} from '../profit/types'
import { LotWeighingDataType } from 'store/main/types'
import { Animal } from 'store/animal/types'
export type LotStore = {
  lots: Lot[]
  selectedLot?: Lot
  selectedLotList?: Lot[]
  lotLoading: boolean
  lotWeighingDataType: LotWeighingDataType.TABLE | LotWeighingDataType.CHARTS
  lotAccessIndexLoading: boolean
  lotAccessIndex?: LotAcessIndex[]
  feedlotWeeksNumber: number
}
export type AverageWeight = {
  day: number
  weight: number
}
export type LotAcessIndex = {
  week: number
  frequency?: number
  weighings?: number
}
export type Lot = {
  id: number
  feedlotId: number
  description: string
  initDate: string
  pen: Pen
  animalsCount: number
  zootechnicalData?: ZootechnicalLotDataBySaleType
  environmentalData?: EnvironmentalLotDataBySaleType
  profit?: LotProfitBySaleType
  animalsSlaughterhouse?: LotAnimalsSlaughterhouseByMethod
  averageWeight?: AverageWeight[]
  animals?: Animal[]
}

export enum LotConstants {
  ACTION_GET_SELECTED_LOT = 'ACTION_GET_SELECTED_LOT',
  REDUCER_CLEAR_LOT_DATA = 'REDUCER_CLEAR_LOT_DATA',
  ACTION_GET_LOT = 'ACTION_GET_LOT',
  ACTION_GET_LOT_ACCESS_INDEX = 'ACTION_GET_LOT_ACCESS_INDEX',
  ACTION_SET_SELECTED_LOT_SALE = 'ACTION_SET_SELECTED_SALE',
  REDUCER_SET_LOT_DATA = 'REDUCER_SET_LOT_DATA'
}
