import {
  AnimalEconomicDailyData,
  AnimalProfit,
  AnimalProfitBySale,
  AnimalZootechnicalDailyData,
  AnimalsEconomicDailyStore,
  AnimalsZootechnicalDaily,
  AnimalsWeighing,
  AnimalsEnvironmentalDaily,
  AnimalEnvironmentalDailyData,
  WeighingData
} from 'store/profit/types'

export type AnimalStore = {
  animals: Animal[]
  animalsEconomicDaily: AnimalsEconomicDailyStore[]
  animalsZootechnicalDailyData: AnimalsZootechnicalDaily[]
  animalsEnvironmentalDailyData: AnimalsEnvironmentalDaily[]
  animalsWeighingData: AnimalsWeighing[]
  weighing: AnimalWeighing[]
  weighingLoading: boolean
  allWeighinsLoaded: boolean
  selectedAnimal?: Animal
  animalLoading: boolean
  animalEconomicDailyLoading: boolean
  animalZootechnicalDailyDataLoading: boolean
  animalEnvironmentalDailyDataLoading: boolean
  animalWeighingLoading: boolean
  animalWeighingComplete: boolean //identifica se todos os pesos já foram "baixados"
  animalsWeighingPage: number
  animalAdditionalInfoTemplates?: AnimalAdditionalInfoTemplate[]
  animalAdditionalInfoTemplateLoading?: boolean
  genders?: Gender[]
  animalGendersLoading: boolean
}

export type Animal = {
  id: number
  feedlotId: number
  lotId: number
  earring?: string
  tag?: string
  sisbov?: string
  initialWeight?: number
  active?: boolean
  endDate?: Date
  lastWeighingDate?: Date
  gender: Gender
  breed: Breed
  ultrasoundAssessment: UltrasoundAssessment[]
  weighing: AnimalWeighing[]
  additionalField?: Record<string, any> | { [key: string]: any }
  zootechnicalData: AnimalZootechnicalDataBySaleType
  environmentalData: AnimalEnvironmentalDataBySaleType
  profit: AnimalProfitBySaleType
  zootechnicalDailyData?: AnimalZootechnicalDailyData[]
  economicDailyData?: AnimalEconomicDailyData[]
  environmentalDailyData?: AnimalEnvironmentalDailyData[]
}

export type Gender = {
  id: number
  code: string
  description: string
}

export type Breed = {
  id: number
  code: string
  description: string
}
export type UltrasoundAssessment = {
  egs?: number
  collectDate?: Date
}

export type AnimalZootechnicalDataBySaleType = {
  current: AnimalZootechnicalData
  beeftraderMethod: AnimalZootechnicalDataBySale[]
}

export type AnimalEnvironmentalDataBySaleType = {
  current: AnimalEnvironmentalData
  beeftraderMethod: AnimalEnvironmentalDataBySale[]
}

export type AnimalZootechnicalDataBySale = {
  saleType: number
  sale: number
  result: AnimalZootechnicalData
}

export type AnimalEnvironmentalDataBySale = {
  saleType: number
  sale: number
  result: AnimalEnvironmentalData
}

export type AnimalWeighing = {
  id: number
  animalId?: number
  earring?: string
  day: number
  weight: number
  type: string
}

export type AnimalZootechnicalData = {
  [key: string]: any
  dwg: number
  realDwg: number
  weight: number
  ingestion: number
  ingestionInTime: number
  dailyIngestion: number
  feedConversion: number
  feedConversionInTime: number
  predictedTime: number
  egs: number
  egsCategory: string
  // totalFastingKg: number
  // totalFastingArroba: number
  // energeticEfficiency: number
}

export type AnimalEnvironmentalData = {
  waterIntake: number
  co2Emission: number
  manure: number
  environmentEfficiency: number
  predictedTime: number
}

export type AnimalProfitBySaleType = {
  current: AnimalProfit
  // traditionalMethod: AnimalProfit
  beeftraderMethod: AnimalProfitBySale[]
}

export type AnimalAdditionalInfoTemplate = {
  clientId: number
  id: number
  type?: string
  value?: any
  active: number
  description?: string
}

export enum AnimalConstants {
  ACTION_GET_ANIMALS = 'ACTION_GET_ANIMALS',
  ACTION_GET_GENDERS = 'ACTION_GET_GENDERS',
  ACTION_GET_ANIMAL_GENERAL_FUNCTION = 'ACTION_GET_ANIMAL_GENERAL_FUNCTION',
  ACTION_GET_ECONOMIC_DAILY_ANIMALS = 'ACTION_GET_ECONOMIC_DAILY_ANIMALS',
  ACTION_GET_ZOOTECHNICAL_DAILY_DATA_ANIMALS = 'ACTION_GET_ZOOTECHNICAL_DAILY_DATA_ANIMALS',
  ACTION_GET_ENVIRONMENTAL_DAILY_DATA_ANIMALS = 'ACTION_GET_ENVIRONMENTAL_DAILY_DATA_ANIMALS',
  ACTION_GET_WEIGHING_ANIMALS = 'ACTION_GET_WEIGHING_ANIMALS',
  ACTION_GET_ANIMAL_ADDITIONAL_INFO_TEMPLATE = 'ACTION_GET_ANIMAL_ADDITIONAL_INFO_TEMPLATE',
  ACTION_GET_WEIGHING = 'ACTION_GET_WEIGHING',
  REDUCER_CLEAR_ANIMAL_DATA = 'REDUCER_CLEAR_ANIMAL_DATA',
  REDUCER_SET_ANIMAL_DATA = 'REDUCER_SET_ANIMAL_DATA'
}
