import { LotConstants } from './types'

export const getSelectedLot = (feedlotId: number, lotId?: number) => ({
  type: LotConstants.ACTION_GET_SELECTED_LOT,
  payload: { feedlotId, lotId }
})

export const getLotAccessIndex = () => ({
  type: LotConstants.ACTION_GET_LOT_ACCESS_INDEX
})
