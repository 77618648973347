import produce from 'immer'
import { AnyAction } from 'redux'

import { FeedlotStore, FeedlotConstants } from './types'

const INITIAL_STORE: FeedlotStore = {
  feedlots: [],
  selectedFeedlot: undefined,
  feedlotLoading: false,
  feedlotNextSalesLoading: false,
  selectedSaleType: 1, // 1, 2 ou 3
  nextSalesArgs: {
    scaledLotsMaxSaleTime: 45,
    nextLotsMaxSaleTime: 60,
    maxFeedlotTime: 45
  }
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case FeedlotConstants.REDUCER_SET_FEEDLOT_DATA:
        Object.assign(draft, payload)
        break
      case FeedlotConstants.REDUCER_CLEAR_FEEDLOT_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
