import React from 'react'
import * as Yup from 'yup'

// Types
import {
  LotSalesParams,
  LotSalesParamsQueryFields,
  LotNutritionQueryFields,
  LotZootechnicalParams,
  LotZootechnicalParamsQueryFields,
  LotCarcassYieldQueryFields
} from 'api/params/types'
import { LotQueryFields } from 'api/lot/types'

export interface Lot {
  id: number
  description: string
}

export interface FormValidationMessages {
  mtDif: string
  dif: string
  saleAnimalsNumber: string
  sbwLimit: string
  lossPercentage: string
  ingestionAdjustment: string
  truckAnimalsLimit: string
  eccLimit: string
}

export interface Props {
  lots: Lot[]
  selectedLot: Lot | undefined
  setSelectedLot: React.Dispatch<React.SetStateAction<Lot | undefined>>
  currentSalesParams: LotSalesParams | undefined
  setCurrentSalesParams: React.Dispatch<React.SetStateAction<LotSalesParams | undefined>>
  currentZootechnicalParams: LotZootechnicalParams | undefined
  setCurrentZootechnicalParams: React.Dispatch<
    React.SetStateAction<LotZootechnicalParams | undefined>
  >
  nutritions: LotNutritionGridData[]
  setNutritions: React.Dispatch<React.SetStateAction<LotNutritionGridData[]>>
  nutritionGridActions: LotNutritionGridActions
  setNutritionGridActions: React.Dispatch<React.SetStateAction<LotNutritionGridActions>>
  carcassYields: LotCarcassYieldGridData[]
  setCarcassYields: React.Dispatch<React.SetStateAction<LotCarcassYieldGridData[]>>
  carcassYieldGridActions: LotCarcassYieldGridActions
  setCarcassYieldGridActions: React.Dispatch<React.SetStateAction<LotCarcassYieldGridActions>>
  formValidationMessages: FormValidationMessages | undefined
  formErrorMessages: any
}
export interface FormDataProps {
  setLots: React.Dispatch<React.SetStateAction<Lot[]>>
  selectedLot: Lot | undefined
  setSelectedLot: React.Dispatch<React.SetStateAction<Lot | undefined>>
  currentSalesParams: LotSalesParams | undefined
  setCurrentSalesParams: React.Dispatch<React.SetStateAction<LotSalesParams | undefined>>
  currentZootechnicalParams: LotZootechnicalParams | undefined
  setCurrentZootechnicalParams: React.Dispatch<
    React.SetStateAction<LotZootechnicalParams | undefined>
  >
  setFormErrorsMessages: React.Dispatch<React.SetStateAction<any>>
}

export interface FormDataRef {
  submit(): void
  formValidationMessages: FormValidationMessages
}

export const lotSalesParamsQuery: LotSalesParamsQueryFields = {
  id: true,
  lotId: true,
  mtDif: true,
  dif: true,
  saleAnimalsNumber: true,
  sbwLimit: true,
  truckAnimalsLimit: true,
  eccLimit: true
}
export const lotZootechnicalParamsQuery: LotZootechnicalParamsQueryFields = {
  id: true,
  lotId: true,
  lossPercentage: true,
  ingestionAdjustment: true
}

export const getConfigurationFormDataSchema = (formValidationText: FormValidationMessages) => {
  return Yup.object().shape({
    mtDif: Yup.number()
      .typeError(formValidationText.mtDif)
      .min(60, formValidationText.mtDif)
      .max(200, formValidationText.mtDif),
    dif: Yup.number()
      .typeError(formValidationText.dif)
      .min(60, formValidationText.dif)
      .max(200, formValidationText.dif),
    saleAnimalsNumber: Yup.number()
      .typeError(formValidationText.saleAnimalsNumber)
      .positive(formValidationText.saleAnimalsNumber),
    sbwLimit: Yup.number()
      .typeError(formValidationText.sbwLimit)
      .positive(formValidationText.sbwLimit),
    lossPercentage: Yup.number()
      .typeError(formValidationText.lossPercentage)
      .min(0, formValidationText.lossPercentage),
    ingestionAdjustment: Yup.number()
      .typeError(formValidationText.ingestionAdjustment)
      .integer(formValidationText.ingestionAdjustment),
    truckAnimalsLimit: Yup.number()
      .typeError(formValidationText.truckAnimalsLimit)
      .positive(formValidationText.truckAnimalsLimit),
    eccLimit: Yup.number()
      .typeError(formValidationText.eccLimit)
      .min(1, formValidationText.eccLimit)
      .max(9, formValidationText.eccLimit)
  })
}

export const lotQuery: LotQueryFields = {
  id: true,
  description: true
}

export interface NutritionDataProps {
  selectedLot: Lot | undefined
  nutritions: LotNutritionGridData[]
  setNutritions: React.Dispatch<React.SetStateAction<LotNutritionGridData[]>>
  nutritionGridActions: LotNutritionGridActions
  setNutritionGridActions: React.Dispatch<React.SetStateAction<LotNutritionGridActions>>
}

export interface NutritionDataRef {
  submit(): void
}

export const lotNutritionQuery: LotNutritionQueryFields = {
  id: true,
  lotId: true,
  category: true,
  start: true,
  end: true,
  tdn: true,
  dryMatter: true,
  dietCost: true
}

export interface LotNutritionGridData {
  id: number
  lotId: number
  category: string
  period: number
  start: number
  end: number
  tdn: number
  dryMatter: number
  dietCost: number
  dryMatterCost: number
  _addId: number
}

export interface LotNutritionGridActions {
  add?: number[]
  update?: number[]
  delete?: LotNutritionGridData[]
}

export const LotCarcassYieldQuery: LotCarcassYieldQueryFields = {
  id: true,
  lotId: true,
  values: {
    carcassYield: true,
    day: true
  }
}
export interface CarcassYieldDataProps {
  carcassYields: LotCarcassYieldGridData[]
  setCarcassYields: React.Dispatch<React.SetStateAction<LotCarcassYieldGridData[]>>
  carcassYieldGridActions: LotCarcassYieldGridActions
  setCarcassYieldGridActions: React.Dispatch<React.SetStateAction<LotCarcassYieldGridActions>>
}
export interface CarcassYieldDataRef {
  submit(): void
}

export interface LotCarcassYieldGridData {
  id: number
  lotId: number
  day: number
  carcassYield: number
  _addId: number
}

export interface LotCarcassYieldGridActions {
  add?: number[]
  update?: number[]
  delete?: LotCarcassYieldGridData[]
}
