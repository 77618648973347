import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

//Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'
import { Lot } from 'store/lot/types'

//Resources
import { mdiCalendarEdit, mdiWeight, mdiCorn } from '@mdi/js'
import './styles.scss'

//Methods
import { Icon } from '@mdi/react'

//Components
import {
  Tabs,
  Tab,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer
} from '@material-ui/core'
import Format from 'helpers/Format'
import CustomTable from 'components/shared/CustomTable'
import FormatDate from 'helpers/FormatDate'
import { setSaleDate } from '../helpers'
import moment from 'moment'

const LotDetailsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const [dataTable, setDataTable] = useState<any[]>([])

  useEffect(() => {
    createDataTable(props.dataLot, props.saleSelected)
  }, [props.dataLot, props.saleSelected])

  const createDataTable = (lotData: Lot, value: number) => {
    const aux = []
    const columns = [
      'totalFastingArroba',
      'dwg',
      'weight',
      'arrobaStock',
      'ingestion',
      'feedConversion',
      'energeticEfficiency'
    ]
    const columnsProfit = [
      'profitByAnimal',
      'costByAnimal',
      'revenueByAnimal',
      'profitByWeightUnity',
      'costByWeightUnity',
      'revenueByWeightUnity',
      'totalProfit',
      'totalCost',
      'totalRevenue'
    ]
    const columnsEnvironment: string[] = ['environmentEfficiency', 'waterIntake']

    const methodEnvironment: any[] | undefined = lotData?.environmentalData?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditionalEnvironment: any = lotData?.environmentalData?.traditionalMethod
    const currentEnvironment: any = lotData?.environmentalData?.current

    const methodProfit: any[] | undefined = lotData?.profit?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditionalProfit: any = lotData?.profit?.traditionalMethod
    const currentProfit: any = lotData?.profit?.current

    const method: any[] | undefined = lotData?.zootechnicalData?.beeftraderMethod.find(
      (x) => x.saleType === value
    )?.sale
    const traditional: any = lotData?.zootechnicalData?.traditionalMethod
    const current: any = lotData?.zootechnicalData?.current
    if (
      method &&
      traditional &&
      current &&
      methodProfit &&
      traditionalProfit &&
      currentProfit &&
      methodEnvironment &&
      traditionalEnvironment &&
      currentEnvironment
    ) {
      if (value === 1) {
        aux.push(
          ...[
            {
              typeData: 'sale',
              traditional: 1,
              current: 1,
              sale1: 1
            },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime))
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount
            }
          ]
        )
      } else if (value === 2) {
        aux.push(
          ...[
            {
              typeData: 'sale',
              traditional: 1,
              current: 1,
              sale1: 1,
              sale2: 2
            },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime,
              sale2: method.length >= 2 ? method[1].predictedTime : null
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime)),
              sale2:
                method.length >= 2
                  ? FormatDate(setSaleDate(lotData?.initDate, method[1].predictedTime))
                  : null
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount,
              sale2: method.length >= 2 ? method[1].animalsAmount : null
            }
          ]
        )
      } else if (value === 3) {
        aux.push(
          ...[
            {
              typeData: 'sale',
              traditional: 1,
              current: 1,
              sale1: 1,
              sale2: 2,
              sale3: 3
            },
            {
              typeData: 'predictedTime',
              traditional: traditional.predictedTime,
              current: Number(moment().diff(moment(lotData?.initDate), 'days')) + 1,
              sale1: method[0].predictedTime,
              sale2: method.length >= 2 ? method[1].predictedTime : null,
              sale3: method.length >= 3 ? method[2].predictedTime : null
            },
            {
              typeData: 'date',
              traditional: FormatDate(setSaleDate(lotData?.initDate, traditional.predictedTime)),
              current: FormatDate(setSaleDate(lotData?.initDate, current.predictedTime)),
              sale1: FormatDate(setSaleDate(lotData?.initDate, method[0].predictedTime)),
              sale2:
                method.length >= 2
                  ? FormatDate(setSaleDate(lotData?.initDate, method[1].predictedTime))
                  : null,
              sale3:
                method.length >= 3
                  ? FormatDate(setSaleDate(lotData?.initDate, method[2].predictedTime))
                  : null
            },
            {
              typeData: 'animalsAmount',
              traditional: traditional.animalsAmount,
              current: current.animalsAmount,
              sale1: method[0].animalsAmount,
              sale2: method.length >= 2 ? method[1].animalsAmount : null,
              sale3: method.length >= 3 ? method[2].animalsAmount : null
            }
          ]
        )
      }
      for (const column of columns) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2)),
            sale2: method.length >= 2 ? Format(Number(method[1][column]).toFixed(2)) : null
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditional[column]).toFixed(2)),
            current: Format(Number(current[column]).toFixed(2)),
            sale1: Format(Number(method[0][column]).toFixed(2)),
            sale2: method.length >= 2 ? Format(Number(method[1][column]).toFixed(2)) : null,
            sale3: method.length >= 3 ? Format(Number(method[2][column]).toFixed(2)) : null
          })
        }
      }
      for (const column of columnsProfit) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2)),
            sale2:
              methodProfit.length >= 2 ? Format(Number(methodProfit[1][column]).toFixed(2)) : null
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalProfit[column]).toFixed(2)),
            current: Format(Number(currentProfit[column]).toFixed(2)),
            sale1: Format(Number(methodProfit[0][column]).toFixed(2)),
            sale2:
              methodProfit.length >= 2 ? Format(Number(methodProfit[1][column]).toFixed(2)) : null,
            sale3:
              methodProfit.length >= 3 ? Format(Number(methodProfit[2][column]).toFixed(2)) : null
          })
        }
      }

      for (const column of columnsEnvironment) {
        if (value === 1) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2))
          })
        } else if (value === 2) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2)),
            sale2:
              methodEnvironment.length >= 2
                ? Format(Number(methodEnvironment[1][column]).toFixed(2))
                : null
          })
        } else if (value === 3) {
          aux.push({
            typeData: column,
            traditional: Format(Number(traditionalEnvironment[column]).toFixed(2)),
            current: Format(Number(currentEnvironment[column]).toFixed(2)),
            sale1: Format(Number(methodEnvironment[0][column]).toFixed(2)),
            sale2:
              methodEnvironment.length >= 2
                ? Format(Number(methodEnvironment[1][column]).toFixed(2))
                : null,
            sale3:
              methodEnvironment.length >= 3
                ? Format(Number(methodEnvironment[2][column]).toFixed(2))
                : null
          })
        }
      }
    }

    setDataTable(aux)
  }

  const getTranslateDataTable = (name: string) => {
    if (name == 'sale') return <text>{t('sale')}</text>
    else if (name == 'date') return <text>{t('date')}</text>
    else if (name == 'animalsAmount') return <text>{t('lot:n_animals')}</text>
    else if (name == 'predictedTime') return <text>{t('lot:confinement_days')}</text>
    else if (name == 'totalFastingArroba')
      return (
        <text>
          {t('weight_unity_arroba')} {t('lot:produced')}
        </text>
      )
    else if (name == 'dwg')
      return (
        <text>
          {t('zootechnical:dwg')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'arrobaStock') return <text>{t('animal:arroba_stock')}</text>
    else if (name == 'weight')
      return (
        <text>
          {t('zootechnical:live_weight')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'ingestion')
      return (
        <text>
          {t('zootechnical:ingestion')}, % {t('lot:lw')}
        </text>
      )
    else if (name == 'feedConversion')
      return (
        <text>
          {t('zootechnical:feed_conversion')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'energeticEfficiency')
      return (
        <text>
          {t('lot:energy_efficiency')}, {t('weight_unity_kg').toLocaleLowerCase()}
        </text>
      )
    else if (name == 'profitByAnimal')
      return (
        <text>
          {t('animal:animal_profit')}, {t('currency')}
        </text>
      )
    else if (name == 'costByAnimal')
      return (
        <text>
          {t('animal:animal_cost')}, {t('currency')}
        </text>
      )
    else if (name == 'revenueByAnimal')
      return (
        <text>
          {t('animal:animal_revenue')}, {t('currency')}
        </text>
      )
    else if (name == 'profitByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_profit')}, {t('currency')}
        </text>
      )
    else if (name == 'costByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_cost')}, {t('currency')}
        </text>
      )
    else if (name == 'revenueByWeightUnity')
      return (
        <text>
          {t('animal:weight_unity_revenue')}, {t('currency')}
        </text>
      )
    else if (name == 'totalProfit')
      return (
        <text>
          {t('animal:total_profit')}, {t('currency')}
        </text>
      )
    else if (name == 'totalCost')
      return (
        <text>
          {t('animal:total_cost')}, {t('currency')}
        </text>
      )
    else if (name == 'totalRevenue')
      return (
        <text>
          {t('animal:total_revenue')}, {t('currency')}
        </text>
      )
    else if (name == 'environmentEfficiency')
      return <text>{t('environmental:environment_efficiency')}</text>
    else if (name == 'waterIntake') return <text>{t('environmental:water_intake')}</text>
    else return ''
  }

  return (
    <div className={'lot-table-details'}>
      <Paper>
        <TableContainer className={'table-container'}>
          <Table stickyHeader aria-label='sticky table' padding={'none'}>
            <TableHead>
              <TableRow>
                <TableCell padding={'none'} className={'text-current'}></TableCell>
                <TableCell align='center' className={'text-current background-current'}>
                  {t('current')}
                </TableCell>
                <TableCell align='center' className={'text-traditional background-traditional'}>
                  {t('traditional')}
                </TableCell>
                {[...Array(props.saleSelected)].map((x, i) => (
                  <TableCell align='center' key={i} className={'header-button-selected'}>
                    {i + 1}º {t('sale')}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row) => (
                <TableRow key={row.typeData}>
                  <TableCell width={'30%'} align='left' className={'table-description-value'}>
                    {getTranslateDataTable(row.typeData)}
                  </TableCell>
                  <TableCell align='center' className={'table-values background-current'}>
                    {row.current}
                  </TableCell>
                  <TableCell align='center' className={'table-values background-traditional'}>
                    {row.traditional}
                  </TableCell>
                  <TableCell align='center' className={'table-values background-beeftrader'}>
                    {row.sale1}
                  </TableCell>
                  {props.saleSelected >= 2 ? (
                    <TableCell
                      align='center'
                      className={
                        props.saleSelected >= 2
                          ? 'table-values background-beeftrader'
                          : 'table-values'
                      }
                    >
                      {row.sale2}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {props.saleSelected >= 3 ? (
                    <TableCell
                      align='center'
                      className={
                        props.saleSelected == 3
                          ? 'table-values background-beeftrader'
                          : 'table-values'
                      }
                    >
                      {row.sale3}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default LotDetailsTable
