import produce from 'immer'
import { AnyAction } from 'redux'

// Types
import { MainStore, MainConstants, Themes, Locales, DataType, AlertModalTypes } from './types'

export const columnsAnimalBasic = {
  order: 1,
  id: '',
  label: '',
  collapse: true,
  columns: [
    {
      order: 1,
      id: 'earring',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:earring'
    },
    {
      order: 2,
      id: 'tag',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'animal:tag'
    },
    {
      order: 3,
      id: 'sisbov',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'animal:sisbov'
    },
    {
      order: 4,
      id: 'sale',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'sale'
    },
    {
      order: 5,
      id: 'saleDate',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'sale_date'
    },
    {
      order: 6,
      id: 'breed',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:breed'
    },
    {
      order: 7,
      id: 'gender',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:gender'
    }
  ]
}
export const columnsAnimalCurrentZootechnical = {
  order: 2,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 8,
      id: 'initialWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:initial_weight'
    },
    {
      order: 9,
      id: 'currentWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_weight'
    },
    {
      order: 10,
      id: 'currentDwg',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_dwg'
    },
    {
      order: 11,
      id: 'currentFeedConversion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_feed_conversion'
    },
    {
      order: 12,
      id: 'currentIngestion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_ingestion'
    },
    {
      order: 13,
      id: 'currentEgs',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_egs'
    },
    {
      order: 14,
      id: 'currentEgsCategory',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_egs_category'
    }
  ]
}
export const columnsAnimalZootechnical = {
  order: 3,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 15,
      id: 'dwg',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:daily_weight_gain'
    },
    {
      order: 16,
      id: 'feedConversion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'zootechnical:feed_conversion'
    },
    {
      order: 17,
      id: 'ingestion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'zootechnical:ingestion'
    },
    {
      order: 18,
      id: 'finalWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:final_weight'
    },
    {
      order: 19,
      id: 'egs',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:egs'
    },
    {
      order: 20,
      id: 'egsCategory',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:egs_category'
    }
  ]
}
export const columnsAnimalCurrentEconomic = {
  order: 4,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 21,
      id: 'currentDailyProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_daily_profit'
    },
    {
      order: 22,
      id: 'currentTotalProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_total_profit'
    },
    {
      order: 23,
      id: 'currentProfitByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_profit'
    },
    {
      order: 24,
      id: 'currentCostByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_cost'
    },
    {
      order: 25,
      id: 'currentRevenueByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_revenue'
    },
    {
      order: 26,
      id: 'currentArrobaStock',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_arroba_stock'
    }
  ]
}
export const columnsAnimalEconomic = {
  order: 7,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 27,
      id: 'dailyProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:daily_profit'
    },
    {
      order: 28,
      id: 'totalProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_profit'
    },
    {
      order: 29,
      id: 'totalCost',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_cost'
    },
    {
      order: 30,
      id: 'totalRevenue',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_revenue'
    },
    {
      order: 31,
      id: 'profitByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_profit'
    },
    {
      order: 32,
      id: 'costByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_cost'
    },
    {
      order: 33,
      id: 'revenueByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_revenue'
    },
    {
      order: 34,
      id: 'arrobaStock',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:arroba_stock'
    }
  ]
}
export const columnsAnimalCurrentEnvironmental = {
  order: 4,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 35,
      id: 'currentWaterIntake',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_water_intake'
    },
    {
      order: 36,
      id: 'currentCo2Emission',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_co2_emission'
    },
    {
      order: 37,
      id: 'currentManure',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_manure'
    },
    {
      order: 38,
      id: 'currentEnvironmentEfficiency',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_environment_efficiency'
    }
  ]
}
export const columnsAnimalEnvironmental = {
  order: 5,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 39,
      id: 'waterIntake',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:water_intake'
    },
    {
      order: 40,
      id: 'co2Emission',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:co2_emission'
    },
    {
      order: 41,
      id: 'manure',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:manure'
    },
    {
      order: 42,
      id: 'environmentEfficiency',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:environment_efficiency'
    }
  ]
}

export const columnsAnimalBasicOriginal = {
  order: 1,
  id: '',
  label: '',
  collapse: true,
  columns: [
    {
      order: 1,
      id: 'earring',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:earring'
    },
    {
      order: 2,
      id: 'tag',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'animal:tag'
    },
    {
      order: 3,
      id: 'sisbov',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'animal:sisbov'
    },
    {
      order: 4,
      id: 'sale',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'sale'
    },
    {
      order: 5,
      id: 'saleDate',
      disablePadding: false,
      type: 'string',
      active: true,
      label: 'sale_date'
    },
    {
      order: 6,
      id: 'breed',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:breed'
    },
    {
      order: 7,
      id: 'gender',
      disablePadding: false,
      type: 'string',
      active: false,
      label: 'animal:gender'
    }
  ]
}
export const columnsAnimalCurrentZootechnicalOriginal = {
  order: 2,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 8,
      id: 'initialWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:initial_weight'
    },
    {
      order: 9,
      id: 'currentWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_weight'
    },
    {
      order: 10,
      id: 'currentDwg',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_dwg'
    },
    {
      order: 11,
      id: 'currentFeedConversion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_feed_conversion'
    },
    {
      order: 12,
      id: 'currentIngestion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_ingestion'
    },
    {
      order: 13,
      id: 'currentEgs',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_egs'
    },
    {
      order: 14,
      id: 'currentEgsCategory',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_egs_category'
    }
  ]
}
export const columnsAnimalZootechnicalOriginal = {
  order: 3,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 15,
      id: 'dwg',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:daily_weight_gain'
    },
    {
      order: 16,
      id: 'feedConversion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'zootechnical:feed_conversion'
    },
    {
      order: 17,
      id: 'ingestion',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'zootechnical:ingestion'
    },
    {
      order: 18,
      id: 'finalWeight',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:final_weight'
    },
    {
      order: 19,
      id: 'egs',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:egs'
    },
    {
      order: 20,
      id: 'egsCategory',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:egs_category'
    }
  ]
}
export const columnsAnimalCurrentEconomicOriginal = {
  order: 4,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 21,
      id: 'currentDailyProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_daily_profit'
    },
    {
      order: 22,
      id: 'currentTotalProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:current_total_profit'
    },
    {
      order: 23,
      id: 'currentProfitByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_profit'
    },
    {
      order: 24,
      id: 'currentCostByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_cost'
    },
    {
      order: 25,
      id: 'currentRevenueByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_weight_unity_revenue'
    },
    {
      order: 26,
      id: 'currentArrobaStock',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:current_arroba_stock'
    }
  ]
}
export const columnsAnimalEconomicOriginal = {
  order: 7,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 27,
      id: 'dailyProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:daily_profit'
    },
    {
      order: 28,
      id: 'totalProfit',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_profit'
    },
    {
      order: 29,
      id: 'totalCost',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_cost'
    },
    {
      order: 30,
      id: 'totalRevenue',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'animal:total_revenue'
    },
    {
      order: 31,
      id: 'profitByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_profit'
    },
    {
      order: 32,
      id: 'costByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_cost'
    },
    {
      order: 33,
      id: 'revenueByWeightUnity',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:weight_unity_revenue'
    },
    {
      order: 34,
      id: 'arrobaStock',
      disablePadding: false,
      type: 'numeric',
      active: false,
      label: 'animal:arroba_stock'
    }
  ]
}
export const columnsAnimalCurrentEnvironmentalOriginal = {
  order: 4,
  id: 'current',
  label: 'animal:real',
  collapse: true,
  columns: [
    {
      order: 35,
      id: 'currentWaterIntake',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_water_intake'
    },
    {
      order: 36,
      id: 'currentCo2Emission',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_co2_emission'
    },
    {
      order: 37,
      id: 'currentManure',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_manure'
    },
    {
      order: 38,
      id: 'currentEnvironmentEfficiency',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:current_environment_efficiency'
    }
  ]
}
export const columnsAnimalEnvironmentalOriginal = {
  order: 5,
  id: 'predicted',
  label: 'animal:predicted',
  collapse: true,
  columns: [
    {
      order: 39,
      id: 'waterIntake',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:water_intake'
    },
    {
      order: 40,
      id: 'co2Emission',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:co2_emission'
    },
    {
      order: 41,
      id: 'manure',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:manure'
    },
    {
      order: 42,
      id: 'environmentEfficiency',
      disablePadding: false,
      type: 'numeric',
      active: true,
      label: 'environmental:environment_efficiency'
    }
  ]
}

export const columnsLot = [
  {
    id: 'environmentEfficiency',
    category: 'environmentalData',
    label: 'environmental:environment_efficiency',
    active: true
  },
  // {
  //   id: 'totalCo2Emission',
  //   category: 'environmentalData',
  //   label: 'total_emission',
  //   active: true
  // },
  {
    id: 'waterIntake',
    category: 'environmentalData',
    label: 'environmental:water_intake',
    active: true
  },
  // {
  //   id: 'totalWaterIntake',
  //   category: 'environmentalData',
  //   label: 'water_intake',
  //   active: true
  // },
  // {
  //   id: 'totalManure',
  //   category: 'environmentalData',
  //   label: 'water_intake',
  //   active: true
  // },
  {
    id: 'dwg',
    category: 'zootechnicalData',
    label: 'zootechnical:dwg',
    active: true
  },
  // {
  //   id: 'energeticEfficiency',
  //   category: 'zootechnicalData',
  //   label: 'water_intake',
  //   active: true
  // },
  // {
  //   id: 'feedConversion',
  //   category: 'zootechnicalData',
  //   label: 'water_intake',
  //   active: true
  // },
  // {
  //   id: 'ingestion',
  //   category: 'zootechnicalData',
  //   label: 'water_intake',
  //   active: true
  // },
  // {
  //   id: 'totalFastingArroba',
  //   category: 'zootechnicalData',
  //   label: 'water_intake',
  //   active: true
  // },
  // {
  //   id: 'totalFastingKg',
  //   category: 'zootechnicalData',
  //   label: 'water_intake',
  //   active: true
  // },
  {
    id: 'arrobaStock',
    category: 'zootechnicalData',
    label: 'animal:arroba_stock',
    active: true
  },
  {
    id: 'weight',
    category: 'zootechnicalData',
    label: 'zootechnical:live_weight',
    active: true
  },
  {
    id: 'profitByAnimal',
    category: 'profit',
    label: 'animal:animal_profit',
    active: true
  },
  {
    id: 'costByAnimal',
    category: 'profit',
    label: 'animal:animal_cost',
    active: true
  },
  {
    id: 'revenueByAnimal',
    category: 'profit',
    label: 'animal:animal_revenue',
    active: true
  },
  {
    id: 'profitByWeightUnity',
    category: 'profit',
    label: 'animal:weight_unity_profit',
    active: true
  },
  {
    id: 'costByWeightUnity',
    category: 'profit',
    label: 'animal:weight_unity_cost',
    active: true
  },
  {
    id: 'revenueByWeightUnity',
    category: 'profit',
    label: 'animal:weight_unity_revenue',
    active: true
  },
  {
    id: 'totalProfit',
    category: 'profit',
    label: 'animal:total_profit',
    active: true
  },
  {
    id: 'totalCost',
    category: 'profit',
    label: 'animal:total_cost',
    active: true
  },
  {
    id: 'totalRevenue',
    category: 'profit',
    label: 'animal:total_revenue',
    active: true
  }
]

const INITIAL_STORE: MainStore = {
  isCheckingSettings: true,
  isDrawerOpen: false,
  infoModal: {
    type: AlertModalTypes.info,
    open: false,
    message: undefined
  },
  locale: Locales.en,
  message: undefined,
  theme: Themes.LIGHT,
  dataType: DataType.ZOOTECHNICAL,
  columnsAnimalBasic,
  columnsAnimalCurrentEconomic,
  columnsAnimalEconomic,
  columnsAnimalCurrentZootechnical,
  columnsAnimalZootechnical,
  columnsAnimalCurrentEnvironmental,
  columnsAnimalEnvironmental,
  columnsAnimalBasicOriginal,
  columnsAnimalCurrentEconomicOriginal,
  columnsAnimalEconomicOriginal,
  columnsAnimalCurrentZootechnicalOriginal,
  columnsAnimalZootechnicalOriginal,
  columnsAnimalCurrentEnvironmentalOriginal,
  columnsAnimalEnvironmentalOriginal,
  columnsLot,
  timeZone: ''
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case MainConstants.REDUCER_CLEAR_MAIN_DATA:
        Object.assign(draft, null)
        break
      case MainConstants.REDUCER_SET_MAIN_DATA:
        Object.assign(draft, payload)
        break
      default:
        break
    }
  })
