import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

// Types
import { Props } from './types'

// Components
import SignIn from 'pages/SignIn'
import { ApplicationStore } from 'store/types'

// Resources
import Loading from 'components/shared/Loading'

const AuthRoute = (parentProps: Props) => {
  const { isAuthenticated } = useSelector((state: ApplicationStore) => state.auth)
  return (
    <Route
      exact
      path={parentProps.path}
      render={(props: any) =>
        parentProps.isCheckingAuthentication ? (
          <Loading />
        ) : isAuthenticated ? (
          <Redirect to={props?.location?.state?.from || { from: { pathname: '/' } }} />
        ) : (
          <SignIn />
        )
      }
    />
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  isCheckingAuthentication: store.auth.isCheckingAuthentication
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStoreToProps, mapDispatchToProps)(AuthRoute)
