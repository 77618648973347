import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { Locales } from 'store/main/types'
import locales from './locales'

i18n.use(initReactI18next).init({
  ns: ['default', 'user'],
  resources: locales,
  lng: Locales.en,
  defaultNS: 'default',
  fallbackLng: Locales.en,
  keySeparator: '.',

  interpolation: {
    escapeValue: false // react already safes from xss
  },
  backend: {
    loadPath: './locales/{{lng}}/{{ns}}.json'
  }
})

export default i18n
