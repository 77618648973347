import React, { forwardRef, useImperativeHandle, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {
  FormDataRef,
  FormValidationMessages,
  getConfigurationFormDataSchema,
  feedlotSalesParamsQuery,
  FormDataProps,
  feedlotZootechnicalParamsQuery
} from './types'
import { FeedlotSalesParams, FeedlotZootechnicalParams } from 'api/params/types'
import { ApplicationStore } from 'store/types'
import {
  getFeedlotSalesParams,
  createFeedlotSalesParams,
  updateFeedlotSalesParams,
  getFeedlotZootechnicalParams,
  createFeedlotZootechnicalParams,
  updateFeedlotZootechnicalParams
} from 'api/params'

const FormData: React.RefForwardingComponent<FormDataRef, FormDataProps> = (
  {
    currentSalesParams,
    setCurrentSalesParams,
    currentZootechnicalParams,
    setCurrentZootechnicalParams,
    setFormErrorsMessages
  }: FormDataProps,
  ref
) => {
  const { t } = useTranslation()
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)

  const formValidationMessages: FormValidationMessages = {
    mtDif: `${t('traditional_method')} | 60 <= ${t('value')} <= 200`,
    dif: `60 <= ${t('value')} <= 200`,
    saleAnimalsNumber: `0 < ${t('value')}`,
    sbwLimit: `0 < ${t('value')}`,
    ingestionAdjustment: t('just_integer_number'),
    lossPercentage: `0 <= ${t('value')}`,
    truckAnimalsLimit: `0 < ${t('value')}`,
    eccLimit: `1 <= ${t('value')} <= 9`
  }

  useImperativeHandle(ref, () => ({
    submit,
    formValidationMessages: formValidationMessages
  }))

  useEffect(() => {
    setFormErrorsMessages({})

    async function getParams() {
      const response: FeedlotSalesParams = await getFeedlotSalesParams(
        feedlotSalesParamsQuery,
        selectedFeedlot?.id || 0
      )
      setCurrentSalesParams(response)
      const responseZootechnical: FeedlotZootechnicalParams = await getFeedlotZootechnicalParams(
        feedlotZootechnicalParamsQuery,
        selectedFeedlot?.id || 0
      )

      if (responseZootechnical) {
        if (responseZootechnical.ingestionAdjustment)
          responseZootechnical.ingestionAdjustment = responseZootechnical.ingestionAdjustment * 100
        if (responseZootechnical.lossPercentage)
          responseZootechnical.lossPercentage = responseZootechnical.lossPercentage * 100
      }
      setCurrentZootechnicalParams(responseZootechnical)
    }

    if (selectedFeedlot) getParams()
    else {
      setCurrentSalesParams(undefined)
      setCurrentZootechnicalParams(undefined)
    }
  }, [selectedFeedlot])

  const configurationFormDataSchema = getConfigurationFormDataSchema(formValidationMessages)

  const validateFormData = async () => {
    setFormErrorsMessages({})
    await configurationFormDataSchema.validate(
      { ...currentSalesParams, ...currentZootechnicalParams },
      {
        abortEarly: false
      }
    )
  }

  const setFormErrors = (error: Yup.ValidationError) => {
    const errorMessages: any = {}
    error.inner.forEach((error) => {
      errorMessages[error.path] = error.message
    })
    setFormErrorsMessages(errorMessages)
  }

  const saveParams = async () => {
    const getParamValue = (param: any) => Number(param)
    const params: FeedlotSalesParams = {
      id: currentSalesParams?.id ?? 0,
      feedlotId: selectedFeedlot?.id as number,
      mtDif: getParamValue(currentSalesParams?.mtDif),
      dif: getParamValue(currentSalesParams?.dif),
      saleAnimalsNumber: getParamValue(currentSalesParams?.saleAnimalsNumber),
      sbwLimit: getParamValue(currentSalesParams?.sbwLimit),
      truckAnimalsLimit: getParamValue(currentSalesParams?.truckAnimalsLimit),
      eccLimit: getParamValue(currentSalesParams?.eccLimit)
    }
    const paramsZootechnical: FeedlotZootechnicalParams = {
      id: currentZootechnicalParams?.id ?? 0,
      feedlotId: selectedFeedlot?.id as number,
      ingestionAdjustment: getParamValue(currentZootechnicalParams?.ingestionAdjustment) / 100,
      lossPercentage: getParamValue(currentZootechnicalParams?.lossPercentage) / 100
    }

    if (params.id === 0) {
      const newParamId = await createFeedlotSalesParams(params)
      setCurrentSalesParams({
        ...params,
        id: newParamId
      })
      const newParamIdZootechnical = await createFeedlotZootechnicalParams(paramsZootechnical)
      setCurrentZootechnicalParams({
        ...paramsZootechnical,
        id: newParamIdZootechnical
      })
    } else {
      await updateFeedlotSalesParams(params)
      await updateFeedlotZootechnicalParams(paramsZootechnical)
    }
  }

  async function submit() {
    try {
      if (!currentSalesParams || !currentZootechnicalParams) {
        return
      }
      await validateFormData()
      await saveParams()
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        setFormErrors(error)
      }
      throw error
    }
  }

  return <></>
}
export default forwardRef(FormData)
