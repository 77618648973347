import { Credentials } from 'api/auth/types'

export type AuthData = {
  refreshToken: string
  credentials?: Credentials
}

export enum StorageKeys {
  AuthData = 'techagr@authdata',
  Credentials = 'techagr@credentials',
  DataType = 'techagr@datatype',
  ColumnsAnimalBasic = 'techagr@columnsanimalbasic',
  ColumnsAnimalEconomic = 'techagr@columnsanimaleconomic',
  ColumnsAnimalCurrentEconomic = 'techagr@columnsanimalcurrenteconomic',
  ColumnsAnimalZootechnical = 'techagr@columnsanimalzootechnical',
  ColumnsAnimalCurrentZootechnical = 'techagr@columnsanimalcurrentzootechnical',
  ColumnsAnimalEnvironmental = 'techagr@columnsanimalenvironmental',
  ColumnsAnimalCurrentEnvironmental = 'techagr@columnsanimalcurrentenvironmental',
  ColumnsLot = 'techagr@columnsLot',
  Locale = 'techagr@locale',
  RefreshToken = 'techagr@refreshtoken',
  UnitySystem = 'techagr@unitysystem',
  Theme = 'techagr@theme'
}
