import React from 'react'
import { Props } from './types'

const HomeIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 100 100'
      className={'card-icon'}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect fill={fill} x='15' y='18.77' width='70' height='62.45' />
        </clipPath>
      </defs>
      <g id='Arte_37' data-name='Arte 37'>
        <g fill={fill}>
          <g fill={fill}>
            <path
              fill={fill}
              d='M84.26,43.42,51.08,19.12a1.85,1.85,0,0,0-2.15,0L15.74,43.42a1.82,1.82,0,0,0,2.15,2.93L50,22.85l32.11,23.5a1.79,1.79,0,0,0,1.07.36,1.82,1.82,0,0,0,1.08-3.29'
            />
            <path
              fill={fill}
              d='M75.46,46.77a1.81,1.81,0,0,0-1.81,1.82V77.53H59.16V61.73A9.2,9.2,0,0,0,50,52.64a9,9,0,0,0-9,9.09V77.67H26.35V48.59a1.81,1.81,0,0,0-1.81-1.82,1.92,1.92,0,0,0-1.89,1.82V79.35c.07,1,1.89,1.88,1.89,1.88H42.71a1.81,1.81,0,0,0,1.81-1.68.61.61,0,0,0,0-.14V61.88a5.47,5.47,0,1,1,10.94-.09V79.55a1.83,1.83,0,0,0,1.82,1.68H75.46s1.82-.87,1.88-1.88V48.59a1.91,1.91,0,0,0-1.88-1.82'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HomeIcon
