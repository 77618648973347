import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Resources
import './styles.scss'

// Types
import { DrawerItem } from './types'
import { ApplicationStore } from 'store/types'

//Componentes
import {
  Drawer as DrawerMui,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon
} from '@material-ui/core'
import HomeIcon from 'resources/img/icons/beeftrader-home-icon'
import colors, { Colors } from 'styles/variables/colors'
import OxIcon from 'resources/img/icons/beeftrader-ox-icon'
import ChartIcon from 'resources/img/icons/beeftrader-chart-icon'
import SettingsIcon from 'resources/img/icons/beeftrader-settings-icon'
import LotsIcon from 'resources/img/icons/beeftrader-lots-icon'
import WeightIcon from 'resources/img/icons/beeftrader-weight-icon'
import { mdiCalendarToday } from '@mdi/js'

export const drawerItens: DrawerItem[] = [
  {
    key: 0,
    icon: '',
    label: 'home',
    path: '/',
    checkActivePath: (path: string) => {
      // const reg = /\/feedlot\=/i
      return path === '/' || path.indexOf('/feedlot=') === 0
    }
  },
  {
    key: 1,
    icon: '',
    label: 'pon_calendar',
    path: '/pon-calendar-details',
    checkActivePath: (path: string) => path.indexOf('/pon-calendar-details') >= 0
  },
  {
    key: 2,
    icon: '',
    label: 'lot:lots',
    path: '/lots-summary',
    checkActivePath: (path: string) => path.indexOf('/lots-summary') >= 0
  },
  {
    key: 3,
    icon: '',
    label: 'animal:animals',
    path: '/animals',
    checkActivePath: (path: string) => path.indexOf('/animals') >= 0
  },
  {
    key: 4,
    icon: '',
    label: 'animal:weighings',
    path: '/weighing',
    checkActivePath: (path: string) => path.indexOf('/weighing') >= 0
  },
  {
    key: 5,
    icon: '',
    label: 'charts',
    path: '/charts',
    checkActivePath: (path: string) => path.indexOf('/charts') >= 0
  },
  {
    key: 6,
    icon: '',
    label: 'setting',
    path: '/settings',
    checkActivePath: (path: string) => path.indexOf('/setting') >= 0
  }
]

export const IconItem: React.FC<any> = (item: any) => {
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  if (item.active === true) {
    if (item.item.key == 0)
      return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    if (item.item.key == 1)
      return <Icon style={{ color: colors(theme, Colors.accent) }}>today</Icon>
    else if (item.item.key == 2)
      return <LotsIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 3)
      return <OxIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 4)
      return <WeightIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 5)
      return <ChartIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 6)
      return <SettingsIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else return <OxIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
  } else {
    if (item.item.key == 0)
      return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    if (item.item.key == 1) return <Icon style={{ color: colors(theme, Colors.gray) }}>today</Icon>
    else if (item.item.key == 2)
      return <LotsIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 3)
      return <OxIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 4)
      return <WeightIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 5)
      return <ChartIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 6)
      return <SettingsIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else return <OxIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
  }
}
const Drawer: React.FC = () => {
  // const { pathname } = props.location
  const { pathname } = useLocation()
  const history = useHistory()
  const { theme, isDrawerOpen } = useSelector((state: ApplicationStore) => state.main)
  const { t } = useTranslation()
  const handleClick = (path: string) => {
    history.push(path)
  }
  return (
    <DrawerMui
      className={`drawer ${isDrawerOpen ? 'drawer-opened' : 'drawer-closed'}`}
      variant='permanent'
      classes={{
        paper: isDrawerOpen ? 'drawer-opened' : 'drawer-closed'
      }}
    >
      <div className='drawer-spacing' />
      <List>
        {drawerItens.map((i) => (
          <Tooltip key={i.key} title={t(i.label) as string}>
            <ListItem
              button
              onClick={() => handleClick(i.path)}
              selected={i.checkActivePath(pathname)}
            >
              <ListItemIcon>
                <IconItem item={i} active={i.checkActivePath(pathname)}>
                  {' '}
                </IconItem>
              </ListItemIcon>
              <ListItemText primary={t(i.label)} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </DrawerMui>
  )
}

export default Drawer
