export type State = {
  id: number
  description: string
  code?: string
  cities: City[]
}
export type City = {
  id: number
  description: string
}

export type Address = {
  countryId: number
  countryDescription: string
  stateId: number
  stateDescription: string
  cityId: number
  cityDescription: string
  cityStateDescription: string
}

export type AddressStore = {
  readonly cities: City[]
  readonly states: State[]
  readonly selectedCityId?: number
  readonly selectedStateId?: number
  readonly cityLoading: boolean
  readonly stateLoading: boolean
}

export enum AddressConstants {
  REDUCER_SET_ADDRESS_DATA = 'REDUCER_SET_ADDRESS_DATA',
  REDUCER_CLEAR_ADDRESS_DATA = 'REDUCER_CLEAR_ADDRESS_DATA',
  ACTION_GET_CITY = 'ACTION_GET_CITY',
  ACTION_GET_STATE = 'ACTION_GET_STATE'
}
