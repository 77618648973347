import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
// Components
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
// Methods
import { getSelectedLot } from 'store/lot/actions'
import { setDataType } from 'store/main/actions'
import { textTooltip } from './helpers'
// Types
import { ApplicationStore } from 'store/types'
import { Props, AnimalDaily } from './types'
// Resources
import './styles.scss'
import { add, addDays, differenceInCalendarDays, setDate } from 'date-fns'

const LineCharts = ({
  measurementUnitChart,
  propsCurrentData,
  propsPredictedData,
  nameKey,
  initDate,
  viewByDate
}: Props) => {
  const { t } = useTranslation()

  const [tooltip, setTooltip] = useState<any>(-1)
  const [breakLineX, setBreakLineX] = useState<any>()
  const [domainLineX, setDomainLineX] = useState<any>()
  const [predictedData, setPredictedData] = useState<AnimalDaily[]>([])
  const [currentData, setCurrentData] = useState<AnimalDaily[]>([])
  const [currentDataWithDates, setCurrentDataWithDates] = useState<Array<any>>([])
  const [predictedDataWithDates, setPredictedDataWithDates] = useState<Array<any>>([])

  useEffect(() => {
    setCurrentData(propsCurrentData)
    setPredictedData(propsPredictedData)
  }, [propsCurrentData, propsPredictedData])

  const CustomTooltip = (e: any) => {
    if (!e.payload) return null
    if (e.payload.length <= 0) return null

    if (!e.active || tooltip === -1) return null
    const data = tooltip.daily.find((fin: any) => fin.time === e.payload[0].payload.time)
    if (data)
      return (
        <div className='tooltip-container'>
          {textTooltip(t, data, tooltip.earring, measurementUnitChart, nameKey, initDate)}
        </div>
      )
    else return null
  }
  const CustomizedDot = (e: any) => {
    if (e.id === tooltip.id)
      return (
        <svg x={e.cx - 5} y={e.cy - 5} fill='#ff8400'>
          <circle cx={4} cy={4} r={4} fill='#ff8400' />
        </svg>
      )
    else return null
  }

  const convertDaysToDate = (days: number) => {
    const newDate = new Date(initDate)
    newDate.setDate(newDate.getDate() + days)
    return newDate.getTime()
  }

  const dateFormatter = (date: any) => {
    return new Date(date).toLocaleDateString('pt-Br').slice(0, 5)
  }

  const getBreaks = (startDate: any, endDate: any, num: any) => {
    const diffDays = differenceInCalendarDays(endDate, startDate)
    const current = startDate,
      velocity = Math.round(diffDays / (num - 1))

    const breaks = [startDate.getTime()]

    for (let i = 1; i < num - 1; i++) {
      breaks.push(add(current, { days: i * velocity }).getTime())
    }

    breaks.push(endDate.getTime())
    return breaks
  }

  useEffect(() => {
    const startDate = new Date(initDate)

    if (currentData[0]?.daily) {
      const rangeOfDays = currentData[0].daily.map((daily) => convertDaysToDate(daily.time))

      const newCurrentData = currentData.map((item) => {
        const result = item.daily.map((item, index) => ({ ...item, dateChart: rangeOfDays[index] }))
        return { id: item.id, earring: item.earring, daily: result }
      })
      const rangeOfDaysPredicated = predictedData[0].daily.map((daily) =>
        convertDaysToDate(daily.time)
      )
      const newPredicatedData = predictedData.map((item) => {
        const result = item.daily.map((item, index) => ({
          ...item,
          dateChart: rangeOfDaysPredicated[index]
        }))
        return { id: item.id, earring: item.earring, daily: result }
      })
      setBreakLineX(
        getBreaks(
          startDate,
          addDays(startDate, predictedData[0].daily[predictedData[0].daily.length - 1].time),
          6
        )
      )
      setDomainLineX([
        startDate.getTime(),
        addDays(startDate, predictedData[0].daily[predictedData[0].daily.length - 1].time).getTime()
      ])
      setCurrentDataWithDates(newCurrentData)
      setPredictedDataWithDates(newPredicatedData)
    }
  }, [currentData])

  return (
    <div className={'line-chart'}>
      <ResponsiveContainer className={'chart-responsive-container'}>
        <LineChart
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5
          }}
        >
          {viewByDate ? (
            <XAxis
              dataKey='dateChart'
              scale='time'
              tickFormatter={dateFormatter}
              type='number'
              domain={domainLineX}
              ticks={breakLineX}
            />
          ) : (
            <XAxis dataKey='time' type='number' tickCount={10} />
          )}
          <YAxis dataKey={nameKey} />
          <Tooltip content={<CustomTooltip />} />
          {currentDataWithDates.map((s: any, index: number) => (
            <Line
              key={s.id + 'real'}
              dataKey={nameKey}
              data={s.daily}
              name={s.id}
              stroke={'#ff8400'}
              strokeWidth={1}
              dot={{
                r: 4,
                stroke: 'rgba(0,0,0,0)',
                fill: 'rgba(0,0,0,0)',
                onMouseOver: () => setTooltip(s),
                onMouseOut: () => setTooltip(-1)
              }}
              activeDot={false}
              // activeDot={<CustomizedDot id={Number(s.id)} values={s} />}
            />
          ))}
          {predictedDataWithDates.map((s: any, index: number) => (
            <Line
              key={s.id + 'predicted'}
              dataKey={nameKey}
              data={s.daily}
              name={s.id}
              strokeWidth={1}
              stroke={'#aaa'}
              dot={{
                r: 4,
                stroke: 'rgba(0,0,0,0)',
                fill: 'rgba(0,0,0,0)',
                onMouseOver: () => setTooltip(s),
                onMouseOut: () => setTooltip(-1)
              }}
              activeDot={false}
              // activeDot={<CustomizedDot id={Number(s.id)} values={s} />}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineCharts
