import React, { Fragment, useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Components
import { FormControl, Input, InputAdornment, MenuItem, Select } from '@material-ui/core'
import WeighingScatterCharts from 'components/charts/WeighingScatterCharts'
import { Search } from '@material-ui/icons'
import Loading from 'components/shared/Loading'
import useIsElementVisible from './useIsElementVisible'

// Types
import { ApplicationStore } from 'store/types'

// Methods
import { getWeighingAnimals } from 'store/animal/actions'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import Legend from 'components/shared/Legend'

const AnimalWeighingCharts: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [chartListFilter, setChartListFilter] = useState<string>('earring')
  const [originalData, setOriginalData] = useState<any>([])
  const [searchFieldValue, setSearchFieldValue] = useState<string>('')
  const { theme } = useSelector((state: ApplicationStore) => state.main)

  const { animalsWeighingData, animalWeighingLoading, animalWeighingComplete } = useSelector(
    (state: ApplicationStore) => state.animal
  )
  const { selectedLot, lotLoading } = useSelector((state: ApplicationStore) => state.lot)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const lastRef = useRef(null)
  const [pageScroll, setPageScroll] = useState(0)
  const [chartDataResume, setChartDataResume] = useState<any>([])
  const [chartDataFiltered, setChartDataFiltered] = useState<any>([])

  const isLastVisible = useIsElementVisible(lastRef)

  useEffect(() => {
    setOriginalData([])
    setChartDataResume([])
    setChartDataFiltered([])
  }, [selectedFeedlot, selectedLot])
  useEffect(() => {
    // Fetch ao chegar no final da lista.
    if (isLastVisible) {
      getMoreTodos(pageScroll + 1, chartDataFiltered)
      setPageScroll(pageScroll + 1)
    }
  }, [isLastVisible])

  const getMoreTodos = async (page: number, data: any) => {
    try {
      const initial = 0
      let final = 6 * page
      if (final > data.length) final = data.length
      setChartDataResume(data.slice(initial, final))
    } catch (err) {}
  }

  useEffect(() => {
    if (selectedFeedlot && selectedLot) {
      if (!animalWeighingLoading) carregaDados()
      setPageScroll(0)
      getMoreTodos(0, originalData)
    }
  }, [])

  const carregaDados = () => {
    if (selectedFeedlot && selectedLot && !lotLoading) {
      dispatch(getWeighingAnimals(selectedFeedlot.id, selectedLot.id, true))
    }
  }

  useEffect(() => {
    if (!animalWeighingLoading && !animalWeighingComplete) {
      carregaDados()
    }
  }, [animalWeighingLoading, lotLoading])

  useEffect(() => {
    if (animalsWeighingData.length) {
      const data = animalsWeighingData?.filter((ani) => ani.active === true)
      setOriginalData(data)
      filterCharts(searchFieldValue, data, false)
    }
  }, [animalsWeighingData])

  const filterCharts = (value: string, data: any[], alterFilter: boolean) => {
    let auxPageScroll = pageScroll
    if (alterFilter) {
      auxPageScroll = 0
    }
    if (value === null || value === '') {
      setPageScroll(auxPageScroll)
      setChartDataFiltered(data)
      getMoreTodos(auxPageScroll, data)
      return
    }
    const filtered = data.filter(
      (d: any) =>
        d[chartListFilter] && d[chartListFilter].toLowerCase().includes(value.toLowerCase())
    )
    setPageScroll(auxPageScroll)
    setChartDataFiltered(filtered)
    getMoreTodos(auxPageScroll, filtered)
  }

  return (
    <div className='chart-list'>
      <div className='filters-group-chart'>
        <FormControl style={{ width: '160px', padding: '5px' }}>
          <Select
            classes={{ root: 'select-root', icon: 'select-icon' }}
            value={chartListFilter}
            displayEmpty
            disableUnderline
            onChange={(e: any) => {
              setChartListFilter(e.target.value)
              filterCharts('', originalData, true)
              setSearchFieldValue('')
            }}
          >
            <MenuItem value={'earring'}>{t('animal:earring')}</MenuItem>
            <MenuItem value={'tag'}>{t('animal:tag')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '200px', padding: '5px' }}>
          <Input
            classes={{ root: 'select-root' }}
            disableUnderline
            value={searchFieldValue}
            onChange={(e) => {
              setSearchFieldValue(e.target.value)
              filterCharts(e.target.value, originalData, true)
            }}
            placeholder={
              chartListFilter === 'sale' ? t(chartListFilter) : t('animal:' + chartListFilter)
            }
            endAdornment={
              <InputAdornment position='end'>
                <Search className={'icon'} />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <Legend average={true} />
      <Fragment>
        <div className={'charts-container'}>
          {chartDataResume.map((item: any, id: number) => {
            return (
              <div className={'charts'} key={id}>
                <h3 className={'chart-title'}>
                  {chartListFilter === 'earring' ? item.earring : item[chartListFilter]}
                </h3>
                <WeighingScatterCharts
                  realWeighings={item.weighing}
                  lotWeighingsAverage={selectedLot?.averageWeight}
                ></WeighingScatterCharts>
              </div>
            )
          })}
          <div style={{ minHeight: '30px', width: '100%' }} ref={lastRef} />
        </div>
        {!animalWeighingComplete && <Loading />}
      </Fragment>
    </div>
  )
}

export default AnimalWeighingCharts
