import { all, takeLatest } from 'redux-saga/effects'

// Types
import { AnimalManagementConstants } from './types'

// Methods
import { workerSetAnimalsManagement } from './sagas'

export function* watcherSetAnimalsManagement() {
  yield takeLatest(
    AnimalManagementConstants.ACTION_SET_ANIMAL_MANAGEMENT,
    workerSetAnimalsManagement
  )
}

export default function* animalManagementWatchers() {
  yield all([watcherSetAnimalsManagement()])
}
