import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import {
  Feedlot,
  Lot,
  ZootechnicalPenData,
  ZootechnicalLotData,
  Profit,
  FeedlotProfit,
  Pen,
  NextSale
} from 'store/feedlot/types'
import { FeedlotRequestParams, NextSalesParams, NextSaleArgs, FeedlotQueryArgs } from './types'
import QueryBuilder from 'helpers/QueryBuilder'
import { getItemFromStorage } from 'storage'
import { AuthData, StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'

export async function getFeedlotsRequest(queryNextSalesArgs?: NextSaleArgs): Promise<Feedlot[]> {
  const queryParams: FeedlotRequestParams = {
    id: true,
    description: true,
    lots: {
      id: true,
      description: true,
      initDate: true,
      animalsCount: true
    },
    pens: {
      id: true,
      code: true
    },
    animalsCount: true,
    animalsDelayedWeighingCount: true,
    profit: {
      traditionalMethod: true,
      currentProfit: true,
      beeftraderMethod: {
        saleType: true,
        totalProfit: true
      }
    },
    currentZootechnicalDataByLot: {
      penId: true,
      lotId: true,
      dwg: true,
      weight: true
    }
  }

  const queryNextSales: NextSalesParams = {
    lotId: true,
    description: true,
    sale: true,
    date: true,
    animalsNumber: true
  }

  const QUERY = gql`
    query QUERY (
      $minTime:Float!,
      $maxTime:Float!,
      $saleType:Float!
    ){
      feedlot { 
        ${QueryBuilder(queryParams).slice(1, -2)},
        nextSales(
          startTime: $minTime,
          endTime: $maxTime,
          saleType: $saleType
        )
        ${QueryBuilder(queryNextSales)}
      }
    }
  `

  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })

    const response = await API.query(
      QUERY,
      queryNextSalesArgs ? { ...queryNextSalesArgs } : {},
      credentials.token
    )
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/animal/getFeedlots',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getFeedlots', errors)
    }

    const feedlots: Feedlot[] = []
    data.feedlot.map((f: any) => {
      feedlots.push({
        id: f.id,
        description: f.description,
        pens: getPens(f.pens),
        lots: getLots(f.lots),
        animalsCount: f.animalsCount,
        animalsDelayedWeighingCount: f.animalsDelayedWeighingCount,
        currentZootechnicalData: getZootechnicalPenData(f.currentZootechnicalData),
        profit: getProfit(f.profit),
        nextSales: getNextSales(f.nextSales)
      })
    })
    return feedlots
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getFeedlotsRequest', error)
  }
}

const getNextSales = (data: any): NextSale[] => {
  if (!data) return []
  return data.map((k: any) => ({
    lotId: k.lotId,
    sale: k.sale,
    description: k.description,
    date: k.date,
    animalsNumber: k.animalsNumber
  }))
}

const getPens = (data: any): Pen[] => {
  if (!data) return []
  return data.map((k: any) => ({
    id: k.id,
    code: k.code
  }))
}

const getLots = (data: any): Lot[] => {
  if (!data) return []
  return data.map((k: any) => ({
    id: k.id,
    description: k.description,
    initDate: k.initDate,
    animalsCount: k.animalsCount
  }))
}

const getZootechnicalPenData = (data: any): ZootechnicalPenData[] => {
  if (!data) return []
  return data.map((k: any) => ({
    penId: k.penId,
    lotsData: getZootechnicalLotData(k.lotsData)
  }))
}

const getZootechnicalLotData = (data: any): ZootechnicalLotData[] => {
  return data.map((k: any) => ({
    lotId: k.lotId,
    weight: k.weight,
    dwg: k.dwg
  }))
}

const getProfit = (data: any): FeedlotProfit | undefined => {
  if (!data) return undefined
  return {
    traditional: data.traditionalMethod,
    current: data.currentProfit,
    beeftrader: getBeefTraderMethod(data.beeftraderMethod)
  }
}

const getBeefTraderMethod = (data: any): Profit[] =>
  data.map((k: any) => ({
    sales: k.saleType,
    profit: k.totalProfit
  }))

export async function getFeedlotsPens(feedlotId: FeedlotQueryArgs): Promise<Feedlot[]> {
  const queryParams: FeedlotRequestParams = {
    id: true,
    pens: {
      id: true,
      code: true
    }
  }
  const QUERY = gql`
      query QUERY (
        $feedlotId:[Int!],
      ){
        feedlot(feedlotId: $feedlotId) { 
          ${QueryBuilder(queryParams).slice(1, -2)},
        }
      }
    `

  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })

    const response = await API.query(QUERY, feedlotId, credentials.token)

    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/animal/getFeedlots',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getFeedlots', errors)
    }

    const feedlots: any[] = []
    data.feedlot.map((f: any) => {
      feedlots.push({
        id: f.id,
        pens: getPens(f.pens)
      })
    })
    return feedlots
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getFeedlotsRequest', error)
  }
}
