import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Types
import { quoteSlaughterhousePriceQueryFields } from './types'
import { ApplicationStore } from 'store/types'

// Components
import { FormControlLabel, Switch } from '@material-ui/core'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import CustomMaterialTable from 'components/tables/CustomMaterialTable'
import Loading from 'components/shared/Loading'

// Methods
import { saveFeedlotConfiguration } from 'store/feedlot-slaughterhouse/actions'
// Resources
import './styles.scss'
import { getQuoteSlaughterhousePrice } from 'store/economic/actions'

const RegionPriceSlaughterhouse: React.FC = () => {
  const { t } = useTranslation()
  const formRef = useRef<FormHandles>(null)
  const { quoteSlaughterhousePrice, quoteSlaughterhousePriceLoading } = useSelector(
    (state: ApplicationStore) => state.economic
  )
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const { feedlotConfiguration } = useSelector(
    (state: ApplicationStore) => state.feedlotSlaughterhouse
  )

  const dispatch = useDispatch()
  const [rows, setRows] = useState<Array<any>>([])

  useEffect(() => {
    if (selectedFeedlot?.id)
      dispatch(
        getQuoteSlaughterhousePrice({
          args: {},
          fields: quoteSlaughterhousePriceQueryFields
        })
      )
  }, [selectedFeedlot])

  useEffect(() => {
    if (!quoteSlaughterhousePriceLoading) {
      // let lastSlaughterhouse: string | undefined = ''
      const listSlaughterhousePrice = []
      const uniqueSlaughterhouse = quoteSlaughterhousePrice
        .map((item) => item.region.id)
        .filter((value, index, self) => self.indexOf(value) === index)
      for (const u of uniqueSlaughterhouse) {
        const prices = quoteSlaughterhousePrice.filter((q) => q.region.id == u)
        listSlaughterhousePrice.push({
          region: prices[0].region?.description,
          regionId: prices[0].region?.id,
          status: feedlotConfiguration?.regionIdPreference == prices[0].region.id ? true : false,
          priceMC:
            t('currency') +
            ' ' +
            Number(prices.find((p) => (p.gender ? p.gender.code == 'MC' : ''))?.price).toFixed(2),
          priceMI:
            t('currency') +
            ' ' +
            Number(prices.find((p) => (p.gender ? p.gender.code == 'MI' : ''))?.price).toFixed(2),
          priceVC:
            t('currency') +
            ' ' +
            Number(prices.find((p) => (p.gender ? p.gender.code == 'VC' : ''))?.price).toFixed(2),
          priceNV:
            t('currency') +
            ' ' +
            Number(prices.find((p) => (p.gender ? p.gender.code == 'NV' : ''))?.price).toFixed(2),
          currencyWeightUnity: prices[0].currency?.symbol + '/' + prices[0].weightUnity?.symbol
        })
      }
      quoteSlaughterhousePrice ? setRows(listSlaughterhousePrice) : setRows([])
    }
  }, [quoteSlaughterhousePriceLoading])

  const saveSlaughterhousePriceConfig = async (value: number | null) => {
    try {
      if (value !== feedlotConfiguration?.regionIdPreference && selectedFeedlot)
        dispatch(
          saveFeedlotConfiguration({
            feedlotConfiguration: { feedlotId: selectedFeedlot?.id, regionIdPreference: value }
          })
        )
    } catch (error: any) {
      throw error
    }
  }

  const selectRegion = (oldRow: any, e: any) => {
    const list = []
    let regionSelected: number | null = null
    for (const row of rows) {
      list.push({
        region: row.region,
        regionId: row.regionId,
        status: !row.status,
        priceMC: row.priceMC,
        priceMI: row.priceMI,
        priceVC: row.priceVC,
        priceNV: row.priceNV,
        currencyWeightUnity: row.currencyWeightUnity
      })
      if (!row.status == true) {
        regionSelected = row.regionId
      }
    }
    saveSlaughterhousePriceConfig(regionSelected)
    setRows(list)
  }
  return (
    <div className='region-price-slaughterhouses-body'>
      <div className='params-row'>
        <Form ref={formRef} onSubmit={() => null} className={'form-body'}>
          <div style={{ width: '100%' }}>
            {quoteSlaughterhousePriceLoading ? (
              <Loading />
            ) : (
              <>
                <CustomMaterialTable
                  // parentChildData={(row, rows) => rows.find((a) => a.region === row.parentId)}
                  columns={[
                    {
                      title: t('status'),
                      field: 'status',
                      render: (data) => {
                        if (data?.status !== undefined)
                          return (
                            <FormControlLabel
                              control={
                                <Switch
                                  className={'personal-price-switch'}
                                  checked={data.status}
                                  defaultChecked={false}
                                  onChange={(e) => selectRegion(data, e)}
                                />
                              }
                              label={t('active')}
                            />
                          )
                      },
                      editable: 'always'
                    },
                    {
                      title: t('region'),
                      field: 'region'
                    },
                    {
                      title: t('unity'),
                      field: 'currencyWeightUnity'
                    },
                    {
                      title: t('animal:price_macho_inteiro'),
                      field: 'priceMI'
                    },
                    {
                      title: t('animal:price_macho_castrado'),
                      field: 'priceMC'
                    },
                    {
                      title: t('animal:price_novilha'),
                      field: 'priceNV'
                    },
                    {
                      title: t('animal:price_vaca'),
                      field: 'priceVC'
                    }
                  ]}
                  data={rows}
                  options={{
                    showTitle: false,
                    pageSize: 10
                  }}
                />
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default RegionPriceSlaughterhouse
