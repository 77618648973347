import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts'
// Methods
import { renderCustomBarLabel } from './helpers'
// Types
import { ApplicationStore } from 'store/types'
import { DataChart, Props } from './types'
// Resources
import colors, { Colors } from 'styles/variables/colors'
import { setDataType } from 'store/main/actions'
import { getSelectedLot } from 'store/lot/actions'
import { DataType } from 'store/main/types'
import { useHistory } from 'react-router-dom'
import { getSelectedPen } from 'store/pen/actions'
// Components

const ZootechnicalChart: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const [smallBar, setSmallBar] = useState<number>(0)
  const [minDwg, setMinDwg] = useState<number>(0)
  const [dataBar, setDataBar] = useState<DataChart[]>([])
  const transformData = (data: DataChart[]) => {
    const largerWeightValue: number = Math.max(
      ...data.map(function (o) {
        return o.weight
      })
    )

    const dataWithoutZero: DataChart[] = []
    data.map((value) => {
      if (value.dwg > 0) dataWithoutZero.push(value)
    })
    const minDwgValue: number = Math.min(
      ...dataWithoutZero.map(function (o) {
        return o.dwg
      })
    )
    if (minDwgValue < 0) setMinDwg(minDwgValue * -1)

    const sizeChart = 155

    const aux: DataChart[] = []
    for (const d of data) {
      const valuePercentWeight = (d.weight * 100) / largerWeightValue
      const lengthBar = (valuePercentWeight * sizeChart) / 100
      if (d.currentTime != 0) {
        aux.push({
          lotId: d.lotId,
          lot: d.lot,
          weight: Number(d.weight.toFixed(2)),
          weightPercent: valuePercentWeight,
          dwg: d.dwg,
          currentTime: '(' + d.currentTime + ' dias)',
          lengthBar: lengthBar
        })
      }
    }

    const lengthBarWithoutZero: DataChart[] = []
    aux.map((value) => {
      if (value.lengthBar && value.lengthBar > 0) lengthBarWithoutZero.push(value)
    })
    setSmallBar(
      Math.min(
        ...lengthBarWithoutZero.map(function (o) {
          return o.lengthBar ? o.lengthBar : 0
        })
      )
    )
    setDataBar(aux)
    return
  }

  useEffect(() => {
    transformData(props.dataLot)
  }, [props.dataLot])

  const handleCardClick = (lotId: number) => {
    dispatch(setDataType(DataType.ZOOTECHNICAL))
    // dispatch(getSelectedLot(selectedFeedlot?.id ?? 0, lotId))
    if (selectedFeedlot) dispatch(getSelectedPen(selectedFeedlot?.id, undefined, lotId))
    history.push('/animals')
    history.push(`/animals`)
  }

  return (
    <div className={'zootechnical-chart-container'}>
      <ResponsiveContainer className={'chart-responsive-container'}>
        <BarChart
          data={dataBar}
          margin={{
            top: 20,
            right: 5,
            left: 5,
            bottom: 20
          }}
        >
          <XAxis
            axisLine={true}
            tickLine={true}
            dataKey='lot'
            tickMargin={5}
            stroke={colors(theme, Colors.grayMedium)}
          />
          <YAxis hide />
          <Bar dataKey='weightPercent' barSize={70}>
            {dataBar.map((entry: any, index: any) => (
              <Cell
                style={{ cursor: 'pointer' }}
                key={`cell-${index}`}
                fill={colors(theme, Colors.redDark)}
                onClick={() => {
                  handleCardClick(entry.lotId)
                }}
                // style={{ maxHeight: '30px', margin: '100px' }}
              />
            ))}
            <LabelList dataKey='weight' position='top' className='label-chart' />
            <LabelList
              dataKey='dwg'
              position='bottom'
              content={(labelProps: any) =>
                renderCustomBarLabel(t, theme, labelProps, labelProps, 'dwg', smallBar, minDwg)
              }
            />
            <LabelList dataKey='currentTime' position='bottom' offset={30} className='label-xlim' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ZootechnicalChart
