import React, { MouseEventHandler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Resources
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'
import { AnimalsChecked, Props } from './types'

// Components

// Methods
import { useTranslation } from 'react-i18next'
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Box,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { getSelectedPen } from 'store/pen/actions'
import {
  getAnimals,
  getEconomicDailyAnimals,
  getZootechnicalDailyDataAnimals
} from 'store/animal/actions'
import Loading from 'components/shared/Loading'
import LineCharts from 'components/charts/LineCharts'
import {
  AnimalProfitBySale,
  AnimalsEconomicDaily,
  LotAnimalsSlaughterhouse,
  LotProfitBySale
} from 'store/profit/types'
import ScatterCharts from 'components/charts/ScartterCharts'
import { Animal, AnimalZootechnicalDataBySale } from 'store/animal/types'
import CustomSelectMultipleSublevel from 'components/shared/CustomSelectMultipleSublevel'
import CardSalesButtons from 'components/shared/CardSalesButtons'
import CustomSelectMultiple from 'components/shared/CustomSelectMultiple'
import CustomSelectCategory from 'components/shared/CustomSelectCategory'
import { getSelectedLot } from 'store/lot/actions'
import FormatDate from 'helpers/FormatDate'
import { setSaleDate } from 'pages/helpers'
import moment from 'moment'

type DailyZootechnicalData = {
  time: number
  dwg: number
  feedConversion: number
  ingestion: number
}
type AnimalZootechnicalDaily = {
  id: number
  earring: string
  daily: DailyZootechnicalData[]
}

type DailyEconomicData = {
  time: number
  accumulatedProfit: number
  accumulatedCost: number
  accumulatedRevenue: number
  // cost: number
  // ingestion: number
}
type AnimalEconomicDaily = {
  id: number
  earring: string
  daily: DailyEconomicData[]
}

const Charts: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const {
    animals,
    animalsZootechnicalDailyData,
    animalsEconomicDaily,
    animalLoading,
    animalZootechnicalDailyDataLoading,
    animalEconomicDailyLoading
  } = useSelector((state: ApplicationStore) => state.animal)
  const { pens, selectedPen, penLoading } = useSelector((state: ApplicationStore) => state.pen)

  const [lotSelected, setLotSelected] = useState(0)

  const [zootechnicalDailyData, setZootechnicalDailyData] = useState<AnimalZootechnicalDaily[]>([])
  const [economicDailyData, setEconomicDailyData] = useState<AnimalEconomicDaily[]>([])

  const [predictedEconomicDailyData, setPredictedEconomicDailyData] = useState<
    AnimalEconomicDaily[]
  >([])
  const [predictedZootechnicalDailyData, setPredictedZootechnicalDailyData] = useState<
    AnimalZootechnicalDaily[]
  >([])

  const [zootechnicalDailyDataSelected, setZootechnicalDailyDataSelected] = useState<
    AnimalZootechnicalDaily[]
  >([])
  const [economicDailyDataSelected, setEconomicDailyDataSelected] = useState<AnimalEconomicDaily[]>(
    []
  )
  const [predictedEconomicDailyDataSelected, setPredictedEconomicDailyDataSelected] = useState<
    AnimalEconomicDaily[]
  >([])
  const [predictedZootechnicalDailyDataSelected, setPredictedZootechnicalDailyDataSelected] =
    useState<AnimalZootechnicalDaily[]>([])

  const [scatterData, setScatterData] = useState<any>([])
  const [scatterDataDwg, setScatterDataDwg] = useState<any>([])

  const [scatterDataSelected, setScatterDataSelected] = useState<any>([])
  const [scatterDataDwgSelected, setScatterDataDwgSelected] = useState<any>([])

  const [filter, setFilter] = useState<string>('')
  const [earringChecked, setEarringChecked] = useState<AnimalsChecked[]>([])
  const [listGroupLots, setListGroupLots] = useState<any[]>([])
  const [sales, setSales] = useState(1)
  const [saleData, setSaleData] = useState<LotProfitBySale>()
  const [viewByDate, setViewByDate] = useState<boolean | null>(false)

  const loadDataChart = () => {
    const zootechnicalAnimal: AnimalZootechnicalDaily[] = []
    const predictedZootechnicalAnimal: AnimalZootechnicalDaily[] = []
    if (
      animals &&
      selectedLot &&
      !animalZootechnicalDailyDataLoading &&
      animalsZootechnicalDailyData
    ) {
      animalsZootechnicalDailyData.map((animalZootechnical) => {
        const animalData = animals?.find(
          (ani) => ani.id === animalZootechnical.id && ani.active === true
        )
        if (animalData?.zootechnicalData) {
          const zootechnicalInSale = animalData.zootechnicalData.beeftraderMethod.find(
            (a) => a.saleType === sales
          )
          if (zootechnicalInSale) {
            let dataFilter: DailyZootechnicalData[] = []
            animalZootechnical.zootechnicalDailyData.map((fil) => {
              if (fil.time <= zootechnicalInSale.result.predictedTime)
                dataFilter.push({
                  time: fil.time,
                  dwg: fil.dwg,
                  feedConversion: fil.feedConversion,
                  ingestion: fil.ingestion
                })
            })
            let auxZootechnicalDaily: DailyZootechnicalData[] = [
              ...dataFilter,
              {
                time: zootechnicalInSale.result.predictedTime,
                dwg: zootechnicalInSale.result.realDwg,
                feedConversion: zootechnicalInSale.result.feedConversionInTime,
                ingestion: zootechnicalInSale.result.ingestionInTime
              }
            ]
            zootechnicalAnimal.push({
              id: animalZootechnical.id,
              earring: animalData.earring ? animalData.earring : '',
              daily: auxZootechnicalDaily.sort((a, b) => (a.time > b.time ? 1 : -1))
            })
            dataFilter = []
            animalZootechnical.zootechnicalDailyData.map((fil) => {
              if (fil.time >= zootechnicalInSale.result.predictedTime)
                dataFilter.push({
                  time: fil.time,
                  dwg: fil.dwg,
                  feedConversion: fil.feedConversion,
                  ingestion: fil.ingestion
                })
            })
            auxZootechnicalDaily = [
              ...dataFilter,
              {
                time: zootechnicalInSale.result.predictedTime,
                dwg: zootechnicalInSale.result.realDwg,
                feedConversion: zootechnicalInSale.result.feedConversionInTime,
                ingestion: zootechnicalInSale.result.ingestionInTime
              }
            ]
            predictedZootechnicalAnimal.push({
              id: animalZootechnical.id,
              earring: animalData.earring ? animalData.earring : '',
              daily: auxZootechnicalDaily.sort((a, b) => (a.time > b.time ? 1 : -1))
            })
          }
        }
      })
      setZootechnicalDailyData(zootechnicalAnimal)
      setPredictedZootechnicalDailyData(predictedZootechnicalAnimal)
      setZootechnicalDailyDataSelected(zootechnicalAnimal)
      setPredictedZootechnicalDailyDataSelected(predictedZootechnicalAnimal)
    }
  }
  const loadEconomicData = () => {
    const auxProfitAnimal: AnimalsEconomicDaily[] = []
    const profitAnimal: AnimalEconomicDaily[] = []
    const predictedProfitAnimal: AnimalEconomicDaily[] = []
    if (animals && selectedLot && !animalEconomicDailyLoading && animalsEconomicDaily) {
      const animalsSlaughterhouseSale = selectedLot.animalsSlaughterhouse?.beeftraderMethod.find(
        (fil) => fil.saleType === sales
      )
      if (animalsSlaughterhouseSale) {
        animalsSlaughterhouseSale.sales.map((animalsSales) => {
          animalsSales.animalsSlaughterhouse.map(
            (animalsSlaughterhouse: LotAnimalsSlaughterhouse) => {
              if (animalsEconomicDaily) {
                const animalsSlaughterhouseSelected = animalsEconomicDaily.find(
                  (fil) => fil.slaughterhouseId === animalsSlaughterhouse.slaughterhouseId
                )
                if (animalsSlaughterhouseSelected)
                  auxProfitAnimal.push(
                    ...animalsSlaughterhouseSelected.animals.filter((ani) =>
                      animalsSlaughterhouse.animals.includes(ani.id)
                    )
                  )
              }
            }
          )
        })
        auxProfitAnimal.map((p: AnimalsEconomicDaily) => {
          const animalData: any = animals?.find((ani) => ani.id === p.id)
          if (animalData) {
            const profitInSale: any = animalData.profit.beeftraderMethod.find(
              (a: any) => a.saleType === sales
            )

            if (profitInSale) {
              let dataFilter: DailyEconomicData[] = []
              p.economicDailyData.map((fil) => {
                if (fil.time <= profitInSale.result.predictedTime)
                  dataFilter.push({
                    time: fil.time,
                    accumulatedProfit: fil.accumulatedProfit,
                    accumulatedCost: fil.accumulatedCost,
                    accumulatedRevenue: fil.accumulatedRevenue
                  })
              })
              let auxProfitDaily: DailyEconomicData[] = [
                ...dataFilter,
                {
                  time: profitInSale.result.predictedTime,
                  accumulatedProfit: profitInSale.result.totalProfit,
                  accumulatedCost: profitInSale.result.totalCost,
                  accumulatedRevenue: profitInSale.result.totalRevenue
                }
              ]
              profitAnimal.push({
                id: p.id,
                earring: animalData.earring ? animalData.earring : '',
                daily: auxProfitDaily.sort((a, b) => (a.time > b.time ? 1 : -1))
              })
              dataFilter = []
              p.economicDailyData.map((fil) => {
                if (fil.time >= profitInSale.result.predictedTime)
                  dataFilter.push({
                    time: fil.time,
                    accumulatedProfit: fil.accumulatedProfit,
                    accumulatedCost: fil.accumulatedCost,
                    accumulatedRevenue: fil.accumulatedRevenue
                  })
              })
              auxProfitDaily = [
                ...dataFilter,
                {
                  time: profitInSale.result.predictedTime,
                  accumulatedProfit: profitInSale.result.totalProfit,
                  accumulatedCost: profitInSale.result.totalCost,
                  accumulatedRevenue: profitInSale.result.totalRevenue
                }
              ]
              predictedProfitAnimal.push({
                id: p.id,
                earring: animalData.earring ? animalData.earring : '',
                daily: auxProfitDaily.sort((a, b) => (a.time > b.time ? 1 : -1))
              })
            }
          }
        })
        setEconomicDailyData(profitAnimal)
        setPredictedEconomicDailyData(predictedProfitAnimal)
        setEconomicDailyDataSelected(profitAnimal)
        setPredictedEconomicDailyDataSelected(predictedProfitAnimal)
      }
    }
  }
  const loadScartterChartWeightProfit = () => {
    if (animals) {
      const activeAnimals = animals.map((an) => {
        const animal = an ? JSON.parse(JSON.stringify(an)) : null
        if (animal.profit) {
          animal?.profit?.beeftraderMethod.map((bm: any) => {
            const zootechnicalResult = animal?.zootechnicalData?.beeftraderMethod.find(
              (z: AnimalZootechnicalDataBySale) => z.sale === bm.sale && z.saleType === bm.saleType
            )
            return (
              (bm.result['earring'] = animal.earring),
              (bm.result.totalProfit = Number(bm.result.totalProfit.toFixed(2))),
              (bm.result.weight = Number(zootechnicalResult.result.weight?.toFixed(2)))
            )
          })
        }
        if (an.active === true) if (an) return JSON.parse(JSON.stringify(animal))
      })
      const auxBeeftrader = activeAnimals.map((a: Animal) => {
        const animalProfitBySale = a?.profit?.beeftraderMethod?.filter(
          (f: AnimalProfitBySale) => f.saleType === sales
        )
        animalProfitBySale
        const animal = a ? JSON.parse(JSON.stringify(a)) : null
        if (animalProfitBySale) {
          animal.profit.beeftraderMethod = animalProfitBySale
          return animal
        }
      })

      const animalsActive: number[] = []
      const dataFiltered: AnimalProfitBySale[] = []

      animals.map((fil) => {
        if (fil.active === true) animalsActive.push(fil.id)
      })
      auxBeeftrader.map((ani) => {
        if (animalsActive.includes(ani?.id)) {
          dataFiltered.push(...ani.profit.beeftraderMethod)
        }
      })
      setScatterData(dataFiltered)
      setScatterDataSelected(dataFiltered)
    }
  }
  const loadScartterChartDwgProfit = () => {
    if (animals) {
      const activeAnimals = animals.map((an) => {
        const animal = an ? JSON.parse(JSON.stringify(an)) : null
        if (animal.profit) {
          animal?.profit?.beeftraderMethod.map((bm: any) => {
            const zootechnicalResult = animal?.zootechnicalData?.beeftraderMethod.find(
              (z: AnimalZootechnicalDataBySale) => z.sale === bm.sale && z.saleType === bm.saleType
            )
            return (
              (bm.result['earring'] = animal.earring),
              (bm.result.totalProfit = Number(bm.result.totalProfit.toFixed(2))),
              (bm.result.dwg = Number(zootechnicalResult.result.dwg?.toFixed(2)))
            )
          })
        }
        if (an.active === true) if (an) return JSON.parse(JSON.stringify(animal))
      })
      const auxBeeftrader = activeAnimals.map((a: Animal) => {
        const animalProfitBySale = a?.profit?.beeftraderMethod?.filter(
          (f: AnimalProfitBySale) => f.saleType === sales
        )
        const animal = a ? JSON.parse(JSON.stringify(a)) : null
        if (animalProfitBySale) {
          animal.profit.beeftraderMethod = animalProfitBySale
          return animal
        }
      })

      const animalsActive: number[] = []
      const dataFiltered: AnimalProfitBySale[] = []

      animals.map((fil) => {
        if (fil.active === true) animalsActive.push(fil.id)
      })
      auxBeeftrader.map((ani) => {
        if (animalsActive.includes(ani?.id)) {
          dataFiltered.push(...ani.profit.beeftraderMethod)
        }
      })
      setScatterDataDwg(dataFiltered)
      setScatterDataDwgSelected(dataFiltered)
    }
  }

  const handleAnimalsSelected = (event: MouseEventHandler<HTMLElement>, value: AnimalsChecked) => {
    const aux = [
      ...earringChecked.filter((a) => a.animalId !== value.animalId),
      { earring: value.earring, sale: value.sale, active: !value.active, animalId: value.animalId }
    ]
    aux.sort((a, b) => (a.earring > b.earring ? 1 : -1))
    setEarringChecked(aux)
    // handleFilters(timeFilter, aux, dataLineAnimal, predictedDataLineAnimal)
    // handleScatterFilters(aux, scatterData)
  }

  useEffect(() => {
    if (selectedFeedlot) {
      dispatch(getSelectedPen(selectedFeedlot.id))
    }
  }, [selectedFeedlot])

  useEffect(() => {
    if (selectedPen && !penLoading && pens) {
      setListGroupLots(pens)
    }
  }, [penLoading])

  useEffect(() => {
    const animalsChecked: AnimalsChecked[] = []
    if (selectedFeedlot && selectedLot && animals && !animalLoading) {
      animals.map((a) => {
        //RETORNA SOMENTE OS BRINCOS QUE TEM DADOS PROCESSADOS
        if (a.profit) {
          const sale = a.profit.beeftraderMethod.find((b) => b.saleType === 1)?.sale
          animalsChecked.push({
            animalId: a.id,
            earring: a.earring ? a.earring : '',
            sale: sale ? sale : 0,
            active: true
          })
        }
      })
      animalsChecked.sort((a, b) => (a.earring > b.earring ? 1 : -1))
      setEarringChecked(animalsChecked)
      dispatch(getZootechnicalDailyDataAnimals(selectedFeedlot?.id, selectedLot.id))
      dispatch(getEconomicDailyAnimals(selectedFeedlot?.id, selectedLot.id))
      // loadDataChart()
    }
  }, [animalLoading])

  useEffect(() => {
    if (selectedFeedlot && selectedLot) {
      setLotSelected(selectedLot.id)
      dispatch(getAnimals(selectedFeedlot?.id, selectedLot.id))
    }
  }, [selectedLot])

  useEffect(() => {
    if (!animalZootechnicalDailyDataLoading) {
      loadDataChart()
    }
  }, [sales, animalZootechnicalDailyDataLoading])

  useEffect(() => {
    if (!animalEconomicDailyLoading) {
      loadEconomicData()
    }
  }, [sales, animalEconomicDailyLoading])

  useEffect(() => {
    if (!animalEconomicDailyLoading && !animalZootechnicalDailyDataLoading) {
      loadScartterChartWeightProfit()
      loadScartterChartDwgProfit()
    }
  }, [sales, animalEconomicDailyLoading, animalZootechnicalDailyDataLoading])

  useEffect(() => {
    const auxZootechnical: AnimalZootechnicalDaily[] = []
    const auxZootechnicalPredicted: AnimalZootechnicalDaily[] = []
    const auxEconomic: AnimalEconomicDaily[] = []
    const auxEconomicPredicted: AnimalEconomicDaily[] = []
    const auxScatter: any[] = []
    const auxScatterDwg: any[] = []
    earringChecked.map((ear) => {
      if (ear.active) {
        const dataZootechnical = zootechnicalDailyData.find((fil) => fil.id == ear.animalId)
        if (dataZootechnical) auxZootechnical.push(dataZootechnical)

        const dataZootechnicalPredicted = predictedZootechnicalDailyData.find(
          (fil) => fil.id == ear.animalId
        )
        if (dataZootechnicalPredicted) auxZootechnicalPredicted.push(dataZootechnicalPredicted)

        const dataEconomic = economicDailyData.find((fil) => fil.id == ear.animalId)
        if (dataEconomic) auxEconomic.push(dataEconomic)

        const dataEconomicPredicted = predictedEconomicDailyData.find(
          (fil) => fil.id == ear.animalId
        )
        if (dataEconomicPredicted) auxEconomicPredicted.push(dataEconomicPredicted)

        const dataScatter = scatterData.find((fil: any) => fil.result.earring == ear.earring)
        if (dataScatter) auxScatter.push(dataScatter)
        const dataScatterDwg = scatterDataDwg.find((fil: any) => fil.result.earring == ear.earring)
        if (dataScatterDwg) auxScatterDwg.push(dataScatterDwg)
      }
    })

    setPredictedEconomicDailyDataSelected(auxEconomicPredicted)
    setEconomicDailyDataSelected(auxEconomic)
    setPredictedZootechnicalDailyDataSelected(auxZootechnicalPredicted)
    setZootechnicalDailyDataSelected(auxZootechnical)
    setScatterDataSelected(auxScatter)
    setScatterDataDwgSelected(auxScatterDwg)
  }, [earringChecked])
  const getSalesDate = (sale: number) => {
    setSales(sale)
    if (selectedLot) {
      const data = selectedLot.profit?.beeftraderMethod.find((p) => p.saleType === sale)
      if (data) setSaleData(data)
    } else {
      setSaleData(undefined)
    }
  }

  const handleFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    // const selected: number = event.target.value as number
    if (selectedFeedlot) {
      dispatch(getSelectedLot(selectedFeedlot.id, event.target.value as number))
      setLotSelected(event.target.value as number)
    }
  }

  return (
    <div className={'charts-body'}>
      <div className={'charts-card'}>
        <div className={'charts-column'} style={{ width: '75%' }}>
          <div className={'charts-row'}>
            <text className={'title-page'}>{t('compare_lots')}</text>
          </div>
          <div className={'charts-row'}>
            <div className='group-select'>
              <CustomSelectCategory
                textFixed={t('feedlot:pen_lot')}
                onChangeValue={handleFields}
                value={listGroupLots}
                lotSelected={lotSelected}
              />
            </div>
            <FormControlLabel
              control={
                <Switch
                  className={'date-switch'}
                  checked={viewByDate || false}
                  onChange={(e) => setViewByDate(e.target.checked)}
                />
              }
              label={t('chart:view_by_date')}
            />
            <CardSalesButtons
              t={t}
              selectedSales={sales}
              handleSalesChange={(sales) => getSalesDate(sales)}
            />
            <div className={'subheader-table'}>
              {saleData?.sale.map((p, index: number) => (
                <div className={'group-sales'} key={index}>
                  <p className={'sale-text'} key={index}>
                    {index + 1}ª {t('sale')}:{' '}
                  </p>
                  <p className={'description-text'} key={index}>
                    {FormatDate(setSaleDate(selectedLot?.initDate, p.predictedTime))}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={'charts-column'} style={{ width: '25%' }}>
          <div className={'charts-row'}>
            <Box className='box'>
              <div className={'charts-row'}>
                <text className={'text-title'}>{t('lot_summary')}</text>
              </div>
              <div className={'charts-row'}>
                <div className='group-value'>
                  <text className={'text-value'}>
                    {FormatDate(selectedLot?.initDate as string)}
                  </text>
                  <text className={'text-item'}>{t('start_date')}</text>
                </div>
                <div className='group-value'>
                  <text className={'text-value'}>{selectedLot?.animalsCount}</text>
                  <text className={'text-item'}>{t('animal:animals')}</text>
                </div>
                <div className='group-value'>
                  <text className={'text-value'}>
                    {Number(moment().diff(moment(selectedLot?.initDate), 'days')) + 1}
                  </text>
                  <text className={'text-item'}>{t('time_feedlot')}</text>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className={'charts-card'}>
        <div className={'charts-grid'} style={{ maxWidth: '250px' }}>
          <List
            disablePadding
            className={'list border'}
            style={{ minHeight: window.innerHeight - 300 + 'px' }}
          >
            {animalLoading ? (
              <Loading />
            ) : (
              earringChecked.map((value: AnimalsChecked, index: number) => {
                const labelId = `checkbox-list-label-${value}`
                return (
                  <ListItem
                    key={index}
                    role={undefined}
                    dense
                    button
                    onClick={(e: any) => handleAnimalsSelected(e, value)}
                  >
                    <Checkbox
                      classes={{ root: 'checkbox-root' }}
                      edge='start'
                      checked={value.active}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                    <ListItemText
                      id={labelId}
                      className={'text-description'}
                      primary={value.earring}
                    />
                  </ListItem>
                )
              })
            )}
          </List>
        </div>
        <div className={'charts-grid'}>
          <div className={'chart-box'}>
            <text className={'chart-title'}>{t('chart:profit_by_animal')}</text>
            {!animalEconomicDailyLoading && !animalLoading ? (
              <LineCharts
                titleChart={'zootechnical:feed_conversion'}
                nameKey={'accumulatedProfit'}
                propsCurrentData={economicDailyDataSelected}
                propsPredictedData={predictedEconomicDailyDataSelected}
                measurementUnitChart='currency'
                initDate={selectedLot?.initDate}
                viewByDate={viewByDate}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className={'chart-box'}>
            <text className={'chart-title'}>{t('chart:dwg_by_animal')}</text>
            {!animalZootechnicalDailyDataLoading && !animalLoading ? (
              <LineCharts
                titleChart={'chart:dwg_by_animal'}
                nameKey={'dwg'}
                propsCurrentData={zootechnicalDailyDataSelected}
                propsPredictedData={predictedZootechnicalDailyDataSelected}
                measurementUnitChart='weight'
                initDate={selectedLot?.initDate}
                viewByDate={viewByDate}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className={'chart-box'}>
            <text className={'chart-title'}>
              {t('zootechnical:feed_conversion')} {t('by')} {t('animal:animal')}
            </text>
            {!animalZootechnicalDailyDataLoading && !animalLoading ? (
              <LineCharts
                titleChart={'zootechnical:feed_conversion'}
                nameKey={'feedConversion'}
                propsCurrentData={zootechnicalDailyDataSelected}
                propsPredictedData={predictedZootechnicalDailyDataSelected}
                measurementUnitChart='weight'
                initDate={selectedLot?.initDate}
                viewByDate={viewByDate}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className={'chart-box'}>
            <text className={'chart-title'}>
              {t('zootechnical:ingestion')} {t('by')} {t('animal:animal')}
            </text>
            {!animalZootechnicalDailyDataLoading && !animalLoading ? (
              <LineCharts
                titleChart={'zootechnical:feed_conversion'}
                nameKey={'ingestion'}
                propsCurrentData={zootechnicalDailyDataSelected}
                propsPredictedData={predictedZootechnicalDailyDataSelected}
                measurementUnitChart='weight'
                initDate={selectedLot?.initDate}
                viewByDate={viewByDate}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className={'chart-box'}>
            <text className={'chart-title'}>{t('chart:accumulated_profit')}</text>
            {!animalEconomicDailyLoading &&
            !animalZootechnicalDailyDataLoading &&
            !animalLoading &&
            scatterData &&
            animals ? (
              <ScatterCharts
                animals={scatterDataSelected}
                yFieldKey={'totalProfit'}
                xFieldKey={'weight'}
                chartName={'accumulated_profit'}
              />
            ) : (
              <Loading />
            )}
          </div>
          <div className={'chart-box'}>
            <text className={'chart-title'}>{t('chart:average_dwg')}</text>
            {!animalEconomicDailyLoading &&
            !animalZootechnicalDailyDataLoading &&
            !animalLoading &&
            scatterDataDwg &&
            animals ? (
              <ScatterCharts
                animals={scatterDataDwgSelected}
                yFieldKey={'totalProfit'}
                xFieldKey={'dwg'}
                chartName={'average_dwg'}
              />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Charts
