import React from 'react'
import * as Yup from 'yup'
import {
  FeedlotSalesParams,
  FeedlotSalesParamsQueryFields,
  FeedlotNutritionQueryFields,
  DailyFeedlotQueryFields,
  FeedlotZootechnicalParams,
  FeedlotZootechnicalParamsQueryFields,
  CarcassYieldQueryFields,
  FeedlotCarcassYieldQueryFields
} from 'api/params/types'

export interface FormValidationMessages {
  mtDif: string
  dif: string
  saleAnimalsNumber: string
  sbwLimit: string
  lossPercentage: string
  ingestionAdjustment: string
  truckAnimalsLimit: string
  eccLimit: string
}

export interface Props {
  currentSalesParams: FeedlotSalesParams | undefined
  setCurrentSalesParams: React.Dispatch<React.SetStateAction<FeedlotSalesParams | undefined>>
  currentZootechnicalParams: FeedlotZootechnicalParams | undefined
  setCurrentZootechnicalParams: React.Dispatch<
    React.SetStateAction<FeedlotZootechnicalParams | undefined>
  >
  nutritions: FeedlotNutritionGridData[]
  setNutritions: React.Dispatch<React.SetStateAction<FeedlotNutritionGridData[]>>
  nutritionGridActions: FeedlotNutritionGridActions
  setNutritionGridActions: React.Dispatch<React.SetStateAction<FeedlotNutritionGridActions>>
  dailyFeedlot: DailyFeedlotGridData[]
  setDailyFeedlot: React.Dispatch<React.SetStateAction<DailyFeedlotGridData[]>>
  dailyFeedlotGridActions: DailyFeedlotGridActions
  setDailyFeedlotGridActions: React.Dispatch<React.SetStateAction<DailyFeedlotGridActions>>
  carcassYields: FeedlotCarcassYieldGridData[]
  setCarcassYields: React.Dispatch<React.SetStateAction<FeedlotCarcassYieldGridData[]>>
  carcassYieldGridActions: FeedlotCarcassYieldGridActions
  setCarcassYieldGridActions: React.Dispatch<React.SetStateAction<FeedlotCarcassYieldGridActions>>
  formValidationMessages: FormValidationMessages | undefined
  formErrorMessages: any
}
export interface FormDataProps {
  currentSalesParams: FeedlotSalesParams | undefined
  setCurrentSalesParams: React.Dispatch<React.SetStateAction<FeedlotSalesParams | undefined>>
  currentZootechnicalParams: FeedlotZootechnicalParams | undefined
  setCurrentZootechnicalParams: React.Dispatch<
    React.SetStateAction<FeedlotZootechnicalParams | undefined>
  >
  setFormErrorsMessages: React.Dispatch<React.SetStateAction<any>>
}

export interface FormDataRef {
  submit(): void
  formValidationMessages: FormValidationMessages
}

export const feedlotSalesParamsQuery: FeedlotSalesParamsQueryFields = {
  id: true,
  feedlotId: true,
  mtDif: true,
  dif: true,
  saleAnimalsNumber: true,
  sbwLimit: true,
  truckAnimalsLimit: true,
  eccLimit: true
}

export const feedlotZootechnicalParamsQuery: FeedlotZootechnicalParamsQueryFields = {
  id: true,
  feedlotId: true,
  lossPercentage: true,
  ingestionAdjustment: true
}

export const getConfigurationFormDataSchema = (formValidationText: FormValidationMessages) => {
  return Yup.object().shape({
    mtDif: Yup.number()
      .typeError(formValidationText.mtDif)
      .min(60, formValidationText.mtDif)
      .max(200, formValidationText.mtDif),
    dif: Yup.number()
      .typeError(formValidationText.dif)
      .min(60, formValidationText.dif)
      .max(200, formValidationText.dif),
    saleAnimalsNumber: Yup.number()
      .typeError(formValidationText.saleAnimalsNumber)
      .positive(formValidationText.saleAnimalsNumber),
    sbwLimit: Yup.number()
      .typeError(formValidationText.sbwLimit)
      .positive(formValidationText.sbwLimit),
    lossPercentage: Yup.number()
      .typeError(formValidationText.lossPercentage)
      .min(0, formValidationText.lossPercentage),
    ingestionAdjustment: Yup.number()
      .typeError(formValidationText.ingestionAdjustment)
      .integer(formValidationText.ingestionAdjustment),
    truckAnimalsLimit: Yup.number()
      .typeError(formValidationText.truckAnimalsLimit)
      .positive(formValidationText.truckAnimalsLimit),
    eccLimit: Yup.number()
      .typeError(formValidationText.eccLimit)
      .min(1, formValidationText.eccLimit)
      .max(9, formValidationText.eccLimit)
  })
}

export interface NutritionDataProps {
  nutritions: FeedlotNutritionGridData[]
  setNutritions: React.Dispatch<React.SetStateAction<FeedlotNutritionGridData[]>>
  nutritionGridActions: FeedlotNutritionGridActions
  setNutritionGridActions: React.Dispatch<React.SetStateAction<FeedlotNutritionGridActions>>
}

export interface NutritionDataRef {
  submit(): void
}
export const feedlotNutritionQuery: FeedlotNutritionQueryFields = {
  id: true,
  feedlotId: true,
  category: true,
  start: true,
  end: true,
  tdn: true,
  dryMatter: true,
  dietCost: true
}

export interface FeedlotNutritionGridData {
  id: number
  feedlotId: number
  category: string
  period: number
  start: number
  end: number
  tdn: number
  dryMatter: number
  dietCost: number
  dryMatterCost: number
  _addId: number
}

export interface FeedlotNutritionGridActions {
  add?: number[]
  update?: number[]
  delete?: FeedlotNutritionGridData[]
}

export interface DailyFeedlotGridData {
  id: number
  feedlotId: number
  startingWeight: number
  finalWeight: number
  cost: number
  _addId: number
}

export interface DailyFeedlotGridActions {
  add?: number[]
  update?: number[]
  delete?: DailyFeedlotGridData[]
}

export const dailyFeedlotQuery: DailyFeedlotQueryFields = {
  id: true,
  feedlotId: true,
  startingWeight: true,
  finalWeight: true,
  cost: true
}

export interface DailyFeedlotDataProps {
  dailyFeedlot: DailyFeedlotGridData[]
  setDailyFeedlot: React.Dispatch<React.SetStateAction<DailyFeedlotGridData[]>>
  dailyFeedlotGridActions: DailyFeedlotGridActions
  setDailyFeedlotGridActions: React.Dispatch<React.SetStateAction<DailyFeedlotGridActions>>
}

export interface DailyFeedlotDataRef {
  submit(): void
}

export const FeedlotCarcassYieldQuery: FeedlotCarcassYieldQueryFields = {
  id: true,
  feedlotId: true,
  values: {
    carcassYield: true,
    day: true
  }
}
export interface CarcassYieldDataProps {
  carcassYields: FeedlotCarcassYieldGridData[]
  setCarcassYields: React.Dispatch<React.SetStateAction<FeedlotCarcassYieldGridData[]>>
  carcassYieldGridActions: FeedlotCarcassYieldGridActions
  setCarcassYieldGridActions: React.Dispatch<React.SetStateAction<FeedlotCarcassYieldGridActions>>
}
export interface CarcassYieldDataRef {
  submit(): void
}

export interface FeedlotCarcassYieldGridData {
  id: number
  feedlotId: number
  day: number
  carcassYield: number
  _addId: number
}

export interface FeedlotCarcassYieldGridActions {
  add?: number[]
  update?: number[]
  delete?: FeedlotCarcassYieldGridData[]
}
