/**
 * Format value
 */
import { StorageKeys } from 'storage/types'
import { getItemFromStorage } from 'storage'
import { Locales } from 'store/main/types'

const getLocale = () => {
  return getItemFromStorage(StorageKeys.Locale)
}

/**
 * @function
 * @param number Value to be formatted
 * @return number formatted
 */
export default (number: number | string) => {
  if (number === null || number === undefined) {
    return ''
  }

  const locale = getLocale()

  const rSign = locale === Locales.en ? '.' : ','
  const wSign = locale === Locales.en ? ',' : '.'
  let res = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, wSign)

  let dotIndex = res[res.length - 2] === wSign ? res.length - 2 : null
  dotIndex = res[res.length - 3] === wSign ? res.length - 3 : dotIndex

  if (dotIndex !== null) {
    res = res.substr(0, dotIndex) + rSign + res.substr(dotIndex + 1, res.length)
  }
  return res
}
