/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react'

import { Props } from './types'

import './styles.scss'

// apply the same method of the tabs on params

const Erros : FC<Props> = ({required, numeric, date, breed, gender, ECC}) => {
    return(
        <>
        <div className={'error'}> 
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div className={required == true ? 'error-type-button-required-active':'error-type-button-required'}/>
                </div> 
                <label className={required == true ? 'error-type-label-required-active':'error-type-label-required'}>
                    Campo Obrigatório
                </label>
            </div>
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div  className={numeric == true ? 'error-type-button-numeric-active':'error-type-button-numeric'}/>
                </div> 
                <label className={numeric == true ? 'error-type-label-numeric-active':'error-type-label-numeric'}>
                    Número Inválido
                </label>
            </div>
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div className={date == true ? 'error-type-button-date-active':'error-type-button-date'}/>
                </div> 
                <label className={date == true ? 'error-type-label-date-active':'error-type-label-date'}>
                    Data Inválida
                </label>
            </div>
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div className={breed == true ? 'error-type-button-breed-active':'error-type-button-breed'}/>
                </div> 
                <label className={breed == true ? 'error-type-label-breed-active':'error-type-label-breed'}>
                    Raça Inválida
                </label>
            </div>
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div className={gender == true ? 'error-type-button-gender-active':'error-type-button-gender'}/>
                </div> 
                <label className={gender == true ? 'error-type-label-gender-active':'error-type-label-gender'}>
                    Gênero/sexo Inválido
                </label>
            </div>
            <div className={'error-type'}>
                <div className={'error-type-button'}>
                    <div className={ECC == true ? 'error-type-button-ecc-active':'error-type-button-ecc'}/>
                </div>
                <label className={ECC == true ? 'error-type-label-ecc-active':'error-type-label-ecc'}>
                    ECC Inválido
                </label>
            </div>
        </div>
        </>
    )
}

export default Erros
