import { FeedlotConstants } from './types'

export const getSelectedFeedlot = (feedlotId?: number) => ({
  type: FeedlotConstants.ACTION_GET_SELECTED_FEEDLOT,
  payload: { feedlotId }
})

export const getFeedlotNextSales = (feedlotId?: number, saleType?: number) => ({
  type: FeedlotConstants.ACTION_GET_FEEDLOT_NEXT_SALES,
  payload: { feedlotId, saleType }
})

export const setSelectedSaleType = (saleType: number) => ({
  type: FeedlotConstants.REDUCER_SET_FEEDLOT_DATA,
  payload: { selectedSaleType: saleType }
})

export const setSelectedSale = (sale?: number) => ({
  type: FeedlotConstants.REDUCER_SET_FEEDLOT_DATA,
  payload: { selectedSale: sale }
})
