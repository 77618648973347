/**
 * Format value
 */
import { StorageKeys } from 'storage/types'
import { getItemFromStorage } from 'storage'
import { Locales } from 'store/main/types'
import moment from 'moment'

const getUnitySystem = () => {
  return getItemFromStorage(StorageKeys.Locale)
}

/**
 * @function
 * @param Date Value to be formatted
 * @return Date formatted
 */
export default (value: Date | string) => {
  if (value === null || value === undefined) {
    return ''
  }

  const unitySystem = getUnitySystem()

  const res =
    unitySystem === Locales.en
      ? moment(value).utc().format('MM/DD/YY')
      : moment(value).utc().format('DD/MM/YY')

  return res
}
