import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'

const Legend: React.FC<Props> = ({ average }) => {
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { t } = useTranslation()

  return (
    <div className={'legend'}>
      <div className={'border-legend'}>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.redDark) }}
          />
          <p className={'text-legend'}>{t('animal:real')}</p>
        </div>
        <div className={'group-legend'}>
          <div className={'color-legend'} style={{ backgroundColor: colors(theme, Colors.gray) }} />
          <p className={'text-legend'}>{t('animal:interpolated')}</p>
        </div>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.accent) }}
          />
          <p className={'text-legend'}>{t('animal:predicted')}</p>
        </div>
        {average ? (
          <div className={'group-legend'}>
            <div
              className={'color-legend'}
              style={{ backgroundColor: colors(theme, Colors.yellow) }}
            />
            <p className={'text-legend'}>{t('lot:lot_average')}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Legend
