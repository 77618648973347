import React from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core'

// Resources
import './styles.scss'
import UserIcon from 'resources/img/icons/beeftrader-user-icon'
// Types
import { Props } from './types'
import { withRouter } from 'react-router-dom'
import colors, { Colors } from 'styles/variables/colors'
import { ApplicationStore } from 'store/types'
import { connect } from 'react-redux'

const ProfileButton = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const id = open ? 'menu-settings' : undefined
  const { t } = useTranslation()
  return (
    <div>
      <IconButton onClick={handleMenu}>
        <UserIcon width={'1.5rem'} height={'1.5rem'} fill={colors(props.theme, Colors.white)} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          // subheader={
          //   <ListSubheader component='div' id='nested-list-subheader'>
          //     {t('settings')}
          //   </ListSubheader>
          // }
          className='setting-menu-root'
        >
          {props.items.map((item) => (
            <ListItem button key={item.title} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.title)} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

export default withRouter(connect(mapStoreToProps)(ProfileButton))
