import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Resources
import OxIcon from 'resources/img/icons/beeftrader-ox-icon'
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'

// Types
import { ApplicationStore } from 'store/types'
import { AlertModalTypes, InfoModal } from 'store/main/types'

// Methods
import { getFeedlotNextSales } from 'store/feedlot/actions'
import { setInfoModalMessage } from 'store/main/actions'

// Components
import HelpButton from 'components/shared/HelpButton'
import CardSalesButtons from 'components/shared/CardSalesButtons'
import TrafficLightContainer from '../../shared/TrafficLightContainer'

const LotPonCalendarCard: React.FC = () => {
  const { t } = useTranslation()
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const [sales, setSales] = useState<number>(1)
  const { scaledLotsAnimalsNumber, selectedFeedlot } = useSelector(
    (state: ApplicationStore) => state.feedlot
  )
  const { nextSalesArgs } = useSelector((state: ApplicationStore) => state.feedlot)
  const previusSelectedSale = useRef(1)
  const dispatch = useDispatch()

  useEffect(() => {
    const message =
      t('you_have') +
      ' ' +
      scaledLotsAnimalsNumber +
      ' ' +
      t('feedlot:number_of_scaled_animals') +
      ' ' +
      nextSalesArgs.scaledLotsMaxSaleTime +
      ' ' +
      t('days')

    const infoModal: InfoModal = {
      type: AlertModalTypes.warning,
      title: t('attention'),
      message,
      open: true,
      btnTxt: t('see_more'),
      btnLink: 'pon-calendar-details'
    }

    if (scaledLotsAnimalsNumber) {
      dispatch(setInfoModalMessage(infoModal))
    }
  }, [scaledLotsAnimalsNumber])

  useEffect(() => {
    if (sales !== previusSelectedSale.current)
      dispatch(getFeedlotNextSales(selectedFeedlot?.id, sales))
  }, [sales])

  return (
    <div className={'card-container'} style={{ marginTop: '40px' }}>
      <HelpButton infoText={'lot:lots_exit'} theme={theme} />
      <div className='card-icon-container'>
        <OxIcon width={'3.5rem'} height={'3.5rem'} fill={colors(theme, Colors.accent)} />
      </div>
      <div className='card-body'>
        <div className='card-title-container'>
          <p className={'card-title'}>{t('pon_calendar')}</p>
          <div className='card-sales-buttons-line'>
            <CardSalesButtons
              t={t}
              selectedSales={sales}
              handleSalesChange={(sale) => {
                previusSelectedSale.current = sales
                setSales(sale)
              }}
            />
          </div>
        </div>
        <div className={'card-content'}>
          <TrafficLightContainer redirect={true} />
        </div>
      </div>
    </div>
  )
}

export default LotPonCalendarCard
