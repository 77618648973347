import produce from 'immer'
import { AnyAction } from 'redux'
import { FeedlotSlaughterhouseStore, FeedlotSlaughterhouseConstants } from './types'

const INITIAL_STORE: FeedlotSlaughterhouseStore = {
  feedlotSlaughterhouses: undefined,
  feedlotSlaughterhouseLoading: false,
  feedlotConfigurationLoading: false,
  feedlotConfiguration: undefined,
  saving: false
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case FeedlotSlaughterhouseConstants.REDUCER_SET_FEEDLOT_SLAUGHTERHOUSE_DATA:
        Object.assign(draft, payload)
        break
      case FeedlotSlaughterhouseConstants.REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
