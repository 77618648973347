import { call, select, put, Effect } from 'redux-saga/effects'
import _ from 'lodash'

// Types
import { MainConstants } from 'store/main/types'

// Methods
import { workerCheckAuthentication } from 'store/auth/sagas'
import Exception from 'helpers/Exception'
import { AnimalManagementConstants } from './types'
import { PenQueryArgs, PenQueryFields } from 'api/pen/types'
import { createPen, getPensRequest, PenMutationFields } from 'api/pen'
import { FeedlotConstants, Lot } from 'store/feedlot/types'
import { LotMutationFields } from 'api/lot/types'
import { createLot } from 'api/lot'
import { AnimalMutationFields } from 'api/animal/types'
import { createAnimal, getBreedQuery, getGendersQuery } from 'api/animal'
import { FeedlotRequestParams } from 'api/feedlot/types'
import { getFeedlotsPens } from 'api/feedlot'
import moment from 'moment'

function* putAnimalsManagement(payload: any) {
  yield put({
    type: AnimalManagementConstants.REDUCER_SET_ANIMAL_MANAGEMENT,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerSetAnimalsManagement(action: Effect): any {
  yield call(putAnimalsManagement, { animalManagementLoading: true })
  try {
    const { animals, feedlotId } = action.payload
    if (animals.length == 0) return

    //Lots of CSV to register
    const lotsNameRegister = animals
      .map((item: any) => item.lotDescription)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    //Pens of CSV
    const pensNameRegister = animals
      .map((item: any) => item.penDescription)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    //Pens registered in database
    const penStore = yield call(getFeedlotsPens, { feedlotId: [feedlotId] })
    const penDatabase = penStore[0].pens
      .map((item: any) => item.code)
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)

    //Cheching pens is need register
    const pensRegister = pensNameRegister.filter((x: any) => !penDatabase.includes(x))

    //Get genders
    const gendersStore = yield call(getGendersQuery)
    //Get genders
    const breedStore = yield call(getBreedQuery)

    //Register Pens
    const listPens = penStore[0].pens
    for (const p of pensRegister) {
      const pen: PenMutationFields = {
        feedlotId: feedlotId,
        code: p,
        status: 1
      }
      const penId = yield call(createPen, pen)
      listPens.push({
        id: penId,
        code: p
      })
    }
    for (const l of lotsNameRegister) {
      const animalsLot = animals.filter((x: any) => x.lotDescription == l)
      const penLot = listPens.find((x: any) => x.code == animalsLot[0].penDescription)

      //Register lot
      const stringDate = animalsLot[0].entryDatePen.split('/')
      const lot: LotMutationFields = {
        feedlotId: feedlotId,
        description: animalsLot[0].lotDescription,
        initDate: stringDate[2] + '-' + stringDate[1] + '-' + stringDate[0] + 'T00:00:00-03:00',
        penId: penLot.id
      }
      const lotId = yield call(createLot, lot)

      //Identify additional fields
      const columns = Object.keys(animalsLot[0])
      const columnsAdditional = columns.filter((c) => c.includes('ADC.'))

      //Register animals
      const animalsRegister: AnimalMutationFields[] = []
      for (const a of animalsLot) {
        const genderId = gendersStore.find((x: any) => x.code == a.genderId)
        const breedId = breedStore.find((x: any) => x.code == a.breedId)
        const penId = listPens.find((x: any) => x.code == a.penDescription)

        //Formatting additional fields
        const additionalField = Object()
        for (const colAdd of columnsAdditional) {
          const nameColumn = colAdd.split('ADC.')
          if (nameColumn[1] != 'undefined') additionalField[nameColumn[1]] = a[colAdd]
        }

        animalsRegister.push({
          lotId: lotId,
          feedlotId: feedlotId,
          active: true,
          earring: a.earring,
          age: Number(a.age),
          sisbov: a.sisbov,
          additionalField: additionalField,
          initialWeight: Number(a.initialWeightPen),
          genderId: genderId.id,
          eccHandling: Number(a.ecc),
          coatId: 24,
          penId: penId.id,
          breedId: breedId.id,
          tag: a.tag
        })
      }
      let animalIds: number[] = []
      animalIds = yield call(createAnimal, animalsRegister)
    }

    yield call(putAnimalsManagement, { animalManagementLoading: false, messageRegister: 'Sucess' })
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      yield call(putAnimalsManagement, { animalManagementLoading: false, messageRegister: 'Error' })
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}
