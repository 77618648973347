import { StateQueryParams, CityQueryParams } from './types'
import { City, State } from 'store/address/types'
import store from 'store'
import Codes from 'helpers/Codes'
import QueryBuilder from 'helpers/QueryBuilder'
import API from 'api'
import gql from 'graphql-tag'
import Exception from 'helpers/Exception'
import { getItemFromStorage } from 'storage'
import { StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'

export const getStates = async (queryParams: StateQueryParams): Promise<State[]> => {
  if (!queryParams.fields)
    queryParams.fields = {
      id: true,
      description: true,
      code: true,
      cities: { id: true, description: true }
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      state(id: $id) ${QueryBuilder(queryParams.fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { ...queryParams.args }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/address/getState',
        errors
      )
    }
    return data.state
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/address/getState', error)
  }
}

export const getCities = async (queryParams: CityQueryParams): Promise<City[]> => {
  if (!queryParams.fields) queryParams.fields = { id: true, description: true }
  const QUERY = gql`
    query QUERY($id: Int, $description: String) {
      city(id: $id, description: $description) ${QueryBuilder(queryParams.fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { ...queryParams.args }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/address/getCity',
        errors
      )
    }
    return data.city
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) throw error
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/address/getCity', error)
  }
}
