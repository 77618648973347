import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'

// Components
import CustomBoxButton from '../CustomBoxButton'

// Resources
import './styles.scss'

const TrafficLightContainer: React.FC<Props> = ({ redirect }: Props) => {
  const { t } = useTranslation()
  const {
    scaledLots,
    nextSaleLots,
    initialLots,
    initialLotsAnimalsNumber,
    scaledLotsAnimalsNumber,
    nextSaleLotsAnimalsNumber
  } = useSelector((state: ApplicationStore) => state.feedlot)
  const { nextSalesArgs } = useSelector((state: ApplicationStore) => state.feedlot)

  let scaledMaxDate: any = new Date(
    new Date().setDate(new Date().getDate() + nextSalesArgs.scaledLotsMaxSaleTime)
  )
  scaledMaxDate = scaledMaxDate.getDate() + '/' + (Number(scaledMaxDate.getMonth()) + 1)
  let nextSaleMaxDate: any = new Date(
    new Date().setDate(new Date().getDate() + nextSalesArgs.nextLotsMaxSaleTime)
  )
  nextSaleMaxDate = nextSaleMaxDate.getDate() + '/' + (Number(nextSaleMaxDate.getMonth()) + 1)

  const scaledLotsButtonText =
    scaledLots?.length +
    ' ' +
    (scaledLots && scaledLots?.length === 1 ? t('lot:lot') : t('lot:lots')) +
    ' ' +
    t('until') +
    ' ' +
    scaledMaxDate
  const nextSaleLotsButtonText =
    nextSaleLots?.length +
    ' ' +
    (nextSaleLots && nextSaleLots?.length === 1 ? t('lot:lot') : t('lot:lots')) +
    ' ' +
    t('until') +
    ' ' +
    nextSaleMaxDate

  const initialLotsButtonText =
    initialLots?.length +
    ' ' +
    t('lot:lots') +
    ' ' +
    t('with') +
    ' ' +
    t('less') +
    ' ' +
    t('of') +
    ' ' +
    nextSalesArgs.maxFeedlotTime +
    ' ' +
    t('days')

  return (
    <div className='card-traffic-light-container'>
      <div className='traffic-red-light'>
        <CustomBoxButton
          redirect={redirect || false}
          color={'red'}
          data={scaledLots}
          dataNumber={scaledLotsAnimalsNumber}
          buttonText={scaledLotsButtonText}
        />
      </div>
      <div className='traffic-yellow-light'>
        <CustomBoxButton
          redirect={redirect || false}
          color={'yellow'}
          data={nextSaleLots}
          dataNumber={nextSaleLotsAnimalsNumber}
          buttonText={nextSaleLotsButtonText}
        />
      </div>
      <div className='traffic-green-light'>
        <CustomBoxButton
          redirect={redirect || false}
          color={'green'}
          data={initialLots}
          dataNumber={initialLotsAnimalsNumber}
          buttonText={initialLotsButtonText}
        />
      </div>
    </div>
  )
}

export default TrafficLightContainer
