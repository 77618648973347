import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from './types'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const SelectButton: React.FC<Props> = ({
  label,
  values,
  selected,
  onChange,
  text,
  disabled
}: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState<number | string>(selected ?? 'none')

  useEffect(() => {
    setValue(selected ?? 'none')
  }, [selected])

  const handleChange = (event: any) => {
    setValue(event?.target?.value)
    onChange ? onChange(event?.target?.value) : null
  }
  return (
    <FormControl className={'select'} variant='standard' style={{ margin: '1%' }}>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled ?? false}
      >
        <MenuItem key={0} value='none'>
          <em>{t('none')}</em>
        </MenuItem>
        {values?.map((array, index) => (
          <MenuItem key={index} value={array?.id}>
            {array[text]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectButton
