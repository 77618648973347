import { Themes } from 'store/main/types'
import colors, { Colors } from 'styles/variables/colors'
import React from 'react'
import i18next from 'i18next'
import Format from 'helpers/Format'

export const getGradientFill = (
  theme: Themes,
  index: number,
  dataKey: string
): string | undefined => {
  if ((dataKey === 'accumulated_profit' || dataKey === 'average_dwg') && index === 1)
    return colors(theme, Colors.accent)
  if ((dataKey === 'accumulated_profit' || dataKey === 'average_dwg') && index === 2)
    return colors(theme, Colors.accent70)
  if ((dataKey === 'accumulated_profit' || dataKey === 'average_dwg') && index === 3)
    return colors(theme, Colors.accent35)
}

export const CustomTooltip: any = ({ active, payload, xFieldKey, yFieldKey }: any): any => {
  if (active && payload && payload.length) {
    return (
      <div className='tooltip-container'>
        <p>{payload[1].payload.result.earring}</p>
        <p>{`
        ${i18next.t(xFieldKey === 'dwg' ? xFieldKey.toUpperCase() : xFieldKey)} ${Format(
          payload[1].payload.result[xFieldKey]
        )} ${xFieldKey === 'dwg' || 'weight' ? 'kg:' : ':'} R$: ${Format(
          payload[1].payload.result[yFieldKey]
        )}`}</p>
      </div>
    )
  }
  return null
}

export const renderLegend: any = (
  props: any,
  firstSaleData: any[],
  secondSaleData: any[],
  thirdSaleData: any[]
): any => {
  const { payload } = props
  return (
    <div className={'custom-legend'}>
      {payload.map((entry: any, index: any) => {
        if (index === 0)
          return firstSaleData.length ? (
            <p className={'firstSale'}>
              <span key={`item-${index}`}>{entry.value}</span>
              <span className='small-txt'>{firstSaleData.length + ' ' + i18next.t('animals')}</span>
            </p>
          ) : null
        else if (index === 1)
          return secondSaleData.length ? (
            <p className={'secondSale'}>
              <span key={`item-${index}`}>{entry.value}</span>
              <span className='small-txt'>
                {secondSaleData.length + ' ' + i18next.t('animals')}
              </span>
            </p>
          ) : null
        else if (index === 2)
          return thirdSaleData.length ? (
            <p className={'thirdSale'}>
              <span key={`item-${index}`}>{entry.value}</span>
              <span className='small-txt'>{thirdSaleData.length + ' ' + i18next.t('animals')}</span>
            </p>
          ) : null
        else
          return (
            <p className={'legend-item'}>
              <span key={`item-${index}`}>{entry.value}</span>
            </p>
          )
      })}
    </div>
  )
}
