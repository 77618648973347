import React from 'react'

// Types
import { Props } from './types'

// Resources
import './styles.scss'

// Components
import ReactLoading from 'react-loading'

const Loading: React.FC<Props> = ({ size }: Props) => {
  return (
    <div className='loading'>
      <ReactLoading color='#ff4400' type='spin' width={size || 30} height={size || 30} />
    </div>
  )
}
export default Loading
