import { EconomicConstants, EconomicStore } from './types'

// Creates a route to get the values from BD
const slaughterhousePriceGenders = [
  {
    id: 2,
    description: 'Macho Inteiro',
    code: 'MI'
  },
  {
    id: 1,
    description: 'Macho Castrado',
    code: 'MC'
  },
  {
    id: 4,
    description: 'Novilha',
    code: 'NV'
  },
  {
    id: 3,
    description: 'Vaca',
    code: 'VC'
  }
]

export const INITIAL_STATE: EconomicStore = {
  feedlotSlaughterhousePriceLoading: false,
  quoteSlaughterhousePriceLoading: false,
  currencyLoading: false,
  weightUnityLoading: false,
  slaughterhousePriceGenders: slaughterhousePriceGenders,
  feedlotSlaughterhousePrice: [],
  quoteSlaughterhousePrice: [],
  currencies: [],
  weightUnits: []
}

export default (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  switch (action.type) {
    case EconomicConstants.REDUCER_CLEAR_ECONOMIC_DATA:
      return { ...INITIAL_STATE, checkingAuthentication: false }
    case EconomicConstants.REDUCER_SET_ECONOMIC_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
