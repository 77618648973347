import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// Types
import { Props } from './types'

// Components
import { Box, Icon, Button, Tooltip } from '@material-ui/core'

const CustomBoxButton: React.FC<Props> = ({
  redirect,
  color,
  data,
  dataNumber,
  buttonText
}: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const redirectToDetails = () => {
    history.push('pon-calendar-details')
  }

  return (
    <div className='card-traffic-light-container'>
      <div className='traffic-red-light1'>
        <Box>
          <Icon className='material-icons-two-tone' style={{ color: color, fontSize: '3rem' }}>
            circle
          </Icon>
        </Box>
        <Tooltip title={t('more_information') ?? ''}>
          <Button
            variant='outlined'
            onClick={
              redirect
                ? redirectToDetails
                : () => {
                    return
                  }
            }
          >
            <span>{buttonText}</span>
            <span>
              {data?.length
                ? dataNumber === 1
                  ? dataNumber + ' ' + t('animal:animal')
                  : dataNumber + ' ' + t('animal:animals')
                : ' '}
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default CustomBoxButton
