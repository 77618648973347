import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import { Animal, AnimalAdditionalInfoTemplate, AnimalWeighing } from 'store/animal/types'
import {
  AnimalQueryFields,
  AnimalQueryArgs,
  AnimalEconomicDailyDataQueryArgs,
  AnimalZootechnicalDailyDataFields,
  AnimalEconomicDailyDataFields,
  AnimalZootechnicalDailyDataQueryArgs,
  AnimalEnvironmentalDailyDataFields,
  AnimalEnvironmentalDailyDataQueryArgs,
  AdditionalInfoTemplateQueryParams,
  Gender,
  AnimalMutationFields,
  WeighingQueryFields,
  WeighingQueryArgs
} from './types'
import QueryBuilder from 'helpers/QueryBuilder'
import { validateAuthentication } from 'api/auth'
import { getItemFromStorage } from 'storage'
import { StorageKeys } from 'storage/types'

export async function getAnimals(
  queryFields: AnimalQueryFields,
  queryArgs: AnimalQueryArgs,
  queryEconomicDailyDataArgs?: AnimalEconomicDailyDataQueryArgs | undefined,
  queryEconomicDailyData?: AnimalEconomicDailyDataFields | undefined,
  queryZootechnicalDailyDataArgs?: AnimalZootechnicalDailyDataQueryArgs | undefined,
  queryZootechnicalDailyData?: AnimalZootechnicalDailyDataFields | undefined,
  queryEnvironmentalDailyDataArgs?: AnimalEnvironmentalDailyDataQueryArgs | undefined,
  queryEnvironmentalDailyData?: AnimalEnvironmentalDailyDataFields | undefined
) {
  let QUERY
  if (queryEconomicDailyDataArgs) {
    QUERY = gql`query QUERY(
      $parameters: AnimalsParameters!,
      $pagination: Pagination,
      $slaughterhouseId:Float!, 
      $startTime:Float!,
      $endTime:Float!,
      $interval:Float) {
      animal(parameters: $parameters, pagination:$pagination) {
        ${QueryBuilder(queryFields).slice(1, -2)},
        economicDailyData(
          slaughterhouseId: $slaughterhouseId,
          startTime:$startTime,
          endTime:$endTime,
          interval:$interval)
        ${QueryBuilder(queryEconomicDailyData)}
      }
    }`
  } else if (queryZootechnicalDailyDataArgs) {
    QUERY = gql`query QUERY(
      $parameters: AnimalsParameters!,
      $pagination: Pagination,
      $startTime:Float!,
      $endTime:Float!,
      $interval:Float) {
      animal(parameters: $parameters, pagination:$pagination) {
        ${QueryBuilder(queryFields).slice(1, -2)},
        zootechnicalDailyData(
          startTime:$startTime,
          endTime:$endTime,
          interval:$interval)
        ${QueryBuilder(queryZootechnicalDailyData)}
      }
    }`
  } else if (queryEnvironmentalDailyDataArgs) {
    QUERY = gql`query QUERY(
      $parameters: AnimalsParameters!,
      $pagination: Pagination,
      $startTime:Float!,
      $endTime:Float!,
      $interval:Float) {
      animal(parameters: $parameters, pagination:$pagination) {
        ${QueryBuilder(queryFields).slice(1, -2)},
        environmentalDailyData(
          startTime:$startTime,
          endTime:$endTime,
          interval:$interval)
        ${QueryBuilder(queryEnvironmentalDailyData)}
      }
    }`
  } else {
    QUERY = gql`
    query QUERY(
      $parameters: AnimalsParameters!,
      $pagination: Pagination
      ) {
      animal(parameters:$parameters, pagination:$pagination) ${QueryBuilder(queryFields)}
    }`
  }
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })

    const auxParams = queryEconomicDailyDataArgs
      ? {
          parameters: queryArgs.parameters,
          pagination: queryArgs.pagination,
          slaughterhouseId: queryEconomicDailyDataArgs.slaughterhouseId,
          startTime: queryEconomicDailyDataArgs.startTime,
          endTime: queryEconomicDailyDataArgs.endTime,
          interval: queryEconomicDailyDataArgs.interval
        }
      : queryZootechnicalDailyDataArgs
      ? {
          parameters: queryArgs.parameters,
          pagination: queryArgs.pagination,
          startTime: queryZootechnicalDailyDataArgs.startTime,
          endTime: queryZootechnicalDailyDataArgs.endTime,
          interval: queryZootechnicalDailyDataArgs.interval
        }
      : queryEnvironmentalDailyDataArgs
      ? {
          parameters: queryArgs.parameters,
          pagination: queryArgs.pagination,
          startTime: queryEnvironmentalDailyDataArgs.startTime,
          endTime: queryEnvironmentalDailyDataArgs.endTime,
          interval: queryEnvironmentalDailyDataArgs.interval
        }
      : {
          parameters: queryArgs.parameters,
          pagination: queryArgs.pagination
        }
    const response = await API.query(QUERY, { ...auxParams }, credentials.token)

    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/animal/getAnimals',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getAnimal', errors)
    }

    return data.animal as Animal[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getAnimalsRequest', error)
  }
}

export const additionalInfoTemplateQuery = async (
  params: AdditionalInfoTemplateQueryParams
): Promise<AnimalAdditionalInfoTemplate[]> => {
  if (!params.fields)
    params.fields = {
      clientId: true,
      id: true,
      type: true,
      value: true,
      active: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: [Float!], $clientId: Int) {
      additionalInfoTemplate(id: $id, clientId: $clientId) ${QueryBuilder(params.fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { ...params.args }, credentials.token)

    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/additionalInfoTemplate/additionalInfoTemplateQuery',
        errors
      )
    }
    return data.additionalInfoTemplate
  } catch (error: any) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/additionalInfoTemplate/additionalInfoTemplateQuery',
      error
    )
  }
}

export const getGendersQuery = async (fields?: Gender): Promise<Gender[]> => {
  if (!fields)
    fields = {
      id: true,
      code: true,
      description: true
    }
  const QUERY = gql`
    query QUERY {
      gender ${QueryBuilder(fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, {}, credentials.token)

    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/animal/genderQuery',
        errors
      )
    }
    return data.gender
  } catch (error: any) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/genderQuery', error)
  }
}

export const getBreedQuery = async (fields?: Gender): Promise<Gender[]> => {
  if (!fields)
    fields = {
      id: true,
      code: true,
      description: true
    }
  const QUERY = gql`
    query QUERY {
      breed ${QueryBuilder(fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, {}, credentials.token)

    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/animal/breedQuery',
        errors
      )
    }
    return data.breed
  } catch (error: any) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }

    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/breedQuery', error)
  }
}

export const getWeighingsQuery = async (
  queryFields: WeighingQueryFields,
  queryArgs: WeighingQueryArgs
): Promise<AnimalWeighing[]> => {
  if (!queryFields)
    queryFields = {
      id: true,
      animalId: true,
      earring: true,
      weight: true,
      day: true,
      type: true
    }
  const QUERY = gql`
    query QUERY($parameters: AnimalsParameters!) {
      weighing(parameters: $parameters) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const response = await API.query(QUERY, queryArgs, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      const { statusCode } = errors[0].message
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/animal/weighingQuery',
        errors
      )
    }

    return data.weighing
  } catch (error: any) {
    console.log(error)

    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/weighingQuery', error)
  }
}

export const createAnimal = async (animal: AnimalMutationFields[]): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($animal: [CreateAnimalDto!]!) {
        createAnimal(animal: $animal)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { animal: animal }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/animal/createAnimal',
        errors
      )
    }
    return data.createAnimal as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/createAnimal', error)
  }
}
