import React, { Fragment } from 'react'

import colors, { Colors } from 'styles/variables/colors'
import { Themes } from 'store/main/types'
import Format from 'helpers/Format'

const titleWidth = 50
const minChartLeft = 30

export const getMethodColor = (theme: Themes, index: number) => {
  if (index === 0) {
    return colors(theme, Colors.accent)
  }
  if (index === 1) {
    return colors(theme, Colors.redDark)
  }
  return colors(theme, Colors.gray)
}

const getTickText = (t: any, index: number) => {
  if (index === 0) return t('beeftrader')
  if (index === 1) return t('current')
  return t('traditional')
}

export const renderCustomBarLabel = (
  t: any,
  theme: Themes,
  { x, y, width, height, value, index }: any
) => {
  const negativeExists = true
  const positiveNumberDiff = negativeExists ? x - minChartLeft - titleWidth : 0

  const xChart =
    value < 0 ? x + width - titleWidth : width + titleWidth + minChartLeft + positiveNumberDiff + 20
  return (
    <Fragment>
      <text className={'chart-tick-text'} x={50} y={y + 20}>
        {getTickText(t, index)}
      </text>
      <text
        className={'chart-value-text'}
        x={xChart}
        y={height / 2 + y + 5}
        fill={getMethodColor(theme, index)}
        textAnchor='top'
        dy={0}
      >
        {Format(value)}
      </text>
    </Fragment>
  )
}

export const renderCustomAxisTick = (t: any, theme: Themes, { y, payload }: any) => {
  return (
    <text className={'chart-tick-text'} x={30} y={y + 5}>
      {getTickText(t, payload.index)}
    </text>
  )
}

export const getGradientFill = (theme: Themes, index: number) => {
  if (index === 0) return colors(theme, Colors.accent)
  if (index === 1) return colors(theme, Colors.redDark)
  return colors(theme, Colors.gray)
}
