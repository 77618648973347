import React from 'react'
import { Props } from './types'

const UserIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='user-line'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 26 30'
    >
      <path
        id='Caminho_39'
        data-name='Caminho 39'
        d='M18,17a7,7,0,1,0-7-7,7,7,0,0,0,7,7ZM18,5a5,5,0,1,1-5,5A5,5,0,0,1,18,5Z'
        transform='translate(-5 -3)'
        fill={fill}
      />
      <path
        id='Caminho_40'
        data-name='Caminho 40'
        d='M30.47,24.37a17.16,17.16,0,0,0-24.93,0A2,2,0,0,0,5,25.74V31a2,2,0,0,0,2,2H29a2,2,0,0,0,2-2V25.74a2,2,0,0,0-.53-1.37ZM29,31H7V25.73a15.17,15.17,0,0,1,22,0Z'
        transform='translate(-5 -3)'
        fill={fill}
      />
    </svg>
  )
}

export default UserIcon
