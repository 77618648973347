/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react'
import CloseIcon from '@material-ui/icons/Close'

import ModalProps from './types'

import './styles.scss'

const Modal: React.FC<ModalProps> = ({ title, isOpen, onClose, children }) => {
  const overlayRef = useRef(null)
  const handleOverlayClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e.target === overlayRef.current) {
      onClose()
    }
  }

  return isOpen ? (
    <div className={'modal-body'}>
      <div className={'modal-body-overlay'} ref={overlayRef} onClick={handleOverlayClick} />
      <div className={'modal-body-box'}>
        <div className={'modal-body-box-close-btn'}>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={'modal-body-box-title'}>{title}</div>
        <div className={'modal-body-box-content'}>{children}</div>
      </div>
    </div>
  ) : null
}

export default Modal
