import { CityQueryParams, StateQueryParams } from 'api/address/types'
import { AddressConstants } from './types'

export const getCities = (params?: CityQueryParams) => ({
  type: AddressConstants.ACTION_GET_CITY,
  payload: { params }
})
export const getStates = (params?: StateQueryParams) => ({
  type: AddressConstants.ACTION_GET_STATE,
  payload: { params }
})
