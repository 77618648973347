import { all, takeLatest } from 'redux-saga/effects'

import {
  workerSignOut,
  workerCheckAuthentication,
  workerSignIn,
  workerReplacePassword,
  workerRequestChangePwdEmail,
  workerCheckChangePwdToken
} from './sagas'

import { AuthConstants } from './types'

export function* watcherCheckAuthentication() {
  yield takeLatest(AuthConstants.ACTION_CHECK_AUTHENTICATION, workerCheckAuthentication)
}

export function* watcherSignIn() {
  yield takeLatest(AuthConstants.ACTION_SIGN_IN, workerSignIn)
}

export function* watcherChangePassword() {
  yield takeLatest(AuthConstants.ACTION_CHANGE_PWD, workerReplacePassword)
}

export function* watcherRequestChangePwdEmail() {
  yield takeLatest(AuthConstants.ACTION_REQUEST_CHANGE_PWD_EMAIL, workerRequestChangePwdEmail)
}

export function* watcherSignOut() {
  yield takeLatest(AuthConstants.ACTION_SIGN_OUT, workerSignOut)
}

export function* watcherCheckChangePwdToken() {
  yield takeLatest(AuthConstants.ACTION_CHECK_CHANGE_PWD_TOKEN, workerCheckChangePwdToken)
}

export default function* authWatchers() {
  yield all([
    watcherCheckAuthentication(),
    watcherSignIn(),
    watcherChangePassword(),
    watcherRequestChangePwdEmail(),
    watcherSignOut(),
    watcherCheckChangePwdToken()
  ])
}
