import { all, takeLatest } from 'redux-saga/effects'

// Types
import { LotConstants } from './types'

// Methods
import { workerGetLotAccessIndex, workerGetSelectedLots } from './sagas'

export function* watcherGetSelectedLots() {
  yield takeLatest(LotConstants.ACTION_GET_SELECTED_LOT, workerGetSelectedLots)
}

export function* watcherGetLotAccessIndex() {
  yield takeLatest(LotConstants.ACTION_GET_LOT_ACCESS_INDEX, workerGetLotAccessIndex)
}

export default function* lotWatchers() {
  yield all([watcherGetSelectedLots(), watcherGetLotAccessIndex()])
}
