import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

// Types
import {
  NutritionDataRef,
  NutritionDataProps,
  feedlotNutritionQuery,
  FeedlotNutritionGridData
} from './types'
import { FeedlotNutrition } from 'api/params/types'
import { ApplicationStore } from 'store/types'

// Methods
import {
  getFeedlotNutritions,
  createFeedlotNutritions,
  updateFeedlotNutritions,
  deleteFeedlotNutritions
} from 'api/params'

const NutritionData: React.RefForwardingComponent<NutritionDataRef, NutritionDataProps> = (
  { nutritions, setNutritions, nutritionGridActions, setNutritionGridActions }: NutritionDataProps,
  ref
) => {
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)

  function setNutritionGridDataNewIds(newNutritionIds: number[]) {
    const currentNutritionsGridData = [...nutritions.filter((n) => n.id !== 0)]
    const newNutritionsGridData = [...nutritions.filter((n) => n.id === 0)]
    const newNutritionsWithIds = newNutritionsGridData.map((n, index) => {
      return { ...n, id: newNutritionIds[index] }
    })

    setNutritions([...currentNutritionsGridData, ...newNutritionsWithIds])
  }

  async function submit() {
    function convertGridDataToFeedlotNutritions(
      nutritionsGridData: FeedlotNutritionGridData[] | undefined
    ): FeedlotNutrition[] | undefined {
      return (
        nutritionsGridData?.map((n) => {
          return {
            id: n.id,
            feedlotId: n.feedlotId,
            category: n.category,
            start: n.start,
            end: n.end,
            tdn: n.tdn,
            dryMatter: n.dryMatter,
            dietCost: n.dietCost
          }
        }) ?? []
      )
    }

    try {
      const newNutritionsGridData = [...nutritions.filter((n) => n.id === 0)]
      const updateNutritionsGridData = [
        ...nutritions.filter((n) => nutritionGridActions.update?.some((nga) => n.id === nga))
      ]

      const newNutritions = convertGridDataToFeedlotNutritions(newNutritionsGridData)
      const updateNutritions = convertGridDataToFeedlotNutritions(updateNutritionsGridData)
      const deleteNutritions = convertGridDataToFeedlotNutritions(nutritionGridActions.delete)

      if (newNutritions !== undefined && newNutritions?.length > 0) {
        const newNutritionIds = await createFeedlotNutritions(newNutritions)
        setNutritionGridDataNewIds(newNutritionIds)
      }
      if (updateNutritions !== undefined && updateNutritions?.length > 0) {
        await updateFeedlotNutritions(updateNutritions)
      }
      if (deleteNutritions !== undefined && deleteNutritions?.length > 0) {
        await deleteFeedlotNutritions(deleteNutritions)
      }
      setNutritionGridActions({})
    } catch (error: any) {
      throw error
    }
  }

  useImperativeHandle(ref, () => ({
    submit
  }))

  useEffect(() => {
    async function getNutritions() {
      const response: FeedlotNutrition[] = await getFeedlotNutritions(
        feedlotNutritionQuery,
        selectedFeedlot?.id || 0
      )
      setNutritions(response as FeedlotNutritionGridData[])
    }

    selectedFeedlot ? getNutritions() : setNutritions([])
    setNutritionGridActions({})
  }, [selectedFeedlot])

  return <></>
}

export default forwardRef(NutritionData)
