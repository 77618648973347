import moment from 'moment'
import { ColumnsCollapse, Columns } from 'store/main/types'

export const setSaleDate = (initialDate: string | undefined, amountDays: number | undefined) => {
  if (initialDate === undefined || amountDays === undefined) return ''
  return moment(initialDate).add(amountDays, 'days').toDate()
}

export const handleColumnsSelected = (
  data: ColumnsCollapse,
  selected: string[]
): ColumnsCollapse => {
  const newColumnsConfig: Columns[] = []
  data.columns.map((c) => {
    if (selected.find((d) => d === c.id)) {
      newColumnsConfig.push({
        order: c.order,
        id: c.id,
        disablePadding: c.disablePadding,
        label: c.label,
        type: c.type,
        active: true
      })
    } else
      newColumnsConfig.push({
        order: c.order,
        id: c.id,
        disablePadding: c.disablePadding,
        label: c.label,
        type: c.type,
        active: false
      })
  })
  return {
    id: data.id,
    order: data.order,
    collapse: data.collapse,
    label: data.label,
    columns: newColumnsConfig
  }
}
