import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

// Resources
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'

// Components
import ProfitCard from 'components/cards/ProfitCard'
import ZootechnicalPerformanceCard from 'components/cards/ZootechnicalPerformanceCard'

// Methods
import { getSelectedFeedlot } from 'store/feedlot/actions'
import PonCalendarCard from 'components/cards/PonCalendarCard'

const Home = () => {
  return (
    <div className={'home-body'}>
      <div className='home-row'>
        <ProfitCard />
        <PonCalendarCard />
      </div>
      <div className='home-row'>
        <ZootechnicalPerformanceCard />
      </div>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  selectedFeedlot: store.feedlot.selectedFeedlot
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSelectedFeedlot
    },
    dispatch
  )

export default connect(mapStoreToProps, mapDispatchToProps)(Home)
