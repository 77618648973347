import { RouteComponentProps } from 'react-router-dom'
import { Themes } from 'store/main/types'
import { UserNotificationCustomData, UserNotificationType } from 'api/user/notification/types'
import { TFunction } from 'i18next'

export interface PopMenuItem {
  id: string
  viewed: boolean
  date: string
  customData: UserNotificationCustomData
  icon?: any
  click?: () => any
}

export const popMenuItemTranslation = (
  t: TFunction,
  item: PopMenuItem,
  property: 'title' | 'text'
): string => {
  const result = { title: '', text: '' }

  switch (item.customData.__typename) {
    case UserNotificationType.AnimalsWithDelayedWeighing:
      result.title = t('animal:animals_without_weighing')
      result.text = t('animal:animals_without_weighing_message').replace(
        '{0}',
        String(item.customData.animalsCount)
      )
      break
    case UserNotificationType.AnimalsNotProcessed:
      result.title = t('animal:animals_with_no_processing')
      result.text = t('animal:animals_with_no_processing_message').replace(
        '{0}',
        String(item.customData.animalsCount)
      )
      break
  }
  return result[property]
}

type StoreProps = {
  theme: Themes
}

export type Props = StoreProps & RouteComponentProps
