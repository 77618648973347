import React, { useState, useImperativeHandle, forwardRef } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { useTranslation } from 'react-i18next'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

export interface Props {
  type: 'success' | 'error' | 'info' | 'warning'
  customMessage?: string
}

export interface AlertHandles {
  openAlert(): void
}

function CustomAlert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const Alert: React.RefForwardingComponent<AlertHandles, Props> = (props: Props, ref) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  function getMessage(
    type: 'success' | 'error' | 'info' | 'warning',
    customMessage?: string
  ): string {
    switch (type) {
      case 'success':
        if (customMessage) return t(customMessage)
        else return t('saved_successfully')
      case 'error':
        if (customMessage) return t(customMessage)
        else return t('unexpected_error')
      case 'warning':
        if (customMessage) return t(customMessage)
        else return t('incorrect_data')
      default:
        if (customMessage) return t(customMessage)
        else return ''
    }
  }

  function openAlert() {
    setOpen(true)
  }

  useImperativeHandle(ref, () => ({
    openAlert
  }))

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      onClose={() => setOpen(false)}
    >
      <CustomAlert severity={props.type} onClose={() => setOpen(false)}>
        {getMessage(props.type, props.customMessage)}
      </CustomAlert>
    </Snackbar>
  )
}

export default forwardRef(Alert)
