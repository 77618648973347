import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

// Resources
import './styles.scss'

// Components
import { Tabs, Tab, CircularProgress } from '@material-ui/core'
import { TabPanel, a11yProps } from '../helpers'
import WarningIcon from '@material-ui/icons/Warning'
import FeedlotSlaughterhouseForm from '../FeedlotSlaughterhouse'
import RegionPriceSlaughterhouse from '../RegionPriceSlaughterhouse'

const Slaughterhouses: React.FC = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = React.useState(0)

  // Feedlot consts
  const [feedlotFormErrorsMessages, setFeedlotFormErrorsMessages] = React.useState<any>({})
  const [lotFormErrorsMessages, setLotFormErrorsMessages] = React.useState<any>({})

  // #region UI handles

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const hasParamsErrors = (tab: 'feedlot' | 'lot') => {
    switch (tab) {
      case 'feedlot':
        return Object.keys(feedlotFormErrorsMessages).length > 0 ? (
          <WarningIcon color='error' />
        ) : (
          <></>
        )

      case 'lot':
        return Object.keys(lotFormErrorsMessages).length > 0 ? <WarningIcon color='error' /> : <></>
    }
  }

  return (
    <div className='slaughterhouse'>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        classes={{
          root: 'slaughterhouse-tab',
          indicator: 'slaughterhouse-tab-indicator'
        }}
      >
        <Tab label={'Meus preços de venda'} icon={hasParamsErrors('lot')} {...a11yProps(0)} />
        <Tab label={'Preços Balcão (Spot)'} icon={hasParamsErrors('feedlot')} {...a11yProps(1)} />
      </Tabs>
      <div className='slaughterhouse-row'>
        <div className='slaughterhouse-header'></div>
      </div>

      <TabPanel value={currentTab} index={0}>
        <FeedlotSlaughterhouseForm />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <RegionPriceSlaughterhouse />
      </TabPanel>
    </div>
  )
}

export default Slaughterhouses
