import React from 'react'
import { Route, Redirect } from 'react-router'

import MyAccount from 'pages/MyAccount'
import Home from 'pages/Home'
import Lots from 'pages/Lots'
import Settings from 'pages/Settings'
import Weighing from 'pages/Weighing'
import Animals from 'pages/Animals'
import Charts from 'pages/Charts'
import LotsSummary from 'pages/LotsSummary'
import PonCalendarDetails from 'pages/PonCalendarDetails'

export default [
  <Route exact path='/' key={'/'} component={Home} />,
  <Route exact path='/my-account' key={'/my-account'} component={MyAccount} />,
  <Route exact path='/feedlot=:feedlotId&sales=:sales' key={'/feedlotId&sales'} component={Home} />,
  <Route exact path='/animals' key={'/animals'} component={Animals} />,
  <Route exact path='/charts' key={'/charts'} component={Charts} />,
  <Route exact path='/lots-summary' key={'/lots-summary'} component={LotsSummary} />,
  <Route exact path='/lots' key={'/lots'} component={Lots} />,
  <Route exact path='/weighing' key={'/weighing'} component={Weighing} />,
  <Route exact path='/settings' key={'/settings'} component={Settings} />,
  <Route
    exact
    path='/pon-calendar-details'
    key={'/pon-calendar-details'}
    component={PonCalendarDetails}
  />,
  <Route path='*' key={'*'}>
    <Redirect to={'/not-found'} />
  </Route>
]
