import { AnimalQueryParams, Gender } from 'api/animal/types'
import { AnimalConstants } from './types'

export const getAnimals = (feedlotId: number, lotId: number, animalId?: number) => ({
  type: AnimalConstants.ACTION_GET_ANIMALS,
  payload: { feedlotId, lotId, animalId }
})

export const getAnimalGeneralFunction = (payload?: AnimalQueryParams) => ({
  type: AnimalConstants.ACTION_GET_ANIMAL_GENERAL_FUNCTION,
  payload
})

export const getEconomicDailyAnimals = (feedlotId: number, lotId: number) => ({
  type: AnimalConstants.ACTION_GET_ECONOMIC_DAILY_ANIMALS,
  payload: { feedlotId, lotId }
})

export const getZootechnicalDailyDataAnimals = (feedlotId: number, lotId: number) => ({
  type: AnimalConstants.ACTION_GET_ZOOTECHNICAL_DAILY_DATA_ANIMALS,
  payload: { feedlotId, lotId }
})

export const getEnvironmentalDailyDataAnimals = (feedlotId: number, lotId: number) => ({
  type: AnimalConstants.ACTION_GET_ENVIRONMENTAL_DAILY_DATA_ANIMALS,
  payload: { feedlotId, lotId }
})

export const getWeighingAnimals = (feedlotId: number, lotId: number, pagination: boolean) => ({
  type: AnimalConstants.ACTION_GET_WEIGHING_ANIMALS,
  payload: { feedlotId, lotId, pagination }
})

export const getWeighing = (feedlotId: number, lotId: number) => ({
  type: AnimalConstants.ACTION_GET_WEIGHING,
  payload: { feedlotId, lotId }
})

export const getGenders = (fields?: Gender) => ({
  type: AnimalConstants.ACTION_GET_GENDERS,
  payload: { fields }
})
