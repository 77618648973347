import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// Resources
import DollarIcon from 'resources/img/icons/beeftrader-dollar-icon'
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
// Components
import HelpButton from 'components/shared/HelpButton'
import Format from 'helpers/Format'
import CardSalesButtons from '../../shared/CardSalesButtons'
//'   /shared/CardSalesButtons/CardSalesButtons'
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts'
// Types
import { ApplicationStore } from 'store/types'
//Functions
import { getGradientFill, renderCustomBarLabel } from './helpers'
import { Button } from '@material-ui/core'
import { setDataType } from 'store/main/actions'
import { DataType } from 'store/main/types'

const ProfitCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const [sales, setSales] = useState<number>(1)

  function navigateToLotsPage() {
    if (selectedFeedlot) {
      dispatch(setDataType(DataType.ECONOMIC))
      history.push('/lots-summary')
    }
  }

  let chart = (
    <div className={'empty-card-body'}>
      <text>{t('no_data')}</text>
    </div>
  )
  let footerText = ''

  if (selectedFeedlot?.profit) {
    const { profit } = selectedFeedlot

    let salesParam = sales - 1
    if (salesParam > 3 || salesParam < 0) {
      salesParam = 1
    }

    const data = [
      { name: 'profit', value: Math.floor(profit.beeftrader[salesParam].profit || 0) },
      { name: 'profit', value: Math.floor(profit.current || 0) },
      { name: 'profit', value: Math.floor(profit.traditional || 0) }
    ]

    // Futuramente parametro do confinamento
    const currency = 'R$'

    const minChartLeft = 30
    const diff =
      (Number(profit.beeftrader[salesParam].profit) || 0) - (Number(profit.traditional) || 0)
    footerText = `${currency} ${Format(Math.floor(diff))} ${t('profit:compared_to_traditional')}`

    const beeftrader = Math.floor(Number(profit.beeftrader[salesParam].profit || 0))
    const current = Math.floor(Number(profit.current || 0))
    const traditional = Math.floor(Number(profit.traditional || 0))

    const max = [beeftrader, current, traditional].reduce((prev: number, value: number) =>
      prev < value ? value : prev
    )

    const min = [beeftrader, current, traditional].reduce((prev: number, value: number) =>
      prev > value ? value : prev
    )

    const chartLeft = min < 0 ? minChartLeft + min.toString().length * 16 : minChartLeft

    chart = (
      <div className={'card-chart-container'}>
        <ResponsiveContainer className={'chart-responsive-container'}>
          <BarChart
            data={data}
            layout='vertical'
            margin={{
              left: chartLeft,
              right: max.toString().length * 16,
              top: 0,
              bottom: 0
            }}
          >
            <XAxis type='number' hide />
            <YAxis
              hide
              axisLine={false}
              tickLine={false}
              type='category'
              // tick={tickProps => renderCustomAxisTick(t, theme, tickProps)}
            />
            <Bar
              label={(labelProps: any) => renderCustomBarLabel(t, theme, labelProps)}
              dataKey='value'
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getGradientFill(theme, index)} height={30} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <div className={'card-container'} style={{ marginTop: '40px' }}>
      <div className='card-icon-container'>
        <DollarIcon width={'3.5rem'} height={'3.5rem'} fill={colors(theme, Colors.accent)} />
      </div>
      <HelpButton infoText={'profit:all_feedlot_profit'} theme={theme} />
      <div className='card-body'>
        <div className='card-title-container'>
          <p className={'card-title'}>{t('profit')}</p>
          <div className='card-sales-buttons-line'>
            <CardSalesButtons
              t={t}
              selectedSales={sales}
              handleSalesChange={(sales) => setSales(sales)}
            />
          </div>
        </div>
        <div className={'card-content'}>{chart}</div>
        <div className='profit-footer'>
          <p className='card-profit-footer'>{footerText}</p>
          <Button
            className='info-button'
            onClick={() => {
              navigateToLotsPage()
            }}
          >
            <p className='text-info-button'>{t('more_information')}</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProfitCard
