import React from 'react'
import { connect } from 'react-redux'
import { ApplicationStore } from 'store/types'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

// Resources
// import './styles.scss'
import imgBgLight from 'resources/img/beeftrader-background-image-light.png'
import imgBgDark from 'resources/img/beeftrader-background-image-dark.jpg'
import logoLight from 'resources/img/beeftrader-logo-light.svg'
import logoRegular from 'resources/img/beeftrader-logo-regular.svg'

// Types
import { Props } from './types'
import { Themes } from 'store/main/types'
import { Dispatch, bindActionCreators } from 'redux'

// Methods
import { setTheme } from 'store/main/actions'
import ThemeToggle from 'components/shared/ThemeToggle'
import LocaleSelector from 'components/shared/LocaleSelector'

const NotFound = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div id={'notfound'} className={'auth-container'}>
      <div className={'auth-picture-container'}>
        <img
          src={props.theme === Themes.LIGHT ? imgBgLight : imgBgDark}
          className={'auth-picture'}
        />
      </div>
      <aside className='auth-panel'>
        <div className='auth-settings-row'>
          <ThemeToggle />
          <LocaleSelector />
        </div>
        <div className='auth-form-container'>
          <div className='auth-paper'>
            <img
              src={props.theme === Themes.LIGHT ? logoRegular : logoLight}
              className={'auth-logo'}
            />
            <div className='auth-row'>
              <text className={'not-found-text'}>{t('not_found')}</text>
            </div>
            <NavLink id='not-found-link' to='/' className='not-found-link'>
              {t('go_home')}
            </NavLink>
            <div className='auth-form'>
              <div className='auth-row'></div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTheme
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(NotFound))
