/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Resources
import './styles.scss'

import RegisterAnimals from './RegisterAnimals'
import Slaughterhouses from './Slaughterhouses'
import Parameters from './Parameters'
import { useTranslation } from 'react-i18next'
import FeedlotSlaughterhouseForm from 'pages/Settings/FeedlotSlaughterhouse'

// let countBrinco = 0
// let countPeso = 0
// let countECC = 0
// let countRaca = 0
// let countGenero = 0
// let countData = 0
// let countLote = 0
// let countBaia = 0

const Settings = () => {
  const { t } = useTranslation()

  //tabs
  const [toggleState, setToggleState] = useState<number>(3)

  //control the tab selection
  const toggleTab = (index: number) => {
    setToggleState(index)
  }
  return (
    <div className={'settings'}>
      <header className={'settings-name'}>
        <h1 className={'settings-name-h1'}>{t('settings')}</h1>
      </header>
      <div className={'settings-container'}>
        <div className={'settings-container-tabs'}>
          {/* <div
            className={
              toggleState == 1
                ? 'settings-container-tabs-tab-active-tabs'
                : 'settings-container-tabs-tab'
            }
            style={{ borderTopLeftRadius: '10px' }}
            onClick={() => toggleTab(1)}
          >
            {t('register_animals')}
          </div> */}
          {/* <div
            className={
              toggleState == 2
                ? 'settings-container-tabs-tab-active-tabs'
                : 'settings-container-tabs-tab'
            }
            onClick={() => toggleTab(2)}
          >
            Agenda de Vendas
          </div> */}
          <div
            className={
              toggleState == 3
                ? 'settings-container-tabs-tab'
                : 'settings-container-tabs-tab'
            }
            onClick={() => toggleTab(3)}
          >
            {t('parameters')}
          </div>
          {/* <div
            className={
              toggleState == 4
                ? 'settings-container-tabs-tab-active-tabs'
                : 'settings-container-tabs-tab'
            }
            style={{ borderTopRightRadius: '10px' }}
            onClick={() => toggleTab(4)}
          >
            {t('slaughterhouses')}
          </div> */}
        </div>

        <div className={'settings-container-contents-tabs'}>
          <div
            className={
              toggleState == 1
                ? 'settings-container-contents-tabs-content-active-content'
                : 'settings-container-contents-tabs-content'
            }
          >
            <RegisterAnimals />
          </div>
          <div
            className={
              toggleState == 2
                ? 'settings-container-contents-tabs-content-active-content'
                : 'settings-container-contents-tabs-content'
            }
          ></div>
          <div
            className={
              toggleState == 3
                ? 'settings-container-contents-tabs-content-active-content'
                : 'settings-container-contents-tabs-content'
            }
          >
            <Parameters />
          </div>
          <div
            className={
              toggleState == 4
                ? 'settings-container-contents-tabs-content-active-content'
                : 'settings-container-contents-tabs-content'
            }
          >
            <Slaughterhouses />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
