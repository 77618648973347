import React from 'react'

import Format from 'helpers/Format'

export const textTooltip = (
  t: any,
  value: any,
  name: any,
  measurementUnitChart: string,
  nameKey: string,
  initDate: any
) => {
  const convertDateToDayDate = () => {
    const date = new Date(Date.parse(initDate.slice(0, 10)))
    date.setDate(date.getDate() + (value.time - 1))
    return date.toLocaleDateString('pt-BR')
  }

  const dateOfDay = convertDateToDayDate()

  if (measurementUnitChart == 'weight')
    return (
      <p className={'text-description'}>
        {name}
        <br />
        {dateOfDay} ({Format(Number(value.time))} {t('days')})
        <br />
        {Format(Number(value[nameKey]).toFixed(2))} {t('weight_unity_kg').toLowerCase()}
      </p>
    )
  else if (measurementUnitChart === 'currency')
    return (
      <p className={'text-description'}>
        {name}
        <br />
        {dateOfDay} ({Format(Number(value.time))} {t('days')})
        <br />
        {t('currency')} {Format(Number(value[nameKey]).toFixed(2))}
      </p>
    )
  return
}
