import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

// Types
import { feedlotSlaughterhouseQueryFields, stateQueryFields } from './types'
import { FeedlotSlaughterhouseCreate } from 'api/feedlot-slaughterhouse/types'
import { ApplicationStore } from 'store/types'
// Components
import { Grid, FormControlLabel, Switch } from '@material-ui/core'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import CustomMaterialTable, { notNullValidate } from 'components/tables/CustomMaterialTable'
import SelectButton from 'components/shared/Select'
import Loading from 'components/shared/Loading'
// import SlaughterhousePriceForm from 'components/Forms/slaughterhousePrice'

// Methods
import { getStates } from 'store/address/actions'
import {
  deleteFeedlotSlaughterhouse,
  getFeedlotConfiguration,
  getFeedlotSlaughterhouses,
  saveFeedlotConfiguration,
  saveFeedlotSlaughterhouse
} from 'store/feedlot-slaughterhouse/actions'
// Resources
import './styles.scss'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import {
  deleteFeedlotSlaughterhousePrice,
  getCurrency,
  getFeedlotSlaughterhousePrice,
  getWeightUnity
} from 'store/economic/actions'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const filter = createFilterOptions()

const FeedlotSlaughterhouseForm: React.FC = () => {
  const { t } = useTranslation()
  const formRef = useRef<FormHandles>(null)
  const { saving, feedlotSlaughterhouses, feedlotSlaughterhouseLoading, feedlotConfiguration } =
    useSelector((state: ApplicationStore) => state.feedlotSlaughterhouse)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const { feedlotSlaughterhousePrice, feedlotSlaughterhousePriceLoading, currencies, weightUnits } =
    useSelector((state: ApplicationStore) => state.economic)
  const { states } = useSelector((state: ApplicationStore) => state.address)
  const dispatch = useDispatch()
  const [rows, setRows] = useState<Array<any>>([])
  const [cpRows, setCpRows] = useState<Array<any>>([])
  const [cpRowsUnity, setCpRowsUnity] = useState<Array<any>>([])
  const [listSlaughterhouse, setListSlaughterhouse] = useState<Array<any>>([])
  const [personalPrice, setPersonalPrice] = useState<boolean>(false)
  const [initialPersonalPrice, setInitialPersonalPrice] = useState<boolean | null>(null)

  useEffect(() => {
    if (!currencies.length) dispatch(getCurrency({ fields: undefined }))
    if (!weightUnits.length) dispatch(getWeightUnity({ fields: undefined }))
  }, [currencies, weightUnits])

  useEffect(() => {
    if (!feedlotConfiguration && selectedFeedlot?.id) {
      dispatch(getFeedlotConfiguration({ feedlotId: [selectedFeedlot?.id] }))
      setInitialPersonalPrice(null)
    }
    if (feedlotConfiguration && initialPersonalPrice === null) {
      setInitialPersonalPrice(feedlotConfiguration.customPrice)
      setPersonalPrice(feedlotConfiguration.customPrice)
    }
  }, [feedlotConfiguration, selectedFeedlot])

  useEffect(() => {
    if (!states.length) dispatch(getStates({ fields: stateQueryFields }))
    getDataTable()
  }, [selectedFeedlot, saving])

  const getDataTable = () => {
    if (selectedFeedlot?.id && !saving) {
      dispatch(
        getFeedlotSlaughterhouses({
          args: { feedlotId: [selectedFeedlot?.id] },
          fields: feedlotSlaughterhouseQueryFields
        })
      )

      if (!feedlotSlaughterhouses) return
      dispatch(getFeedlotSlaughterhousePrice({ args: { all: true } }))
    }
  }
  useEffect(() => {
    if (feedlotSlaughterhousePrice && feedlotSlaughterhouses) {
      const auxRows = []
      const slaughterhousesList = []
      for (const f of feedlotSlaughterhouses) {
        const prices = feedlotSlaughterhousePrice.filter((p) => p.feedlotSlaughterhouse?.id == f.id)
        if (prices.length > 0) {
          auxRows.push({
            id: f.id,
            feedlotId: f.feedlotId,
            description: f.description,
            status: f.status,
            countryId: f.address?.countryId,
            countryDescription: f.address?.countryDescription,
            stateId: f.address?.stateId,
            stateDescription: f.address?.stateDescription,
            cityStateDescription: f.address?.cityStateDescription,
            cityId: f.address?.cityId,
            cityDescription: f.address?.cityDescription,
            priceIdMI: prices.find((p) => (p.gender ? p.gender.code == 'MI' : ''))?.id,
            priceMI: Number(
              prices.find((p) => (p.gender ? p.gender.code == 'MI' : ''))?.price || 0
            ).toFixed(2),
            priceIdMC: prices.find((p) => (p.gender ? p.gender.code == 'MC' : ''))?.id,
            priceMC: Number(
              prices.find((p) => (p.gender ? p.gender.code == 'MC' : ''))?.price || 0
            ).toFixed(2),
            priceIdNV: prices.find((p) => (p.gender ? p.gender.code == 'NV' : ''))?.id,
            priceNV: Number(
              prices.find((p) => (p.gender ? p.gender.code == 'NV' : ''))?.price || 0
            ).toFixed(2),
            priceIdVC: prices.find((p) => (p.gender ? p.gender.code == 'VC' : ''))?.id,
            priceVC: Number(
              prices.find((p) => (p.gender ? p.gender.code == 'VC' : ''))?.price || 0
            ).toFixed(2),
            currencyId: prices[0].currency?.id,
            weightUnityId: prices[0].weightUnity?.id,
            currency: prices[0].currency?.symbol,
            weightUnity: prices[0].weightUnity?.symbol,
            currencyWeightUnity: prices[0].currency?.symbol + ' / ' + prices[0].weightUnity?.symbol
          })
        } else {
          slaughterhousesList.push(f)
        }
      }
      setRows(auxRows)

      setListSlaughterhouse(
        slaughterhousesList.map((o) => ({ description: o.description, id: o.id }))
      )
    }
  }, [feedlotSlaughterhouses, feedlotSlaughterhousePrice])

  const deleteFeedlotSlaughterhouses = async (feedlotSlaughterhouse: any) => {
    const priceId: number[] = []
    priceId.push(
      ...[
        feedlotSlaughterhouse.priceIdNV,
        feedlotSlaughterhouse.priceIdMC,
        feedlotSlaughterhouse.priceIdVC,
        feedlotSlaughterhouse.priceIdMI
      ]
    )
    dispatch(deleteFeedlotSlaughterhousePrice(priceId.filter((x) => x !== undefined)))
    dispatch(deleteFeedlotSlaughterhouse([feedlotSlaughterhouse.id]))
  }

  const saveTableData = async (prices: any, feedlotSlaughterhouse: any) => {
    try {
      let feedlotSlaughterhouseCopy: FeedlotSlaughterhouseCreate | undefined = undefined

      const deletePrices = prices.filter((p: any) => p.id !== -1 && p.price == 0)
      prices = prices.filter((p: any) => p.price !== 0)

      if (deletePrices.length > 0)
        dispatch(deleteFeedlotSlaughterhousePrice(deletePrices.map((x: any) => x.id)))

      if (feedlotSlaughterhouse.length > 0) {
        feedlotSlaughterhouseCopy = {
          id: feedlotSlaughterhouse[0].id,
          feedlotId: feedlotSlaughterhouse[0].feedlotId,
          description: feedlotSlaughterhouse[0].description,
          cityId: feedlotSlaughterhouse[0].cityId,
          status: feedlotSlaughterhouse[0].status
        }
      }

      if (feedlotSlaughterhouseCopy) {
        await dispatch(
          saveFeedlotSlaughterhouse(
            true,
            {
              feedlotSlaughterhousePrice: prices
            },
            { feedlotSlaughterhouses: feedlotSlaughterhouseCopy }
          )
        )
      } else {
        await dispatch(
          saveFeedlotSlaughterhouse(true, {
            feedlotSlaughterhousePrice: prices
          })
        )
      }
    } catch (err: any) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => (validationErrors[error.path] = error.message))
        formRef?.current?.setErrors(validationErrors)
      }
    }
  }

  const labelSwitch = { inputProps: { 'aria-label': 'Switch demo' } }

  const saveSlaughterhousePriceConfig = async (value: boolean) => {
    try {
      setPersonalPrice(value)
      setInitialPersonalPrice(value)
      if (value !== feedlotConfiguration?.customPrice && selectedFeedlot)
        dispatch(
          saveFeedlotConfiguration({
            feedlotConfiguration: { feedlotId: selectedFeedlot?.id, customPrice: value ?? false }
          })
        )
    } catch (error: any) {
      throw error
    }
  }

  return (
    <div className='customer-slaughterhouses-body'>
      <div className='params-row'>
        <Form ref={formRef} onSubmit={() => null} className={'form-body'}>
          <div style={{ width: '100%' }}>
            {feedlotSlaughterhouseLoading || feedlotSlaughterhousePriceLoading || saving ? (
              <Loading />
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      className={'personal-price-switch'}
                      checked={initialPersonalPrice || false}
                      onChange={(event) => saveSlaughterhousePriceConfig(event.target.checked)}
                      name='personalPriceBtn'
                    />
                  }
                  label={t('use_my_prices')}
                />
                <CustomMaterialTable
                  columns={[
                    {
                      title: t('slaughterhouse'),
                      field: 'description',
                      validate: notNullValidate('description'),
                      render: (rowData) => (
                        <Grid>
                          {rowData.description} {rowData.cityStateDescription}
                        </Grid>
                      ),
                      editComponent: ({ rowData, onChange }) => {
                        const [slaughterhouseId, setSlaughterhouseId] = useState<number>(
                          rowData?.id
                        )
                        const [description, setDescription] = useState<string>(rowData?.description)
                        const [selectedStateId, setSelectedStateId] = useState<number>(
                          rowData?.stateId || 1
                        )
                        const [selectedCityId, setSelectedCityId] = useState<number>(
                          rowData?.cityId ||
                            states.filter((s) => s.id === selectedStateId)[0].cities[0]?.id
                        )

                        return (
                          <div
                            style={{
                              width: 570,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'end'
                            }}
                          >
                            <Autocomplete
                              style={{
                                width: 170,
                                margin: '1%'
                              }}
                              defaultValue={description}
                              onInputChange={(e, nv) => {
                                onChange(nv)
                              }}
                              options={listSlaughterhouse}
                              // value={rowData.description}
                              onChange={(event, newValue) => {
                                if (!rowData.tableData) {
                                  rowData.tableData = { id: -1 }
                                  rowData.id = -1
                                }
                                if (newValue) {
                                  const auxSlaughterhouse = feedlotSlaughterhouses?.find(
                                    (f) => f.id == newValue.id
                                  )

                                  if (auxSlaughterhouse) {
                                    setSelectedStateId(auxSlaughterhouse?.address?.stateId || 0)
                                    setSelectedCityId(auxSlaughterhouse?.address?.cityId || 0)
                                    setDescription(auxSlaughterhouse.description)
                                    setSlaughterhouseId(auxSlaughterhouse.id)

                                    const auxRows = {
                                      pos: rowData.tableData.id,
                                      id: auxSlaughterhouse.id,
                                      description: auxSlaughterhouse.description,
                                      countryId: auxSlaughterhouse?.address?.countryId,
                                      countryDescription:
                                        auxSlaughterhouse?.address?.countryDescription,
                                      stateId: auxSlaughterhouse?.address?.stateId,
                                      stateDescription:
                                        auxSlaughterhouse?.address?.stateDescription,
                                      cityStateDescription:
                                        auxSlaughterhouse?.address?.cityStateDescription,
                                      cityId: auxSlaughterhouse?.address?.cityId,
                                      cityDescription: auxSlaughterhouse?.address?.cityDescription
                                    }

                                    cpRows[0] = auxRows
                                  } else {
                                    setDescription(newValue.inputValue)
                                    setSlaughterhouseId(rowData.id)

                                    const auxRows = {
                                      pos: rowData.tableData.id,
                                      id: rowData.id,
                                      description: newValue.inputValue,
                                      countryId: '',
                                      countryDescription: '',
                                      stateId: selectedStateId,
                                      stateDescription: states.find((s) => s.id === selectedStateId)
                                        ?.description,
                                      cityStateDescription:
                                        states
                                          .find((s) => s.id === selectedStateId)
                                          ?.cities.find((s) => s.id === selectedCityId)
                                          ?.description +
                                        ' - ' +
                                        states.find((s) => s.id === selectedStateId)?.code,
                                      cityId: selectedCityId,
                                      cityDescription: states
                                        .find((s) => s.id === selectedStateId)
                                        ?.cities.find((s) => s.id === selectedCityId)?.description
                                    }

                                    cpRows[0] = auxRows
                                  }
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)
                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    description: `Adicionar "${params.inputValue}"`
                                  })
                                }

                                return filtered
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.description
                              }}
                              renderOption={(option) => option.description}
                              freeSolo
                              renderInput={(params) => <TextField {...params} />}
                            />
                            <SelectButton
                              selected={selectedStateId || states[0]?.id}
                              values={states}
                              text={'description'}
                              onChange={(id: number) => {
                                if (!rowData.tableData) {
                                  rowData.tableData = { id: -1 }
                                }

                                onChange(id)
                                setSelectedStateId(id)
                                setSelectedCityId(
                                  states.filter((s) => s.id === id)[0].cities[0]?.id
                                )
                                rowData.stateId = id

                                const auxRows = {
                                  pos: rowData.tableData.id,
                                  id: slaughterhouseId,
                                  description: description,
                                  countryId: '',
                                  countryDescription: '',
                                  stateId: selectedStateId,
                                  stateDescription: states.find((s) => s.id === id)?.description,
                                  cityStateDescription:
                                    states.filter((s) => s.id === id)[0].cities[0]?.description +
                                    ' - ' +
                                    states.find((s) => s.id === id)?.code,
                                  cityId: selectedCityId,
                                  cityDescription: states.filter((s) => s.id === id)[0].cities[0]
                                    ?.description
                                }
                                cpRows[0] = auxRows
                              }}
                            />
                            <SelectButton
                              selected={
                                selectedCityId ||
                                states.filter((s) => s.id === selectedStateId)[0].cities[0]?.id
                              }
                              values={
                                states.filter((s) => s.id === selectedStateId)[0]?.cities ||
                                states[0].cities
                              }
                              text={'description'}
                              onChange={(id: number) => {
                                if (!rowData.tableData) {
                                  rowData.tableData = { id: -1 }
                                }
                                onChange(id)
                                setSelectedCityId(id)

                                const auxRows = {
                                  pos: rowData.tableData.id,
                                  id: slaughterhouseId,
                                  description: description,
                                  countryId: '',
                                  countryDescription: '',
                                  stateId: selectedStateId,
                                  stateDescription: states.find((s) => s.id === selectedStateId)
                                    ?.description,
                                  cityStateDescription:
                                    states
                                      .find((s) => s.id === selectedStateId)
                                      ?.cities.find((s) => s.id === id)?.description +
                                    ' - ' +
                                    states.find((s) => s.id === selectedStateId)?.code,
                                  cityId: id,
                                  cityDescription: states
                                    .find((s) => s.id === selectedStateId)
                                    ?.cities.find((s) => s.id === id)?.description
                                }
                                cpRows[0] = auxRows
                              }}
                            />
                          </div>
                        )
                      }
                    },
                    {
                      title: t('status'),
                      field: 'status',
                      validate: notNullValidate('status'),
                      render: (rowData) => (
                        <Grid className={`field ${rowData?.status ? 'active' : 'inactive'}`}>
                          {rowData?.status ? t('active') : t('inactive')}
                        </Grid>
                      ),
                      editComponent: ({ rowData }) => {
                        const [checked, setChecked] = useState<boolean>(
                          rowData?.status === undefined || rowData?.status ? true : false
                        )
                        if (rowData?.status === undefined) rowData.status = true
                        return (
                          <FormControlLabel
                            control={
                              <Switch
                                {...labelSwitch}
                                color='secondary'
                                defaultChecked={checked}
                                onChange={(e) => {
                                  setChecked(e.target.checked)
                                  rowData.status = e.target.checked ? true : false
                                }}
                              />
                            }
                            label={t('active')}
                          />
                        )
                      }
                    },
                    {
                      title: t('currency'),
                      field: 'currencyWeightUnity',
                      validate: notNullValidate('currencyWeightUnity'),
                      render: (rowData) => <Grid>{rowData.currencyWeightUnity}</Grid>,
                      editComponent: ({ rowData, onChange }) => {
                        const [selectedCurrencyId, setSelectedCurrencyId] = useState<number>(
                          rowData?.currencyId || 1
                        )
                        const [selectedWeightUnityId, setSelectedWeightUnityId] = useState<number>(
                          rowData?.weightUnityId || 1
                        )

                        return (
                          <>
                            <SelectButton
                              selected={rowData?.currencyId}
                              values={currencies}
                              text={'symbol'}
                              onChange={(id: number) => {
                                if (!rowData.tableData) {
                                  rowData.tableData = { id: -1 }
                                  rowData.id = -1
                                }
                                onChange(id)
                                setSelectedCurrencyId(id)
                                rowData.currencyId = id
                                const auxRows = {
                                  pos: rowData.tableData.id,
                                  id: rowData.id,
                                  weightUnityId: selectedWeightUnityId,
                                  currencyId: id,
                                  currencyWeightUnity:
                                    currencies.find((c) => c.id == id)?.symbol +
                                    ' / ' +
                                    weightUnits.find((c) => c.id == selectedWeightUnityId)?.symbol
                                }
                                cpRowsUnity[0] = auxRows
                              }}
                            />
                            <SelectButton
                              selected={rowData?.weightUnityId}
                              values={weightUnits}
                              text={'symbol'}
                              onChange={(id: number) => {
                                onChange(id)
                                setSelectedWeightUnityId(id)
                                rowData.weightUnityId = id
                                const auxRows = {
                                  pos: rowData.tableData.id,
                                  weightUnityId: id,
                                  currencyId: selectedCurrencyId,
                                  currencyWeightUnity:
                                    currencies.find((c) => c.id == selectedCurrencyId)?.symbol +
                                    ' / ' +
                                    weightUnits.find((c) => c.id == id)?.symbol
                                }
                                cpRowsUnity[0] = auxRows
                              }}
                            />
                          </>
                        )
                      }
                    },
                    {
                      title: t('animal:price_macho_inteiro'),
                      field: 'priceMI',
                      validate: notNullValidate('price')
                    },
                    {
                      title: t('animal:price_macho_castrado'),
                      field: 'priceMC',
                      validate: notNullValidate('price')
                    },
                    {
                      title: t('animal:price_novilha'),
                      field: 'priceNV',
                      validate: notNullValidate('price')
                    },
                    {
                      title: t('animal:price_vaca'),
                      field: 'priceVC',
                      validate: notNullValidate('price')
                    }
                  ]}
                  data={rows}
                  options={{
                    showTitle: false,
                    pageSize: 10,
                    search: personalPrice ?? false
                  }}
                  editable={{
                    onRowUpdateCancelled: (newData) => {
                      setCpRows([])
                      setCpRowsUnity([])
                    },
                    onRowAdd: async (newData) => {
                      // const aux = rows
                      let savePrices = []
                      let weightUnityId = newData.weightUnityId
                      let currencyId = newData.currencyId
                      let feedlotSlaughterhouseId = newData.id

                      if (cpRowsUnity.length > 0) {
                        weightUnityId = cpRowsUnity[0].weightUnityId
                        currencyId = cpRowsUnity[0].currencyId
                      }
                      if (cpRows.length > 0) {
                        feedlotSlaughterhouseId = cpRows[0].id
                        cpRows[0].feedlotId = selectedFeedlot?.id
                        cpRows[0].status = newData.status
                      }

                      savePrices.push(
                        ...[
                          {
                            id: Number(newData.priceIdMC),
                            genderId: 1,
                            price: Number(newData.priceMC),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdMI),
                            genderId: 2,
                            price: Number(newData.priceMI),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdNV),
                            genderId: 4,
                            price: Number(newData.priceNV),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdVC),
                            genderId: 3,
                            price: Number(newData.priceVC),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          }
                        ]
                      )
                      savePrices.forEach((item) => {
                        if (item.price !== 0 && !Number.isNaN(item.price) && Number.isNaN(item.id))
                          item.id = -1
                      })
                      savePrices = savePrices.filter((s) => !Number.isNaN(s.id))

                      saveTableData(savePrices, cpRows)
                      setCpRows([])
                      setCpRowsUnity([])
                    },
                    onRowUpdate: async (newData, oldData) => {
                      let savePrices = []
                      let weightUnityId = newData.weightUnityId
                      let currencyId = newData.currencyId
                      let feedlotSlaughterhouseId = newData.id

                      if (cpRowsUnity.length > 0) {
                        weightUnityId = cpRowsUnity[0].weightUnityId
                        currencyId = cpRowsUnity[0].currencyId
                      }

                      if (cpRows.length > 0) {
                        feedlotSlaughterhouseId = cpRows[0].id
                        cpRows[0].feedlotId = newData.feedlotId
                        cpRows[0].status = newData.status
                      }

                      savePrices.push(
                        ...[
                          {
                            id: Number(newData.priceIdMC),
                            genderId: 1,
                            price: Number(newData.priceMC),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdMI),
                            genderId: 2,
                            price: Number(newData.priceMI),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdNV),
                            genderId: 4,
                            price: Number(newData.priceNV),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          },
                          {
                            id: Number(newData.priceIdVC),
                            genderId: 3,
                            price: Number(newData.priceVC),
                            feedlotSlaughterhouseId: feedlotSlaughterhouseId,
                            currencyId: currencyId,
                            weightUnityId: weightUnityId
                          }
                        ]
                      )
                      savePrices.forEach((item) => {
                        if (item.price !== 0 && Number.isNaN(item.id)) item.id = -1
                      })
                      savePrices = savePrices.filter((s) => !Number.isNaN(s.id))

                      saveTableData(savePrices, cpRows)
                      setCpRows([])
                      setCpRowsUnity([])
                    },
                    onRowDelete: async (row) => {
                      deleteFeedlotSlaughterhouses(row)
                    }
                  }}
                />
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default FeedlotSlaughterhouseForm
