import produce from 'immer'
import { AnyAction } from 'redux'
import { AddressConstants, AddressStore } from './types'

const INITIAL_STORE: AddressStore = {
  cities: [],
  states: [],
  cityLoading: false,
  stateLoading: false
}
export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case AddressConstants.REDUCER_SET_ADDRESS_DATA:
        Object.assign(draft, payload)
        break
      case AddressConstants.REDUCER_CLEAR_ADDRESS_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
