import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import QueryBuilder from 'helpers/QueryBuilder'
import {
  FeedlotSlaughterhousePriceQueryParams,
  FeedlotSlaughterhousePriceCreateArgs,
  FeedlotSlaughterhousePriceUpdateArgs,
  CurrencyQueryParams,
  WeightUnityQueryParams,
  FeedlotSlaughterhousePriceDelete,
  QuoteSlaughterhousePriceQueryParams
} from './types'
import { Currency, FeedlotSlaughterhousePrice, WeightUnity } from 'store/economic/types'
import { QuoteSlaughterhousePrice } from '../../store/economic/types'

export const getFeedlotSlaughterhousePrice = async (
  params: FeedlotSlaughterhousePriceQueryParams
): Promise<FeedlotSlaughterhousePrice[]> => {
  if (!params?.fields) {
    params.fields = {
      id: true,
      price: true,
      genderId: true,
      gender: {
        id: true,
        code: true,
        description: true
      },
      feedlotSlaughterhouse: {
        id: true,
        feedlotId: true,
        description: true,
        status: true,
        cityId: true,
        updateDate: true
      },
      currency: {
        id: true,
        code: true,
        description: true,
        symbol: true
      },
      weightUnity: {
        id: true,
        code: true,
        symbol: true
      }
    }
  }
  const QUERY = gql`
    query QUERY($id: Int, $all: Boolean, $feedlotSlaughterhouseId: Int) {
      feedlotSlaughterhousePrice(id: $id, all:$all, feedlotSlaughterhouseId: $feedlotSlaughterhouseId) ${QueryBuilder(
        params.fields
      )}
    }`
  try {
    const { credentials } = store.getState().auth
    const response = await API.query(QUERY, { ...params?.args }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/economic/getFeedlotSlaughterhousePrice',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/economic/getFeedlotSlaughterhousePrice',
        errors
      )
    }

    return data.feedlotSlaughterhousePrice as FeedlotSlaughterhousePrice[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/economic/getFeedlotSlaughterhousePrice',
      error
    )
  }
}

export const getQuotedSlaughterhousePrice = async (
  params: QuoteSlaughterhousePriceQueryParams
): Promise<QuoteSlaughterhousePrice[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      regionId: true,
      currencyId: true,
      weightUnityId: true,
      genderId: true,
      price: true,
      price30Days: true,
      grossPrice: true,
      grossPrice30Days: true,
      updateDate: true,
      region: {
        id: true,
        description: true,
        address: true,
        latitude: true,
        longitude: true,
        updateDate: true
      },
      currency: {
        id: true,
        code: true,
        description: true,
        symbol: true
      },
      weightUnity: {
        id: true,
        code: true,
        symbol: true
      },
      gender: {
        id: true,
        code: true,
        description: true
      }
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      regionPrice(id: $id) ${QueryBuilder(params.fields)}
    }`
  try {
    const { credentials } = store.getState().auth
    const response = await API.query(QUERY, { ...params.args }, credentials.token)
    const { data, errors } = response

    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/economic/getQuoteSlaughterhousePrice',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/economic/getQuoteSlaughterhousePrice',
        errors
      )
    }

    return data.regionPrice as QuoteSlaughterhousePrice[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/economic/getQuoteSlaughterhousePrice',
      error
    )
  }
}

export const createFeedlotSlaughterhousePrice = async (
  params: FeedlotSlaughterhousePriceCreateArgs
) => {
  const MUTATION = gql`
    mutation MUTATION($feedlotSlaughterhousePrice: [CreateFeedlotSlaughterhousePrice!]!) {
      createFeedlotSlaughterhousePrice(feedlotSlaughterhousePrice: $feedlotSlaughterhousePrice)
    }
  `
  try {
    const { credentials } = store.getState().auth
    const response = await API.mutate(MUTATION, { ...params }, credentials.token)
    const { data, errors } = response
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/economic/createFeedlotSlaughterhousePrice',
        errors
      )
    }
    return data.createFeedlotSlaughterhousePrice as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/economic/createFeedlotSlaughterhousePrice',
      error
    )
  }
}

export const updateFeedlotSlaughterhousePrice = async (
  params: FeedlotSlaughterhousePriceUpdateArgs
) => {
  const MUTATION = gql`
    mutation MUTATION($feedlotSlaughterhousePrice: [UpdateFeedlotSlaughterhousePrice!]!) {
      updateFeedlotSlaughterhousePrice(feedlotSlaughterhousePrice: $feedlotSlaughterhousePrice)
    }
  `
  try {
    const { credentials } = store.getState().auth
    const response = await API.mutate(MUTATION, { ...params }, credentials.token)
    const { data, errors } = response
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/economic/updateFeedlotSlaughterhousePrice',
        errors
      )
    }
    return data.updateFeedlotSlaughterhousePrice as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/economic/updateFeedlotSlaughterhousePrice',
      error
    )
  }
}

export const getCurrency = async (params: CurrencyQueryParams): Promise<Currency[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      symbol: true,
      description: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      currency(id: $id) ${QueryBuilder(params.fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth
    const response = await API.query(QUERY, { ...params.args }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/economic/currency',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/economic/currency', errors)
    }
    return data.currency as Currency[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/economic/currency', error)
  }
}

export const getWeightUnity = async (params: WeightUnityQueryParams): Promise<WeightUnity[]> => {
  if (!params.fields)
    params.fields = {
      id: true,
      code: true,
      symbol: true
    }
  const QUERY = gql`
    query QUERY($id: Int) {
      weightUnity(id: $id) ${QueryBuilder(params.fields)}
    }
    `
  try {
    const { credentials } = store.getState().auth
    const response = await API.query(QUERY, { ...params.args }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/economic/weightUnity',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/economic/weightUnity', errors)
    }
    return data.weightUnity as WeightUnity[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/economic/weightUnity', error)
  }
}

export const deleteFeedlotSlaughterhousePrice = async (
  params: FeedlotSlaughterhousePriceDelete
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($ids: [Float!]!) {
        deleteFeedlotSlaughterhousePrices(ids: $ids)
      }
    `
    const { credentials } = store.getState().auth
    const { errors, data } = await API.mutate(MUTATION, { ...params }, credentials.token)
    if (errors) {
      const { code } = errors
      throw new Exception(
        code || Codes.Internals.UNEXPECTED_ERROR,
        'api/economic/deleteFeedlotSlaughterhousePrice',
        errors
      )
    }
    return data.deleteFeedlotSlaughterhousePrice as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/economic/deleteFeedlotSlaughterhousePrice',
      error
    )
  }
}
