import { combineReducers, applyMiddleware, Store, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import address from './address/reducers'
import auth from './auth/reducers'
import animal from './animal/reducers'
import animalManagement from './configuration/animal-management/reducers'
import feedlot from './feedlot/reducers'
import lot from './lot/reducers'
import pen from './pen/reducers'
import main from './main/reducers'
import feedlotSlaughterhouse from './feedlot-slaughterhouse/reducers'
import economic from './economic/reducers'

import addressWatchers from './address/watchers'
import authWatchers from './auth/watchers'
import animalWatchers from './animal/watchers'
import feedlotWatchers from './feedlot/watchers'
import lotWatchers from './lot/watchers'
import mainWatchers from './main/watchers'
import feedlotSlaughterhouseWatchers from './feedlot-slaughterhouse/watchers'
import economicWatchers from './economic/watchers'

import { ApplicationStore } from './types'
import penWatchers from './pen/watchers'
import animalManagementWatchers from './configuration/animal-management/watchers'

const reducers = combineReducers({
  address,
  animal,
  animalManagement,
  auth,
  feedlot,
  lot,
  main,
  feedlotSlaughterhouse,
  economic,
  pen
})

const sagaMiddleware = createSagaMiddleware()
const store: Store<ApplicationStore> = createStore(reducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(addressWatchers)
sagaMiddleware.run(authWatchers)
sagaMiddleware.run(animalWatchers)
sagaMiddleware.run(lotWatchers)
sagaMiddleware.run(feedlotWatchers)
sagaMiddleware.run(penWatchers)
sagaMiddleware.run(mainWatchers)
sagaMiddleware.run(feedlotSlaughterhouseWatchers)
sagaMiddleware.run(economicWatchers)
sagaMiddleware.run(animalManagementWatchers)

export default store
