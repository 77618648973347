import React from 'react'
import { useTranslation } from 'react-i18next'
import MaterialTable, { MaterialTableProps } from 'material-table'

const notNullValidate = (fieldName: string) => {
  return (rowData: any) => rowData[fieldName] !== ''
}

const isPositiveValidate = (fieldName: string) => {
  return (rowData: any) => Number(rowData[fieldName]) !== NaN && rowData[fieldName] > -1
}

const CustomMaterialTable: React.FC<MaterialTableProps<any>> = (props: MaterialTableProps<any>) => {
  const { t } = useTranslation()
  return (
    <MaterialTable
      localization={{
        toolbar: {
          searchTooltip: t('search'),
          searchPlaceholder: t('search'),
          exportTitle: t('export'),
          exportCSVName: t('export_to_csv')
        },
        pagination: {
          labelRowsSelect: t('components:material_table:lines'),
          firstTooltip: t('components:material_table:first_page'),
          lastTooltip: t('components:material_table:last_page'),
          nextTooltip: t('components:material_table:next_page'),
          previousTooltip: t('components:material_table:previous_page'),
          labelDisplayedRows: t('components:material_table:label_displayed_rows')
        },
        body: { editRow: { deleteText: t('components:material_table:delete_row_message') } }
      }}
      {...props}
    />
  )
}
export default CustomMaterialTable
export { notNullValidate, isPositiveValidate }
