import React, { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
// Types
import { CSVLink } from 'react-csv'
import { ApplicationStore } from 'store/types'
import { Props, EnhancedTableProps, Filter, Order, useStyles, HeaderCSV } from './types'
//Resources
import Paper from '@material-ui/core/Paper'
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiFilterMenu,
  mdiGreaterThanOrEqual,
  mdiLessThanOrEqual,
  mdiEqual,
  mdiFileDownloadOutline
} from '@mdi/js'

import './styles.scss'
import './stylesPopover.scss'
//Components
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TableSortLabel,
  Popover,
  Select,
  MenuItem,
  TextField,
  Chip,
  IconButton
} from '@material-ui/core'
import { Columns, ColumnsCollapse } from 'store/main/types'
import { stableSort, filterData, getComparator } from './helpers'
import Icon from '@mdi/react'
import { setSelectedSale } from 'store/feedlot/actions'

function EnhancedTableHead(props: EnhancedTableProps) {
  const { t } = useTranslation()
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onFilterData,
    onResetFilterData,
    onActiveColumns,
    columns,
    columnsCollapse,
    columnsAmountNull,
    filters
  } = props

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<string>('')
  const [textFilter, setTextFilter] = useState<Filter[]>([])

  useEffect(() => {
    setTextFilter(filters)
  }, [filters])

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  const createFilterHandler =
    (property: any, typeProperty: 'condition' | 'value', fieldType: 'numeric' | 'string') =>
    (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      const data = textFilter.find((t) => t.id === property)

      if (typeProperty === 'value') {
        // if (event.target.value)
        onFilterData(event, property, data ? data.condition : '=', event.target.value, fieldType)
        // else onResetFilterData(property)
      } else if (typeProperty === 'condition') {
        onFilterData(
          event,
          property,
          event.target.value as string,
          data ? data?.value : '',
          fieldType
        )
      }
    }
  const handleViewColumn = (event: React.MouseEvent<unknown>, info: ColumnsCollapse) => {
    onActiveColumns(event, info)
  }
  const handleTextFilter = (property: any) => {
    const filter = textFilter.find((t) => t.id === property)
    if (!filter) return

    let icon = <text></text>
    const value = <text> {filter.value}</text>
    const condition = filter.condition

    if (condition === '=') icon = <Icon path={mdiEqual} size={0.6} className={'icon-button'} />
    else if (condition === '=')
      icon = <Icon path={mdiFilterMenu} size={0.6} className={'icon-button'} />
    if (condition === '>')
      icon = <Icon path={mdiGreaterThanOrEqual} size={0.6} className={'icon-button'} />
    else if (condition === '<')
      icon = <Icon path={mdiLessThanOrEqual} size={0.6} className={'icon-button'} />

    return (
      <div className={'content-chip'}>
        {icon} {value}
      </div>
    )
  }

  const handleConditionText = (property: any) => {
    const filter = textFilter.find((t) => t.id === property)
    if (filter) return filter.condition
    else return '='
  }

  const handleValueText = (property: any) => {
    const filter = textFilter.find((t) => t.id === property)
    if (filter) return filter.value
    else return ''
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, value: any) => {
    setAnchorEl(event.currentTarget)
    setOpenedPopoverId(value)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenedPopoverId('')
  }
  const handleDeleteChip = (property: any) => {
    onResetFilterData(property)
  }
  return (
    <TableHead>
      <TableRow>
        {columnsCollapse.map((c: ColumnsCollapse) =>
          c.id === '' ? (
            <TableCell
              colSpan={c.columns.filter((fil) => fil.active).length}
              key={c.id}
            ></TableCell>
          ) : (
            <TableCell
              className={
                c.id == 'current'
                  ? 'button-collapse-real'
                  : c.id == 'predicted'
                  ? 'button-collapse-sales'
                  : 'button-collapse'
              }
              colSpan={c.collapse ? c.columns.filter((fil) => fil.active).length : 1}
              key={c.id}
              onClick={(e) => handleViewColumn(e, c)}
            >
              <div className={'collapse-content'}>
                {t(c.label)}
                {c.collapse ? (
                  <Icon
                    path={mdiChevronLeft}
                    size={1.1}
                    className={
                      c.id == 'current'
                        ? 'icon-button-collapse-real'
                        : c.id == 'predicted'
                        ? 'icon-button-collapse-sales'
                        : 'icon-button-collapse'
                    }
                  />
                ) : (
                  <Icon
                    path={mdiChevronRight}
                    size={1.1}
                    className={
                      c.id == 'current'
                        ? 'icon-button-collapse-real'
                        : c.id == 'predicted'
                        ? 'icon-button-collapse-sales'
                        : 'icon-button-collapse'
                    }
                  />
                )}
              </div>
            </TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        {columns.map((headCell: any, index: any) => (
          <Fragment key={index}>
            {headCell.active && (
              <TableCell
                style={{ top: 43, borderBottom: 'none', height: '50px' }}
                className={'table-cell-head'}
                classes={{ root: 'table-cell' }}
                key={headCell.id}
                align='left'
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TableSortLabel
                    className={'table-cell-sort'}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {t(headCell.label)}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                  <IconButton
                    size={'small'}
                    aria-label='Filter'
                    onClick={(e) => handleClick(e, headCell.id)}
                  >
                    <Icon path={mdiFilterMenu} size={0.6} className={'icon-button'} />
                  </IconButton>
                </div>
                <Popover
                  elevation={0}
                  id={headCell.id}
                  open={openedPopoverId === headCell.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <div className={`popover-${props.theme}`}>
                    <div className={'box'}>
                      {headCell.type !== 'string' ? (
                        <Select
                          value={handleConditionText(headCell.id)}
                          onChange={createFilterHandler(headCell.id, 'condition', headCell.type)}
                        >
                          <MenuItem value='>'>{t('greater_than')}</MenuItem>
                          <MenuItem value='<'>{t('less_than')}</MenuItem>
                          <MenuItem value='='>{t('equal')}</MenuItem>
                        </Select>
                      ) : (
                        <Select
                          value={handleConditionText(headCell.id)}
                          onChange={createFilterHandler(headCell.id, 'condition', headCell.type)}
                        >
                          <MenuItem value='='>{t('equal')}</MenuItem>
                        </Select>
                      )}
                      <TextField
                        value={handleValueText(headCell.id)}
                        placeholder={t(headCell.label)}
                        onChange={createFilterHandler(headCell.id, 'value', headCell.type)}
                        style={{ width: '100px' }}
                      />
                    </div>
                  </div>
                </Popover>
              </TableCell>
            )}
            {columnsAmountNull
              .filter((el) => el === headCell.order)
              .map((i, indexMap) => (
                <TableCell
                  style={{
                    //  width: '257px',
                    borderBottom: 'none',
                    top: 43,
                    height: '50px'
                  }}
                  className={'table-cell-head'}
                  classes={{ root: 'table-cell' }}
                  key={indexMap + 'null'}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
                ></TableCell>
              ))}
          </Fragment>
        ))}
      </TableRow>
      {textFilter.length > 0 && (
        <TableRow>
          {columns.map((headCell: any, index: any) => (
            <Fragment key={index}>
              {headCell.active && (
                <TableCell
                  classes={{ root: 'table-cell' }}
                  key={index}
                  style={{ paddingBottom: '2px', top: 103 }}
                  padding={'none'}
                >
                  {textFilter.find((t) => t.id === headCell.id)?.value && (
                    <Chip
                      className={'chip-head'}
                      label={handleTextFilter(headCell.id)}
                      onClick={(e: any) => handleClick(e, headCell.id)}
                      onDelete={() => handleDeleteChip(headCell.id)}
                      variant='outlined'
                    />
                  )}
                </TableCell>
              )}
              {columnsAmountNull
                .filter((el) => el === headCell.order)
                .map((i, indexMap) => (
                  <TableCell
                    classes={{ root: 'table-cell' }}
                    key={indexMap}
                    style={{ paddingBottom: '2px', top: 103 }}
                    padding={'none'}
                  ></TableCell>
                ))}
            </Fragment>
          ))}
        </TableRow>
      )}
    </TableHead>
  )
}

const CustomTable = (props: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>('')
  const [filter, setFilter] = useState<Filter[]>([])
  const [columns, setColumns] = useState<Columns[]>([])
  const [columnsCollapse, setColumnsCollapse] = useState<ColumnsCollapse[]>([])
  const [columnsAmountNull, setColumnsAmountNull] = useState<number[]>([])
  const { selectedSale } = useSelector((state: ApplicationStore) => state.feedlot)
  const { selectedLot } = useSelector((state: ApplicationStore) => state.lot)
  const dispatch = useDispatch()

  useEffect(() => {
    const columnsAux: Columns[] = []
    const columnsNull: number[] = []
    let pos = 0

    props.columns.map((c: any) => {
      if (c.collapse) {
        columnsAux.push(...c.columns)
        pos = c.columns[c.columns.length - 1].order
      } else {
        columnsNull.push(pos)
      }
    })

    setColumnsAmountNull(columnsNull)
    setColumns(
      columnsAux.sort((a, b) => {
        if (a.order > b.order) {
          return 1
        } else if (a.order < b.order) {
          return -1
        } else {
          return 0
        }
      })
    )
    setColumnsCollapse(
      props.columns.sort((a, b) => {
        if (a.order > b.order) {
          return 1
        } else if (a.order < b.order) {
          return -1
        } else {
          return 0
        }
      })
    )
  }, [props.columns])

  // verifies if there is a selected sale (from PON calendar) to filters table
  useEffect(() => {
    if (selectedSale)
      setFilter([
        ...filter.filter((fil) => fil.id !== 'sale'),
        { id: 'sale', condition: '=', value: selectedSale, type: 'numeric' }
      ])
  }, [selectedSale])

  const handleFilterData = (
    event: React.ChangeEvent<unknown>,
    property: any,
    condition: string,
    value: any,
    fieldType: 'string' | 'numeric'
  ) => {
    setFilter([
      ...filter.filter((fil) => fil.id !== property),
      { id: property, condition, value, type: fieldType }
    ])
  }
  const handleColumnsViewer = (event: React.MouseEvent<unknown>, info: ColumnsCollapse) => {
    props.onCollapseColumns(info)
  }

  // Clears selected sale (from PON calendar)
  const handleResetFilterData = (property: any) => {
    dispatch(setSelectedSale())
    setFilter(filter.filter((fil) => fil.id !== property))
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const setDataCsv = () => {
    const data = filterData(props.data, filter)
    return data
  }
  const setHeaderCsv = () => {
    const header: HeaderCSV[] = []
    props.columns.map((columnGroups, index) => {
      columnGroups.columns.map((col) => {
        if (col.active) header.push({ label: t(col.label), key: col.id })
      })
    })
    return header
  }

  const csvReport = {
    data: setDataCsv(),
    headers: setHeaderCsv(),
    filename: 'Relatorio ' + selectedLot?.description + '.csv'
  }

  return (
    <div className={'custom-table'}>
      <div className='download-group'>
        <CSVLink {...csvReport}>
          <div className={'download-csv'}>
            <Icon path={mdiFileDownloadOutline} size={1.2} /> <text>{t('export_to_csv')}</text>
          </div>
        </CSVLink>
        {/* <CSVLink {...csvReport}>
          <div className={'download-csv'}>
            <Icon path={mdiFileDownloadOutline} size={1.2} /> <text>{t('export_to_pdf')}</text>
          </div>
        </CSVLink> */}
      </div>
      <Paper>
        <TableContainer className={'table-container'}>
          <Table stickyHeader aria-label='sticky table'>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onActiveColumns={handleColumnsViewer}
              rowCount={props.data.length}
              columns={columns}
              columnsCollapse={columnsCollapse}
              columnsAmountNull={columnsAmountNull}
              theme={props.theme}
              onFilterData={handleFilterData}
              onResetFilterData={handleResetFilterData}
              filters={filter}
            />
            <TableBody>
              {stableSort(filterData(props.data, filter), getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map(
                        (k: any, indexCol: any) =>
                          row[k.id] !== undefined && (
                            <Fragment key={indexCol}>
                              {k.active && (
                                <TableCell
                                  classes={{ root: 'table-cell' }}
                                  align='left'
                                  key={indexCol}
                                >
                                  {row[k.id]}
                                </TableCell>
                              )}
                              {columnsAmountNull
                                .filter((el) => el === k.order)
                                .map((i, indexMap) => (
                                  <TableCell
                                    classes={{ root: 'table-cell' }}
                                    align='left'
                                    key={indexMap + 'null'}
                                  ></TableCell>
                                ))}
                            </Fragment>
                          )
                      )}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStoreToProps, mapDispatchToProps)(CustomTable)
