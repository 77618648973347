import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

// Resources
import './styles.scss'

// Types
import {
  LotSalesParams,
  FeedlotSalesParams,
  FeedlotZootechnicalParams,
  LotZootechnicalParams
} from 'api/params/types'
import {
  FeedlotNutritionGridData,
  FeedlotNutritionGridActions,
  DailyFeedlotGridData,
  DailyFeedlotGridActions,
  DailyFeedlotDataRef,
  FeedlotCarcassYieldGridData,
  FeedlotCarcassYieldGridActions
} from './FeedlotParams/types'
import {
  FormDataRef as FeedlotParamsFormDataRef,
  NutritionDataRef as FeedlotNutritionDataRef,
  CarcassYieldDataRef as FeedlotCarcassYieldDataRef
} from './FeedlotParams/types'
import {
  Lot,
  LotNutritionGridData,
  LotNutritionGridActions,
  NutritionDataRef as LotNutritionDataRef,
  LotCarcassYieldGridData,
  LotCarcassYieldGridActions,
  CarcassYieldDataRef as LotCarcassYieldDataRef
} from './LotParams/types'

// Components
import { Tabs, Tab, CircularProgress } from '@material-ui/core'
import { TabPanel, a11yProps } from '../helpers'
import Button from '@material-ui/core/Button'
import FeedlotParams from './FeedlotParams'
import FeedlotParamsFormData from './FeedlotParams/FormData'
import LotParams from './LotParams'
import LotParamsFormData from './LotParams/FormData'
import { FormDataRef as LotParamsFormRef } from './LotParams/types'
import FeedlotNutritionData from './FeedlotParams/NutritionData'
import FeedlotCarcassYieldData from './FeedlotParams/CarcassYieldData'
import LotCarcassYieldData from './LotParams/CarcassYieldData'

import DailyFeedlotData from './FeedlotParams/DailyFeedlotData'
import LotNutritionData from './LotParams/NutritionData'
import Alert, { AlertHandles } from 'components/shared/Alert'
import WarningIcon from '@material-ui/icons/Warning'

// #region DOCS

// SALE PARAMS FIELDS
// web - services - db - entity

// Feedlot maximum time (traditional method)*
// Tempo máximo confinado (método tradicional)*
// maxTimeMt - dif_mt - difmt - mtDif

// Feedlot maximum time
// Tempo máximo confinado
// max_time - dif - dif - dif

// Animals minimum number per sale
// Número mínimo de animais por venda
// numAnimals - sale_animals_number - numanimaisvenda - saleAnimalsNumber

// Minimum weight kg
// Peso mínimo kg
// minimumSbw - minimum_sbw - limsbw - sbwLimit

//#endregion

const Parameters = () => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = React.useState(0)
  const [isSaving, setIsSaving] = React.useState(false)

  // Feedlot consts
  const [feedlotFormErrorsMessages, setFeedlotFormErrorsMessages] = React.useState<any>({})
  const feedlotParamsFormRef = useRef<FeedlotParamsFormDataRef>(null)
  //Sales Params Feedlot
  const [currentFeedlotSalesParams, setCurrentFeedlotSalesParams] = React.useState<
    FeedlotSalesParams | undefined
  >()
  const [currentFeedlotZootechnicalParams, setCurrentFeedlotZootechnicalParams] = React.useState<
    FeedlotZootechnicalParams | undefined
  >()
  //Nutrition Feedlot
  const [feedlotNutritionsGridData, setFeedlotNutritionsGridData] = React.useState<
    FeedlotNutritionGridData[]
  >([])
  const feedlotNutritionsDataRef = useRef<FeedlotNutritionDataRef>(null)
  const [feedlotNutritionGridActions, setFeedlotNutritionGridActions] =
    React.useState<FeedlotNutritionGridActions>({})
  //Daily Feedlot
  const dailyFeedlotDataRef = useRef<DailyFeedlotDataRef>(null)
  const [dailyFeedlotGridData, setDailyFeedlotGridData] = React.useState<DailyFeedlotGridData[]>([])
  const [dailyFeedlotGridActions, setDailyFeedlotGridActions] =
    React.useState<DailyFeedlotGridActions>({})
  //Carcass Yield Feedlot
  const [feedlotCarcassYieldGridData, setFeedlotCarcassYieldGridData] = React.useState<
    FeedlotCarcassYieldGridData[]
  >([])
  const feedlotCarcassYieldDataRef = useRef<FeedlotCarcassYieldDataRef>(null)
  const [feedlotCarcassYieldGridActions, setFeedlotCarcassYieldGridActions] =
    React.useState<FeedlotCarcassYieldGridActions>({})

  // Lot consts
  const [lots, setLots] = React.useState<Lot[]>([])
  const [selectedLot, setSelectedLot] = React.useState<Lot | undefined>()
  const [currentLotSalesParams, setCurrentLotSalesParams] = React.useState<
    LotSalesParams | undefined
  >()
  const [currentLotZootechnicalParams, setCurrentLotZootechnicalParams] = React.useState<
    LotZootechnicalParams | undefined
  >()
  const [lotFormErrorsMessages, setLotFormErrorsMessages] = React.useState<any>({})
  const lotParamsFormRef = useRef<LotParamsFormRef>(null)
  const [lotNutritionsGridData, setLotNutritionsGridData] = React.useState<LotNutritionGridData[]>(
    []
  )
  const lotNutritionsDataRef = useRef<LotNutritionDataRef>(null)
  const [lotNutritionGridActions, setLotNutritionGridActions] =
    React.useState<LotNutritionGridActions>({})
  //Carcass Yield Lot
  const [lotCarcassYieldGridData, setLotCarcassYieldGridData] = React.useState<
    LotCarcassYieldGridData[]
  >([])
  const lotCarcassYieldDataRef = useRef<LotCarcassYieldDataRef>(null)
  const [lotCarcassYieldGridActions, setLotCarcassYieldGridActions] =
    React.useState<LotCarcassYieldGridActions>({})

  const successAlertRef = useRef<AlertHandles>(null)
  const errorAlertRef = useRef<AlertHandles>(null)
  const warningAlertRef = useRef<AlertHandles>(null)

  // #region UI handles

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const hasParamsErrors = (tab: 'feedlot' | 'lot') => {
    switch (tab) {
      case 'feedlot':
        return Object.keys(feedlotFormErrorsMessages).length > 0 ? (
          <WarningIcon color='error' />
        ) : (
          <></>
        )

      case 'lot':
        return Object.keys(lotFormErrorsMessages).length > 0 ? <WarningIcon color='error' /> : <></>
    }
  }

  //#endregion

  const handleSubmit = async () => {
    try {
      setIsSaving(true)
      if (currentTab == 0) {
        await feedlotParamsFormRef.current?.submit()
        await feedlotNutritionsDataRef.current?.submit()
        await feedlotCarcassYieldDataRef.current?.submit()
        await dailyFeedlotDataRef.current?.submit()
      } else if (currentTab == 1) {
        await lotParamsFormRef.current?.submit()
        await lotNutritionsDataRef.current?.submit()
        await lotCarcassYieldDataRef.current?.submit()
      }
      successAlertRef.current?.openAlert()
    } catch (error: any) {
      if (!(error instanceof Yup.ValidationError)) {
        console.log(error)
        errorAlertRef.current?.openAlert()
      } else {
        warningAlertRef.current?.openAlert()
      }
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className='params'>
      <FeedlotParamsFormData
        ref={feedlotParamsFormRef}
        currentSalesParams={currentFeedlotSalesParams}
        setCurrentSalesParams={setCurrentFeedlotSalesParams}
        currentZootechnicalParams={currentFeedlotZootechnicalParams}
        setCurrentZootechnicalParams={setCurrentFeedlotZootechnicalParams}
        setFormErrorsMessages={setFeedlotFormErrorsMessages}
      />
      <LotParamsFormData
        ref={lotParamsFormRef}
        setLots={setLots}
        selectedLot={selectedLot}
        setSelectedLot={setSelectedLot}
        currentSalesParams={currentLotSalesParams}
        setCurrentSalesParams={setCurrentLotSalesParams}
        currentZootechnicalParams={currentLotZootechnicalParams}
        setCurrentZootechnicalParams={setCurrentLotZootechnicalParams}
        setFormErrorsMessages={setLotFormErrorsMessages}
      />
      <FeedlotNutritionData
        ref={feedlotNutritionsDataRef}
        nutritions={feedlotNutritionsGridData}
        setNutritions={setFeedlotNutritionsGridData}
        nutritionGridActions={feedlotNutritionGridActions}
        setNutritionGridActions={setFeedlotNutritionGridActions}
      />
      <FeedlotCarcassYieldData
        ref={feedlotCarcassYieldDataRef}
        carcassYields={feedlotCarcassYieldGridData}
        setCarcassYields={setFeedlotCarcassYieldGridData}
        carcassYieldGridActions={feedlotCarcassYieldGridActions}
        setCarcassYieldGridActions={setFeedlotCarcassYieldGridActions}
      />
      <LotCarcassYieldData
        ref={lotCarcassYieldDataRef}
        carcassYields={lotCarcassYieldGridData}
        setCarcassYields={setLotCarcassYieldGridData}
        carcassYieldGridActions={lotCarcassYieldGridActions}
        setCarcassYieldGridActions={setLotCarcassYieldGridActions}
      />
      <LotNutritionData
        ref={lotNutritionsDataRef}
        selectedLot={selectedLot}
        nutritions={lotNutritionsGridData}
        setNutritions={setLotNutritionsGridData}
        nutritionGridActions={lotNutritionGridActions}
        setNutritionGridActions={setLotNutritionGridActions}
      />
      <DailyFeedlotData
        ref={dailyFeedlotDataRef}
        dailyFeedlot={dailyFeedlotGridData}
        setDailyFeedlot={setDailyFeedlotGridData}
        dailyFeedlotGridActions={dailyFeedlotGridActions}
        setDailyFeedlotGridActions={setDailyFeedlotGridActions}
      />
      <Alert ref={successAlertRef} type='success' />
      <Alert ref={errorAlertRef} type='error' />
      <Alert ref={warningAlertRef} type='warning' />
      <div className='params-row'>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          classes={{
            root: 'params-tab',
            indicator: 'params-tab-indicator'
          }}
        >
          <Tab label={t('feedlot:feedlot')} icon={hasParamsErrors('feedlot')} {...a11yProps(0)} />
          <Tab label={t('lot:lot')} icon={hasParamsErrors('lot')} {...a11yProps(1)} />
        </Tabs>
        <div className='params-row'>
          <div className='params-header'>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              onClick={handleSubmit}
              disabled={isSaving}
              startIcon={
                isSaving ? (
                  <CircularProgress style={{ maxWidth: '1.5rem', maxHeight: '1.5rem' }} />
                ) : (
                  <div />
                )
              }
            >
              <span className='button-text'>{t('save')}</span>
            </Button>
          </div>
        </div>

        <TabPanel value={currentTab} index={0}>
          <FeedlotParams
            currentSalesParams={currentFeedlotSalesParams}
            setCurrentSalesParams={setCurrentFeedlotSalesParams}
            currentZootechnicalParams={currentFeedlotZootechnicalParams}
            setCurrentZootechnicalParams={setCurrentFeedlotZootechnicalParams}
            formValidationMessages={feedlotParamsFormRef.current?.formValidationMessages}
            formErrorMessages={feedlotFormErrorsMessages}
            nutritions={feedlotNutritionsGridData}
            setNutritions={setFeedlotNutritionsGridData}
            nutritionGridActions={feedlotNutritionGridActions}
            setNutritionGridActions={setFeedlotNutritionGridActions}
            dailyFeedlot={dailyFeedlotGridData}
            setDailyFeedlot={setDailyFeedlotGridData}
            dailyFeedlotGridActions={dailyFeedlotGridActions}
            setDailyFeedlotGridActions={setDailyFeedlotGridActions}
            carcassYields={feedlotCarcassYieldGridData}
            setCarcassYields={setFeedlotCarcassYieldGridData}
            carcassYieldGridActions={feedlotCarcassYieldGridActions}
            setCarcassYieldGridActions={setFeedlotCarcassYieldGridActions}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <LotParams
            lots={lots}
            selectedLot={selectedLot}
            setSelectedLot={setSelectedLot}
            currentSalesParams={currentLotSalesParams}
            setCurrentSalesParams={setCurrentLotSalesParams}
            currentZootechnicalParams={currentLotZootechnicalParams}
            setCurrentZootechnicalParams={setCurrentLotZootechnicalParams}
            formValidationMessages={lotParamsFormRef.current?.formValidationMessages}
            formErrorMessages={lotFormErrorsMessages}
            nutritions={lotNutritionsGridData}
            setNutritions={setLotNutritionsGridData}
            nutritionGridActions={lotNutritionGridActions}
            setNutritionGridActions={setLotNutritionGridActions}
            carcassYields={lotCarcassYieldGridData}
            setCarcassYields={setLotCarcassYieldGridData}
            carcassYieldGridActions={lotCarcassYieldGridActions}
            setCarcassYieldGridActions={setLotCarcassYieldGridActions}
          />
        </TabPanel>
      </div>
    </div>
  )
}

export default Parameters
