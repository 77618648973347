import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Resources
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'

// Components
// Methods
import { getSelectedPen } from 'store/pen/actions'
import { useTranslation } from 'react-i18next'
import CustomSelectMultipleSublevel from 'components/shared/CustomSelectMultipleSublevel'
import LotsSummaryTable from 'components/tables/lot/LotsSummaryTable'
import CardSalesButtons from 'components/shared/CardSalesButtons'
import CustomSelectMultiple from 'components/shared/CustomSelectMultiple'
import { setColumnsLot } from 'store/main/actions'
import Loading from 'components/shared/Loading'

export type SelectList = {
  id: number
  label: string
  active: boolean
}
export type Columns = {
  id: string
  category: string
  label: string
  active: boolean
}
const LotsSummary: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)
  const { lots, lotLoading } = useSelector((state: ApplicationStore) => state.lot)
  const { pens, selectedPen, penLoading } = useSelector((state: ApplicationStore) => state.pen)
  const { columnsLot } = useSelector((state: ApplicationStore) => state.main)

  const [fields, setFields] = useState<Columns[]>(columnsLot)
  const [listGroupLots, setListGroupLots] = useState<any[]>([])
  const [listActiveLots, setListActiveLots] = useState<any[]>([])

  const [sales, setSales] = useState(1)

  useEffect(() => {
    if (selectedFeedlot) {
      dispatch(getSelectedPen(selectedFeedlot.id))
    }
  }, [selectedFeedlot])

  useEffect(() => {
    if (selectedPen && !penLoading) {
      const auxListLot: any[] = []
      const auxListLotsActive: any[] = []

      pens.map((p) => {
        if (selectedPen.id == p.id) {
          auxListLot.push({
            id: p.id,
            label: p.code,
            active: true,
            indeterminate: false,
            children: p.lot.map((l) => {
              auxListLotsActive.push(l.id)
              return {
                id: l.id,
                active: true,
                label: l.description
              }
            })
          })
        } else {
          auxListLot.push({
            id: p.id,
            label: p.code,
            active: false,
            indeterminate: false,
            children: p.lot.map((l) => {
              return {
                id: l.id,
                active: false,
                label: l.description
              }
            })
          })
        }
      })
      setListGroupLots(auxListLot)
      setListActiveLots(auxListLotsActive)
    } else if (selectedPen == undefined) {
      setListGroupLots([])
      setListActiveLots([])
    }
  }, [penLoading])

  const getSalesDate = (sale: number) => {
    setSales(sale)
  }
  const handleChangeLot = (pen: string, lot: string) => {
    const listLots = listGroupLots
    for (const item of listLots) {
      if (item.id == pen) {
        for (const chil of item.children) {
          if (chil.id == lot) {
            chil.active = !chil.active
            break
          }
        }
        const count = item.children.filter((c: any) => c.active).length
        if (count == 0) {
          item.active = false
          item.indeterminate = false
        } else if (count == item.children.length) {
          item.active = true
          item.indeterminate = false
        } else {
          item.active = false
          item.indeterminate = true
        }
        break
      }
    }
    setListGroupLots(listLots)
    const aux: any[] = []
    listLots.map((p) => {
      aux.push(...p.children.filter((o: any) => o.active).map((f: any) => f.id))
    })
    setListActiveLots(aux)
  }
  const handleChangePen = (pen: string | number) => {
    const listLots = listGroupLots
    for (const listPen of listLots) {
      if (listPen.id == pen) {
        listPen.active = !listPen.active
        listPen.indeterminate = false

        if (listPen.active == true) {
          for (const chil of listPen.children) {
            chil.active = true
          }
        } else {
          for (const chil of listPen.children) {
            chil.active = false
          }
        }
        break
      }
    }
    setListGroupLots(listLots)

    const aux: any[] = []
    listLots.map((p) => {
      aux.push(...p.children.filter((o: any) => o.active).map((f: any) => f.id))
    })
    setListActiveLots(aux)
  }

  const handleFields = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected: string[] = event.target.value as string[]

    const newColumnsConfig: Columns[] = []
    fields.map((c) => {
      if (selected.find((d) => d === c.id)) {
        newColumnsConfig.push({
          id: c.id,
          label: c.label,
          category: c.category,
          active: true
        })
      } else
        newColumnsConfig.push({
          id: c.id,
          label: c.label,
          category: c.category,
          active: false
        })
    })
    setFields(newColumnsConfig)
    dispatch(setColumnsLot(newColumnsConfig))
  }

  return (
    <div className={'lot-summary-body'}>
      <div className={'lot-summary-card'}>
        <div className={'lot-summary-column'} style={{ width: '75%' }}>
          <div className={'lot-summary-row'}>
            <text className={'title-page'}>{t('compare_lots')}</text>
          </div>
          <div className={'lot-summary-row'}>
            <div className='group-select'>
              <CustomSelectMultipleSublevel
                textFixed={t('feedlot:pen_lot')}
                onChangeValue={handleChangeLot}
                onChangeValuePen={handleChangePen}
                value={listGroupLots}
                activeLots={listActiveLots}
              />
            </div>
            <CardSalesButtons
              t={t}
              selectedSales={sales}
              handleSalesChange={(sales) => getSalesDate(sales)}
            />
            <CustomSelectMultiple
              textFixed={t('fields_selected')}
              onChangeValue={handleFields}
              value={fields}
            />
          </div>
        </div>
      </div>
      <div className={'lot-summary-card'}>
        {!lotLoading ? (
          <div className='lot-summary-table'>
            {listActiveLots.map((list, index) => (
              <LotsSummaryTable
                key={index}
                dataLot={lots.find((f) => f.id == Number(list))}
                saleSelected={sales}
                firstLot={index == 0 ? true : false}
                columnsEconomic={fields
                  .filter((f) => f.category == 'profit' && f.active)
                  .map((m: any) => m.id)}
                columnsEnvironment={fields
                  .filter((f) => f.category == 'environmentalData' && f.active)
                  .map((m: any) => m.id)}
                columnsZootechnical={fields
                  .filter((f) => f.category == 'zootechnicalData' && f.active)
                  .map((m: any) => m.id)}
                numberOfColumns={fields.filter((f) => f.active).length + 1}
              />
            ))}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}

export default LotsSummary
