import { LotQueryFields } from 'api/lot/types'
import { AnimalQueryFields, AnimalZootechnicalDailyDataFields } from 'api/animal/types'

export enum DateType {
  start = 'start',
  end = 'end'
}

export const lotQuery: LotQueryFields = {
  initDate: true
}

export const animalQuery: AnimalQueryFields = {
  id: true,
  earring: true
}

export const processedWeighingsQuery: AnimalQueryFields = {
  id: true,
  earring: true,
  weighing: {
    day: true,
    weight: true,
    type: true
  }
}

export const animalZootechnicalDailyDataQuery: AnimalZootechnicalDailyDataFields = {
  time: true,
  date: true,
  weight: true,
  dwg: false,
  feedConversion: false,
  ingestion: false
}

export interface AnimalWeighing {
  id?: number
  earring: string
  weighings: {
    time: number
    date: Date
    weight: number
    type: string
    color: string
  }[]
}
