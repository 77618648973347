import { takeLatest, all } from 'redux-saga/effects'
import { FeedlotSlaughterhouseConstants } from './types'
import {
  workerDeleteFeedlotSlaughterhouse,
  workerGetFeedlotConfiguration,
  workerGetFeedlotSlaughterhouses,
  workerSaveFeedlotConfiguration,
  workerSaveFeedlotSlaughterhouse
} from './sagas'

export function* watcherGetFeedlotSlaughterhouses() {
  yield takeLatest(
    FeedlotSlaughterhouseConstants.ACTION_GET_FEEDLOT_SLAUGHTERHOUSES,
    workerGetFeedlotSlaughterhouses
  )
}

export function* watcherSaveFeedlotSlaughterhouse() {
  yield takeLatest(
    FeedlotSlaughterhouseConstants.ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE,
    workerSaveFeedlotSlaughterhouse
  )
}
export function* watcherDeleteFeedlotSlaughterhouse() {
  yield takeLatest(
    FeedlotSlaughterhouseConstants.ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE,
    workerDeleteFeedlotSlaughterhouse
  )
}

export function* watcherGetFeedlotConfiguration() {
  yield takeLatest(
    FeedlotSlaughterhouseConstants.ACTION_GET_FEEDLOT_CONFIGURATION,
    workerGetFeedlotConfiguration
  )
}

export function* watcherSaveFeedlotConfiguration() {
  yield takeLatest(
    FeedlotSlaughterhouseConstants.ACTION_SAVE_FEEDLOT_CONFIGURATION,
    workerSaveFeedlotConfiguration
  )
}

export default function* authRootSaga() {
  yield all([
    watcherGetFeedlotSlaughterhouses(),
    watcherSaveFeedlotSlaughterhouse(),
    watcherGetFeedlotConfiguration(),
    watcherSaveFeedlotConfiguration(),
    watcherDeleteFeedlotSlaughterhouse()
  ])
}
