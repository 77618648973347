export enum UserNotificationType {
  Default = 'Default',
  AnimalsWithDelayedWeighing = 'AnimalsWithDelayedWeighingNotificationDto',
  AnimalsNotProcessed = 'AnimalsNotProcessedNotificationDto',
  AnimalsWithRecurrenceOfWeighing = 'AnimalsWithRecurrenceOfWeighingNotificationDto'
}

export interface AnimalsWithDelayedWeighingNotificationData {
  __typename: UserNotificationType.AnimalsWithDelayedWeighing
  animalsCount: number
}

export interface AnimalsNotProcessedNotificationData {
  __typename: UserNotificationType.AnimalsNotProcessed
  animalsCount: number
}

export interface AnimalsWithRecurrenceOfWeighingNotificationData {
  __typename: UserNotificationType.AnimalsWithRecurrenceOfWeighing
  animalsCount: number
  animals: { animalId: number; earring: string; animalTag: string }
}

export type UserNotificationCustomData =
  | AnimalsWithDelayedWeighingNotificationData
  | AnimalsNotProcessedNotificationData
  | AnimalsWithRecurrenceOfWeighingNotificationData

export interface UserNotificationData {
  createDate: string
  viewed: boolean
  customData: UserNotificationCustomData
}

export interface UserNotification {
  id: bigint
  data: UserNotificationData
}
