import { createMuiTheme } from '@material-ui/core/styles'
import colors, { Colors } from './variables/colors'
import { Themes } from 'store/main/types'

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    // common: {
    //   white: colors(Themes.LIGHT, Colors.white),
    //   black: colors(Themes.LIGHT, Colors.black)
    // },
    primary: {
      main: colors(Themes.LIGHT, Colors.accentDark)
    },
    secondary: {
      main: colors(Themes.LIGHT, Colors.accent)
    },
    background: {
      default: colors(Themes.LIGHT, Colors.white)
    },
    error: {
      main: colors(Themes.LIGHT, Colors.error)
    },
    info: {
      main: colors(Themes.LIGHT, Colors.info)
    },
    warning: {
      main: colors(Themes.LIGHT, Colors.warning),
      contrastText: colors(Themes.LIGHT, Colors.warning)
    },
    success: {
      main: colors(Themes.LIGHT, Colors.success),
      contrastText: colors(Themes.LIGHT, Colors.success)
    },
    action: {
      active: colors(Themes.LIGHT, Colors.grayMedium),
      hover: colors(Themes.LIGHT, Colors.ghostWhite),
      selected: colors(Themes.LIGHT, Colors.white)
      // disabled: colors(Themes.LIGHT, Colors.accent),
      // disabledBackground: colors(Themes.LIGHT, Colors.accent),
      // focus: colors(Themes.LIGHT, Colors.accent)
    }
  }
})

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors(Themes.LIGHT, Colors.black)
    },
    background: {
      default: colors(Themes.LIGHT, Colors.grayDark)
    },
    secondary: {
      main: colors(Themes.LIGHT, Colors.white)
    },
    error: {
      main: colors(Themes.LIGHT, Colors.error)
    },
    text: {
      primary: colors(Themes.LIGHT, Colors.white)
    }
  }
})
