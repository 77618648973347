import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

// Types
import { ApplicationStore } from 'store/types'
import { NextSale } from 'store/feedlot/types'
import { AlertModalTypes, InfoModal } from 'store/main/types'

// Methods
import { setInfoModalMessage } from 'store/main/actions'
import { getSelectedPen } from 'store/pen/actions'
import { getSelectedLot } from 'store/lot/actions'

// Components
import TrafficLightContainer from 'components/shared/TrafficLightContainer'
import { getFeedlotNextSales, setSelectedSale } from 'store/feedlot/actions'

// Components
import Draggable from 'react-draggable'
import { Button, Divider } from '@material-ui/core'
import CardSalesButtons from 'components/shared/CardSalesButtons'
import Loading from 'components/shared/Loading'
import CustomBoxButton from 'components/shared/CustomBoxButton'

// Resources
import './styles.scss'
import { useTranslation } from 'react-i18next'

const PonCalendarDetails: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    selectedFeedlot,
    feedlotNextSalesLoading,
    scaledLots,
    nextSaleLots,
    initialLots,
    selectedSaleType,
    initialLotsAnimalsNumber,
    scaledLotsAnimalsNumber,
    nextSaleLotsAnimalsNumber
  } = useSelector((state: ApplicationStore) => state.feedlot)
  const [sales, setSales] = useState<number>(1)
  const previusSelectedSale = useRef(1)
  const history = useHistory()
  const { nextSalesArgs } = useSelector((state: ApplicationStore) => state.feedlot)
  const { penLoading } = useSelector((state: ApplicationStore) => state.pen)

  let scaledMaxDate: any = new Date(
    new Date().setDate(new Date().getDate() + nextSalesArgs.scaledLotsMaxSaleTime)
  )
  scaledMaxDate = scaledMaxDate.getDate() + '/' + (Number(scaledMaxDate.getMonth()) + 1)
  let nextSaleMaxDate: any = new Date(
    new Date().setDate(new Date().getDate() + nextSalesArgs.nextLotsMaxSaleTime)
  )
  nextSaleMaxDate = nextSaleMaxDate.getDate() + '/' + (Number(nextSaleMaxDate.getMonth()) + 1)

  const scaledLotsButtonText =
    scaledLots?.length +
    ' ' +
    (scaledLots && scaledLots?.length === 1 ? t('lot:lot') : t('lot:lots')) +
    ' ' +
    t('until') +
    ' ' +
    scaledMaxDate
  const nextSaleLotsButtonText =
    nextSaleLots?.length +
    ' ' +
    (nextSaleLots && nextSaleLots?.length === 1 ? t('lot:lot') : t('lot:lots')) +
    ' ' +
    t('until') +
    ' ' +
    nextSaleMaxDate

  const initialLotsButtonText =
    initialLots?.length +
    ' ' +
    t('lot:lots') +
    ' ' +
    t('with') +
    ' ' +
    t('less') +
    ' ' +
    t('of') +
    ' ' +
    nextSalesArgs.maxFeedlotTime +
    ' ' +
    t('days')

  useEffect(() => {
    if (sales !== selectedSaleType) setSales(selectedSaleType)
  }, [selectedSaleType])

  useEffect(() => {
    if (!selectedFeedlot?.lots?.length) {
      const infoModal: InfoModal = {
        type: AlertModalTypes.info,
        title: t('attention'),
        message: t('feedlot:none_active_lot_for_this_feedlot'),
        open: true
      }
      dispatch(setInfoModalMessage(infoModal))
    }
  }, [selectedFeedlot])

  useEffect(() => {
    if (sales !== previusSelectedSale.current)
      dispatch(getFeedlotNextSales(selectedFeedlot?.id, sales))
  }, [sales])

  const redirectToAnimals = (lot: NextSale) => {
    if (selectedFeedlot) dispatch(getSelectedPen(selectedFeedlot?.id, undefined, lot?.lotId))
    if (selectedFeedlot) dispatch(getSelectedLot(selectedFeedlot?.id, lot?.lotId))
    dispatch(setSelectedSale(lot.sale ?? 1))
    if (!penLoading) history.push('/animals')
  }

  return (
    <div className='pon-calendar-details-body'>
      <div className='title-container'>
        <p className={'title'}>{t('pon_calendar')}</p>
        <small>{t('PON_mean')}</small>
      </div>
      <div className='sales-buttons-line'>
        <CardSalesButtons
          t={t}
          selectedSales={sales}
          handleSalesChange={(sale) => {
            previusSelectedSale.current = sales
            setSales(sale)
          }}
        />
      </div>
      <div className={'pon-calendar'}>
        {feedlotNextSalesLoading ? (
          <Loading />
        ) : (
          <div className={'sales'}>
            <div className='scaled-lots-container'>
              <div className='traffic-red-light'>
                <CustomBoxButton
                  color={'red'}
                  data={scaledLots}
                  dataNumber={scaledLotsAnimalsNumber}
                  buttonText={scaledLotsButtonText}
                />
              </div>
              {scaledLots?.length ? (
                scaledLots?.map((l: NextSale) => {
                  return (
                    <Draggable disabled={true} key={l.lotId}>
                      <Button
                        key={l.lotId}
                        variant='outlined'
                        className='scaled-lots'
                        onClick={() => redirectToAnimals(l)}
                      >
                        <span className='button-title'>{l.description + ' '}</span>
                        <small>{t('sale') + ' ' + l.sale}</small>
                        <small>
                          {l.animalsNumber > 1
                            ? l.animalsNumber + ' ' + t('animal:animals')
                            : l.animalsNumber + ' ' + t('animal:animal')}
                        </small>
                      </Button>
                    </Draggable>
                  )
                })
              ) : (
                <div className='none-lot-txt'>{t('lot:none_lot')}</div>
              )}
            </div>
            <Divider />
            <div className='next-sale-lots-container'>
              <div className='traffic-yellow-light'>
                <CustomBoxButton
                  color={'yellow'}
                  data={nextSaleLots}
                  dataNumber={nextSaleLotsAnimalsNumber}
                  buttonText={nextSaleLotsButtonText}
                />
              </div>
              {nextSaleLots?.length ? (
                nextSaleLots?.map((l: NextSale) => {
                  return (
                    <Draggable disabled={true} key={l.lotId}>
                      <Button
                        key={l.lotId}
                        variant='outlined'
                        className='next-sale-lots'
                        onClick={() => redirectToAnimals(l)}
                      >
                        <span className='button-title'>{l.description + ' '}</span>
                        <small>{t('sale') + ' ' + l.sale}</small>
                        <small>
                          {l.animalsNumber > 1
                            ? l.animalsNumber + ' ' + t('animal:animals')
                            : l.animalsNumber + ' ' + t('animal:animal')}
                        </small>
                      </Button>
                    </Draggable>
                  )
                })
              ) : (
                <div className='none-lot-txt'>{t('lot:none_lot')}</div>
              )}
            </div>
            <Divider />
            <div className='initial-lots-container'>
              <div className='traffic-green-light'>
                <CustomBoxButton
                  color={'green'}
                  data={initialLots}
                  dataNumber={initialLotsAnimalsNumber}
                  buttonText={initialLotsButtonText}
                />
              </div>
              {initialLots?.length ? (
                initialLots?.map((l: NextSale) => {
                  return (
                    <Draggable disabled={true} key={l.lotId}>
                      <Button
                        key={l.lotId}
                        variant='outlined'
                        className='initial-lots'
                        onClick={() => redirectToAnimals(l)}
                      >
                        <span className='button-title'>{l.description + ' '}</span>
                        <small>
                          {l.animalsNumber > 1
                            ? l.animalsNumber + ' ' + t('animal:animals')
                            : l.animalsNumber + ' ' + t('animal:animal')}
                        </small>
                      </Button>
                    </Draggable>
                  )
                })
              ) : (
                <div className='none-lot-txt'>{t('lot:none_lot')}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PonCalendarDetails
