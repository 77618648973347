import { all, takeLatest } from 'redux-saga/effects'
import {
  workerDeleteFeedlotSlaughterhousePrice,
  workerGetCurrency,
  workerGetFeedlotSlaughterhousePrice,
  workerGetQuoteSlaughterhousePrice,
  workerGetWeightUnity,
  workerSaveFeedlotSlaughterhousePrice
} from './sagas'
import { EconomicConstants } from './types'

export function* watcherGetFeedlotSlaughterhousePrice() {
  yield takeLatest(
    EconomicConstants.ACTION_GET_FEEDLOT_SLAUGHTERHOUSE_PRICE,
    workerGetFeedlotSlaughterhousePrice
  )
}

export function* watcherGetQuoteSlaughterhousePrice() {
  yield takeLatest(
    EconomicConstants.ACTION_GET_QUOTE_SLAUGHTERHOUSE_PRICE,
    workerGetQuoteSlaughterhousePrice
  )
}

export function* watcherSaveFeedlotSlaughterhousePrice() {
  yield takeLatest(
    EconomicConstants.ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE_PRICE,
    workerSaveFeedlotSlaughterhousePrice
  )
}
export function* watcherDeleteFeedlotSlaughterhousePrice() {
  yield takeLatest(
    EconomicConstants.ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE_PRICE,
    workerDeleteFeedlotSlaughterhousePrice
  )
}

export function* watcherGetCurrency() {
  yield takeLatest(EconomicConstants.ACTION_GET_CURRENCY, workerGetCurrency)
}

export function* watcherGetWeightUnity() {
  yield takeLatest(EconomicConstants.ACTION_GET_WEIGHTUNITY, workerGetWeightUnity)
}

export default function* authRootSaga() {
  yield all([
    watcherGetFeedlotSlaughterhousePrice(),
    watcherGetQuoteSlaughterhousePrice(),
    watcherSaveFeedlotSlaughterhousePrice(),
    watcherDeleteFeedlotSlaughterhousePrice(),
    watcherGetCurrency(),
    watcherGetWeightUnity()
  ])
}
