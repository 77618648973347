import React from 'react'
import { Props } from './types'

const SettingsIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='user-line'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 34 34.921'
    >
      <g id='settings' transform='translate(-2.715 -2.295)'>
        <path
          id='Caminho_86'
          data-name='Caminho 86'
          d='M33.466,20.705V18.792l2.4-2.1a2.5,2.5,0,0,0,.475-3.19l-2.952-5a2.521,2.521,0,0,0-2.964-1.125L27.387,8.4a14.2,14.2,0,0,0-1.639-.938L25.11,4.308a2.5,2.5,0,0,0-2.5-2.013H16.755a2.5,2.5,0,0,0-2.5,2.013L13.616,7.46a14.358,14.358,0,0,0-1.651.938L8.989,7.322a2.5,2.5,0,0,0-.8-.074A2.5,2.5,0,0,0,6.026,8.5l-2.952,5a2.5,2.5,0,0,0,.513,3.14L5.95,18.8v1.913l-2.364,2.1a2.5,2.5,0,0,0-.513,3.19l2.952,5A2.521,2.521,0,0,0,8.99,32.136l3.039-1.025a14.2,14.2,0,0,0,1.639.938l.638,3.152a2.5,2.5,0,0,0,2.5,2.013h5.9a2.5,2.5,0,0,0,2.5-2.013l.638-3.152a14.358,14.358,0,0,0,1.651-.938l3.027,1.025a2.521,2.521,0,0,0,2.964-1.125l2.852-5a2.5,2.5,0,0,0-.513-3.14ZM31.227,29.76l-4.29-1.451a11.081,11.081,0,0,1-3.39,1.963l-.887,4.49h-5.9l-.888-4.44a11.707,11.707,0,0,1-3.377-1.963l-4.3,1.4-2.952-5,3.4-3a11.132,11.132,0,0,1,0-3.914l-3.4-3.09,2.952-5,4.29,1.451a11.081,11.081,0,0,1,3.39-1.963l.887-4.49h5.9l.888,4.44a11.707,11.707,0,0,1,3.377,1.963l4.3-1.4,2.952,5-3.4,3a11.132,11.132,0,0,1,0,3.914l3.4,3.09Z'
          transform='translate(0)'
          fill={fill}
        />
        <path
          id='Caminho_87'
          data-name='Caminho 87'
          d='M18,24.75a6.683,6.683,0,1,1,4.793-1.957A6.75,6.75,0,0,1,18,24.75ZM18,13.5A4.4,4.4,0,0,0,13.5,18,4.4,4.4,0,0,0,18,22.5,4.4,4.4,0,0,0,22.5,18,4.4,4.4,0,0,0,18,13.5Z'
          transform='translate(1.707 1.755)'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default SettingsIcon
