import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormHandles } from '@unform/core'
import { useTranslation } from 'react-i18next'

// Resources
import './styles.scss'

// Types
import { LotSalesParams, LotZootechnicalParams } from 'api/params/types'
import { Props, Lot, LotNutritionGridData, LotCarcassYieldGridData } from './types'
import { AlertModalTypes, InfoModal } from 'store/main/types'

// Components
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Form } from '@unform/web'
import { TextField } from 'unform-material-ui'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CustomMaterialTable, {
  notNullValidate,
  isPositiveValidate
} from 'components/tables/CustomMaterialTable'

// Methods
import { setInfoModalMessage } from 'store/main/actions'

const LotParams: React.FC<Props> = ({
  lots,
  selectedLot,
  setSelectedLot,
  currentSalesParams,
  setCurrentSalesParams,
  currentZootechnicalParams,
  setCurrentZootechnicalParams,
  formValidationMessages,
  formErrorMessages,
  nutritions,
  setNutritions,
  nutritionGridActions,
  setNutritionGridActions,
  carcassYields,
  setCarcassYields,
  carcassYieldGridActions,
  setCarcassYieldGridActions
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [expandedAll, setExpandedAll] = React.useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
    const aux = { ...currentSalesParams, ...currentZootechnicalParams }
    formRef.current?.setData(aux as LotSalesParams & LotZootechnicalParams)
    if (lots.length === 0) {
      const infoModal: InfoModal = {
        type: AlertModalTypes.info,
        message: t('feedlot:none_active_lot_for_this_feedlot'),
        open: true
      }
      dispatch(setInfoModalMessage(infoModal))
    }
  }, [currentSalesParams, currentZootechnicalParams, lots, dispatch])

  useEffect(() => {
    formRef.current?.setErrors(formErrorMessages)
    setExpandedAll(Object.keys(formErrorMessages).length > 0)
  }, [formErrorMessages])

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (expandedAll) {
        let aux = ''
        if (panel === 'config-panel' || 'zootechnical-panel') aux = 'sales-panel'
        else if (panel === 'sales-panel' || 'zootechnical-panel') aux = 'config-panel'
        else aux = 'zootechnical-panel'

        setExpanded(aux)
        setExpandedAll(false)
      } else setExpanded(isExpanded ? panel : false)
    }

  const handleFormChange = () => {
    const formData = formRef.current?.getData() as LotSalesParams & LotZootechnicalParams

    const saleParams = {
      dif: formData.dif,
      mtDif: formData.mtDif,
      saleAnimalsNumber: formData.saleAnimalsNumber,
      sbwLimit: formData.sbwLimit,
      truckAnimalsLimit: formData.truckAnimalsLimit,
      eccLimit: formData.eccLimit
    } as LotSalesParams
    setCurrentSalesParams({ ...currentSalesParams, ...saleParams })

    const zootechnicalParams = {
      ingestionAdjustment: formData.ingestionAdjustment,
      lossPercentage: formData.lossPercentage
    } as LotZootechnicalParams
    setCurrentZootechnicalParams({ ...currentZootechnicalParams, ...zootechnicalParams })
  }

  const renderLotsSelectList = () => {
    const lotOptions: any[] = []
    lots.map((l) => {
      const lotOption: Lot = { id: l.id, description: l.description }
      lotOptions.push(
        <MenuItem key={lotOption.id} value={lotOption.id}>
          {lotOption.description}
        </MenuItem>
      )
    })
    return lotOptions
  }

  const handleLotsSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lotId = event.target.value
    setSelectedLot(
      lotId ? lots.find((l) => l.id === Number(event.target.value as string)) : undefined
    )
  }

  const updateGridCalcFields = (nutritions: LotNutritionGridData[]) => {
    nutritions?.map((n) => {
      n.period = n.end - n.start + 1
      n.dryMatterCost = Number(((n.dietCost / n.dryMatter) * 100).toFixed(2))
    })
  }

  const addNutrition = (nutrition: LotNutritionGridData) => {
    const newNutritionId = nutritionGridActions.add?.length ?? 0
    nutrition.id = 0
    nutrition.lotId = selectedLot?.id as number
    nutrition._addId = newNutritionId
    updateGridCalcFields([nutrition])

    const nutritionsCopy = [...nutritions]
    nutritionsCopy.push(nutrition)

    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      add: [...(nutritionGridActions.add ?? []), nutrition._addId]
    })
  }

  const updateNutrition = (nutrition: LotNutritionGridData) => {
    updateGridCalcFields([nutrition])
    const nutritionsCopy = [...nutritions]
    const updatedNutritionIndex = nutritionsCopy.findIndex((n) =>
      n._addId ? n._addId === nutrition._addId : n.id === nutrition.id
    )
    nutritionsCopy.splice(updatedNutritionIndex, 1, nutrition)
    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      update: [...(nutritionGridActions.update ?? []), nutrition.id]
    })
  }

  const deleteNutrition = (nutrition: LotNutritionGridData) => {
    const nutritionsCopy = [...nutritions]
    const deletedNutritionIndex = nutritionsCopy.findIndex((n) => n.id === nutrition.id)
    const deletedNutritions = nutritionsCopy.splice(deletedNutritionIndex, 1)
    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      delete: [...(nutritionGridActions.delete ?? []), ...deletedNutritions]
    })
  }

  //#region CarcassYield

  const addCarcassYield = (carcassYield: LotCarcassYieldGridData) => {
    const newCarcassYieldId = carcassYieldGridActions.add?.length ?? 0
    carcassYield.lotId = selectedLot?.id as number
    carcassYield._addId = newCarcassYieldId

    const carcassYieldsCopy = [...carcassYields]
    carcassYieldsCopy.push(carcassYield)

    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      add: [...(carcassYieldGridActions.add ?? []), carcassYield._addId]
    })
  }

  const updateCarcassYield = (carcassYield: LotCarcassYieldGridData) => {
    const carcassYieldsCopy = [...carcassYields]
    const updatedCarcassYieldIndex = carcassYieldsCopy.findIndex(
      (n) => n._addId === carcassYield._addId
    )
    carcassYieldsCopy.splice(updatedCarcassYieldIndex, 1, carcassYield)
    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      update: [...(carcassYieldGridActions.update ?? []), carcassYield.id]
    })
  }

  const deleteCarcassYield = (carcassYield: LotCarcassYieldGridData) => {
    const carcassYieldsCopy = [...carcassYields]
    const deletedCarcassYieldIndex = carcassYieldsCopy.findIndex(
      (n) => n._addId === carcassYield._addId
    )
    const deletedCarcassYields = carcassYieldsCopy.splice(deletedCarcassYieldIndex, 1)
    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      delete: [...(carcassYieldGridActions.delete ?? []), ...deletedCarcassYields]
    })
  }

  //#endregion
  return (
    <div className='lot-params-body'>
      <FormControl id='lots-select-fc' size='small'>
        <InputLabel id='lots-label'>{t('lot:lot')}</InputLabel>
        <Select
          labelId='lots-label'
          id='lots-select'
          label={t('sort')}
          value={selectedLot?.id ?? ''}
          onChange={handleLotsSelectChange}
        >
          {renderLotsSelectList()}
        </Select>
      </FormControl>
      <div hidden={!selectedLot}>
        <Form
          ref={formRef}
          style={{ width: '100%' }}
          onSubmit={() => null}
          onChange={handleFormChange}
        >
          <ExpansionPanel
            expanded={expanded === 'config-panel' || expandedAll}
            onChange={handlePanelChange('config-panel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='config-panel-content'
              id='config-panel-header'
            >
              <Typography>{t('settings')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='form-body'>
                <TextField
                  name='mtDif'
                  label={t('feedlot:feedlot_maximum_time')}
                  helperText={formValidationMessages?.mtDif}
                  classes={{ root: 'input-margin' }}
                  className='input-margin-right'
                  type='number'
                  style={{ width: '230px' }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='dif'
                  label={t('feedlot:feedlot_maximum_time')}
                  helperText={formValidationMessages?.dif}
                  type='number'
                  style={{ width: '230px' }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'sales-panel' || expandedAll}
            onChange={handlePanelChange('sales-panel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='sales-panel-content'
              id='sales-panel-header'
            >
              <Typography>{t('sale')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='form-body'>
                <TextField
                  name='saleAnimalsNumber'
                  label={t('animal:animals_mininum_number_per_sale')}
                  helperText={formValidationMessages?.saleAnimalsNumber}
                  classes={{ root: 'input-margin' }}
                  className='input-margin-right'
                  style={{ width: '300px' }}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='sbwLimit'
                  label={`${t('animal:minimum_weight')} (${t('weight_unity_kg')})`}
                  helperText={formValidationMessages?.sbwLimit}
                  className='input-margin-right'
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='truckAnimalsLimit'
                  label={t('animal:maximum_truck_animals')}
                  helperText={formValidationMessages?.truckAnimalsLimit}
                  classes={{ root: 'input-margin' }}
                  className='input-margin-right'
                  style={{ width: '400px' }}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='eccLimit'
                  label={t('animal:animals_mininum_ecc')}
                  helperText={formValidationMessages?.eccLimit}
                  classes={{ root: 'input-margin' }}
                  className='input-margin-right'
                  style={{ width: '300px' }}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'zootecnical-panel' || expandedAll}
            onChange={handlePanelChange('zootecnical-panel')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='zootecnical-panel-content'
              id='zootecnical-panel-header'
            >
              <Typography>{t('zootechnicians')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className='form-body'>
                <TextField
                  name='lossPercentage'
                  label={`${t('zootechnical:loss_percentage')} (%)`}
                  helperText={formValidationMessages?.lossPercentage}
                  classes={{ root: 'input-margin' }}
                  className='input-margin-right'
                  style={{ width: '300px' }}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name='ingestionAdjustment'
                  label={`${t('zootechnical:ingestion_adjustment')} (%)`}
                  helperText={formValidationMessages?.ingestionAdjustment}
                  type='number'
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Form>
        <ExpansionPanel
          expanded={expanded === 'nutrition-panel'}
          onChange={handlePanelChange('nutrition-panel')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='nutrition-panel-content'
            id='nutrition-panel-header'
          >
            <Typography>{t('nutrition')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <CustomMaterialTable
                columns={[
                  {
                    title: t('classification'),
                    field: 'category',
                    validate: notNullValidate('category')
                  },
                  {
                    title: t('period'),
                    field: 'period',
                    type: 'numeric',
                    editable: 'never'
                  },
                  {
                    title: t('start'),
                    field: 'start',
                    type: 'numeric',
                    defaultSort: 'asc',
                    validate: isPositiveValidate('start')
                  },
                  {
                    title: t('final'),
                    field: 'end',
                    type: 'numeric',
                    validate: isPositiveValidate('end')
                  },
                  {
                    title: t('profit:dry_matter_unit'),
                    field: 'dryMatter',
                    type: 'numeric',
                    validate: isPositiveValidate('dryMatter')
                  },
                  {
                    title: t('profit:tdn'),
                    field: 'tdn',
                    type: 'numeric',
                    validate: isPositiveValidate('tdn')
                  },
                  {
                    title: t('profit:cost_original_matter_unit'),
                    field: 'dietCost',
                    type: 'numeric',
                    validate: isPositiveValidate('dietCost')
                  },
                  {
                    title: t('profit:dry_matter_cost'),
                    field: 'dryMatterCost',
                    type: 'numeric',
                    editable: 'never'
                  }
                ]}
                data={nutritions}
                options={{
                  showTitle: false,
                  pageSize: 10
                }}
                editable={{
                  onRowAdd: async (newData) => {
                    addNutrition(newData)
                  },
                  onRowUpdate: async (newData) => {
                    updateNutrition(newData)
                  },
                  onRowDelete: async (row) => {
                    deleteNutrition(row)
                  }
                }}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'carcass-yield-panel'}
          onChange={handlePanelChange('carcass-yield-panel')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='carcass-yield-panel-content'
            id='carcass-yield-panel-header'
          >
            <Typography>{t('carcass_yield')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <CustomMaterialTable
                columns={[
                  {
                    title: t('day'),
                    field: 'day',
                    type: 'numeric'
                  },
                  {
                    title: t('carcass_yield'),
                    field: 'carcassYield',
                    type: 'numeric'
                  }
                ]}
                data={carcassYields}
                options={{
                  showTitle: false,
                  pageSize: 10
                }}
                editable={{
                  onRowAdd: async (newData) => {
                    addCarcassYield(newData)
                  },
                  onRowUpdate: async (newData) => {
                    updateCarcassYield(newData)
                  },
                  onRowDelete: async (row) => {
                    deleteCarcassYield(row)
                  }
                }}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  )
}

export default LotParams
