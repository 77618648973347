import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import {
  Feedlot,
  Lot,
  ZootechnicalPenData,
  ZootechnicalLotData,
  Profit,
  FeedlotProfit,
  Pen
} from 'store/feedlot/types'
import { PenQueryFields, PenQueryArgs } from './types'
import QueryBuilder from 'helpers/QueryBuilder'
import { getItemFromStorage } from 'storage'
import { StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'

export async function getPensRequest(queryFields: PenQueryFields, queryArgs: PenQueryArgs) {
  const QUERY = gql`
  query QUERY($parameters: PenParameters!) {
    pen(parameters: $parameters) ${QueryBuilder(queryFields)}
  }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, queryArgs, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/lot/getPens',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getPens', errors)
    }

    return data.pen as Lot[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/getPens', error)
  }
}

export const createPen = async (pen: PenMutationFields): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($pen: CreatePen!) {
        createPen(pen: $pen)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { pen: { ...pen } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/pen/createPen',
        errors
      )
    }
    return data.createPen as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/pen/createPen', error)
  }
}

export type PenMutationFields = {
  feedlotId: number
  code: string
  status: number
}
