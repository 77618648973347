import { CityQueryFields, StateQueryFields } from 'api/address/types'
import { QuoteSlaughterhousePriceQueryFields } from 'api/economic/types'
import { FeedlotSlaughterhouseQueryFields } from 'api/feedlot-slaughterhouse/types'
import { RouteComponentProps } from 'react-router-dom'

export const quoteSlaughterhousePriceQueryFields: QuoteSlaughterhousePriceQueryFields = {
  id: true,
  regionId: false,
  currencyId: false,
  weightUnityId: false,
  genderId: false,
  price: true,
  price30Days: true,
  grossPrice: true,
  grossPrice30Days: true,
  updateDate: true,
  region: {
    id: true,
    description: true,
    address: true,
    latitude: false,
    longitude: false,
    updateDate: true
  },
  currency: {
    id: true,
    code: true,
    description: true,
    symbol: true
  },
  weightUnity: {
    id: true,
    code: true,
    symbol: true
  },
  gender: {
    id: true,
    code: true,
    description: true
  }
}

export const cityQueryFields: CityQueryFields = {
  id: true,
  description: true
}

export const stateQueryFields: StateQueryFields = {
  id: true,
  description: true,
  code: true,
  cities: {
    id: true,
    description: true
  }
}

export type Props = RouteComponentProps<any>
