import { put, call, Effect } from 'redux-saga/effects'

// Constants
import Codes from 'helpers/Codes'

// Types
import { AuthConstants } from './types'
import { AuthData, StorageKeys } from 'storage/types'
import { MainConstants } from 'store/main/types'
import { ReplacePasswordParams } from 'api/auth/types'
import { FeedlotConstants } from 'store/feedlot/types'
import { MessageType, MessageAlertMode } from 'helpers/Message/types'

// Classes
import Message from 'helpers/Message'
import Exception from 'helpers/Exception'

// Methods
import { setItemInStorage, getItemFromStorage } from 'storage'

import {
  authenticate,
  validateAuthentication,
  sendRecoverPasswordRequest,
  replacePasswordRequest,
  checkResetPasswordToken
} from 'api/auth'
import { LotConstants } from 'store/lot/types'
import { AnimalConstants } from 'store/animal/types'
import { FeedlotSlaughterhouseConstants } from 'store/feedlot-slaughterhouse/types'
import { EconomicConstants } from 'store/economic/types'
import { AddressConstants } from 'store/address/types'

function* putAuthData(payload: any) {
  yield put({
    type: AuthConstants.REDUCER_SET_AUTH_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerSignIn(action: Effect): any {
  yield call(putAuthData, { isAuthenticating: true })

  try {
    const { payload } = action
    const refreshToken = yield call(authenticate, payload.email, payload.password)
    const credentials = yield call(validateAuthentication, { refreshToken })

    setItemInStorage(StorageKeys.RefreshToken, refreshToken)
    setItemInStorage(StorageKeys.Credentials, credentials)
    yield call(putAuthData, { credentials, isAuthenticating: false, isAuthenticated: true })
  } catch (error: any) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
    } else {
      message = new Message(
        MessageType.ERROR,
        'title',
        'unexpected_error',
        MessageAlertMode.INNER,
        'workerSignIn'
      )
    }
    yield call(putAuthData, { isAuthenticating: false })
    yield call(putMainData, { message })
  }
}

export function* workerCheckAuthentication(action?: Effect): any {
  const checkingFromRouter = action?.payload.checkingFromRouter
  if (checkingFromRouter) {
    yield call(putAuthData, {
      isCheckingAuthentication: true
    })
  }
  try {
    const authData = getItemFromStorage(StorageKeys.AuthData) as AuthData
    if (authData) {
      const credentials = yield call(validateAuthentication, authData)
      if (credentials) {
        setItemInStorage(StorageKeys.Credentials, credentials)
      }
    }
    yield call(putAuthData, {
      credentials: authData?.credentials,
      isAuthenticated: !!authData,
      isAuthenticating: false,
      isCheckingAuthentication: false
    })
  } catch (error: any) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      if (error.code === Codes.HTTP.UNAUTHORIZED_401) {
        setItemInStorage(StorageKeys.Credentials)
        setItemInStorage(StorageKeys.RefreshToken)
        message = new Exception(
          Codes.UserInteraction.SIGN_IN_AGAIN,
          'workerCheckAuthentication',
          ''
        ).getMessage()
      }
    } else {
      message = new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'sagas/auth/sagas/workerCheckAuthentication',
        error
      ).getMessage()
    }
    yield call(putMainData, {
      message
    })
    if (checkingFromRouter) {
      yield call(putAuthData, {
        isAuthenticating: false,
        isAuthenticated: false,
        isCheckingAuthentication: false
      })
    }
  }
}

export function* workerSignOut(action?: Effect): any {
  try {
    const { logout } = action?.payload
    setItemInStorage(StorageKeys.Credentials)
    setItemInStorage(StorageKeys.RefreshToken)
    yield put({
      type: AuthConstants.REDUCER_CLEAR_AUTH_DATA
    })
    yield put({
      type: FeedlotConstants.REDUCER_CLEAR_FEEDLOT_DATA
    })
    yield put({
      type: LotConstants.REDUCER_CLEAR_LOT_DATA
    })
    yield put({
      type: AnimalConstants.REDUCER_CLEAR_ANIMAL_DATA
    })
    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA
    })
    yield put({
      type: EconomicConstants.REDUCER_CLEAR_ECONOMIC_DATA
    })
    yield put({
      type: AddressConstants.REDUCER_CLEAR_ADDRESS_DATA
    })
    yield put({
      type: FeedlotSlaughterhouseConstants.REDUCER_CLEAR_FEEDLOT_SLAUGHTERHOUSE_DATA
    })
    yield put({
      type: EconomicConstants.REDUCER_CLEAR_ECONOMIC_DATA
    })
    yield put({
      type: MainConstants.REDUCER_CLEAR_MAIN_DATA
    })
    if (!logout) {
      yield call(putMainData, {
        payload: {
          message: new Message(
            MessageType.ERROR,
            '',
            'signin_again',
            MessageAlertMode.INNER,
            'signout'
          )
        }
      })
    }
  } catch (error: any) {
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/auth/sagas/workerSignOut',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putMainData, {
      message
    })
  }
}

export function* workerRequestChangePwdEmail(action: Effect): any {
  try {
    yield call(putAuthData, { sendingEmail: true })
    const { email, history } = action.payload
    yield call(sendRecoverPasswordRequest, email)
    yield call(putAuthData, { sendingEmail: false })
    history.push('/')
  } catch (error: any) {
    console.log(error)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/auth/sagas/workerSendEmail',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }

    yield call(putMainData, { message })
    yield call(putAuthData, { sendingEmail: false })
  }
}

export function* workerReplacePassword(action: Effect): any {
  try {
    yield call(putAuthData, { replacingPassword: true })
    const { token, password, history } = action.payload
    const params: ReplacePasswordParams = {
      token,
      password,
      repeatPassword: password
    }
    yield call(replacePasswordRequest, params)

    yield call(putAuthData, { replacingPassword: false })

    history.push('/')
  } catch (error: any) {
    console.log(error)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/auth/sagas/workerReplacePassword',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putAuthData, { replacingPassword: false })
    yield call(putMainData, { message })
  }
}

export function* workerCheckChangePwdToken(action: Effect): any {
  const { token, history } = action.payload
  try {
    yield call(putAuthData, { replacingPassword: true })

    yield call(checkResetPasswordToken, token)
    yield call(putAuthData, { replacingPassword: false })
  } catch (error: any) {
    const ex = new Exception(
      Codes.UserInteraction.INVALID_RESET_PASSWORD_TOKEN,
      'sagas/auth/sagas/workerCheckPasswordToken',
      error
    )
    const message = ex.getMessage()
    yield call(putMainData, { message })
    yield call(putAuthData, { replacingPassword: false })

    history.push('/')
  }
}
