import React from 'react'
import { Props } from './types'

const OxIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 110.68 104'
      className={'card-icon'}
    >
      <path
        id='oxIcon'
        d='M82.24,127H67.76a18.45,18.45,0,0,1-18.31-16l-8-42.2H24.74v-2a15.55,15.55,0,0,1,10-14.47A32.39,32.39,0,0,1,19.66,25V23h7.52l.52,1.21c4.89,11.32,24.63,19.6,46.93,19.69h.75c22.29-.09,42-8.37,46.92-19.69l.52-1.21h7.52v2a32.41,32.41,0,0,1-15.12,27.38,15.53,15.53,0,0,1,9.86,14.42v2H108.51l-8,42.2a18.45,18.45,0,0,1-18.27,16ZM28.91,64.8H44.8l8.61,45.63A14.44,14.44,0,0,0,67.76,123H82.24a14.44,14.44,0,0,0,14.35-12.57v-.12L105.2,64.8h15.71a11.6,11.6,0,0,0-11.41-9.51H107l.69-3.6,1.45-.6A28.46,28.46,0,0,0,126.27,27h-.87c-6.32,12.27-26.58,20.81-50,20.9h-.77c-23.44-.09-43.7-8.63-50-20.9h-.87A28.46,28.46,0,0,0,40.88,51.09l1.45.6L43,55.34l-2.59-.05h0A11.63,11.63,0,0,0,28.91,64.8Z'
        transform='translate(-19.66 -23)'
        fill={fill}
      />
    </svg>
  )
}

export default OxIcon
