import {
  Themes,
  MainConstants,
  Locales,
  DataType,
  ColumnsCollapse,
  InfoModal,
  ColumnsLot
} from './types'

export const checkSettings = () => {
  return {
    type: MainConstants.ACTION_CHECK_SETTINGS,
    payload: {}
  }
}

export const setTheme = (theme: Themes) => {
  return {
    type: MainConstants.ACTION_SET_THEME,
    payload: {
      theme
    }
  }
}

export const setDataType = (dataType: DataType) => {
  return {
    type: MainConstants.ACTION_SET_DATA_TYPE,
    payload: {
      dataType
    }
  }
}

export const setColumnsAnimalBasic = (columnsAnimalBasic: ColumnsCollapse) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_BASIC,
    payload: {
      columnsAnimalBasic
    }
  }
}
export const setColumnsAnimalEconomic = (columnsAnimalEconomic: ColumnsCollapse) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_ECONOMIC,
    payload: {
      columnsAnimalEconomic
    }
  }
}
export const setColumnsAnimalCurrentEconomic = (columnsAnimalCurrentEconomic: ColumnsCollapse) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ECONOMIC,
    payload: {
      columnsAnimalCurrentEconomic
    }
  }
}
export const setColumnsAnimalZootechnical = (columnsAnimalZootechnical: ColumnsCollapse) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_ZOOTECHNICAL,
    payload: {
      columnsAnimalZootechnical
    }
  }
}
export const setColumnsAnimalCurrentZootechnical = (
  columnsAnimalCurrentZootechnical: ColumnsCollapse
) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ZOOTECHNICAL,
    payload: {
      columnsAnimalCurrentZootechnical
    }
  }
}
export const setColumnsAnimalEnvironmental = (columnsAnimalEnvironmental: ColumnsCollapse) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_ENVIRONMENTAL,
    payload: {
      columnsAnimalEnvironmental
    }
  }
}
export const setColumnsAnimalCurrentEnvironmental = (
  columnsAnimalCurrentEnvironmental: ColumnsCollapse
) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_ANIMAL_CURRENT_ENVIRONMENTAL,
    payload: {
      columnsAnimalCurrentEnvironmental
    }
  }
}

export const setColumnsLot = (columnsLot: ColumnsLot[]) => {
  return {
    type: MainConstants.ACTION_SET_COLUMNS_LOT,
    payload: {
      columnsLot
    }
  }
}

export const setLocale = (locale: Locales) => {
  return {
    type: MainConstants.ACTION_SET_LOCALE,
    payload: {
      locale
    }
  }
}

export const toggleDrawer = (isDrawerOpen: boolean) => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      isDrawerOpen
    }
  }
}

export const setInfoModalMessage = (infoModal: InfoModal) => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      infoModal: infoModal
    }
  }
}
export const closeInfoModal = () => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      infoModal: {
        open: false,
        message: null
      }
    }
  }
}
