import { CityQueryFields, StateQueryFields } from 'api/address/types'
import { FeedlotSlaughterhouseQueryFields } from 'api/feedlot-slaughterhouse/types'
import { RouteComponentProps } from 'react-router-dom'

export const feedlotSlaughterhouseQueryFields: FeedlotSlaughterhouseQueryFields = {
  id: true,
  description: true,
  feedlotId: true,
  status: true,
  cityId: true,
  address: {
    countryId: true,
    countryDescription: true,
    stateId: true,
    stateDescription: true,
    cityId: true,
    cityDescription: true,
    cityStateDescription: true
  }
}

export const cityQueryFields: CityQueryFields = {
  id: true,
  description: true
}

export const stateQueryFields: StateQueryFields = {
  id: true,
  description: true,
  code: true,
  cities: {
    id: true,
    description: true
  }
}

export type Props = RouteComponentProps<any>
