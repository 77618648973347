import React from 'react'
import { Props } from './types'

const DollarIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 48.584 101.46'
      className={'card-icon'}
    >
      <g id='dollarIcon' data-name='white_$' transform='translate(-50.73 -24.29)'>
        <path
          id='Caminho_14'
          data-name='Caminho 14'
          d='M95.63,95.47a15.8,15.8,0,0,0-1.84-8,17.45,17.45,0,0,0-6.23-5.84A70.08,70.08,0,0,0,74.6,76Q66,73,62,70.19a18.23,18.23,0,0,1-6-6.55,19.31,19.31,0,0,1-2-9.08q0-8.72,5.71-14T74.63,35V25h2.94V35q9.83.54,15.17,6.55t5.35,16.38H95.2q0-9.08-5.16-14.61T76,37.77q-8.65,0-13.87,4.65a15.39,15.39,0,0,0-5.21,12,16.71,16.71,0,0,0,1.74,8.28,16.23,16.23,0,0,0,5.77,5.54,58.791,58.791,0,0,0,11.36,5A97.208,97.208,0,0,1,87,77.73a24.63,24.63,0,0,1,6.47,4.51A17,17,0,0,1,97.26,88a20,20,0,0,1,1.26,7.43q0,8.51-5.53,13.76T78.15,115v10H75.21V115q-11.32-.44-17.53-6.44T51.48,92h2.89q0,9.44,5.91,14.77t16.06,5.33q8.39,0,13.84-4.8a15.18,15.18,0,0,0,5.45-11.83Z'
          fill={fill}
        />
        <path
          id='Caminho_15'
          data-name='Caminho 15'
          d='M78.9,125.75H74.46v-10c-7.36-.4-13.18-2.62-17.3-6.62S50.73,99.22,50.73,92v-.75h4.39V92c0,6,1.9,10.82,5.66,14.21s9,5.14,15.56,5.14c5.38,0,9.87-1.55,13.34-4.62a14.32,14.32,0,0,0,5.2-11.28,15.19,15.19,0,0,0-1.74-7.65,16.74,16.74,0,0,0-6-5.58,68.929,68.929,0,0,0-12.82-5.57A58.061,58.061,0,0,1,61.5,70.79,19.26,19.26,0,0,1,55.28,64a20.27,20.27,0,0,1-2-9.43c0-6,2-10.89,6-14.53,3.77-3.48,8.71-5.41,14.67-5.75v-10h4.44v10c6.39.49,11.43,2.76,15,6.75s5.54,9.81,5.54,16.88v.75H94.45v-.75c0-5.83-1.66-10.58-5-14.1s-7.83-5.3-13.54-5.3c-5.55,0-10,1.5-13.37,4.46a14.74,14.74,0,0,0-5,11.47,16.06,16.06,0,0,0,1.64,7.91,15.58,15.58,0,0,0,5.51,5.27A58.14,58.14,0,0,0,76,72.55a98.509,98.509,0,0,1,11.32,4.5A26,26,0,0,1,94,81.71a17.94,17.94,0,0,1,4,6,20.89,20.89,0,0,1,1.31,7.71c0,5.85-1.94,10.67-5.77,14.31-3.66,3.48-8.57,5.48-14.6,6Zm-2.9-1.5h1.4v-10h.71c6-.34,10.82-2.22,14.36-5.59a17.51,17.51,0,0,0,5.3-13.22,19.31,19.31,0,0,0-1.21-7.15,16.11,16.11,0,0,0-3.65-5.47,24,24,0,0,0-6.27-4.37A96,96,0,0,0,75.5,74,58.92,58.92,0,0,1,64,68.9a16.78,16.78,0,0,1-6-5.8,17.47,17.47,0,0,1-1.84-8.65,16.19,16.19,0,0,1,5.46-12.59C65.19,38.65,70,37,76,37c6.1,0,11,2,14.59,5.79,3.42,3.66,5.21,8.5,5.35,14.38h1.39c-.12-6.34-1.85-11.43-5.15-15.13s-8.34-5.95-14.66-6.3h-.7v-10H75.38v10h-.73c-5.91.21-10.77,2-14.43,5.39S54.75,49,54.75,54.56a18.82,18.82,0,0,0,1.86,8.74,17.63,17.63,0,0,0,5.78,6.27c2.63,1.83,6.83,3.74,12.45,5.68A71,71,0,0,1,88,81a18.12,18.12,0,0,1,6.48,6.1,16.51,16.51,0,0,1,1.95,8.41,16,16,0,0,1-5.71,12.41c-3.75,3.31-8.58,5-14.33,5-6.93,0-12.51-1.86-16.57-5.52s-6-8.45-6.14-14.58H52.24c.15,6.43,2.15,11.56,6,15.26s9.71,6,17,6.23h.72Z'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default DollarIcon
