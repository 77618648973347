import { call, select, put, Effect } from 'redux-saga/effects'
import _ from 'lodash'

// Types
import { MainConstants } from 'store/main/types'

// Methods
import { workerCheckAuthentication } from 'store/auth/sagas'
import Exception from 'helpers/Exception'
import { PenConstants, Pen, LotConstants, LotsSummary } from './types'
import { PenQueryArgs, PenQueryFields } from 'api/pen/types'
import { getPensRequest } from 'api/pen'
import { FeedlotConstants } from 'store/feedlot/types'

function* putPenData(payload: any) {
  yield put({
    type: PenConstants.REDUCER_SET_PEN_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetSelectedPens(action: Effect): any {
  try {
    const queryFields: PenQueryFields = {
      id: true,
      feedlotId: true,
      code: true,
      lot: {
        id: true,
        description: true
      }
    }
    yield call(putPenData, { penLoading: true })
    yield call(workerCheckAuthentication)

    const penStore = yield select((s) => s.pen)
    let { pens, selectedPen } = penStore
    const { feedlotId, penId, lotId } = action.payload

    if (selectedPen && selectedPen.id == penId) {
      yield call(putPenData, { penLoading: false })
      return
    }

    if (!pens.length) {
      const queryArgs: PenQueryArgs = {
        parameters: {
          feedlotId: feedlotId
        }
      }
      pens = []
      pens = yield call(getPensRequest, queryFields, queryArgs)
    }
    selectedPen = undefined
    if (pens.length) selectedPen = _.find(pens, (k: Pen) => k.id === penId) || pens[0]
    if (!penId && lotId)
      selectedPen =
        _.find(pens, (k: Pen) => k.lot.find((l: LotsSummary) => l.id === lotId)) || pens[0]

    yield put({
      type: LotConstants.ACTION_GET_SELECTED_LOT,
      payload: {
        feedlotId: feedlotId,
        lotId: lotId ?? selectedPen?.lot[0]?.id
      }
    })

    yield call(putPenData, {
      pens,
      selectedPen,
      penLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      const payload = {
        message: error.getMessage()
      }
      yield call(putMainData, payload)
    }
  }
}
