import React from 'react'
import { connect } from 'react-redux'
import { mdiHelpCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@material-ui/core'

// Types
import { Props } from './types'
import { AlertModalTypes } from 'store/main/types'

// Methods
import colors, { Colors } from 'styles/variables/colors'
import { setInfoModalMessage } from 'store/main/actions'

// Resources
import './styles.scss'
import { bindActionCreators, Dispatch } from 'redux'

const HelpButton = (props: Props) => (
  <div className='help-button-container'>
    <IconButton
      aria-label='help'
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        props.setInfoModalMessage({
          type: AlertModalTypes.info,
          message: props.infoText,
          open: true
        })
      }}
      size='small'
      classes={{
        root: 'help-button'
      }}
    >
      <Icon size={1} color={colors(props.theme, Colors.gray)} path={mdiHelpCircleOutline} />
    </IconButton>
  </div>
)

// const mapStoreToProps = (store: ApplicationStore) => ({
//   theme: store.main.theme
// })

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setInfoModalMessage
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(HelpButton)
