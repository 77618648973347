import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'

import './styles.scss'
//Components
import { Select, MenuItem, ListItemText, Input, Checkbox } from '@material-ui/core'

const CustomSelectMultiple = (props: Props) => {
  const { t } = useTranslation()
  return (
    <div className={'custom-select-multiple'}>
      <Select
        // classes={{ root: 'select-root', icon: 'select-icon', select: 'select' }}
        multiple
        autoWidth
        value={props.value.filter((o) => o.active).map((f) => f.id)}
        onChange={props.onChangeValue}
        // input={<Input disableUnderline={true} />}
        renderValue={(selected) => {
          const values: string[] = selected as string[]
          const dataLabel: string[] = []
          if (props.textFixed) return t(props.textFixed)
          else {
            values.map((s) => {
              const founded = props.value.find((v) => v.id === s)
              if (founded) dataLabel.push(founded.label)
            })
            return dataLabel.map((f) => t(f)).join(', ')
          }
        }}
      >
        {props.value.map((name) => (
          <MenuItem key={name.id} value={name.id}>
            <Checkbox checked={name.active} />
            <ListItemText primary={t(name.label)} />
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default CustomSelectMultiple
