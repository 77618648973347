import React, { useRef, useEffect } from 'react'
import { FormHandles } from '@unform/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Resources
import './styles.scss'

// Types
import { FeedlotSalesParams, FeedlotZootechnicalParams } from 'api/params/types'
import {
  Props,
  FeedlotNutritionGridData,
  DailyFeedlotGridData,
  FeedlotCarcassYieldGridData
} from './types'
import { ApplicationStore } from 'store/types'

// Components
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Form } from '@unform/web'
import { TextField } from 'unform-material-ui'
import CustomMaterialTable, {
  notNullValidate,
  isPositiveValidate
} from 'components/tables/CustomMaterialTable'

const FeedlotParams: React.FC<Props> = ({
  currentSalesParams,
  setCurrentSalesParams,
  currentZootechnicalParams,
  setCurrentZootechnicalParams,
  formValidationMessages,
  formErrorMessages,
  nutritions,
  setNutritions,
  nutritionGridActions,
  setNutritionGridActions,
  dailyFeedlot,
  setDailyFeedlot,
  dailyFeedlotGridActions,
  setDailyFeedlotGridActions,
  carcassYields,
  setCarcassYields,
  carcassYieldGridActions,
  setCarcassYieldGridActions
}: Props) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [expandedAll, setExpandedAll] = React.useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)
  const selectedFeedlot = useSelector((state: ApplicationStore) => state.feedlot.selectedFeedlot)

  useEffect(() => {
    const aux = { ...currentSalesParams, ...currentZootechnicalParams }
    formRef.current?.setData(aux as FeedlotSalesParams & FeedlotZootechnicalParams)
  }, [currentSalesParams, currentZootechnicalParams])

  useEffect(() => {
    updateNutritionGridCalcFields(nutritions)
  }, [nutritions])

  useEffect(() => {
    formRef.current?.setErrors(formErrorMessages || {})
    setExpandedAll(Object.keys(formErrorMessages || {}).length > 0)
  }, [formErrorMessages])

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (expandedAll) {
        let aux = ''
        if (panel === 'config-panel' || 'zootechnical-panel') aux = 'sales-panel'
        else if (panel === 'sales-panel' || 'zootechnical-panel') aux = 'config-panel'
        else aux = 'zootechnical-panel'

        setExpanded(aux)
        setExpandedAll(false)
      } else setExpanded(isExpanded ? panel : false)
    }

  const handleFormChange = () => {
    const formData = formRef.current?.getData() as FeedlotSalesParams & FeedlotZootechnicalParams
    if (setCurrentSalesParams) {
      const saleParams = {
        dif: formData.dif,
        mtDif: formData.mtDif,
        saleAnimalsNumber: formData.saleAnimalsNumber,
        sbwLimit: formData.sbwLimit,
        truckAnimalsLimit: formData.truckAnimalsLimit,
        eccLimit: formData.eccLimit
      } as FeedlotSalesParams
      setCurrentSalesParams({ ...currentSalesParams, ...saleParams })
    }
    if (setCurrentZootechnicalParams) {
      const zootechnicalParams = {
        ingestionAdjustment: formData.ingestionAdjustment,
        lossPercentage: formData.lossPercentage
      } as FeedlotZootechnicalParams
      setCurrentZootechnicalParams({ ...currentZootechnicalParams, ...zootechnicalParams })
    }
  }

  //#region Nutrition

  const updateNutritionGridCalcFields = (nutritions: FeedlotNutritionGridData[]) => {
    nutritions?.map((n) => {
      n.period = n.end - n.start + 1
      n.dryMatterCost = Number(((n.dietCost / n.dryMatter) * 100).toFixed(2))
    })
  }

  const addNutrition = (nutrition: FeedlotNutritionGridData) => {
    const newNutritionId = nutritionGridActions.add?.length ?? 0
    nutrition.id = 0
    nutrition.feedlotId = selectedFeedlot?.id as number
    nutrition._addId = newNutritionId
    updateNutritionGridCalcFields([nutrition])

    const nutritionsCopy = [...nutritions]
    nutritionsCopy.push(nutrition)

    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      add: [...(nutritionGridActions.add ?? []), nutrition._addId]
    })
  }

  const updateNutrition = (nutrition: FeedlotNutritionGridData) => {
    updateNutritionGridCalcFields([nutrition])
    const nutritionsCopy = [...nutritions]
    const updatedNutritionIndex = nutritionsCopy.findIndex((n) =>
      n._addId ? n._addId === nutrition._addId : n.id === nutrition.id
    )
    nutritionsCopy.splice(updatedNutritionIndex, 1, nutrition)
    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      update: [...(nutritionGridActions.update ?? []), nutrition.id]
    })
  }

  const deleteNutrition = (nutrition: FeedlotNutritionGridData) => {
    const nutritionsCopy = [...nutritions]
    const deletedNutritionIndex = nutritionsCopy.findIndex((n) => n.id === nutrition.id)
    const deletedNutritions = nutritionsCopy.splice(deletedNutritionIndex, 1)
    setNutritions(nutritionsCopy)
    setNutritionGridActions({
      ...nutritionGridActions,
      delete: [...(nutritionGridActions.delete ?? []), ...deletedNutritions]
    })
  }

  //#endregion

  //#region Daily Feedlot

  const addDailyFeedlot = (newDailyFeedlot: DailyFeedlotGridData) => {
    const newDailyFeedlotId = nutritionGridActions.add?.length ?? 0
    newDailyFeedlot.id = 0
    newDailyFeedlot.feedlotId = selectedFeedlot?.id as number
    newDailyFeedlot._addId = newDailyFeedlotId

    const dailyFeedlotCopy = [...dailyFeedlot]
    dailyFeedlotCopy.push(newDailyFeedlot)

    setDailyFeedlot(dailyFeedlotCopy)
    setDailyFeedlotGridActions({
      ...dailyFeedlotGridActions,
      add: [...(dailyFeedlotGridActions.add ?? []), newDailyFeedlot._addId]
    })
  }

  const updateDailyFeedlot = (updatedDailyFeedlot: DailyFeedlotGridData) => {
    const dailyFeedlotsCopy = [...dailyFeedlot]
    const updatedDailyFeedlotIndex = dailyFeedlotsCopy.findIndex((d) =>
      d._addId ? d._addId === updatedDailyFeedlot._addId : d.id === updatedDailyFeedlot.id
    )
    dailyFeedlotsCopy.splice(updatedDailyFeedlotIndex, 1, updatedDailyFeedlot)
    setDailyFeedlot(dailyFeedlotsCopy)
    setDailyFeedlotGridActions({
      ...dailyFeedlotGridActions,
      update: [...(dailyFeedlotGridActions.update ?? []), updatedDailyFeedlot.id]
    })
  }

  const deleteDailyFeedlot = (deletedDailyFeedlot: DailyFeedlotGridData) => {
    const dailyFeedlotCopy = [...dailyFeedlot]
    const deletedDailyFeedlotIndex = dailyFeedlotCopy.findIndex(
      (d) => d.id === deletedDailyFeedlot.id
    )
    const deletedDailyFeedlotData = dailyFeedlotCopy.splice(deletedDailyFeedlotIndex, 1)
    setDailyFeedlot(dailyFeedlotCopy)
    setDailyFeedlotGridActions({
      ...dailyFeedlotGridActions,
      delete: [...(dailyFeedlotGridActions.delete ?? []), ...deletedDailyFeedlotData]
    })
  }

  //#endregion

  //#region CarcassYield

  const addCarcassYield = (carcassYield: FeedlotCarcassYieldGridData) => {
    const newCarcassYieldId = carcassYieldGridActions.add?.length ?? 0
    carcassYield.feedlotId = selectedFeedlot?.id as number
    carcassYield._addId = newCarcassYieldId

    const carcassYieldsCopy = [...carcassYields]
    carcassYieldsCopy.push(carcassYield)

    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      add: [...(carcassYieldGridActions.add ?? []), carcassYield._addId]
    })
  }

  const updateCarcassYield = (carcassYield: FeedlotCarcassYieldGridData) => {
    const carcassYieldsCopy = [...carcassYields]
    const updatedCarcassYieldIndex = carcassYieldsCopy.findIndex(
      (n) => n._addId === carcassYield._addId
    )
    carcassYieldsCopy.splice(updatedCarcassYieldIndex, 1, carcassYield)
    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      update: [...(carcassYieldGridActions.update ?? []), carcassYield.id]
    })
  }

  const deleteCarcassYield = (carcassYield: FeedlotCarcassYieldGridData) => {
    const carcassYieldsCopy = [...carcassYields]
    const deletedCarcassYieldIndex = carcassYieldsCopy.findIndex(
      (n) => n._addId === carcassYield._addId
    )
    const deletedCarcassYields = carcassYieldsCopy.splice(deletedCarcassYieldIndex, 1)
    setCarcassYields(carcassYieldsCopy)
    setCarcassYieldGridActions({
      ...carcassYieldGridActions,
      delete: [...(carcassYieldGridActions.delete ?? []), ...deletedCarcassYields]
    })
  }

  //#endregion
  return (
    <div className='feedlot-params-body'>
      <Form
        ref={formRef}
        style={{ width: '100%' }}
        onSubmit={() => null}
        onChange={handleFormChange}
      >
        <ExpansionPanel
          expanded={expanded === 'config-panel' || expandedAll}
          onChange={handlePanelChange('config-panel')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='config-panel-content'
            id='config-panel-header'
          >
            <Typography>{t('settings')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='form-body'>
              <TextField
                name='mtDif'
                label={t('feedlot:feedlot_maximum_time')}
                helperText={formValidationMessages?.mtDif}
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                type='number'
                style={{ width: '230px' }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='dif'
                label={t('feedlot:feedlot_maximum_time')}
                helperText={formValidationMessages?.dif}
                type='number'
                style={{ width: '230px' }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'sales-panel' || expandedAll}
          onChange={handlePanelChange('sales-panel')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='sales-panel-content'
            id='sales-panel-header'
          >
            <Typography>{t('sale')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='form-body'>
              <TextField
                name='saleAnimalsNumber'
                label={t('animal:animals_mininum_number_per_sale')}
                helperText={formValidationMessages?.saleAnimalsNumber}
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                style={{ width: '300px' }}
                type='number'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='sbwLimit'
                label={`${t('animal:minimum_weight')} (${t('weight_unity_kg')})`}
                helperText={formValidationMessages?.sbwLimit}
                type='number'
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='truckAnimalsLimit'
                label={t('animal:maximum_truck_animals')}
                helperText={formValidationMessages?.truckAnimalsLimit}
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                style={{ width: '400px' }}
                type='number'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='eccLimit'
                label={t('animal:animals_mininum_ecc')}
                helperText={formValidationMessages?.eccLimit}
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                style={{ width: '300px' }}
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === 'zootechnical-panel' || expandedAll}
          onChange={handlePanelChange('zootechnical-panel')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='zootechnical-panel-content'
            id='zootechnical-panel-header'
          >
            <Typography>{t('zootechnicians')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className='form-body'>
              <TextField
                name='lossPercentage'
                label={`${t('zootechnical:loss_percentage')} (%)`}
                helperText={formValidationMessages?.lossPercentage}
                classes={{ root: 'input-margin' }}
                className='input-margin-right'
                style={{ width: '300px' }}
                type='number'
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name='ingestionAdjustment'
                label={`${t('zootechnical:ingestion_adjustment')} (%)`}
                helperText={formValidationMessages?.ingestionAdjustment}
                type='number'
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Form>
      <ExpansionPanel
        expanded={expanded === 'nutrition-panel'}
        onChange={handlePanelChange('nutrition-panel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='nutrition-panel-content'
          id='nutrition-panel-header'
        >
          <Typography>{t('nutrition')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <CustomMaterialTable
              columns={[
                {
                  title: t('classification'),
                  field: 'category',
                  validate: notNullValidate('category')
                },
                {
                  title: t('period'),
                  field: 'period',
                  type: 'numeric',
                  editable: 'never'
                },
                {
                  title: t('start'),
                  field: 'start',
                  type: 'numeric',
                  defaultSort: 'asc',
                  validate: isPositiveValidate('start')
                },
                {
                  title: t('final'),
                  field: 'end',
                  type: 'numeric',
                  validate: isPositiveValidate('end')
                },
                {
                  title: t('profit:dry_matter_unit'),
                  field: 'dryMatter',
                  type: 'numeric',
                  validate: isPositiveValidate('dryMatter')
                },
                {
                  title: t('profit:tdn'),
                  field: 'tdn',
                  type: 'numeric',
                  validate: isPositiveValidate('tdn')
                },
                {
                  title: t('profit:cost_original_matter_unit'),
                  field: 'dietCost',
                  type: 'numeric',
                  validate: isPositiveValidate('dietCost')
                },
                {
                  title: t('profit:dry_matter_cost'),
                  field: 'dryMatterCost',
                  type: 'numeric',
                  editable: 'never'
                }
              ]}
              data={nutritions}
              options={{
                showTitle: false,
                pageSize: 10
              }}
              editable={{
                onRowAdd: async (newData) => {
                  addNutrition(newData)
                },
                onRowUpdate: async (newData) => {
                  updateNutrition(newData)
                },
                onRowDelete: async (row) => {
                  deleteNutrition(row)
                }
              }}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expanded === 'carcass-yield-panel'}
        onChange={handlePanelChange('carcass-yield-panel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='carcass-yield-panel-content'
          id='carcass-yield-panel-header'
        >
          <Typography>{t('carcass_yield')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <CustomMaterialTable
              columns={[
                {
                  title: t('day'),
                  field: 'day',
                  type: 'numeric'
                },
                {
                  title: t('carcass_yield'),
                  field: 'carcassYield',
                  type: 'numeric'
                }
              ]}
              data={carcassYields}
              options={{
                showTitle: false,
                pageSize: 10
              }}
              editable={{
                onRowAdd: async (newData) => {
                  addCarcassYield(newData)
                },
                onRowUpdate: async (newData) => {
                  updateCarcassYield(newData)
                },
                onRowDelete: async (row) => {
                  deleteCarcassYield(row)
                }
              }}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expanded === 'daily-feedlot-panel'}
        onChange={handlePanelChange('daily-feedlot-panel')}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='daily-feedlot-panel-content'
          id='daily-feedlot-panel-header'
        >
          <Typography>{t('feedlot:daily_feedlot')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <CustomMaterialTable
              columns={[
                {
                  title: t('animal:initial_weight'),
                  field: 'startingWeight',
                  defaultSort: 'asc',
                  type: 'numeric',
                  validate: isPositiveValidate('startingWeight')
                },
                {
                  title: t('animal:final_weight'),
                  field: 'finalWeight',
                  type: 'numeric',
                  validate: isPositiveValidate('finalWeight')
                },
                {
                  title: t('cost'),
                  field: 'cost',
                  type: 'numeric',
                  validate: isPositiveValidate('cost')
                }
              ]}
              data={dailyFeedlot}
              options={{
                showTitle: false,
                pageSize: 10
              }}
              editable={{
                onRowAdd: async (row) => {
                  addDailyFeedlot(row)
                },
                onRowUpdate: async (row) => {
                  updateDailyFeedlot(row)
                },
                onRowDelete: async (row) => {
                  deleteDailyFeedlot(row)
                }
              }}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default FeedlotParams
