import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import { UserNotification } from './types'
import { StorageKeys } from 'storage/types'
import { getItemFromStorage } from 'storage'
import { validateAuthentication } from 'api/auth'

const queryFields = `{
    id
    data {
      createDate
      viewed
      customData {
        __typename
         ... on AnimalsWithDelayedWeighingNotificationDto{
          animalsCount
        }
        ... on AnimalsNotProcessedNotificationDto{
          animalsCount
        }
        ... on AnimalsWithRecurrenceOfWeighingNotificationDto{
          animalsCount
          animals {
            animalId
            earring
            animalTag
          }
        }
      }
    }
  }`

export async function getUserNotifications(
  operation: 'GetNew' | 'GetPrior',
  referenceDate: string
) {
  const QUERY = gql`
    query QUERY($operation: String!, $referenceDate: String!) {
      userNotification(operation: $operation, referenceDate: $referenceDate) ${queryFields}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { operation, referenceDate }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/user/notification/getUserNotifications',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/user/notification/getUserNotifications',
        errors
      )
    }

    return data.userNotification as UserNotification[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getUserNotifications', error)
  }
}

export const setNotificationsViewed = async (notificationIds: string[]): Promise<void> => {
  if (notificationIds.length === 0) {
    return
  }

  try {
    const MUTATION = gql`
      mutation MUTATION($notificationIds: [String!]!) {
        setNotificationsViewed(notificationIds: $notificationIds)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { notificationIds: notificationIds },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/user/notification/setNotificationsViewed',
        errors
      )
    }
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/user/notification/setNotificationsViewed',
      error
    )
  }
}
