import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import {
  FeedlotSalesParams,
  FeedlotSalesParamsQueryFields,
  LotSalesParamsQueryFields,
  LotSalesParams,
  FeedlotNutritionQueryFields,
  FeedlotNutrition,
  LotNutrition,
  LotNutritionQueryFields,
  DailyFeedlotQueryFields,
  DailyFeedlot,
  FeedlotZootechnicalParamsQueryFields,
  FeedlotZootechnicalParams,
  LotZootechnicalParams,
  LotZootechnicalParamsQueryFields,
  FeedlotCarcassYieldQueryFields,
  FeedlotCarcassYield,
  LotCarcassYieldQueryFields,
  LotCarcassYield
} from './types'
import QueryBuilder from 'helpers/QueryBuilder'
import { getItemFromStorage } from 'storage'
import { StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'

export async function getFeedlotSalesParams(
  queryFields: FeedlotSalesParamsQueryFields,
  feedlotId: number
) {
  const QUERY = gql`
    query QUERY($feedlotId: Int!) {
      feedlotSalesParams(feedlotId: $feedlotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { feedlotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getFeedlotSalesParams',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/params/getFeedlotSalesParams',
        errors
      )
    }

    return data.feedlotSalesParams as FeedlotSalesParams
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getFeedlotSalesParams', error)
  }
}

export const createFeedlotSalesParams = async (param: FeedlotSalesParams): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: FeedlotSalesParams!) {
        createFeedlotSalesParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createFeedlotSalesParams',
        errors
      )
    }
    return data.createFeedlotSalesParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/createFeedlotSalesParams',
      error
    )
  }
}

export const updateFeedlotSalesParams = async (param: FeedlotSalesParams): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: FeedlotSalesParams!) {
        updateFeedlotSalesParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateFeedlotSalesParams',
        errors
      )
    }
    return data.updateFeedlotSalesParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/updateFeedlotSalesParams',
      error
    )
  }
}

export async function getLotSalesParams(queryFields: LotSalesParamsQueryFields, lotId: number) {
  const QUERY = gql`
    query QUERY($lotId: Int!) {
      lotSalesParams(lotId: $lotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { lotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getLotSalesParams',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotSalesParams', errors)
    }

    return data.lotSalesParams as LotSalesParams
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotSalesParams', error)
  }
}

export const createLotSalesParams = async (param: LotSalesParams): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: LotSalesParams!) {
        createLotSalesParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createLotSalesParams',
        errors
      )
    }
    return data.createLotSalesParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/createLotSalesParams', error)
  }
}

export const updateLotSalesParams = async (param: LotSalesParams): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: LotSalesParams!) {
        updateLotSalesParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateLotSalesParams',
        errors
      )
    }
    return data.updateLotSalesParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/updateLotSalesParams', error)
  }
}

export async function getFeedlotZootechnicalParams(
  queryFields: FeedlotZootechnicalParamsQueryFields,
  feedlotId: number
) {
  const QUERY = gql`
    query QUERY($feedlotId: Int!) {
      feedlotZootechnicalParams(feedlotId: $feedlotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { feedlotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getFeedlotZootechnicalParams',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/params/getFeedlotZootechnicalParams',
        errors
      )
    }

    return data.feedlotZootechnicalParams as FeedlotZootechnicalParams
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/getFeedlotZootechnicalParams',
      error
    )
  }
}

export const createFeedlotZootechnicalParams = async (
  param: FeedlotZootechnicalParams
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: FeedlotZootechnicalParams!) {
        createFeedlotZootechnicalParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createFeedlotZootechnicalParams',
        errors
      )
    }
    return data.createFeedlotZootechnicalParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/createFeedlotZootechnicalParams',
      error
    )
  }
}

export const updateFeedlotZootechnicalParams = async (
  param: FeedlotZootechnicalParams
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: FeedlotZootechnicalParams!) {
        updateFeedlotZootechnicalParams(param: $param)
      }
    `
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateFeedlotZootechnicalParams',
        errors
      )
    }
    return data.updateFeedlotZootechnicalParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/updateFeedlotZootechnicalParams',
      error
    )
  }
}

export async function getLotZootechnicalParams(
  queryFields: LotZootechnicalParamsQueryFields,
  lotId: number
) {
  const QUERY = gql`
    query QUERY($lotId: Int!) {
      lotZootechnicalParams(lotId: $lotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { lotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getLotZootechnicalParams',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/params/getLotZootechnicalParams',
        errors
      )
    }

    return data.lotZootechnicalParams as LotZootechnicalParams
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/getLotZootechnicalParams',
      error
    )
  }
}

export const createLotZootechnicalParams = async (
  param: LotZootechnicalParams
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: LotZootechnicalParams!) {
        createLotZootechnicalParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createLotZootechnicalParams',
        errors
      )
    }
    return data.createLotZootechnicalParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/createLotZootechnicalParams',
      error
    )
  }
}

export const updateLotZootechnicalParams = async (
  param: LotZootechnicalParams
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($param: LotZootechnicalParams!) {
        updateLotZootechnicalParams(param: $param)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { param: { ...param } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateLotZootechnicalParams',
        errors
      )
    }
    return data.updateLotZootechnicalParams as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/updateLotZootechnicalParams',
      error
    )
  }
}
export async function getFeedlotNutritions(
  queryFields: FeedlotNutritionQueryFields,
  feedlotId: number
) {
  const QUERY = gql`
    query QUERY($feedlotId: Int!) {
      feedlotNutrition(feedlotId: $feedlotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { feedlotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getFeedlotNutrition',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/params/getFeedlotNutrition',
        errors
      )
    }

    return data.feedlotNutrition as FeedlotNutrition[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getFeedlotNutrition', error)
  }
}

export const createFeedlotNutritions = async (
  nutritions: FeedlotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [FeedlotNutrition!]!) {
        createFeedlotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createFeedlotNutritions',
        errors
      )
    }
    return data.createFeedlotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/createFeedlotNutritions',
      error
    )
  }
}

export const updateFeedlotNutritions = async (
  nutritions: FeedlotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [FeedlotNutrition!]!) {
        updateFeedlotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateFeedlotNutritions',
        errors
      )
    }
    return data.updateFeedlotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/updateFeedlotNutritions',
      error
    )
  }
}

export const deleteFeedlotNutritions = async (
  nutritions: FeedlotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [FeedlotNutrition!]!) {
        deleteFeedlotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/deleteFeedlotNutritions',
        errors
      )
    }
    return data.deleteFeedlotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/deleteFeedlotNutritions',
      error
    )
  }
}

export async function getLotNutritions(queryFields: LotNutritionQueryFields, lotId: number) {
  const QUERY = gql`
    query QUERY($lotId: Int!) {
      lotNutrition(lotId: $lotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { lotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getLotNutrition',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotNutrition', errors)
    }

    return data.lotNutrition as LotNutrition[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotNutrition', error)
  }
}

export const createLotNutritions = async (
  nutritions: LotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [LotNutrition!]!) {
        createLotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createLotNutritions',
        errors
      )
    }
    return data.createLotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/createLotNutritions', error)
  }
}

export const updateLotNutritions = async (
  nutritions: LotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [LotNutrition!]!) {
        updateLotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateLotNutritions',
        errors
      )
    }
    return data.updateLotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/updateLotNutritions', error)
  }
}

export const deleteLotNutritions = async (
  nutritions: LotNutrition[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($nutritions: [LotNutrition!]!) {
        deleteLotNutritions(nutritions: $nutritions)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { nutritions: nutritions },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/deleteLotNutritions',
        errors
      )
    }
    return data.deleteLotNutritions as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/deleteLotNutritions', error)
  }
}

export async function getDailyFeedlot(queryFields: DailyFeedlotQueryFields, feedlotId: number) {
  const QUERY = gql`
    query QUERY($feedlotId: Int!) {
      dailyFeedlot(feedlotId: $feedlotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { feedlotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/dailyFeedlot',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/dailyFeedlot', errors)
    }

    return data.dailyFeedlot as DailyFeedlot[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/dailyFeedlot', error)
  }
}

export const createDailyFeedlot = async (
  dailyFeedlot: DailyFeedlot[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($dailyFeedlot: [DailyFeedlot!]!) {
        createDailyFeedlot(dailyFeedlot: $dailyFeedlot)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { dailyFeedlot: dailyFeedlot },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createDailyFeedlot',
        errors
      )
    }
    return data.createDailyFeedlot as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/createDailyFeedlot', error)
  }
}

export const updateDailyFeedlot = async (
  dailyFeedlot: DailyFeedlot[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($dailyFeedlot: [DailyFeedlot!]!) {
        updateDailyFeedlot(dailyFeedlot: $dailyFeedlot)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { dailyFeedlot: dailyFeedlot },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateDailyFeedlot',
        errors
      )
    }
    return data.updateDailyFeedlot as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/updateDailyFeedlot', error)
  }
}

export const deleteDailyFeedlot = async (
  dailyFeedlot: DailyFeedlot[] | undefined
): Promise<number[]> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($dailyFeedlot: [DailyFeedlot!]!) {
        deleteDailyFeedlot(dailyFeedlot: $dailyFeedlot)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { dailyFeedlot: dailyFeedlot },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/deleteDailyFeedlot',
        errors
      )
    }
    return data.deleteDailyFeedlot as number[]
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/deleteDailyFeedlot', error)
  }
}

export async function getFeedlotCarcassYield(
  queryFields: FeedlotCarcassYieldQueryFields,
  feedlotId: number
) {
  const QUERY = gql`
    query QUERY($feedlotId: Int!) {
      feedlotCarcassYield(feedlotId: $feedlotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { feedlotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getFeedlotCarcassYield',
          errors
        )
      }
      throw new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'api/params/getFeedlotCarcassYield',
        errors
      )
    }

    return data.feedlotCarcassYield as FeedlotCarcassYield
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/getFeedlotCarcassYield',
      error
    )
  }
}

export const createFeedlotCarcassYield = async (
  carcassYield: FeedlotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotCarcassYield: FeedlotCarcassYield!) {
        createFeedlotCarcassYields(feedlotCarcassYield: $feedlotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { feedlotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createFeedlotCarcassYield',
        errors
      )
    }
    return data.createFeedlotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/createFeedlotCarcassYield',
      error
    )
  }
}

export const updateFeedlotCarcassYield = async (
  carcassYield: FeedlotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotCarcassYield: FeedlotCarcassYield!) {
        updateFeedlotCarcassYields(feedlotCarcassYield: $feedlotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { feedlotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateFeedlotCarcassYield',
        errors
      )
    }
    return data.updateFeedlotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/updateFeedlotCarcassYield',
      error
    )
  }
}

export const deleteFeedlotCarcassYield = async (
  carcassYield: FeedlotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($feedlotCarcassYield: FeedlotCarcassYield!) {
        deleteFeedlotCarcassYields(feedlotCarcassYield: $feedlotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { feedlotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/deleteFeedlotCarcassYield',
        errors
      )
    }
    return data.deleteFeedlotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/params/deleteFeedlotCarcassYield',
      error
    )
  }
}

export async function getLotCarcassYield(queryFields: LotCarcassYieldQueryFields, lotId: number) {
  const QUERY = gql`
    query QUERY($lotId: Int!) {
      lotCarcassYield(lotId: $lotId) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, { lotId }, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/params/getLotCarcassYield',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotCarcassYield', errors)
    }

    return data.lotCarcassYield as LotCarcassYield
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/getLotCarcassYield', error)
  }
}

export const createLotCarcassYield = async (
  carcassYield: LotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($lotCarcassYield: LotCarcassYield!) {
        createLotCarcassYields(lotCarcassYield: $lotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { lotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/createLotCarcassYield',
        errors
      )
    }
    return data.createLotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/createLotCarcassYield', error)
  }
}

export const updateLotCarcassYield = async (
  carcassYield: LotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($lotCarcassYield: LotCarcassYield!) {
        updateLotCarcassYields(lotCarcassYield: $lotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { lotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/updateLotCarcassYield',
        errors
      )
    }
    return data.updateLotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/updateLotCarcassYield', error)
  }
}

export const deleteLotCarcassYield = async (
  carcassYield: LotCarcassYield | undefined
): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($lotCarcassYield: LotCarcassYield!) {
        deleteLotCarcassYields(lotCarcassYield: $lotCarcassYield)
      }
    `

    const { credentials } = store.getState().auth

    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(
      MUTATION,
      { lotCarcassYield: carcassYield },
      credentials.token
    )
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/params/deleteLotCarcassYield',
        errors
      )
    }
    return data.deleteLotCarcassYield as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/params/deleteLotCarcassYield', error)
  }
}
