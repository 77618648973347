import { call, put, select, Effect } from 'redux-saga/effects'

// Types
import { MainConstants } from './types'
import { StorageKeys } from 'storage/types'

// Methods
import { getItemFromStorage, setItemInStorage } from 'storage'

function* fetchData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerCheckSettings() {
  try {
    yield fetchData({ isCheckingSettings: true })
    const theme = getItemFromStorage(StorageKeys.Theme)
    const locale = getItemFromStorage(StorageKeys.Locale)
    const dataType = getItemFromStorage(StorageKeys.DataType)
    const columnsAnimalBasic = getItemFromStorage(StorageKeys.ColumnsAnimalBasic)
    const columnsAnimalCurrentZootechnical = getItemFromStorage(
      StorageKeys.ColumnsAnimalCurrentZootechnical
    )
    const columnsAnimalZootechnical = getItemFromStorage(StorageKeys.ColumnsAnimalZootechnical)
    const columnsAnimalCurrentEconomic = getItemFromStorage(
      StorageKeys.ColumnsAnimalCurrentEconomic
    )
    const columnsAnimalEconomic = getItemFromStorage(StorageKeys.ColumnsAnimalEconomic)
    const columnsLot = getItemFromStorage(StorageKeys.ColumnsLot)

    // const columnsTableEconomic = getItemFromStorage(StorageKeys.ColumnsTableEconomic)
    yield call(fetchData, {
      theme,
      locale,
      dataType,
      columnsAnimalBasic,
      columnsAnimalCurrentZootechnical,
      columnsAnimalZootechnical,
      columnsAnimalCurrentEconomic,
      columnsAnimalEconomic,
      columnsLot
    })
  } catch (error: any) {
  } finally {
    yield fetchData({ isCheckingSettings: true })
  }
}

export function* workerSetTheme(action: Effect): any {
  try {
    const { theme } = action.payload
    setItemInStorage(StorageKeys.Theme, theme)
    yield put({
      type: MainConstants.REDUCER_SET_MAIN_DATA,
      payload: {
        theme
      }
    })
    yield call(fetchData, {
      theme
    })
  } catch (error: any) {}
}

export function* workerSetDataType(action: Effect): any {
  try {
    const { dataType } = action.payload
    setItemInStorage(StorageKeys.DataType, dataType)

    yield call(fetchData, {
      dataType
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalBasic(action: Effect): any {
  try {
    const { columnsAnimalBasic } = action.payload
    setItemInStorage(StorageKeys.ColumnsAnimalBasic, columnsAnimalBasic)

    yield call(fetchData, {
      columnsAnimalBasic
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalEconomic(action: Effect): any {
  try {
    const { columnsAnimalEconomic } = action.payload

    setItemInStorage(StorageKeys.ColumnsAnimalEconomic, columnsAnimalEconomic)

    yield call(fetchData, {
      columnsAnimalEconomic
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalCurrentEconomic(action: Effect): any {
  try {
    const { columnsAnimalCurrentEconomic } = action.payload

    setItemInStorage(StorageKeys.ColumnsAnimalCurrentEconomic, columnsAnimalCurrentEconomic)

    yield call(fetchData, {
      columnsAnimalCurrentEconomic
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalZootechnical(action: Effect): any {
  try {
    const { columnsAnimalZootechnical } = action.payload

    setItemInStorage(StorageKeys.ColumnsAnimalZootechnical, columnsAnimalZootechnical)

    yield call(fetchData, {
      columnsAnimalZootechnical
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalCurrentZootechnical(action: Effect): any {
  try {
    const { columnsAnimalCurrentZootechnical } = action.payload

    setItemInStorage(StorageKeys.ColumnsAnimalCurrentZootechnical, columnsAnimalCurrentZootechnical)

    yield call(fetchData, {
      columnsAnimalCurrentZootechnical
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalEnvironmental(action: Effect): any {
  try {
    const { columnsAnimalEnvironmental } = action.payload

    setItemInStorage(StorageKeys.ColumnsAnimalEnvironmental, columnsAnimalEnvironmental)

    yield call(fetchData, {
      columnsAnimalEnvironmental
    })
  } catch (error: any) {}
}

export function* workerSetColumnsAnimalCurrentEnvironmental(action: Effect): any {
  try {
    const { columnsAnimalCurrentEnvironmental } = action.payload

    setItemInStorage(
      StorageKeys.ColumnsAnimalCurrentEnvironmental,
      columnsAnimalCurrentEnvironmental
    )

    yield call(fetchData, {
      columnsAnimalCurrentEnvironmental
    })
  } catch (error: any) {}
}

export function* workerSetColumnsLot(action: Effect): any {
  try {
    const { columnsLot } = action.payload

    setItemInStorage(StorageKeys.ColumnsLot, columnsLot)

    yield call(fetchData, {
      columnsLot
    })
  } catch (error: any) {}
}

export function* workerSetLocale(action: Effect): any {
  try {
    const { locale } = action.payload
    setItemInStorage(StorageKeys.Locale, locale)
    yield call(fetchData, {
      locale
    })
  } catch (error: any) {}
}
