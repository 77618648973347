import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

// Components
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  Button,
  Typography
} from '@material-ui/core'
import {
  NotificationsNoneRounded,
  NotificationsActiveTwoTone,
  NewReleases,
  Info
} from '@material-ui/icons'

// Types
import { ApplicationStore } from 'store/types'
import { Props, PopMenuItem, popMenuItemTranslation } from './types'
import { withRouter } from 'react-router-dom'

// Methods
import {
  getUserNotifications,
  setNotificationsViewed as setNotificationsViewedApi
} from 'api/user/notification'
import { UserNotification } from 'api/user/notification/types'

// Resource
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'

const NotificationButton = (props: Props) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)
  const [fetchNotifications, setFetchNotifications] = React.useState<{
    mode: 'GetNew' | 'GetPrior'
  }>({
    mode: 'GetPrior'
  })
  const [popMenuItems, setPopMenuItems] = React.useState<PopMenuItem[]>([])

  const setNotificationsViewed = () => {
    type NotificationViewedData = {
      notViewedIds: string[]
      popMenuItemsViewed: PopMenuItem[]
    }

    const initalValue: NotificationViewedData = {
      notViewedIds: [],
      popMenuItemsViewed: []
    }

    const { notViewedIds, popMenuItemsViewed } = popMenuItems?.reduce(
      (acc: NotificationViewedData, cur: PopMenuItem) => {
        if (!cur.viewed) {
          acc.notViewedIds.push(String(cur.id))
        }
        acc.popMenuItemsViewed.push({
          ...cur,
          viewed: true
        })
        return acc
      },
      initalValue
    ) as NotificationViewedData

    setPopMenuItems(popMenuItemsViewed)
    setNotificationsViewedApi(notViewedIds)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
    setNotificationsViewed()
  }

  const convertNotificationsToPopMenuItems = (notifications: UserNotification[]): PopMenuItem[] => {
    const newPopMenuItems: PopMenuItem[] = notifications.map((n) => {
      const item: PopMenuItem = {
        id: String(n.id),
        viewed: n.data.viewed,
        date: n.data.createDate,
        customData: n.data.customData
      }

      return item
    })

    return newPopMenuItems
  }

  async function getNewNotifications(): Promise<UserNotification[]> {
    try {
      const newestNotificationDate: string =
        popMenuItems.length > 0 ? popMenuItems[0].date : new Date().toISOString()

      const response: UserNotification[] = await getUserNotifications(
        'GetNew',
        newestNotificationDate
      )

      if (response.length === 0) {
        return []
      }

      setPopMenuItems([...convertNotificationsToPopMenuItems(response), ...popMenuItems])

      return response
    } catch {
      return []
    }
  }

  async function getPriorNotifications(): Promise<UserNotification[]> {
    try {
      const oldestNotificationDate =
        popMenuItems.length > 0
          ? popMenuItems[popMenuItems.length - 1].date
          : new Date().toISOString()

      const response: UserNotification[] = await getUserNotifications(
        'GetPrior',
        oldestNotificationDate
      )

      if (response.length === 0) {
        return []
      }

      setPopMenuItems([...popMenuItems, ...convertNotificationsToPopMenuItems(response)])

      return response
    } catch {
      return []
    }
  }

  const handleLoadMoreNotifications = () => {
    getPriorNotifications()
  }

  const fetchNotificationsJob = async () => {
    const setFetchNotificationsMode = { ...fetchNotifications }

    if (fetchNotifications.mode === 'GetNew') {
      getNewNotifications()
      setFetchNotificationsMode.mode = 'GetNew'
    } else {
      const notifications = await getPriorNotifications()
      setFetchNotificationsMode.mode = notifications.length > 0 ? 'GetNew' : 'GetPrior'
    }

    setTimeout(() => {
      setFetchNotifications({ ...setFetchNotificationsMode })
    }, 300000)
  }

  useEffect(() => {
    fetchNotificationsJob()
  }, [fetchNotifications])

  return (
    <div>
      <IconButton onClick={handleMenu}>
        {popMenuItems?.some((i) => i.viewed === false) ? (
          <NotificationsActiveTwoTone style={{ color: colors(props.theme, Colors.white) }} />
        ) : (
          <NotificationsNoneRounded style={{ color: colors(props.theme, Colors.ghostWhite) }} />
        )}
      </IconButton>
      <Popover
        className={`popover-${props.theme}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {popMenuItems.length > 0 ? (
          <>
            <List component='nav' aria-labelledby='nested-list-subheader'>
              {popMenuItems?.map((item) => (
                <div key={item.id}>
                  <ListItem
                    alignItems='flex-start'
                    button
                    onClick={() => {
                      return ''
                    }}
                  >
                    <ListItemIcon>
                      {!item.viewed ? (
                        <NewReleases color={'primary'} />
                      ) : (
                        <Info color={'inherit'} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={popMenuItemTranslation(t, item, 'title')}
                      secondary={
                        <>
                          {popMenuItemTranslation(t, item, 'text')}
                          <br />
                          {new Date(item.date).toLocaleDateString()}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider variant='inset' component='li' />
                </div>
              ))}
            </List>
            <Button onClick={handleLoadMoreNotifications}>{t('load_more')}</Button>
          </>
        ) : (
          <Typography className='no-notifications'>{t('no_notifications')}</Typography>
        )}
      </Popover>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

export default withRouter(connect(mapStoreToProps)(NotificationButton))
