import React from 'react'
import { Button } from '@material-ui/core'
import './styles.scss'
import { DataType } from 'store/main/types'

interface Props {
  t: any
  selectedType: DataType
  handleTypeChange: (type: DataType) => void
}

const CardDataTypeButtons: React.FC<Props> = ({ t, selectedType, handleTypeChange }: Props) => {
  function handleClick(event: React.MouseEvent<HTMLElement>, type: DataType) {
    event.stopPropagation()
    handleTypeChange(type)
  }
  return (
    <div className={'type-button'}>
      <Button
        className={
          selectedType === DataType.ZOOTECHNICAL ? 'selected-card-type-button' : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          handleClick(event, DataType.ZOOTECHNICAL)
        }}
      >
        <span
          className={
            selectedType === DataType.ZOOTECHNICAL ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('report_zootechnical')}
        </span>
      </Button>
      <Button
        className={
          selectedType === DataType.ECONOMIC ? 'selected-card-type-button' : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, DataType.ECONOMIC)
        }}
      >
        <span
          className={selectedType === DataType.ECONOMIC ? 'selected-type-text' : 'type-button-text'}
        >
          {t('report_economic')}
        </span>
      </Button>
      <Button
        className={
          selectedType === DataType.ENVIRONMENTAL ? 'selected-card-type-button' : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, DataType.ENVIRONMENTAL)
        }}
      >
        <span
          className={
            selectedType === DataType.ENVIRONMENTAL ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('report_environmental')}
        </span>
      </Button>
      {/* <Button
        className={
          selectedType === DataType.CUSTOM ? 'selected-card-type-button' : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, DataType.CUSTOM)
        }}
      >
        <span
          className={selectedType === DataType.CUSTOM ? 'selected-type-text' : 'type-button-text'}
        >
          {t('report_custom')}
        </span>
      </Button> */}
    </div>
  )
}

export default CardDataTypeButtons
