import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { useTranslation } from 'react-i18next'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'

// Resources
import ChartIcon from 'resources/img/icons/beeftrader-chart-icon'
import './styles.scss'

// Types
import { ApplicationStore } from 'store/types'
import { LotQueryFields } from 'api/lot/types'
import { AlertModalTypes, DataType, InfoModal } from 'store/main/types'

// Methods
import { getLots as getLotsApi } from 'api/lot'
import colors, { Colors } from 'styles/variables/colors'

// Components
import HelpButton from 'components/shared/HelpButton'
import MenuItem from '@material-ui/core/MenuItem'
import ZootechnicalChart from 'components/charts/ZootechnicalChart'
import { setDataType, setInfoModalMessage } from 'store/main/actions'
import { useHistory } from 'react-router-dom'
import { DataChart } from './types'
import {
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  FormControl,
  Button
} from '@material-ui/core'

const ZootechnicalPerformanceCard: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { selectedFeedlot } = useSelector((state: ApplicationStore) => state.feedlot)

  const lotsPageSize = 8
  const [lotsSortingField, setLotsSortingField] = useState<string>('lot')
  const [currentLotsPage, setCurrentLotsPage] = useState<number>(1)
  const [totalLotsPage, setTotalLotsPage] = useState<number>(1)
  const [dataZootechnicalChart, setDataZootechnicalChart] = useState<DataChart[]>([])
  const [currentLots, setCurrentLots] = useState<DataChart[]>([])

  const lotQuery: LotQueryFields = {
    id: true,
    description: true,
    animalsCount: true,
    initDate: true,
    pen: {
      id: true,
      code: true
    },
    zootechnicalData: {
      current: {
        weight: true,
        dwg: true,
        predictedTime: true
      }
    }
  }

  useEffect(() => {
    if (!selectedFeedlot) {
      setDataZootechnicalChart([])
      return
    }

    async function getLots() {
      const response = await getLotsApi(lotQuery, {
        parameters: { feedlotId: selectedFeedlot?.id || 0 }
      })
      // setLots(response)
      const dataZootechnicalChartAux = []
      for (const lot of response) {
        const aux: DataChart = {
          lotId: lot.id,
          currentTime: lot.zootechnicalData?.current.predictedTime
            ? lot.zootechnicalData?.current.predictedTime
            : 0,
          lot: lot.description,
          dwg: lot.zootechnicalData?.current.dwg ? lot.zootechnicalData?.current.dwg : 0,
          weight: lot.zootechnicalData?.current.weight ? lot.zootechnicalData?.current.weight : 0
        }
        dataZootechnicalChartAux.push(aux)
      }
      setDataZootechnicalChart(dataZootechnicalChartAux)
      if (response.length === 0) {
        const infoModal: InfoModal = {
          type: AlertModalTypes.info,
          message: t('feedlot:none_active_lot_for_this_feedlot'),
          open: true
        }
        dispatch(setInfoModalMessage(infoModal))
      }
      setTotalLotsPage(Math.ceil(response.length / lotsPageSize) || 1)
      setCurrentLotsPage(1)
    }

    getLots()
  }, [selectedFeedlot])

  useEffect(() => {
    if (dataZootechnicalChart.length === 0) {
      setCurrentLots([])
    }

    setCurrentLots(filterCurrentLots(dataZootechnicalChart))
  }, [dataZootechnicalChart])

  useEffect(() => {
    if (dataZootechnicalChart.length === 0) {
      return
    }

    setCurrentLots(filterCurrentLots(dataZootechnicalChart))
  }, [lotsSortingField, currentLotsPage])

  const filterCurrentLots = (lots: DataChart[]) => {
    let orderedLots
    switch (lotsSortingField) {
      case 'lot':
        orderedLots = lots.sort((a, b) => (a.lot > b.lot ? 1 : -1))
        break
      case 'dwg':
        orderedLots = lots.sort((a, b) => ((a.dwg ?? 0) > (b.dwg ?? 0) ? -1 : 1))
        break
      case 'weight':
        orderedLots = lots.sort((a, b) => ((a.weight ?? 0) > (b.weight ?? 0) ? -1 : 1))
        break
      case 'initDate':
        orderedLots = lots.sort((a, b) => (a.currentTime < b.currentTime ? -1 : 1))
        break
      default:
        orderedLots = lots.sort((a, b) => (a.lot > b.lot ? 1 : -1))
        break
    }
    const offset = (currentLotsPage - 1) * lotsPageSize
    const pageLots = orderedLots.slice(offset, offset + lotsPageSize)
    return pageLots
  }

  const handleLotsSorting = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLotsSortingField(event.target.value as string)
  }

  const handleLotsPageSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentLotsPage(event.target.value as number)
  }

  const handleLotsNextPage = (event: React.MouseEvent<HTMLElement>) => {
    setCurrentLotsPage(
      event.currentTarget.getAttribute('aria-label') === 'nextPage'
        ? currentLotsPage + 1
        : currentLotsPage - 1
    )
  }

  const renderLotsPageNumbers = () => {
    const pageItems = []
    for (let page = 1; page <= totalLotsPage; page++) {
      pageItems.push(
        <MenuItem key={page} value={page} style={{ color: colors(theme, Colors.grayMedium) }}>
          {page}
        </MenuItem>
      )
    }
    return pageItems
  }

  function navigateToLotsPage() {
    if (selectedFeedlot) {
      dispatch(setDataType(DataType.ZOOTECHNICAL))
      history.push('/lots-summary')
    }
  }

  return (
    <div className={'card-container'}>
      <div className='card-icon-container'>
        <ChartIcon width={'3.5rem'} height={'3.5rem'} fill={colors(theme, Colors.accent)} />
      </div>
      <HelpButton infoText={'profit:all_feedlot_profit'} theme={theme} />
      <div className='card-body'>
        <div className='card-title-container'>
          <p className={'card-title'}>{t('zootechnical_data')}</p>
          <div className={'card-title-options'}>
            <FormControl id='lots-sort-select' size='small'>
              <InputLabel id='sort-lots-label'>{t('sort')}</InputLabel>
              <Select
                labelId='sort-lots-label'
                style={{ color: colors(theme, Colors.grayMedium) }}
                id='sort-lots-select'
                label={t('sort')}
                value={lotsSortingField}
                onChange={handleLotsSorting}
              >
                <MenuItem value={'lot'} style={{ color: colors(theme, Colors.grayMedium) }}>
                  {t('lot:lot')}
                </MenuItem>
                <MenuItem value={'initDate'} style={{ color: colors(theme, Colors.grayMedium) }}>
                  {t('start_date')}
                </MenuItem>
                <MenuItem value={'dwg'} style={{ color: colors(theme, Colors.grayMedium) }}>
                  {t('zootechnical:dwg')}
                </MenuItem>
                <MenuItem value={'weight'} style={{ color: colors(theme, Colors.grayMedium) }}>
                  {t('zootechnical:live_weight')}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl id='lots-page-select' size='small'>
              <InputLabel id='page-lots-label'>{t('page')}</InputLabel>
              <Select
                labelId='page-lots-label'
                id='page-lots-select'
                label={t('page')}
                style={{ color: colors(theme, Colors.grayMedium) }}
                value={currentLotsPage}
                onChange={handleLotsPageSelect}
                startAdornment={
                  <InputAdornment position='start'>
                    <IconButton
                      aria-label='prevPage'
                      size='small'
                      onClick={handleLotsNextPage}
                      disabled={currentLotsPage === 1}
                    >
                      <Icon
                        size={0.8}
                        color={colors(theme, currentLotsPage > 1 ? Colors.grayMedium : Colors.gray)}
                        path={mdiArrowLeft}
                      />
                    </IconButton>
                    <IconButton
                      aria-label='nextPage'
                      size='small'
                      onClick={handleLotsNextPage}
                      disabled={currentLotsPage === totalLotsPage}
                    >
                      <Icon
                        size={0.8}
                        color={colors(
                          theme,
                          currentLotsPage < totalLotsPage ? Colors.grayDark : Colors.gray
                        )}
                        path={mdiArrowRight}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              >
                {renderLotsPageNumbers()}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={'card-content'}>
          <ZootechnicalChart
            dataLot={currentLots}
            measurementUnitChart={'weight'}
          ></ZootechnicalChart>
        </div>
        <div className='card-footer'>
          <div className={'legend'}>
            <div className={'group-legend'}>
              <div
                className={'color-legend'}
                style={{ backgroundColor: colors(theme, Colors.accent) }}
              />
              <p className={'text-legend'}>
                {t('zootechnical:dwg')} ({t('weight_unity_kg').toLocaleLowerCase()}/
                {t('day').toLocaleLowerCase()})
              </p>
            </div>
            <div className={'group-legend'}>
              <div
                className={'color-legend'}
                style={{ backgroundColor: colors(theme, Colors.redDark) }}
              />
              <p className={'text-legend'}>
                {t('zootechnical:live_weight')} {t('current').toLocaleLowerCase()}
              </p>
            </div>
          </div>
        </div>
        <div className='zootechnical-footer'>
          <Button
            className='info-button'
            onClick={() => {
              navigateToLotsPage()
            }}
          >
            <p className='text-info-button'>{t('more_information')}</p>
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ZootechnicalPerformanceCard
