import i18next from 'i18next'
import React from 'react'

export const CustomTooltip: any = ({ active, payload }: any): any => {
  if (active && payload && payload.length) {
    return (
      <div className='tooltip-container'>
        <p>
          {i18next.t('week')} {payload[0].payload.week}:
        </p>
        <p>
          {payload[0].payload.frequency}% {i18next.t('chart:acess_index_tootip')}
        </p>
      </div>
    )
  }
  return null
}
