import produce from 'immer'
import { AnyAction } from 'redux'
import { LotWeighingDataType } from 'store/main/types'

import { LotStore, LotConstants } from './types'

const INITIAL_STORE: LotStore = {
  lots: [],
  selectedLot: undefined,
  lotLoading: false,
  lotWeighingDataType: LotWeighingDataType.CHARTS,
  lotAccessIndexLoading: false,
  lotAccessIndex: undefined,
  feedlotWeeksNumber: 30
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case LotConstants.REDUCER_SET_LOT_DATA:
        Object.assign(draft, payload)
        break
      case LotConstants.REDUCER_CLEAR_LOT_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
