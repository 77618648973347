import {
  FeedlotSlaughterhousePriceQueryParams,
  FeedlotSlaughterhousePriceMutationArgs,
  CurrencyQueryParams,
  WeightUnityQueryParams,
  QuoteSlaughterhousePriceQueryParams
} from 'api/economic/types'
import { EconomicConstants } from './types'

export const getFeedlotSlaughterhousePrice = (params?: FeedlotSlaughterhousePriceQueryParams) => ({
  type: EconomicConstants.ACTION_GET_FEEDLOT_SLAUGHTERHOUSE_PRICE,
  payload: { params }
})

export const getQuoteSlaughterhousePrice = (params?: QuoteSlaughterhousePriceQueryParams) => ({
  type: EconomicConstants.ACTION_GET_QUOTE_SLAUGHTERHOUSE_PRICE,
  payload: { params }
})

export const saveFeedlotSlaughterhousePrice = (args: FeedlotSlaughterhousePriceMutationArgs) => ({
  type: EconomicConstants.ACTION_SAVE_FEEDLOT_SLAUGHTERHOUSE_PRICE,
  payload: { args }
})
export const deleteFeedlotSlaughterhousePrice = (ids: number[]) => ({
  type: EconomicConstants.ACTION_DELETE_FEEDLOT_SLAUGHTERHOUSE_PRICE,
  payload: { ids }
})

export const getCurrency = (params?: CurrencyQueryParams) => ({
  type: EconomicConstants.ACTION_GET_CURRENCY,
  payload: { params }
})

export const getWeightUnity = (params?: WeightUnityQueryParams) => ({
  type: EconomicConstants.ACTION_GET_WEIGHTUNITY,
  payload: { params }
})
