import { all, takeLatest } from 'redux-saga/effects'

// Types
import { FeedlotConstants } from './types'

// Methods
import { workerGetFeedlotNextSales, workerGetSelectedFeedlots } from './sagas'

export function* watcherGetSelectedFeedlots() {
  yield takeLatest(FeedlotConstants.ACTION_GET_SELECTED_FEEDLOT, workerGetSelectedFeedlots)
}

export function* watcherGetFeedlotNextSales() {
  yield takeLatest(FeedlotConstants.ACTION_GET_FEEDLOT_NEXT_SALES, workerGetFeedlotNextSales)
}

export default function* feedlotWatchers() {
  yield all([watcherGetSelectedFeedlots(), watcherGetFeedlotNextSales()])
}
