import gql from 'graphql-tag'
import store from 'store'

// Classes
import API from 'api'
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import { Lot } from 'store/lot/types'
import { LotQueryFields, LotQueryArgs, LotMutationFields } from './types'
import QueryBuilder from 'helpers/QueryBuilder'
import { StorageKeys } from 'storage/types'
import { validateAuthentication } from 'api/auth'
import { getItemFromStorage } from 'storage'

export async function getLots(queryFields: LotQueryFields, queryArgs: LotQueryArgs) {
  const QUERY = gql`
    query QUERY($parameters: LotParameters!) {
      lot(parameters: $parameters) ${QueryBuilder(queryFields)}
    }
  `
  try {
    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const response = await API.query(QUERY, queryArgs, credentials.token)
    const { data, errors } = response
    if (!data || errors) {
      if (errors) {
        const { statusCode } = errors[0]
        throw new Exception(
          statusCode || Codes.Internals.UNEXPECTED_ERROR,
          'api/lot/getLots',
          errors
        )
      }
      throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lot/getLot', errors)
    }

    return data.lot as Lot[]
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/animal/getFeedlotsRequest', error)
  }
}

export const createLot = async (lot: LotMutationFields): Promise<number> => {
  try {
    const MUTATION = gql`
      mutation MUTATION($lot: CreateLot!) {
        createLot(lot: $lot)
      }
    `

    const { credentials } = store.getState().auth
    const refreshToken = String(getItemFromStorage(StorageKeys.RefreshToken))
    await validateAuthentication({ refreshToken, credentials })
    const { errors, data } = await API.mutate(MUTATION, { lot: { ...lot } }, credentials.token)
    if (errors) {
      const { statusCode } = errors[0]
      throw new Exception(
        statusCode || Codes.Internals.UNEXPECTED_ERROR,
        'api/lot/createLot',
        errors
      )
    }
    return data.createLot as number
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lot/createLot', error)
  }
}
