export enum LotConstants {
  ACTION_GET_SELECTED_LOT = 'ACTION_GET_SELECTED_LOT',
  REDUCER_CLEAR_LOT_DATA = 'REDUCER_CLEAR_LOT_DATA',
  ACTION_GET_LOT = 'ACTION_GET_LOT',
  REDUCER_SET_LOT_DATA = 'REDUCER_SET_LOT_DATA'
}
export type PenStore = {
  pens: Pen[]
  selectedPen?: Pen
  penLoading: boolean
}
export type Pen = {
  id: number
  feedlotId: number
  code: string
  lot: LotsSummary[]
}
export type LotsSummary = {
  id: number
  description: string
}
export enum PenConstants {
  ACTION_GET_SELECTED_PEN = 'ACTION_GET_SELECTED_PEN',
  REDUCER_CLEAR_PEN_DATA = 'REDUCER_CLEAR_PEN_DATA',
  ACTION_GET_PEN = 'ACTION_GET_PEN',
  REDUCER_SET_PEN_DATA = 'REDUCER_SET_PEN_DATA'
}
