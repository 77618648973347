/* eslint-disable prettier/prettier */
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

type Props = {
  title: string
}

const Tab: React.FC<Props> = ({ children }) => {
  return <div>{children}</div>
}

const TabTitle: React.FC<Props> = ({ title}) => {

  return (
    <li>
      <button>{title}</button>
    </li>
  )
}
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

 // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
 const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }


export {Tab,TabTitle, TabPanel, a11yProps}
