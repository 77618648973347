import React from 'react'
import { Route } from 'react-router-dom'

import Contact from 'pages/Contact'
import RecoverPassword from 'pages/RecoverPassword'

const PublicRoutes = [
  <Route key='/recover-password/' exact path='/recover-password/'>
    <RecoverPassword />
  </Route>,
  <Route key='/recover-password/token=:token' exact path='/recover-password/token=:token'>
    <RecoverPassword />
  </Route>,
  <Route key='/contact' exact path='/contact'>
    <Contact />
  </Route>
]

export default PublicRoutes
